import type { Weapon } from './Weapon.type'
import { WeaponTypeId } from './WeaponTypeId.enum'

export default function createInfantryMachineGunWeapon(str: number): Weapon {
  return {
    wt_id: WeaponTypeId.InfantryMachineGun,
    str,
    ca: true,
  }
}
