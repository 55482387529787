import type { Accessor, Setter } from 'solid-js'
import createDefaultWait from './createDefaultWait'

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type AnyFn = (...args: any[]) => any

export default function withBusy<T extends AnyFn>(
  isBusy: Accessor<boolean>,
  setIsBusy: Setter<boolean>,
  fn: T
) {
  return async (...args: Parameters<T>): Promise<ReturnType<T> | undefined> => {
    const dw = createDefaultWait(100)
    if (isBusy()) {
      return undefined
    }
    try {
      setIsBusy(true)
      return await fn(...args)
    } finally {
      await dw
      setIsBusy(false)
    }
  }
}
