import type { Tables } from '@sg/shared/src/supabase/database'
import { Accessor, Setter, createEffect, createSignal, on, onCleanup } from 'solid-js'
import { backendAxios } from '../../../axios'
import type { Nullable } from '../../../typescript'
import toastError from '../../AlertBag/toastError'

type FileId = Tables<'files'>['id']
type FileBlobString = string

// no idea why, but not manually specifying this type caused linting errors
type PathToObjectUrlSignal = [Accessor<Nullable<FileBlobString>>, Setter<Nullable<FileId>>]

/**
 * this is an unusual signal,
 * it's actually 2 signals
 * but the second is a derived async value of the first
 */
export default function createFileIdToObjectUrlSignal(
  initialValue: FileId | null = null
): PathToObjectUrlSignal {
  const [fileId, setFileId] = createSignal<Nullable<FileId>>(initialValue)
  const [fileBlob, setFileBlob] = createSignal<Nullable<FileBlobString>>(null)

  createEffect(() => {
    const id = fileId()
    if (id) {
      downloadImage(id)
    } else {
      setFileBlob(null)
    }
  })

  const downloadImage = async (fileId: FileId) => {
    // console.log(path)
    try {
      // /storage/v1/object/avatars/0.9046106501494644.jpg
      const response = await backendAxios.get(`/file/${fileId}/data`, {
        responseType: 'blob',
      })
      // const response = await supabaseAxios.get(`/storage/v1/object/${bucket}/${path}`, {
      //   responseType: 'blob',
      // })
      const url = URL.createObjectURL(response.data)
      setFileBlob((prev) => {
        if (prev) {
          URL.revokeObjectURL(prev)
        }
        return url
      })
    } catch (error) {
      console.error(error)
      toastError(error)
    }
  }

  onCleanup(() => {
    const blobUrl = fileBlob()
    if (blobUrl) {
      URL.revokeObjectURL(blobUrl)
    }
  })
  createEffect(on(fileBlob, (
    newBloblUrl: Nullable<FileBlobString>,
    oldBlobUrl: Nullable<FileBlobString>) => {
    if (oldBlobUrl) {
      URL.revokeObjectURL(oldBlobUrl)
    }
  }))

  return [fileBlob, setFileId]
}
