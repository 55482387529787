import { modifyMutable, produce } from 'solid-js/store'
import calculateDraftMoveAttackEstimates from '../../core/draft_move/calculateDraftMoveAttackEstimates'
import { Engine } from '../../core/engine/Engine.type'
import { positionFromPixels } from '../../core/tile_position_xy/positionFromPixels'
import { samePosition } from '../../core/tile_position_xy/samePosition'
import { floor } from '../../core/util/math'
import onWindowEvent from '../../onWindowEvent'
import calculateDestPositionHoverPositionPaths from '../../core/draft_move/calculateDestPositionHoverPositionPaths'

const { now } = Date

export default function registerMouseMoveEvent(engine: Engine) {
  // const dirty = engine.cache[1]
  function onMouseMove(event: MouseEvent) {
    modifyMutable(
      engine,
      produce((engine: Engine) => {
        const { viewCtx, state } = engine
        // console.log('onMouseMove', event)
        // console.log('onMouseMove', event.button, event.buttons)

        // const isMap = wasEventTargetMap(event)
        const { offsetX, offsetY } = event
        const devicePixelRatio = window.devicePixelRatio || 1
        engine.lastMouseMoveEvent = event

        const position = positionFromPixels(state, viewCtx, offsetX, offsetY)
        if (!samePosition(engine.hoveredPosition, position)) {
          engine.hoveredPosition = position
          calculateDestPositionHoverPositionPaths(engine)
          calculateDraftMoveAttackEstimates(engine)
        }
        // console.log('hoveredPosition', engine.hoveredPosition)
        if (event.buttons === 1) {
          // console.log('MouseMove.event.buttons === 1')
          viewCtx.pan_x_f += event.movementX / devicePixelRatio
          viewCtx.pan_y_f += event.movementY / devicePixelRatio
          viewCtx.pan_x = floor(viewCtx.pan_x_f)
          viewCtx.pan_y = floor(viewCtx.pan_y_f)
          viewCtx.lastPanAt = now()
          // dirty(EngineCache.ViewCtx)
        }
        if (engine.mouseDownEvent && engine.mouseDragList) {
          engine.mouseDragList.push(event)
          // triggerRef(engine.mouseDragList)
        }
        // if (isMap && engine.mouseDownEvent) {
        // dirty(EngineCache.Cursor)
        // }
      })
    )
  }

  onWindowEvent('mousemove', onMouseMove)

  // const names = ['touchstart', 'touchmove', 'touchend']
  // function logEventWithName(event: TouchEvent) {
  //   console.log(event.type, event)
  // }
  // names.forEach((name: string) => onWindowEvent(name, logEventWithName))
}
