import { InferOutput, nullable, number, optional } from 'valibot'

export const EntityIdSchema = number()

export type EntityId = InferOutput<typeof EntityIdSchema>

export const NullableEntityIdSchema = nullable(EntityIdSchema)

export type NullableEntityId = InferOutput<typeof NullableEntityIdSchema>

export const OptionalNullableEntityIdSchema = optional(NullableEntityIdSchema)

export type OptionalNullableEntityId = InferOutput<typeof OptionalNullableEntityIdSchema>
