import type { Nullable } from "../../../typescript"
import type { UnloadableCargoTile } from "../tile_position_xy/findUnloadableCargoTileCollection"
import { samePosition } from "../tile_position_xy/samePosition"
import type { TilePositionXY } from "../tile_position_xy/TilePositionXY.type"
import type { DraftMove } from "./DraftMove.type"

export default function getSelectedOrHoveredUnloadCargoTileForIcon (draftMove: DraftMove, hoveredPosition: Nullable<TilePositionXY>) {
  // console.log('getUnloadableCargoPathForIcon', deepClone({
  //   unloadPosition: draftMove.unloadPosition,
  //   hoveredPosition,
  // }))
  const iconPosition = draftMove.unloadPosition || hoveredPosition
  const ucpGroup = draftMove.unload
  if (iconPosition && ucpGroup) {
    return ucpGroup.paths.find((ucPath : UnloadableCargoTile) => samePosition(iconPosition, ucPath))
  }
}