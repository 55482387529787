import { array, InferOutput, literal, object, parse } from 'valibot'
import { ArmorSchema } from '../../Armor.type'
import { AttackedThisTurnSchema } from '../../AttackedThisTurn'
import { entityTypeDefaults, EntityTypeMeta } from '../../EntityTypeMeta'
import { FuelSchema } from '../../fuel.type'
import { HasMobilitySchema } from '../../has_mobility'
import { HasXYSchema } from '../../has_xy'
import { HPSchema } from '../../hp.type'
import { PlayerIdOrNullSchema } from '../../player/PlayerIdOrNull'
import { VisionSchema } from '../../vision.type'
import { WasBuiltThisTurnSchema } from '../../WasBuiltThisTurn'
import { ArmorTypeId } from '../Armor/ArmorTypeId.enum'
import { EntityLayerId } from '../entity_layer_id.enum'
import { EntityTypeId } from '../entity_type_id.enum'
import { EntityIdSchema } from '../EntityId.type'
import { MoveTypeId } from '../move_type_id.enum'
import createMediumTankCannonWeapon from '../Weapon/createMediumTankCannonWeapon'
import createVehicleMachineGunWeapon from '../Weapon/createVehicleMachineGunWeapon'
import { WeaponSchema } from '../Weapon/Weapon.type'
import { BaseEntitySchema } from '../BaseEntity'

const entDefaults = {
  etype_id: EntityTypeId.MediumTank,
  layer_id: EntityLayerId.Unit2,
  hp: 10,
  fuel: 50,
  vision: 2,
  mtype_id: MoveTypeId.Treads,
  mobility: 5,
  armor: 12,
} as const

function defaultWeapons() {
  return [createVehicleMachineGunWeapon(4), createMediumTankCannonWeapon(12)]
}

export const MediumTankEntitySchema = object({
  ...BaseEntitySchema.entries,
  etype_id: literal(EntityTypeId.MediumTank),
  layer_id: literal(EntityLayerId.Unit2),
  id: EntityIdSchema,
  player_id: PlayerIdOrNullSchema,
  hp: HPSchema,
  fuel: FuelSchema,
  vision: VisionSchema,
  armor: ArmorSchema,
  ...HasXYSchema.entries,
  ...HasMobilitySchema.entries,
  builtThisTurn: WasBuiltThisTurnSchema,
  attackedThisTurn: AttackedThisTurnSchema,
  weapons: array(WeaponSchema),
})

export type MediumTankEntity = InferOutput<typeof MediumTankEntitySchema>

export const MediumTankEntityTypeMeta: EntityTypeMeta = {
  ...entityTypeDefaults,
  id: EntityTypeId.MediumTank,
  dname: 'Medium Tank',
  layer_id: EntityLayerId.Unit2,
  entDefaults,
  defaultWeapons,
  price: 160,
  armor_type: ArmorTypeId.Tank,
}

export function createMediumTankEntity(
  id: number,
  x: number,
  y: number,
  player_id: number | null
): MediumTankEntity {
  return parse(MediumTankEntitySchema, {
    ...entDefaults,
    weapons: defaultWeapons(),
    id,
    x,
    y,
    player_id,
  })
}
