import { A, useLocation } from "@solidjs/router"
import { FaSolidRightToBracket } from "solid-icons/fa"
import { Component, createMemo } from "solid-js"

const SignInButton: Component = () => {
  const location = useLocation()

  const href = createMemo<string>(() => {
    const return_to = location.pathname + location.search
    return `/auth/sign-in?return_to=${encodeURIComponent(return_to)}`
  })

  return (
    <A href={href()} class="btn btn-success btn-sm">
      <FaSolidRightToBracket /> Sign In
    </A>
  )
}

export default SignInButton