import { Component } from "solid-js"
import type { Player } from "../../lib/core/player/Player.type"
import { playerKindIconsMap, playerKindTextMap } from "../../lib/core/player/PlayerKind.enum"

interface PlayerKindIconProps {
  player: Player
}

const fallbackText = 'Unknown'

const PlayerKindIcon: Component<PlayerKindIconProps> = (props) => {
  return (
    <span
      title={playerKindTextMap[props.player.kind] || fallbackText}
      aria-label={playerKindTextMap[props.player.kind] || fallbackText}
    >
      {playerKindIconsMap[props.player.kind] || '❓'}
    </span>
  )
}

export default PlayerKindIcon
