import type { Nullable } from "../../../../typescript"
import type { PathTurnSteps_Step } from "./PathTurnSteps_Step.type"
import type { PathTurnSteps_Turn } from "./PathTurnSteps_Turn.type"

export function getTurnsLastStep(turns: Nullable<Array<PathTurnSteps_Turn>>): PathTurnSteps_Step | null {
  if (!turns) return null
  // Check if there are any turns in the path
  if (!turns.length) {
    return null // No turns, return null
  }

  // Get the last turn
  const lastTurn = turns[turns.length - 1]

  const { steps } = lastTurn

  // Check if there are any steps in the last turn
  if (!steps.length) {
    return null // No steps in the last turn, return null
  }

  // Get the last step in the last turn
  return steps[steps.length - 1]
}
