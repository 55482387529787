import { MAX_I32 } from "./util/number"

export type LayerMeta = {
  /**
   * the timestamp when this layer's animation(s)
   * need to switch to their next frame
   */
  until: number,
  /**
   * the current timestamp
   * as of when a frame is being rendered
   */
  ts: number
}

// A default "don't render until" value
// 2147483647 = is approx 24 days
// this will make most animation expirations
// "opt-in"
export const EXPIRE_NEVER = MAX_I32