import { onCleanup, onMount } from 'solid-js'

export default function createMountedInverval(fn: () => void, time: number | undefined) {
  let interval: NodeJS.Timeout
  onMount(() => {
    interval = setInterval(fn, time)
  })
  onCleanup(() => {
    clearInterval(interval)
  })
}
