import { PaintTileOptionFolderId } from "./paint_tile_option_folder_id.enum"


export type PaintTileOptionFolder = {
  id: PaintTileOptionFolderId
  dname: string
}

function makePaintTileOptionFolder(id: PaintTileOptionFolderId, dname: string): PaintTileOptionFolder {
  return { id, dname }
}

export const paintTileOptionFolders: Readonly<Array<PaintTileOptionFolder>> = [
  // makePaintTileOptionFolder(PaintTileOptionFolderId.None, 'None'),
  makePaintTileOptionFolder(PaintTileOptionFolderId.T1, 'Terrain L1'),
  makePaintTileOptionFolder(PaintTileOptionFolderId.T2, 'Terrain L2'),
  makePaintTileOptionFolder(PaintTileOptionFolderId.Building, 'Building'),
  makePaintTileOptionFolder(PaintTileOptionFolderId.Road, 'Road'),
  makePaintTileOptionFolder(PaintTileOptionFolderId.Unit, 'Unit'),
]

export type PaintTileOptionFolderIndex = keyof typeof paintTileOptionFolders