import { floor, log, pow } from "./core/util/math"

const suffixes = ['B', 'KB', 'MB', 'GB', 'TB'] as const

/**
 * 
 * @link https://stackoverflow.com/a/71987097
 * @param bytes 
 * @returns 
 */
export const humanFileSize = (bytes: number): `${number} ${'B' | 'KB' | 'MB' | 'GB' | 'TB'}` => {
  const index = floor(log(bytes) / log(1024))
  return `${Number((bytes / pow(1024, index)).toFixed(2)) * 1} ${(suffixes)[index]}`
}