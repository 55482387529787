import { check, InferOutput, maxLength, minLength, pipe, string } from 'valibot'

export const OTPSchema = pipe(
  string(),
  minLength(6, 'OTP must be at least 6 characters'), // Minimum length for security
  maxLength(6, 'OTP must not exceed 6 characters'), // Fixed length if OTPs are standardized
  check((value) => /^[0-9]+$/.test(value), 'OTP must be numeric') // Restrict to numeric characters
)

export type OTP = InferOutput<typeof OTPSchema>
