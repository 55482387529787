import type { MoveCostPair } from './MoveCostPair.type'
import type { EntityTypeId } from './entity_type_id.enum'
import { moveCostList } from './moveCostList'
import type { MoveTypeId } from './move_type_id.enum'

export default function getMoveCostPair(
  etype_id: EntityTypeId,
  mtype_id: MoveTypeId
): MoveCostPair | null {
  const len = moveCostList.length
  for (let index = 0; index < len; index++) {
    const mcp = moveCostList[index]
    if (mcp.etype_id === etype_id && mcp.mtype_id === mtype_id) {
      return mcp
    }
  }
  // the MoveTypeId cannot traverse this EntityTypeId
  return null
}
