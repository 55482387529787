import { createEffect, on } from "solid-js"
import type { Engine } from "../../../core/engine/Engine.type"
import type { LayerMeta } from "../../../core/layer_meta"
import { getCanvas2dContext } from "../../get_canvas_2d_context"

export default function createRenderMoveTileRedCanvasBuffer (engine : Engine, lm: LayerMeta, buffer: OffscreenCanvas) {
  createEffect(on(() => engine.viewCtx.tile_size, (tile_size: number) => {
    buffer.width = tile_size
    buffer.height = tile_size
    lm.until = 0

    const ctx = getCanvas2dContext(buffer)
    if (ctx) {

      ctx.clearRect(0, 0, tile_size, tile_size)

      {
        const gradient = ctx.createLinearGradient(0, 0, tile_size, tile_size)
        addColorStops1(gradient)
        ctx.fillStyle = gradient
        ctx.fillRect(0, 0, tile_size, tile_size)
      }

      {
        const gradient = ctx.createLinearGradient(0, tile_size, tile_size, 0)
        addColorStops1(gradient)
        ctx.fillStyle = gradient
        ctx.fillRect(0, 0, tile_size, tile_size)
      }
    }
  }))
}

function addColorStops1(gradient: CanvasGradient) {
  gradient.addColorStop(0, `rgba(255, 0, 0, 0.8)`)
  gradient.addColorStop(0.1, `rgba(255, 0, 127, 0.6)`)
  gradient.addColorStop(0.38, `rgba(255, 127, 127, 0.2)`)
  gradient.addColorStop(0.42, `rgba(255, 127, 127, 0.05)`)
  gradient.addColorStop(0.6, `rgba(255, 127, 127, 0.2)`)
  gradient.addColorStop(0.1, `rgba(255, 0, 127, 0.6)`)
  gradient.addColorStop(1, `rgba(255, 0, 0, 0.8)`)
}