import { floor } from '../util/math'
import { RNGConfig } from './RNGConfig.type'

export function createNewRNGConfig(): RNGConfig {
  return {
    str: 0,
    crit: 0,
    seed: 0,
    state: 0,
  }
}

// @todo copy more functions from
// @link https://github.com/jhermsmeier/rng.js/blob/master/rng.js

// @link http://indiegamr.com/generate-repeatable-random-numbers-in-js/
export function next(rng: RNGConfig): number {
  rng.state = (rng.state * 9301 + 49297) % 233280
  return rng.state / 233280
}

// (0, 4) can produce both 0 and 3.999... as outputs, but probably not 4
export function nextNumberRange(min: number, max: number, rng: RNGConfig): number {
  return min + next(rng) * (max - min)
}

// (0, 4) can produce both 0 and 4 as outputs
export function nextIntRange(min: number, max: number, rng: RNGConfig): number {
  return floor(min + next(rng) * (max + 1 - min))
}

export function sample<T>(array: Readonly<Array<T>>, rng: RNGConfig): T | null {
  const index = floor(nextIntRange(0, array.length - 1, rng))
  return array[index]
}

export function popSample<T>(array: Array<T>, rng: RNGConfig): T | null {
  const index = floor(nextIntRange(0, array.length - 1, rng))
  return array.splice(index, 1)[0]
}

// @link https://codegolf.stackexchange.com/a/45579
export function shuffleArray<T>(array: Array<T>, rng: RNGConfig): void {
  const len: number = array.length
  let j: number
  array.map((v, index) => {
    array[((array[index] = array[(j = 0 | (index + next(rng) * (len - index)))]), j)] = v
  })
}
