import { InferOutput } from 'valibot'
import { OptionalLiteralTrueSchema } from './LiteralTrue'

export const WasBuiltThisTurnSchema = OptionalLiteralTrueSchema

export type WasBuiltThisTurn = InferOutput<typeof WasBuiltThisTurnSchema>

export type HasWasBuiltThisTurn = {
  builtThisTurn?: WasBuiltThisTurn
}
