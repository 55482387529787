import type { Weapon } from './Weapon.type'
import { WeaponTypeId } from './WeaponTypeId.enum'

const maxAmmo = 9

export default function createArtillaryCannonWeapon(str: number): Weapon {
  return {
    wt_id: WeaponTypeId.ArtillaryCannon,
    str,
    maxAmmo,
    ammo: maxAmmo,
    ammops: 1,
    minRange: 2,
    maxRange: 3,
  }
}
