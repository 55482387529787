import { InferOutput, maxValue, minValue, number, pipe } from 'valibot'

const MIN_ANNEX_POINTS_VALUE = 0
const MAX_ANNEX_POINTS_VALUE = 255

export const AnnexPointsSchema = pipe(
  number(),
  minValue(MIN_ANNEX_POINTS_VALUE),
  maxValue(MAX_ANNEX_POINTS_VALUE)
)

export type AnnexPoints = InferOutput<typeof AnnexPointsSchema>
