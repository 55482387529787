import { type ParentComponent } from "solid-js"

const AlertDanger: ParentComponent = (props) => {
  return <div class="alert alert-danger" role="alert" aria-label="danger alert">
      <div>
        {props.children}
      </div>
    </div>
}

export default AlertDanger