import { InferOutput, maxValue, minValue, number, pipe } from 'valibot'
import { MAX_I16, MIN_I16 } from '../util/number'

export const MIN_PLAYER_MONEY_VALUE = MIN_I16
export const MAX_PLAYER_MONEY_VALUE = MAX_I16

// coerce string to number?
// v.pipe(v.unknown(), v.transform(Number));

export const PlayerMoneySchema = pipe(
  number(),
  minValue(MIN_PLAYER_MONEY_VALUE),
  maxValue(MAX_PLAYER_MONEY_VALUE)
)

export type PlayerMoney = InferOutput<typeof PlayerMoneySchema>
