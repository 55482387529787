import { abs } from '../util/math'
import { TilePositionXY } from './TilePositionXY.type'

const minRange = 1

// Does p1 occupy the 8 surrounding tiles of p2?
export default function isConwayAdjacent(
  p1: TilePositionXY | null,
  p2: TilePositionXY | null
): boolean {
  // both values must not be null
  if (p1 && p2) {
    // same object is not a type of adjacency
    if (p2 === p1) return true
    // same position is not a type of adjacency
    // and likely was checked first
    if (p1.x == p2.x && p1.y == p2.y) return false

    // Check if p1 is within 1 tile of p2 in both x and y directions
    if (abs(p1.x - p2.x) <= minRange && abs(p1.y - p2.y) <= minRange) {
      return true
    }
  }
  return false
}
