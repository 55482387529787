import { array, nullable, optional, type InferOutput } from 'valibot'
import { TilePositionXYSchema } from './TilePositionXY.type'

export const TilePositionXYListSchema = array(TilePositionXYSchema)

export type TilePositionXYList = InferOutput<typeof TilePositionXYListSchema>

export const NullableTilePositionXYListSchema = nullable(TilePositionXYListSchema)

export type NullableTilePositionXYList = InferOutput<typeof NullableTilePositionXYListSchema>

export const OptionalNullableTilePositionXYListSchema = optional(NullableTilePositionXYListSchema)

export type OptionalNullableTilePositionXYList = InferOutput<typeof OptionalNullableTilePositionXYListSchema>
