import type { HasAnnexPoints } from '../HasAnnexPoints.type'
import type { HasPlayerId } from '../player/has_player_id'
import type { HasTaxiID } from './HasTaxiID'
import type { Entity } from './index'
import isWeaponUsable from './isWeaponUsable'

export default function canUnitDirectAttackEntity(
  unit: Entity,
  entity: Entity
): entity is Entity & HasAnnexPoints {
  const { player_id: unitPlayerId } = unit as Entity & HasPlayerId
  // units inside of a taxi cannot be targeted or attacked
  if ((entity as HasTaxiID).taxi_id) {
    return false
  }
  if (unitPlayerId && unitPlayerId !== (entity as HasPlayerId).player_id) {
    if ('hp' in entity) {
      if ('weapons' in unit && unit.weapons) {
        for (let index = 0; index < unit.weapons.length; index++) {
          const weapon = unit.weapons[index]
          if (isWeaponUsable(weapon)) {
            const maxRange = weapon.maxRange
            if (maxRange) {
              continue
            }
            return true
          }
        }
      }
    }
  }
  return false
}
