import type { ArmorTypeId } from './entity/Armor/ArmorTypeId.enum'
import { EntityLayerId } from './entity/entity_layer_id.enum'
import { EntityTypeId } from './entity/entity_type_id.enum'
import type { Weapon } from './entity/Weapon/Weapon.type'
// import { SpriteFolderId } from './sprite_folder'
import { BASE_TILE_SIZE } from './view_ctx'

export type EntityTypeMeta = {
  id: EntityTypeId
  dname: string
  layer_id: EntityLayerId
  ox: number // offset x
  oy: number // offset y
  h: number // tile height
  w: number // tile width
  price?: number
  defense?: number
  income?: number
  fuelPerDay?: number // default zero
  /**
   * APC, transport copter
   * maybe a hovercraft could carry light vehicals
   * maybe a transport ship/lander could carry medium
   */
  transports?: Array<EntityTypeId>
  // Forest, Mountain, Reef, Mist, Ruins
  blocksVision: boolean
  // Mountain + 3, Barren -1
  visionBonus: number
  /**
   * default values for a new entity of this type
   */
  entDefaults: object
  resupplies?: true
  /**
   * rules could be a seperate object, like:
   * { etype_id, rule: Rule.GRANTS_DEFENSE, value: 2 }
   * { etype_id, rule: Rule.RESUPPLIES_IF_OWNED, value: 2 }
   */
  // IDK:
  // /**
  //  * this entity will grant bonuses to units
  //  */
  // terrain?: {
  //   /**
  //    * units recieve defense bonus
  //    */
  //   defense?: number,
  //   /**
  //    * player recieves X tiles of FOW from building
  //    */
  //   sight?: number,
  //   /**
  //    * owned building provides X HP per turn
  //    */
  //   repair?: number,
  //   /**
  //    * owned building provides X supply per turn
  //    */
  //   resupply?: number,
  // },
  // unit?: {
  //   /**
  //    * player recieves X tiles of FOW from unit
  //    */
  //   sight?: number,
  //   /**
  //    * unit's default resupply strength
  //    * TODO: how does PC resupply work?
  //    */
  //   // resupply: number,
  // },
  // // folder_id: SpriteFolderId,
  // 0 to 200
  defaultWeapons?: () => Array<Weapon>
  armor_type?: ArmorTypeId
  cargoLimit?: number
}

export const entityTypeDefaults = {
  ox: 0,
  oy: 0,
  h: BASE_TILE_SIZE,
  w: BASE_TILE_SIZE,
  // // folder_id: SpriteFolderId.None,
  blocksVision: false,
  visionBonus: 0,
} as const

export function isSpriteUnit(sprite: EntityTypeMeta) {
  return sprite.id >= EntityTypeId.Infantry
}

export function isSpriteTerrain(sprite: EntityTypeMeta) {
  return sprite.id >= EntityTypeId.Plain && sprite.id < EntityTypeId.Infantry
}
