import { Engine } from '../../core/engine/Engine.type'
import onWindowEvent from '../../onWindowEvent'

export default function registerKeyDownEvent(engine: Engine) {
  function onKeyDown(event: KeyboardEvent) {
    // console.log('onKeyDown', event)
    engine.keydownMap[event.key] = event
  }

  onWindowEvent('keydown', onKeyDown)
}
