import type { TilePositionXY } from '../tile_position_xy/TilePositionXY.type'
import type { Entity } from './index'

export default function getEntitiesAtPosition(
  ents: Array<Entity>,
  position: TilePositionXY
): Array<Entity> {
  return ents.filter((ent: Entity) => {
    return ent.x === position.x && ent.y === position.y
  })
}
