import { onCleanup, onMount } from 'solid-js'
import removeItem from '../array/remove_item'
import { Engine } from '../core/engine/Engine.type'

export default function bindRenderFunctionToEngine(engine: Engine, render: FrameRequestCallback) {
  onMount(() => {
    engine.renderFns.push(render)
  })
  onCleanup(() => {
    if (engine) {
      removeItem(engine.renderFns, render)
    }
  })
}
