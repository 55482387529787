export default function parseValidIntFromInputEventOrNull(event: InputEvent) {
  const target: HTMLInputElement | null = event.target as HTMLInputElement | null
  // console.log('input.min, input.max', target?.min, target?.max)
  let newValue = parseInt(target?.value as string, 10) || null
  const max = parseInt(target?.max as string, 10)
  const min = parseInt(target?.min as string, 10)
  if (newValue !== null && (newValue > max || min > newValue)) {
    newValue = null
  }
  return newValue
}
