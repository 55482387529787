import { Component, createEffect, createMemo, Match, Show, Switch, useContext, type Accessor } from 'solid-js'
import type { DraftMove_AttackEstimate } from '../../lib/core/draft_move/DraftMove_AttackEstimate.type'
import type { Engine } from '../../lib/core/engine/Engine.type'
import getEntitiesAtPosition from '../../lib/core/entity/getEntitiesAtPosition'
import findByPosition from '../../lib/core/findByPosition'
import formatGameMoney from '../../lib/core/player/formatGameMoney'
import { samePosition } from '../../lib/core/tile_position_xy/samePosition'
import toCoord from '../../lib/core/tile_position_xy/toCoord'
import toFixed1 from '../../lib/core/util/toFixed1'
import { deepClone } from '../../lib/deep_clone'
import type { Nullable } from '../../typescript'
import EngineContext from '../EngineContext'
import PositionStackStats from './PositionStackStats'

const AttackPositionCornerWidget: Component = () => {
  const engine: Engine = useContext(EngineContext)
  // const [isExpanded, setIsExpanded] = createSignal<boolean>(false)
  // let prevAttackPosition : Nullable<DraftMove['attackPosition']> = null
  // createEffect(() => {
  //   const nextAttackPosition = engine.draftMove?.attackPosition
  //   if (nextAttackPosition && !samePosition(nextAttackPosition, prevAttackPosition)) {
  //     setIsExpanded(false)
  //   }
  //   prevAttackPosition = nextAttackPosition
  // })
  // createEffect(() => {
  //   const draftMove = engine.draftMove
  //   if (draftMove) {
  //     const { attackPosition } = draftMove
  //     if (!attackPosition) {
  //       // draftMove.attackPosition = coord(6,6)
  //     }
  //   }
  // })

  const attackPositionTargetEntsStack = createMemo(() => {
    const attackPosition = engine.draftMove?.attackPosition || engine.hoveredPosition
    if (attackPosition) {
      return getEntitiesAtPosition(engine.state.ents, attackPosition)
    }
    return []
  })

  const attackPositionUnitEntsStack = createMemo(() => {
    const { draftMove } = engine
    if (draftMove) {
      const { unit, destPosition } = draftMove
      const attackPosition = destPosition || toCoord(unit)
      if (attackPosition) {
        return getEntitiesAtPosition(engine.state.ents, attackPosition)
      }
    }
    return []
  })

  const estimate = createMemo<Nullable<DraftMove_AttackEstimate>>(() => {
    const { draftMove, hoveredPosition } = engine
    if (draftMove) {
      const { attackEstimates, rangedEstimates, attackPosition } = draftMove
      // hypothetical scenario, helicopter standing over land unit
      if (attackEstimates.length === 1) {
        return attackEstimates[0]
      }
      // unit standing between 2 enemy units?
      if (attackEstimates.length > 1) {
        if (hoveredPosition) {
          const hoveredPositionEstimate = attackEstimates.find((ae) => samePosition(ae.target, hoveredPosition))
          if (hoveredPositionEstimate) {
            return hoveredPositionEstimate
          }
        }
        if (attackPosition) {
          const attackPositionEstimate = attackEstimates.find((ae) => samePosition(ae.target, attackPosition))
          if (attackPositionEstimate) {
            return attackPositionEstimate
          }
        }

        return null
      }
      if (hoveredPosition && rangedEstimates) {
        const hoveredPositionEstimate = findByPosition(rangedEstimates, hoveredPosition)
        if (hoveredPositionEstimate) {
          return hoveredPositionEstimate.estimate
        }
        // console.log('estimate.draftMove.rangedEstimates', deepClone(rangedEstimates))
        // if (rangedEstimates.length > 1) {
        //   throw new NotImplemented('rangedEstimates.length > 1')
        // }
        // if (rangedEstimates.length === 1) {
        //   console.log('estimate.rangedEstimates.rangedEstimates', deepClone(rangedEstimates[0].estimate))
        //   return rangedEstimates[0].estimate
        // }
      }
    }
  })
  createEffect(() => {
    console.log('effect.estimate()', deepClone(estimate()))
  })
  createEffect(() => {
    console.log('effect.draftMove', deepClone(engine.draftMove))
  })

  return (
    <Show when={estimate()} children={(estimate: Accessor<DraftMove_AttackEstimate>) => {
      // console.log('AttackPositionCornerWidget', 'estimate()', deepClone(estimate()))
      return (
        <div class="cw cw-atkps-est cw-sm-tl float-end">
          <div class="row d-flex">
            <div class="text-center">
              <Switch>
                <Match when={(estimate().unitWeaponEstimate?.distance as number) === 1}>
                  <span class="badge text-bg-success">Direct Attack</span>
                </Match>
                <Match when={(estimate().unitWeaponEstimate?.distance as number) > 1}>
                  <span class="badge text-bg-success">Ranged Attack</span>
                </Match>
                <Match when={(estimate().targetWeaponEstimate?.distance as number) >= 1}>
                  <span class="badge text-bg-success">Defense</span>
                </Match>
                <Match when={true}>
                  <span class="badge text-bg-error">Error</span>
                </Match>
              </Switch>
            </div>
          </div>
          <table>
            <tbody>
              <tr>
                <td class="text-end">Approx DMG</td>
                <td class="text-end text-success">{toFixed1(estimate().targetDmg)}</td>
                <td class="text-end text-danger">{toFixed1(estimate().unitDmg)}</td>
                <td class="text-start">Approx DMG</td>
              </tr>
              <tr>
                <td class="text-end">Approx CBA</td>
                <td class="text-end text-success">{formatGameMoney(estimate().targetCba)}</td>
                <td class="text-end text-danger">{formatGameMoney(estimate().unitCba)}</td>
                <td class="text-start">Approx CBA</td>
              </tr>
            </tbody>
          </table>
          {/*
            TODO make a new PositionStackStats for comparing 2 units side-by-side
            the current one is too wide because the grey icon is there twice
            plus the unit's sprite takes up space

            TODO allow mobile to expand to see the full estimate
          */}
          <div class="row d-none d-md-flex">
            <div class="col">
              <PositionStackStats ents={attackPositionUnitEntsStack()} x={false} />
            </div>
            <div class="col">
              <PositionStackStats ents={attackPositionTargetEntsStack()} x={false} />
            </div>
          </div>
        </div>
      )
    }} />
  )
}

export default AttackPositionCornerWidget
