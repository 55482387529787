import { check, pipe } from "valibot"
import { type EntityId } from "./EntityId.type"
import { EntitySchema } from "./index"

export const CargoEntitySchema = pipe(EntitySchema, check((ent) => {
  return !!(ent as HasTaxiID).taxi_id
}, 'Cargo Missing Taxi ID'))

export interface HasTaxiID {
  taxi_id: EntityId
}