import { ArmorTypeId } from "../entity/Armor/ArmorTypeId.enum"

export default function armorMultFromType(at_id: ArmorTypeId) {
  if (at_id === ArmorTypeId.LightVehicle) {
    return 1
  }
  if (at_id === ArmorTypeId.Tank) {
    return 0.5
  }
  return 1
}