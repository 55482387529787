import type { Nullable } from "../../../typescript"
import type { State } from "./State.type"

export default function gameDisplayName(
  state: Nullable<State>,
): Nullable<string> {
  if (state) {
    const { online, map } = state
    if (online) {
      return online.dname || `Game ${online?.id}`
    }
    if (map.dname) {
      return `Playing ${map.dname}`
    }
  }
}
