import { EntityAnimation } from "./entity_animation"
import { EntityAnimationGroupId } from "./entity_animation_group_id.enum"

export default function createCursorEntityAnimation(
  groupId : EntityAnimationGroupId,
  tileSize : number): EntityAnimation {
  return {
    frames: [],
    groupId,
    tileSize,
    lastReadAt: 0,
  }
}
