import { Nullable } from '../../../../typescript'
import { Engine } from '../../../core/engine/Engine.type'
import { Entity } from '../../../core/entity'
import shouldEntityShowFadedFrames from '../../../core/entity/shouldEntityShowFadedFrames'
import { LayerMeta } from '../../../core/layer_meta'
import getEntityPlayer from '../../../core/player/get_entity_player'
import { Player } from '../../../core/player/Player.type'
import { SHADOW_ALPHA } from '../../../core/player/player_color'
import { RenderSpriteFunction } from '../../../core/render_sprite'
import { min } from '../../../core/util/math'
import { BASE_TILE_SIZE } from '../../../core/view_ctx'
import {
  Infantry_Group1_BodyGrey1_d,
  Infantry_Group1_BodyGrey1_fill,
  Infantry_Group1_BodyGrey2_d,
  Infantry_Group1_BodyGrey2_fill,
  Infantry_Group1_FaceSkin1_d,
  Infantry_Group1_FaceSkin1_fill,
  Infantry_Group1_HelmetGrey1_d,
  Infantry_Group1_HelmetGrey1_fill,
  Infantry_Group1_HelmetGrey2_d,
  Infantry_Group1_HelmetGrey2_fill,
  Infantry_Group1_HelmetGrey3_d,
  Infantry_Group1_HelmetGrey3_fill,
  Infantry_Group1_RifleGrey1_d,
  Infantry_Group1_RifleGrey1_fill,
  Infantry_height,
  Infantry_width,
} from '../../../svg/sprites_data'
import { DEFAULT_GLOBAL_COMPOSITE_OPERATION, DESTINATION_ATOP, SOURCE_ATOP } from '../../canvas'
import { createOffscreenCanvas } from '../../create_canvas'
import { addBackgroundBorder } from '../../draw/add_background_border'
import createFadedFrameCanvas from '../../draw/createFadedFrameCanvas'
import { EitherRenderingContext2D, getCanvas2dContext } from '../../get_canvas_2d_context'

const totalLoopMs = 1000
const frameDurationMs = totalLoopMs / 2

export default function makeRenderInfantry(engine: Engine, entity: Entity): RenderSpriteFunction {
  // console.log('makeRenderInfantry')
  const tile_size = engine.viewCtx.tile_size
  const player: Nullable<Player> = getEntityPlayer(engine, entity)

  const sRenderMult = tile_size / BASE_TILE_SIZE
  const sRenderMultInv = BASE_TILE_SIZE / tile_size
  const sWidth = Infantry_width * sRenderMult
  const sHeight = Infantry_height * sRenderMult

  const frames: Array<OffscreenCanvas> = []
  const fadedFrames: Array<OffscreenCanvas> = []

  function makeFrame(frameIndex: number): OffscreenCanvas {
    const elem = createOffscreenCanvas(sWidth, sHeight)

    const ctx = getCanvas2dContext(elem)
    if (ctx) {
      ctx.scale(sRenderMult, sRenderMult)
      ctx.fillStyle = player?.color1 || Infantry_Group1_HelmetGrey1_fill
      ctx.fill(Infantry_Group1_HelmetGrey1_d)

      ctx.globalAlpha = SHADOW_ALPHA
      ctx.globalCompositeOperation = SOURCE_ATOP
      ctx.fillStyle = Infantry_Group1_HelmetGrey2_fill
      ctx.fill(Infantry_Group1_HelmetGrey2_d)

      ctx.fillStyle = Infantry_Group1_HelmetGrey3_fill
      ctx.fill(Infantry_Group1_HelmetGrey3_d)
      ctx.globalCompositeOperation = DEFAULT_GLOBAL_COMPOSITE_OPERATION
      ctx.globalAlpha = 1

      ctx.globalCompositeOperation = DESTINATION_ATOP
      ctx.fillStyle = Infantry_Group1_FaceSkin1_fill
      ctx.fill(Infantry_Group1_FaceSkin1_d)
      ctx.globalCompositeOperation = DEFAULT_GLOBAL_COMPOSITE_OPERATION

      ctx.fillStyle = player?.color1 || Infantry_Group1_BodyGrey1_fill
      ctx.fill(Infantry_Group1_BodyGrey1_d)

      ctx.fillStyle = player?.color2 || Infantry_Group1_BodyGrey2_fill
      ctx.fill(Infantry_Group1_BodyGrey2_d)

      if (frameIndex === 0) {
        ctx.translate(1, 0)
      }

      ctx.fillStyle = Infantry_Group1_RifleGrey1_fill
      ctx.fill(Infantry_Group1_RifleGrey1_d)

      if (frameIndex === 0) {
        ctx.translate(-1, 0)
      }

      ctx.scale(sRenderMultInv, sRenderMultInv)
      addBackgroundBorder(ctx, elem, 0, 0, 0, 255, 1)
    }
    return elem
  }

  const fn = function renderInfantry(
    ctx: EitherRenderingContext2D,
    lm: LayerMeta,
    entity: Entity,
    dx: number,
    dy: number,
    tile_size: number
  ): void {
    // console.log('renderInfantry', floor(lm.ts), floor(lm.until))
    let frameIndex = 0
    let frame: Nullable<OffscreenCanvas>

    if (shouldEntityShowFadedFrames(entity)) {
      // if the unit was built, or is out of moves
      // it shall be faded instead of bright and animated
      fadedFrames[0] ||= createFadedFrameCanvas((frames[0] ||= makeFrame(0)))
      frame = fadedFrames[0]
    } else if (player) {
      // tank is bouncy
      const mod = lm.ts % totalLoopMs
      if (mod > frameDurationMs) {
        frameIndex = 1
      }
      lm.until = min(lm.until, lm.ts + (frameDurationMs - mod))
    }
    if (!frame) {
      frame = frames[frameIndex] ||= makeFrame(frameIndex)
    }
    if (!frame) {
      throw new Error('unexpected !frame')
    }

    ctx.drawImage(frame, 0, 0, sWidth, sHeight, dx, dy, tile_size, tile_size)
  }
  return fn
}
