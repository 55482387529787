import { Component } from "solid-js"
import type { Player } from "../../lib/core/player/Player.type"
import { PlayerGameStatus } from "../../lib/core/player/PlayerGameStatus"

interface PlayerGameStatusBadgeProps {
  player: Player
}

const PlayerGameStatusBadge: Component<PlayerGameStatusBadgeProps> = (props) => {
  // Map PlayerGameStatus values to labels
  const getBadgeLabel = (status: PlayerGameStatus): string => {
    switch (status) {
      case PlayerGameStatus.Active:
        return "Active"
      case PlayerGameStatus.Forfeited:
        return "Forfeited"
      case PlayerGameStatus.Resigned:
        return "Resigned"
      case PlayerGameStatus.Lost:
        return "Lost"
      case PlayerGameStatus.Won:
        return "Won"
      case PlayerGameStatus.Draw:
        return "Draw"
      default:
        return "Unknown"
    }
  }

  // Map PlayerGameStatus values to Bootstrap badge styles
  const getBadgeClass = (status: PlayerGameStatus): string => {
    switch (status) {
      case PlayerGameStatus.Active:
        return "bg-primary"
      case PlayerGameStatus.Forfeited:
        return "bg-warning"
      case PlayerGameStatus.Resigned:
        return "bg-secondary"
      case PlayerGameStatus.Lost:
        return "bg-danger"
      case PlayerGameStatus.Won:
        return "bg-success"
      case PlayerGameStatus.Draw:
        return "bg-info"
      default:
        return "bg-dark"
    }
  }

  return (
    <span class={`badge ${getBadgeClass(props.player.game_status)}`}>
      {getBadgeLabel(props.player.game_status)}
    </span>
  )
}

export default PlayerGameStatusBadge
