
/**
 * 
 * 0 == 0            // true
 * 0 == null         // false
 * 0 == undefined    // false
 * null == 0         // false
 * undefined == 0    // false
 * null == undefined // true
 * undefined == null // true
 * 
 * @param a 
 * @param b 
 * @returns boolean
 */
export default function isBothNumbersSameOrBothNil(a: number | null | undefined, b: number | null | undefined): boolean {
  return a == b
}