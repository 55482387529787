import { A } from '@solidjs/router'
import { createMemo, For, Show } from 'solid-js'
import { createMutable } from 'solid-js/store'
import EngineContext from '../../../components/EngineContext'
import SingleSpriteIcon from '../../../components/MapEditor/SingleSpriteIcon'
import bindEngineToComponent from '../../../lib/canvas/bind_engine_to_component'
import createDraftMoveAttackEstimate from '../../../lib/core/draft_move/createDraftMoveAttackEstimate'
import type { DraftMove_AttackEstimate } from '../../../lib/core/draft_move/DraftMove_AttackEstimate.type'
import createEngineWithTwoPlayersForUI from '../../../lib/core/engine/createEngineWithTwoPlayersForUI'
import { Engine } from '../../../lib/core/engine/Engine.type'
import createSampleEntity from '../../../lib/core/entity/create_sample_entity'
import type { EntityTypeId } from '../../../lib/core/entity/entity_type_id.enum'
import entityTypeMetaList from '../../../lib/core/entity/entityTypeMetaList.generated'
import hasAtLeastOneWeapon from '../../../lib/core/entity/hasAtLeastOneWeapon'
import type { Entity } from '../../../lib/core/entity/index'
import { EntityTypeMeta, isSpriteUnit } from '../../../lib/core/EntityTypeMeta'
import type { HasHP } from '../../../lib/core/has_hp'
import { type HP } from '../../../lib/core/hp.type'
import coord from '../../../lib/core/tile_position_xy/coord'

const CodexDamageCalculatorMatrixPage = () => {
  // console.log('CodexDamageCalculatorMatrixPage')

  const entityTypes = entityTypeMetaList.filter((sprite: EntityTypeMeta) => sprite && isSpriteUnit(sprite))
  // console.table(entityTypeMetaList.filter((sprite: EntityType) => sprite))

  const xy0 = coord(0, 0)
  const xy1 = coord(1, 0)

  const engine: Engine = createMutable(createEngineWithTwoPlayersForUI())
  // need sample player for UI engine
  const samplePlayerOneId = createMemo<number>(() => {
    return engine.state.players[0].id
  })
  const samplePlayerTwoId = createMemo<number>(() => {
    return engine.state.players[1]?.id
  })

  // const [selectedUnitEntityTypeMeta, setSelectedUnitEntityTypeMeta] = createSignal<Nullable<EntityTypeMeta>>(null)
  // const [selectedTargetEntityTypeMeta, setSelectedTargetEntityTypeMeta] = createSignal<Nullable<EntityTypeMeta>>(null)

  // const [hiddenList, setHiddenList] = createSignal<Array<EntityTypeId>>([])
  const unitConfigList = createMutable(createConfig(entityTypes))
  const targetConfigList = createMutable(createConfig(entityTypes))

  // const [unitHp, setUnitHp] = createSignal<HP>(10)

  bindEngineToComponent(engine)

  return (
    <EngineContext.Provider value={engine}>
      <div class="pagec">
        <h2>
          <A href="/codex">Codex</A>{' - '}
          Damage Matrix
        </h2>
        <table class="table table-sm w-auto">
          <thead>
            <tr>
              <td class="small">ATK\DEF</td>
              <For each={entityTypes} fallback={<div class="text-muted">Empty List</div>} children={(entityType) =>
                <td class="text-end"><SingleSpriteIcon entity={createSampleEntity(entityType, samplePlayerTwoId())} tileSize={32} /></td>
              } />
            </tr>
          </thead>
          <tbody>
            <For each={entityTypes} fallback={<div class="text-muted">Empty List</div>}>
              {(entityType: EntityTypeMeta) => {
                const unitConfig = (): ConfigRow => unitConfigList.find(config => config.etype_id === entityType.id) || ({ hidden: true } as ConfigRow)

                const sampleUnit = createMemo<Entity & HasHP>(() => {
                  const { hp } = unitConfig()
                  return {
                    ...createSampleEntity(entityType, samplePlayerOneId()),
                    ...xy0,
                    hp,
                  } as Entity & HasHP
                })

                return <tr classList={{ 'd-none': unitConfig().hidden || !hasAtLeastOneWeapon(sampleUnit()) }}>
                  <td class="text-end"><SingleSpriteIcon entity={sampleUnit()} tileSize={32} /></td>
                  <For each={entityTypes} fallback={<div class="text-muted">Empty List</div>}>
                    {(entityType: EntityTypeMeta) => {
                      const targetConfig = (): ConfigRow => targetConfigList.find(config => config.etype_id === entityType.id) || ({ hidden: true } as ConfigRow)

                      const sampleEntity = createMemo<Entity & HasHP>(() => {
                        const { hp } = targetConfig()
                        return {
                          ...createSampleEntity(entityType, samplePlayerTwoId()),
                          ...xy1,
                          hp,
                        } as Entity & HasHP
                      })

                      const daEstimate = createMemo<DraftMove_AttackEstimate>(() => {
                        const u = sampleUnit()
                        const v = createDraftMoveAttackEstimate(engine, u, coord(u.x, u.y), sampleEntity())
                        // console.log('daEstimate', entityType.dname, v)
                        return v
                      })

                      return <>
                        <td classList={{
                          'd-none': targetConfig().hidden,
                          'text-end': true,
                        }}>
                          <Show when={(daEstimate().targetDmg as number > 0)}>
                            <span class="text-monospace">{daEstimate().targetDmg?.toFixed(1)}</span>
                          </Show>
                        </td>
                      </>
                    }}
                  </For>

                </tr>
              }}
            </For>
          </tbody>
        </table>
      </div>
    </EngineContext.Provider>
  )
}

export default CodexDamageCalculatorMatrixPage

type ConfigRow = {
  etype_id: EntityTypeId,
  hidden: boolean,
  defaultHp: HP,
  hp: HP,
}

function createConfig(entityTypes: Array<EntityTypeMeta>): Array<ConfigRow> {
  return entityTypes.map((etm: EntityTypeMeta): ConfigRow => {
    const { hp: defaultHp } = etm.entDefaults as HasHP
    return {
      etype_id: etm.id,
      hidden: false,
      defaultHp,
      hp: defaultHp,
    }
  })
}