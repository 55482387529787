import { enum_ } from 'valibot'

export enum ArmorTypeId {
  None = 0,
  Infantry = 100,
  LightVehicle = 200,
  Tank = 300,
}

export const ArmorTypeIdSchema = enum_(ArmorTypeId, 'Invalid Armor Type ID')
