import { ParentComponent } from 'solid-js'
import { Toaster } from 'solid-toast'
import TheCornerWidget from './components/CornerWidget/TheCornerWidget'
import ModalMainMenu from './components/ModalMainMenu'

const App: ParentComponent = (props) => {

  // const session = createSessionSignal()[0]

  return (
    <div>
      <TheCornerWidget />
      {props.children}
      <Toaster position='bottom-right' />
      <ModalMainMenu />
    </div>
  )
}

export default App
