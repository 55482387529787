import { onCleanup, onMount } from 'solid-js'

export default function onWindowEvent<K extends keyof WindowEventMap>(
  name: K,
  fn: (this: Window, ev: WindowEventMap[K]) => void | null | boolean,
  options?: boolean | AddEventListenerOptions
): void {
  onMount(() => {
    window.addEventListener(name, fn, options)
  })
  onCleanup(() => {
    window.removeEventListener(name, fn, options)
  })
}
