import { ParentComponent } from 'solid-js'

interface SectionProps {
  show: boolean,
}

const FormSection: ParentComponent<SectionProps> = (props) => {
  return <div class="row justify-content-md-center" classList={{
    'd-none': !props.show,
  }}>
    <div class="border border-2 rounded-2 p-2 my-2">
      {props.children}
    </div>
  </div>
}

export default FormSection