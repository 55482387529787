import { Component, createMemo, useContext } from "solid-js"
import { modifyMutable, produce } from "solid-js/store"
import { Engine } from "../../lib/core/engine/Engine.type"
import { EntityLayerId } from "../../lib/core/entity/entity_layer_id.enum"
import { SelectedToolId } from "../../lib/core/map_editor/SelectedToolId.enum"
import EngineContext from "../EngineContext"

const EraseTileSelect: Component = () => {
  const engine : Engine = useContext(EngineContext)
  // const track = engine.cache[0]
  const isEraseTool = createMemo<boolean>(() => {
    // console.log('isEraseTool.memo', engine.selectedTool, SelectedToolId.Erase, engine.selectedTool === SelectedToolId.Erase)
    // track(EngineCache.SelectedToolId)
    return engine.selectedTool === SelectedToolId.Erase
  })
  // const [selectedEntityTypeId, setSelectedEntityTypeId] = createSignal<number | null>(null)

  function toggleEntityLayerId(entity_yer_id: EntityLayerId) {
    return (/*event: Event*/) => {
      modifyMutable(engine, produce((engine) => {
        const { eraseToolOption } = engine
        if (eraseToolOption.entity_layer_ids.includes(entity_yer_id)) {
          eraseToolOption.entity_layer_ids = eraseToolOption.entity_layer_ids.filter(otherEntityLayerId => otherEntityLayerId !== entity_yer_id)
        } else {
          eraseToolOption.entity_layer_ids = [...eraseToolOption.entity_layer_ids, entity_yer_id]
        }
      }))
    }
  }

  return <>
    {isEraseTool() && <div id="MapEditorEraseTileSelect" class="card-body">
      EraseTileSelect
      <div class="entity-type-row">
        <input type="checkbox" id="TerrainL1"
          value={EntityLayerId.TerrainL1}
          checked={engine.eraseToolOption.entity_layer_ids.includes(EntityLayerId.TerrainL1)}
          onChange={toggleEntityLayerId(EntityLayerId.TerrainL1)}
        />
        <label for="TerrainL1">Base Terrain</label>
      </div>
      <div class="entity-type-row">
        <input type="checkbox" id="TerrainL2"
          value={EntityLayerId.TerrainL2}
          checked={engine.eraseToolOption.entity_layer_ids.includes(EntityLayerId.TerrainL2)}
          onChange={toggleEntityLayerId(EntityLayerId.TerrainL2)}
        />
        <label for="TerrainL2">Roads, Trees, Hills, Buildings</label>
      </div>
      <div class="entity-type-row">
        <input type="checkbox" id="Unit2"
          value={EntityLayerId.Unit2}
          checked={engine.eraseToolOption.entity_layer_ids.includes(EntityLayerId.Unit2)}
          onChange={toggleEntityLayerId(EntityLayerId.Unit2)}
        />
        <label for="Unit2">Infantry, Tanks</label>
      </div>
      {/* <pre>EraseTileSelect {JSON.stringify({ eraseTileOption: engine.eraseToolOption }, null, 2)}</pre> */}
    </div>}
  </>
}

export default EraseTileSelect

