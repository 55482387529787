// the color pool should have 8 optimal team colors
// simple tactics will only support 8 players
// @todo an additional visual cue should indicate MyTeam|OtherTeam|neutral
// @todo a player should be able to overwrite the army painter if an opposing player
//       picked a "bad theme". This feature should be useful for color blind players
// @todo simulate color-blindness via canvas transform

// general color tools:
// @link http://www.color-blindness.com/coblis-color-blindness-simulator/
// @link https://color.adobe.com/
// @link https://flatuicolors.com/
// @link http://paletton.com/
// @link http://mkweb.bcgsc.ca/colorblind/
// @link https://www.youtube.com/watch?v=Qj1FK8n7WgY
// @link https://www.youtube.com/watch?v=O8i7OKbWmRM

export function playerColorToHex(color: number): string {
  return '#' + (color || 0).toString(16).padStart(3, '0')
}

export function hexToPlayerColor(hex: string): number {
  if (hex.length === 3) {
    return parseInt(hex, 16)
  }
  if (hex.length === 6) {
    return parseInt(hex, 16)
  }
  throw new Error('expected 3 character hex')
}

export const SHADOW_ALPHA = 0.25
export const SHADOW_FILL = '#000'
export const BLACK_HEX = '#000'
export const WHITE_HEX = '#fff'
export const LIGHTS_ON_HEX = '#f8f870'
export const CURSOR_YELLOW_HEX = '#fffd67'
export const CURSOR_GREY_HEX = '#777'
export const CURSOR_RED_HEX = '#f00'
