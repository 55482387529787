import { isoNow } from '@sg/shared/src/lib/Date'
import { BioSchema } from '@sg/shared/src/valibot/schema/Bio.type'
import { EmailSchema } from '@sg/shared/src/valibot/schema/Email.type'
import { HandleSchema } from '@sg/shared/src/valibot/schema/Handle.type'
import hasNonUndefinedProperty from '@sg/st1/src/lib/hasNonUndefinedProperty'
import { Context } from 'hono'
import { integer, nullable, number, object, optional, parse, pipe } from 'valibot'
import authOr401 from '../../lib/auth/authOr401'
import noContent from '../../lib/hono/noContent'
import createAdminSupabaseClient from '../../lib/supabase/createAdminSupabaseClient'
import { OptionalDisplayNameSchema } from '@sg/shared/src/valibot/schema/DisplayName.type'

export const PatchMyProfileBodySchema = object({
  avatar_id: optional(nullable(pipe(number(), integer()))),
  handle: optional(nullable(HandleSchema)),
  dname: OptionalDisplayNameSchema,
  email: optional(EmailSchema),
  bio: optional(BioSchema),
})

export default async function handlePatchMe(c: Context) {
  const auth = await authOr401(c)
  const profileId = auth.sub
  console.log('handlePatchMe', { profileId })
  if (!profileId) {
    return c.json({
      message: 'No Profile ID',
    }, 400)
  }

  const payload = parse(PatchMyProfileBodySchema, await c.req.json())
  console.log('payload', payload)

  if (!hasNonUndefinedProperty(payload)) {
    return noContent(c)
  }

  const supabaseAdmin = createAdminSupabaseClient(c)

  if (payload.avatar_id) {
    console.log('checking avatar_id', payload.avatar_id)
    const { data, error } = await supabaseAdmin
      .from('files')
      .select("id")
      .eq('uploader_id', profileId)
      .eq('id', payload.avatar_id)
      .is('deleted_at', null)
      .single()

    if (error) {
      throw error
    }

    console.log('FindAvatarFile', data)

  }

  console.log('updating auth profile', {payload})
  const { data, error } = await supabaseAdmin
    .from('auth_profiles')
    .update({
      ...payload,
      updated_at: isoNow(),
    })
    .eq('id', profileId)
  
  console.log('UpdateProfileResult', data)

  if (error) {
    throw error
  }

  return noContent(c)
}
