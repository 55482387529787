/**
 * This is the default setting and draws new shapes on top of the existing canvas content.
 */
export const SOURCE_OVER = "source-over"

/**
 * The new shape is drawn only where both the new shape and the destination canvas overlap. Everything else is made transparent.
 */
export const SOURCE_IN = "source-in"

/**
 * The new shape is drawn where it doesn't overlap the existing canvas content.
 */
export const SOURCE_OUT = "source-out"

/**
 * The new shape is only drawn where it overlaps the existing canvas content.
 */
export const SOURCE_ATOP = "source-atop"

/**
 * New shapes are drawn behind the existing canvas content.
 */
export const DESTINATION_OVER = "destination-over"

/**
 * The existing canvas content is kept where both the new shape and existing canvas content overlap. Everything else is made transparent.
 */
export const DESTINATION_IN = "destination-in"

/**
 * The existing content is kept where it doesn't overlap the new shape.
 */
export const DESTINATION_OUT = "destination-out"

/**
 * The existing canvas is only kept where it overlaps the new shape. The new shape is drawn behind the canvas content.
 */
export const DESTINATION_ATOP = "destination-atop"

/**
 * Where both shapes overlap, the color is determined by adding color values.
 */
export const LIGHTER = "lighter"

/**
 * Only the new shape is shown.
 */
export const COPY = "copy"

/**
 * Shapes are made transparent where both overlap and drawn normal everywhere else.
 */
export const XOR = "xor"

/**
 * The pixels of the top layer are multiplied with the corresponding pixels of the bottom layer. A darker picture is the result.
 */
export const MULTIPLY = "multiply"

/**
 * The pixels are inverted, multiplied, and inverted again. A lighter picture is the result (opposite of multiply)
 */
export const SCREEN = "screen"

/**
 * A combination of multiply and screen. Dark parts on the base layer become darker, and light parts become lighter.
 */
export const OVERLAY = "overlay"

/**
 * Retains the darkest pixels of both layers.
 */
export const DARKEN = "darken"

/**
 * Retains the lightest pixels of both layers.
 */
export const LIGHTEN = "lighten"

/**
 * Divides the bottom layer by the inverted top layer.
 */
export const COLOR_DODGE = "color-dodge"

/**
 * Divides the inverted bottom layer by the top layer, and then inverts the result.
 */
export const COLOR_BURN = "color-burn"

/**
 * Like overlay, a combination of multiply and screen — but instead with the top layer and bottom layer swapped.
 */
export const HARD_LIGHT = "hard-light"

/**
 * A softer version of hard-light. Pure black or white does not result in pure black or white.
 */
export const SOFT_LIGHT = "soft-light"

/**
 * Subtracts the bottom layer from the top layer — or the other way round — to always get a positive value.
 */
export const DIFFERENCE = "difference"

/**
 * Like difference, but with lower contrast.
 */
export const EXCLUSION = "exclusion"

/**
 * Preserves the luma and chroma of the bottom layer, while adopting the hue of the top layer.
 */
export const HUE = "hue"

/**
 * Preserves the luma and hue of the bottom layer, while adopting the chroma of the top layer.
 */
export const SATURATION = "saturation"

/**
 * Preserves the luma of the bottom layer, while adopting the hue and chroma of the top layer.
 */
export const COLOR = "color"

/**
 * Preserves the hue and chroma of the bottom layer, while adopting the luma of the top layer.
 */
export const LUMINOSITY = "luminosity"

/**
 * This is the default setting and draws new shapes on top of the existing canvas content.
 */
export const DEFAULT_GLOBAL_COMPOSITE_OPERATION = SOURCE_OVER
