import { InferOutput, maxValue, minValue, number, pipe } from 'valibot'
import { POSTGRES_MAX_INT } from '../util/number'

export const MIN_STATE_ONLINE_ID_VALUE = 1000000
export const MAX_STATE_ONLINE_ID_VALUE = POSTGRES_MAX_INT

// coerce string to number?
// v.pipe(v.unknown(), v.transform(Number));

export const StateOnlineIdSchema = pipe(
  number(),
  minValue(MIN_STATE_ONLINE_ID_VALUE),
  maxValue(MAX_STATE_ONLINE_ID_VALUE)
)

export type StateOnlineId = InferOutput<typeof StateOnlineIdSchema>
