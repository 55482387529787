import { BioSchema } from '@sg/shared/src/valibot/schema/Bio.type'
import { OptionalNullableDisplayNameSchema } from '@sg/shared/src/valibot/schema/DisplayName.type'
import { PassphraseSchema } from '@sg/shared/src/valibot/schema/Passphrase.type'
import createEngineForServer from '@sg/st1/src/lib/core/engine/createEngineForServer'
import setAuthPlayerIdFromProfileId from '@sg/st1/src/lib/core/engine/setAuthPlayerIdFromProfileId'
import type { ProfileId } from '@sg/st1/src/lib/core/ProfileId.type'
import createDefaultStateOnline from '@sg/st1/src/lib/core/state/create_default_state_online'
import {
  createMapToLobbyAction,
  MapToLobbyActionSchema,
} from '@sg/st1/src/lib/core/state/flux/action/Map/MapToLobbyAction'
import dispatchServer from '@sg/st1/src/lib/core/state/flux/dispatchServer'
import randomStateIdValue from '@sg/st1/src/lib/core/state/randomStateIdValue'
import { StateSchema } from '@sg/st1/src/lib/core/state/State.type'
import { StateOnlineIdSchema } from '@sg/st1/src/lib/core/state/StateOnlineId.type'
import type { Nullable } from '@sg/st1/src/typescript'
import { Context } from 'hono'
import { nullable, object, optional, parse, type InferOutput } from 'valibot'
import authOr401 from '../../../lib/auth/authOr401'
import assertAuthorizedToSeeMapOr404 from '../../../lib/db/assertAuthorizedToSeeMapOr404'
import getMapOr404 from '../../../lib/db/getMapOr404'
import getPublicProfileOr404 from '../../../lib/db/getPublicProfileOr404'
import insertLobbyToDatabase from '../../../lib/st1/insertLobbyToDatabase'
import createAdminSupabaseClient from '../../../lib/supabase/createAdminSupabaseClient'

export const StoreLobbyBodySchema = object({
  // disk: 'cloud' | 'local'
  dname: OptionalNullableDisplayNameSchema,
  bio: optional(nullable(BioSchema)),
  passphrase: optional(nullable(PassphraseSchema)),
  map_id: StateOnlineIdSchema,
})

export type StoreLobbyBody = InferOutput<typeof StoreLobbyBodySchema>

export default async function handleStoreLobby(c: Context) {
  console.log('handleStoreLobby')
  const auth = await authOr401(c)

  console.log('c.req.json()', await c.req.json())
  const body = parse(StoreLobbyBodySchema, await c.req.json())

  const supabaseAdmin = createAdminSupabaseClient(c)

  // console.log('auth', auth)
  const userId: Nullable<ProfileId> = auth.sub
  console.log('userId', userId)
  if (!userId) {
    throw new Error('User not found')
  }

  const profileData = await getPublicProfileOr404(supabaseAdmin, userId)
  const mapRowData = await getMapOr404(supabaseAdmin, body.map_id)
  assertAuthorizedToSeeMapOr404(auth, mapRowData)

  const engine = createEngineForServer()
  engine.state = parse(StateSchema, mapRowData.data)
  setAuthPlayerIdFromProfileId(engine, userId)
  if (!engine.authPlayerId) {
    throw new Error('unexpected !engine.authPlayerId')
  }

  if (!engine.state.online) {
    engine.state.online = createDefaultStateOnline()
  }
  if (body.dname) {
    engine.state.online.dname = body.dname
  }
  if (body.bio) {
    engine.state.online.bio = body.bio
  }
  if (body.passphrase) {
    engine.state.online.passphrase = body.passphrase
  }

  const action = createMapToLobbyAction(profileData)
  const validatedAction = parse(MapToLobbyActionSchema, action)
  await dispatchServer(engine, validatedAction, 1)

  engine.state.online.id = randomStateIdValue()

  await insertLobbyToDatabase(engine, supabaseAdmin)

  if (!engine.state.online?.id) {
    throw new Error('unexpected !engine.state.online.id')
  }

  return c.json({
    id: engine.state.online.id,
  })
}
