import { abs } from "../util/math"
import type { TilePositionXY } from "./TilePositionXY.type"

/**
 * Calculates the Manhattan distance (number of steps via up, down, left, right)
 * between two tile positions.
 * Formula: |x2 - x1| + |y2 - y1|
 * @param pointA - The first tile position
 * @param pointB - The second tile position
 * @returns The Manhattan distance between the two points
 */
export default function getManhattanDistance(pointA: TilePositionXY, pointB: TilePositionXY): number {
  const dx = abs(pointB.x - pointA.x); // Absolute difference in x-coordinates
  const dy = abs(pointB.y - pointA.y); // Absolute difference in y-coordinates
  return dx + dy; // Sum of absolute differences
}