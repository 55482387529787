import { IssuePathItem, ValiError } from 'valibot'

type ErrorBag = Record<string, Array<string>>

/**
 * convert a ValiError to a laravel-style ErrorBag
 * @param valibotErr
 * @returns ErrorBag
 */
export default function valibotToErrorBag(valibotErr: any): ErrorBag | undefined | null {
  if (valibotErr instanceof ValiError && 'issues' in valibotErr) {
    const errorBag: ErrorBag = {}
    valibotErr.issues.forEach((issue) => {
      const dot_key: string = issue.path
        .map((p: IssuePathItem) => (p as any).key || 'unknown')
        .join('.')
      if (!(dot_key in errorBag)) {
        errorBag[dot_key] = []
      }
      errorBag[dot_key].push(issue.message)
      return errorBag
    }, errorBag)
    return errorBag
  }
}
