import { Nullable } from '../../../../typescript'
import { Engine } from '../../../core/engine/Engine.type'
import { Entity } from '../../../core/entity'
import { LayerMeta } from '../../../core/layer_meta'
import getEntityPlayer from '../../../core/player/get_entity_player'
import { Player } from '../../../core/player/Player.type'
import { RenderSpriteFunction } from '../../../core/render_sprite'
import { min } from '../../../core/util/math'
import { BASE_TILE_SIZE } from '../../../core/view_ctx'
import { createOffscreenCanvas } from '../../create_canvas'
import { EitherRenderingContext2D, getCanvas2dContext } from '../../get_canvas_2d_context'

const PATH_SIZE = 16

const Forest_width = BASE_TILE_SIZE
const Forest_height = BASE_TILE_SIZE * 2

const tree_shape = new Path2D('M8,0 16,14 C11 16 5 16 0 14 L0,14')
const tree_shadow = new Path2D(
  'M9.75,3 7.5,5.5 10,6 10,6.5 8,9 11,10 11,10.5 6,13 13,15C13.948 14.739 16 14 16 14Z'
)

// without closePath, the first and last nodes are not correctly connected
tree_shape.closePath()

const totalLoopMs = 2000

const colors = {
  border: '#3f70b0',
  // darkest
  green1: '#3878b0',
  green2: '#48b098',
  // lightest
  shadow: '#80c898',
} as const

function drawSingleTree(
  ctx: EitherRenderingContext2D,
  tx: number,
  ty: number,
  sx: number,
  sy: number
) {
  ctx.translate(tx, ty)
  ctx.scale(sx, sy)
  ctx.fillStyle = colors.green2
  ctx.fill(tree_shape)
  ctx.fillStyle = colors.green1
  ctx.fill(tree_shadow)
  ctx.strokeStyle = colors.border
  ctx.stroke(tree_shape)
  ctx.restore()
}

export default function makeRenderForest(engine: Engine, entity: Entity): RenderSpriteFunction {
  // console.log('makeRenderForest')
  const tile_size = engine.viewCtx.tile_size
  const player: Nullable<Player> = getEntityPlayer(engine, entity)

  const sRenderMult = tile_size / BASE_TILE_SIZE
  // const sRenderMultInv = BASE_TILE_SIZE / tile_size
  const sWidth = Forest_width * sRenderMult
  const sHeight = Forest_height * sRenderMult

  const frames: Array<OffscreenCanvas> = []

  /*eslint @typescript-eslint/no-unused-vars: ["error", { "args": "none" }]*/
  function makeFrame(frameIndex: number): OffscreenCanvas {
    const elem = createOffscreenCanvas(sWidth, sHeight)

    const ctx = getCanvas2dContext(elem)
    if (ctx) {
      ctx.scale(sRenderMult, sRenderMult)
      ctx.translate(0, BASE_TILE_SIZE)
      ctx.save()

      const scale_factor = BASE_TILE_SIZE / PATH_SIZE
      const scale_factor2 = scale_factor * 0.8
      drawSingleTree(ctx, 1, -4, scale_factor2 * 0.75, scale_factor2 * 1)

      drawSingleTree(ctx, 7, 1, scale_factor * 0.75, scale_factor * 0.91)
    }
    return elem
  }

  const fn = function renderForest(
    ctx: EitherRenderingContext2D,
    lm: LayerMeta,
    entity: Entity,
    dx: number,
    dy: number,
    tile_size: number
  ): void {
    // lights off by default
    let frameIndex = 0
    const dWidth = tile_size
    const dHeight = 2 * tile_size

    // sometimes lights on
    if (player) {
      const mod = lm.ts % totalLoopMs
      if (mod > 400) {
        frameIndex = 1
      }
      lm.until = min(lm.until, lm.ts + (totalLoopMs - mod))
    }

    // const player: Nullable<Player> = entity?.player_id
    ctx.drawImage(
      (frames[frameIndex] ||= makeFrame(frameIndex)),
      0,
      0,
      sWidth,
      sHeight,
      dx,
      dy - tile_size,
      dWidth,
      dHeight
    )
  }

  return fn
}
