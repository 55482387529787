export default function onlyDirty<T>(newData: T, oldData: T): Partial<T> {
  // console.log('onlyDirty', newData, oldData)
  const dirtyData: Partial<T> = {
    ...newData,
  }
  for (const key in dirtyData) {
    if (dirtyData?.[key] === oldData?.[key]) {
      delete dirtyData[key]
    }
  }
  return dirtyData
}
