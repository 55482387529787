import type { StateRowValue } from "@sg/backend/src/lib/db/StateRowValue.type"
import { A } from "@solidjs/router"
import { Component, createMemo } from "solid-js"
import { createMutable } from "solid-js/store"
import bindEngineToComponent from "../lib/canvas/bind_engine_to_component"
import createEngineForUI from "../lib/core/engine/createEngineForUI"
import type { Engine } from "../lib/core/engine/Engine.type"
import createSampleEntity from "../lib/core/entity/create_sample_entity"
import { LightTankEntityTypeMeta } from "../lib/core/entity/EntityType/LightTank"
import findPlayerByProfileId from "../lib/core/player/findPlayerByProfileId"
import createAuthProfileSignal from "../rx/shared/profile/createAuthProfileSignal"
import EngineContext from "./EngineContext"
import SingleSpriteIcon from "./MapEditor/SingleSpriteIcon"

interface Props {
  row: StateRowValue
}

const ContinueOrSpectateGameButton: Component<Props> = (props) => {
  const [authProfile] = createAuthProfileSignal()
  const engine: Engine = createMutable<Engine>(createEngineForUI())
  const samplePlayerId = createMemo<number>(() => {
    return engine.state.players[0].id
  })
  const stateId = () => props.row.id
  const authInGameText = createMemo(() => {
    if (findPlayerByProfileId(props.row.data, authProfile()?.id)) {
      return 'Continue Game'
    }
    return 'Observe Game'
  })

  bindEngineToComponent(engine)

  return (
    <EngineContext.Provider value={engine}>
      <A href={`/game/play?game_id=${stateId()}`}
        class="btn btn-success btn-sm"
        aria-label={authInGameText()}
        title={authInGameText()}
      >
        <SingleSpriteIcon
          entity={createSampleEntity(LightTankEntityTypeMeta, samplePlayerId())}
          tileSize={32}
          style={({ "margin-top": `-48px`, "margin-bottom": `-8px` })}
          noBg
        />
        {/* {' '}
        <RiDeviceGamepadLine />
        <FaSolidGamepad/>
        <FaSolidPuzzlePiece/>
        <FaSolidCirclePlus/>
        <FaSolidCube/> */}
        {' '}
        {authInGameText()}
      </A>
    </EngineContext.Provider>
  )
}

export default ContinueOrSpectateGameButton