import { useSearchParams } from '@solidjs/router'
import { createEffect, createMemo, Show, Suspense } from 'solid-js'
import AlertBag from '../../components/AlertBag'
import GamePlayCanvas from '../../components/GamePlayCanvas'
import Loader from '../../components/Loader'
import { StateType } from '../../lib/core/state/state_type.enum'
import { parseIntOrNull } from '../../lib/core/util/math'
import refetchOnHotModuleReload from '../../lib/hmr/refetchOnHotModuleReload'
import createRedirectIfWrongStateTypeEffect from '../../rx/effect/createRedirectIfWrongStateTypeEffect'
import createGameRowResource from '../../rx/resource/createGameRowResource'
import { createLoadingSignal, registerLoadingResource } from '../../rx/signal/create_loading_signal'
import { createAlertBagStore, registerAlertBagResource, setAlertBagWarning } from '../../rx/store/create_alert_bag_store'
import type { Nullable } from '../../typescript'

type GamePlayPageSearchParams = {
  game_id: string
}

const GamePlayPage = () => {
  const [searchParams] = useSearchParams<GamePlayPageSearchParams>()

  const loading = createLoadingSignal()
  const ab = createAlertBagStore()

  // const stateId = createValibotSafeParseMemo(ProfileIdSchema, () => parseIntOrNull(searchParams.game_id))
  const stateId = createMemo<Nullable<number>>((): Nullable<number> => parseIntOrNull(searchParams.game_id))

  // eslint-disable-next-line solid/reactivity
  const stateItemResource = createGameRowResource(stateId)
  refetchOnHotModuleReload(stateItemResource)
  const [stateRow] = stateItemResource
  createRedirectIfWrongStateTypeEffect(StateType.Game, () => stateRow()?.data?.type, () => stateRow()?.data?.online?.id)

  registerLoadingResource(loading, stateItemResource)
  registerAlertBagResource(ab, stateItemResource)

  createEffect(() => {
    const deleted_at = stateRow()?.deleted_at
    if (deleted_at) {
      setAlertBagWarning(ab, 'This map has been marked for deletion and will soon be removed by an automated garbage collection process.')
    } else {
      setAlertBagWarning(ab, null)
    }
  })

  // TODO only GamePlayCanvas can be seen
  return (
    <div id="GamePlayPage">
      <Loader signal={loading} />
      <AlertBag store={ab} />
      <Suspense fallback={<div class="text-muted">Loading...</div>}>
        <Show when={stateRow()}>
          {(sr) => <GamePlayCanvas row={sr()} />}
        </Show>
      </Suspense>
    </div>
  )
}

export default GamePlayPage