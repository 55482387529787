export type HasPrice = {
  price: number,
}

export function byPriceAsc(a: HasPrice, b: HasPrice) {
  return a.price - b.price
}
export function byPriceDesc(a: HasPrice, b: HasPrice) {
  return b.price - a.price
}
