import { OptionalNullableProfileIDSchema } from "@sg/st1/src/lib/core/ProfileId.type"
import { literal, number, object, optional, string, type InferOutput } from "valibot"
import { JwtTokenType } from "./JwtTokenType.enum"

const JwtTokenExpireSchema = number()
const GuestSchema = optional(literal(1))

export const BackendAccessTokenPayloadSchema = object({
  t: literal(JwtTokenType.ACCESS),
  exp: JwtTokenExpireSchema,
  sub: OptionalNullableProfileIDSchema,
  guest: GuestSchema,
})

export type BackendAccessTokenPayload = InferOutput<typeof BackendAccessTokenPayloadSchema>

export const BackendRefreshTokenPayloadSchema = object({
  t: literal(JwtTokenType.REFRESH),
  exp: JwtTokenExpireSchema,
  sub: OptionalNullableProfileIDSchema,
  guest: GuestSchema,
})

export type BackendRefreshTokenPayload = InferOutput<typeof BackendRefreshTokenPayloadSchema>

export const BackendSessionPayloadSchema = object({
  exp: JwtTokenExpireSchema,
  sub: OptionalNullableProfileIDSchema,
  guest: GuestSchema,
  at: string(),
  rt: string(),
})

export type BackendSessionPayload = InferOutput<typeof BackendSessionPayloadSchema>
