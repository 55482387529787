import type { HasWeapons } from '../HasWeapons'
import type { Entity } from './index'
import isWeaponUsable from './isWeaponUsable'

export default function unitHasUsableRangedAttackWeapon(unit: Entity): unit is Entity & HasWeapons {
  if ('weapons' in unit && unit.weapons) {
    for (let index = 0; index < unit.weapons.length; index++) {
      const weapon = unit.weapons[index]
      if (isWeaponUsable(weapon) && weapon.maxRange) {
        return true
      }
    }
  }
  return false
}
