export type GridXY<T> = T[][]

export default function createGridXyOfEmptyArrays<T>(
  gridWidth: number,
  gridHeight: number): GridXY<Array<T>> {
  const grid: GridXY<Array<T>> = Array.from({ length: gridWidth }, () => {
    return Array.from({ length: gridHeight }, () => [])
  })
  // const grid: GridXY<Array<T>> = Array.from({ length: gridWidth }, () => {
  //   const arr = Array(gridHeight)
  //   for (let index = 0; index < arr.length; index++) {
  //     arr[index] = []
  //   }
  //   return arr
  // })
  return grid
}
