import { StoreLobbyBody, StoreLobbyBodySchema } from '@sg/backend/src/api/st1/lobby/handleStoreLobby'
import { DESCRIPTION_MAXIMUM, DESCRIPTION_MINIMUM, DNAME_MAXIMUM, DNAME_MINIMUM, GAME_PASSPHRASE_MAXIMUM, GAME_PASSPHRASE_MINIMUM } from '@sg/shared/src/valibot/schema/DisplayName.type'
import { A, useNavigate, useSearchParams } from '@solidjs/router'
import { Button, FormGroup, FormLabel, FormText } from 'solid-bootstrap'
import { FaSolidCircleArrowRight } from 'solid-icons/fa'
import { Component, createEffect, createMemo, createSignal, on, onMount, Show, Suspense } from 'solid-js'
import { modifyMutable, produce } from 'solid-js/store'
import { parse, safeParse } from 'valibot'
import { backendAxios } from '../../axios'
import AlertBag from '../../components/AlertBag'
import Breadcrumbs from '../../components/Breadcrumb/Breadcrumbs'
import FormSection from '../../components/Form/FormSection'
import InvalidFeedback from '../../components/Form/InvalidFeedback'
import LoadingIcon from '../../components/LoadingIcon'
import MapShowCanvas from '../../components/MapShowCanvas'
import NA from '../../components/Util/na'
import toastError from '../../lib/AlertBag/toastError'
import createEngineForUI from '../../lib/core/engine/createEngineForUI'
import { hasCloud } from '../../lib/core/has_cloud'
import { hasLocal } from '../../lib/core/has_local'
import { createMapToLobbyAction, MapToLobbyActionSchema } from '../../lib/core/state/flux/action/Map/MapToLobbyAction'
import mapDisplayName from '../../lib/core/state/mapDisplayName'
import { StateType } from '../../lib/core/state/state_type.enum'
import { StateOnlineIdSchema, type StateOnlineId } from '../../lib/core/state/StateOnlineId.type'
import { parseIntOrNull } from '../../lib/core/util/math'
import createIsDotKeyInvalidDerivedSignal from '../../rx/derived_signal/createIsDotKeyInvalidDerivedSignal'
import createRedirectIfWrongStateTypeEffect from '../../rx/effect/createRedirectIfWrongStateTypeEffect'
import createSetEngineAuthPlayerIdFromAuthProfileSignalEffect from '../../rx/effect/createSetEngineAuthPlayerIdFromAuthProfileSignalEffect'
import createMapRowResource from '../../rx/resource/createMapRowResource'
import createAuthProfileSignal from '../../rx/shared/profile/createAuthProfileSignal'
import canSessionCreateGame from '../../rx/shared/session/canSessionCreateGame'
import { createSessionSignal } from '../../rx/shared/session/createSessionSignal'
import { createLoadingSignal } from '../../rx/signal/create_loading_signal'
import { createAlertBagStore, setAlertBagError } from '../../rx/store/create_alert_bag_store'
import type { Nullable } from '../../typescript'

type PlayCreateGamePageStep1SearchParams = {
  map_id: string
  // tab: MenuTabMetaKey
}

function createLobbyRowFormData(): StoreLobbyBody {
  return {
    dname: '',
    bio: null,
    passphrase: null,
    map_id: null as unknown as StateOnlineId,
  }
}

const PlayCreateLobbyPage: Component = () => {
  console.log('PlayCreateLobbyPage')
  const navigate = useNavigate()
  const [searchParams] = useSearchParams<PlayCreateGamePageStep1SearchParams>()

  onMount(() => {
    if (!searchParams.map_id) {
      // direct the user to pick a map first
      navigate(`/maps`)
    }
  })

  const loading = createLoadingSignal()
  const ab = createAlertBagStore()

  const session = createSessionSignal()[0]
  const [authProfile] = createAuthProfileSignal()

  const stateId = createMemo<Nullable<number>>((): Nullable<number> => parseIntOrNull(searchParams.map_id))
  // eslint-disable-next-line solid/reactivity
  const mapRowResource = createMapRowResource(stateId)
  const [mapRow] = mapRowResource
  createRedirectIfWrongStateTypeEffect(StateType.Map, () => mapRow()?.data?.type, ()=>mapRow()?.data?.online?.id)

  const [isOnline, setIsOnline] = createSignal<boolean>(!hasLocal)
  const [formData, setFormData] = createSignal<StoreLobbyBody>(createLobbyRowFormData())
  const formDataValidation = createMemo(() => safeParse(StoreLobbyBodySchema, formData()))
  // eslint-disable-next-line solid/reactivity
  const isDotPathInvalid = createIsDotKeyInvalidDerivedSignal(formDataValidation)

  createEffect(on(stateId, (newMapId) => {
    if (newMapId) {
      setFormData((fd)=>({ ...fd, map_id: newMapId}))
    }
  }))

  const hasErrors = () => !formDataValidation().success
  const disableForm = createMemo<boolean>(() => {
    return Boolean(hasErrors() || loading())
  })

  const needsToSignInBeforeCanCreateGame = createMemo(() => {
    return isOnline() && !canSessionCreateGame(session())
  })

  createEffect(on(stateId, (stateId : Nullable<number>) => {
    setFormData(produce((fd) => {
      fd.map_id = stateId as number
    }))
  }))

  function handleClickToMaps() {
    navigate('/maps')
  }

  const engine = createEngineForUI()

  createSetEngineAuthPlayerIdFromAuthProfileSignalEffect(engine, authProfile)

  createEffect(on(mapRow, (mapRow) => {
    const data = mapRow?.data
    if (data) {
      modifyMutable(engine, produce((engine) => {
        engine.state = data
        engine.prevState = data
      }))
    }
  }))

  async function handleGotoStep2ButtonClick() {
    try {
      loading.start()

      const authProfileValue = authProfile()
      if (!authProfileValue?.id) {
        setAlertBagError(ab, 'Sign In Required.')
        return
      }

      const mapId = stateId()
      const action = parse(MapToLobbyActionSchema, createMapToLobbyAction(authProfileValue))
      const path = `/st1/map/${mapId}`
      const body = { action }
      const response = await backendAxios.post(path, body)
      const lobbyId = parse(StateOnlineIdSchema, response.data.id)
      if (!lobbyId) {
        throw new Error('Unexpected !lobbyId')
      }
      navigate(`/game/create?game_id=${lobbyId}`)
    } catch(error) {
      console.error(error)
      setAlertBagError(ab, error as Error)
      toastError(error)
    } finally {
      loading.end()
    }
  }

  return (
    <Suspense fallback={<div class="text-muted">Loading...</div>}>
      <div>
        <Breadcrumbs>
          <A href="/lobby/create">Create Game</A>
        </Breadcrumbs>
        <div class="container overflow-y-auto overflow-x-hidden w-32-rem h-75vh">
          <FormSection show={true}>
            <h2>Simple Tactics - Play - New Game</h2>
            {/* <BreakPointText /> */}
          </FormSection>
          <FormSection show={true}>
            {/* <h4>Basic</h4> */}
            <FormGroup>
              <FormLabel class="w-25">Type</FormLabel>
              <select class="form-control w-75 d-inline" name="is_online"
                value={isOnline() ? '1' : '0'}
                onInput={(event: InputEvent) => {
                  setIsOnline((event.target as HTMLSelectElement)?.value === '1')
                }}
              >
                {hasLocal && <option value="0">Offline</option>}
                {hasCloud && <option value="1">Cloud</option>}
              </select>
              {needsToSignInBeforeCanCreateGame() && <FormText>
                <A href="/auth/sign-in">Sign In</A> to play online
              </FormText>}
            </FormGroup>
            {isOnline() && <>
              <h3>Cloud options</h3>
              <FormGroup>
                <FormLabel class="w-25">Name</FormLabel>
                <input class="form-control w-75 d-inline" name="dname" type="text"
                  value={formData().dname || ''}
                  onInput={(event: InputEvent) => {
                    // console.log('onInput', (event.target as HTMLInputElement)?.value)
                    setFormData(produce((fd) => {
                      fd.dname = (event.target as HTMLInputElement)?.value
                    }))
                  }}
                  minLength={DNAME_MINIMUM} maxLength={DNAME_MAXIMUM}
                  classList={{'is-invalid': isDotPathInvalid('dname')}}
                />
                <InvalidFeedback name="dname" v={formDataValidation()}/>
                <FormText>optional short description</FormText>
              </FormGroup>
              <FormGroup>
                <FormLabel class="w-25">Passphrase</FormLabel>
                <input class="form-control w-75 d-inline" name="passphrase" type="text"
                  value={formData().passphrase || ''}
                  onInput={(event: InputEvent) => {
                    setFormData(produce((fd) => {
                      fd.passphrase = (event.target as HTMLInputElement)?.value
                    }))
                  }}
                  minLength={GAME_PASSPHRASE_MINIMUM} maxLength={GAME_PASSPHRASE_MAXIMUM}
                  classList={{'is-invalid': isDotPathInvalid('passphrase')}}
                  />
                <InvalidFeedback name="passphrase" v={formDataValidation()}/>
                <FormText>If left non-blank, only users who know the game's secret passphrase can join.</FormText>
              </FormGroup>
              <FormGroup>
                <FormLabel>Description &nbsp;<FormText class="d-inline">optional long description</FormText></FormLabel>
                <textarea class="form-control" name="desc"
                  value={formData().bio || ''}
                  onInput={(event: InputEvent) => {
                    setFormData(produce((fd) => {
                      fd.bio = (event.target as HTMLTextAreaElement)?.value
                    }))
                  }}
                  minLength={DESCRIPTION_MINIMUM} maxLength={DESCRIPTION_MAXIMUM}
                  classList={{'is-invalid': isDotPathInvalid('bio')}}
                />
                <InvalidFeedback name="bio" v={formDataValidation()}/>
              </FormGroup>
            </>/** end online */}
          </FormSection>
          <FormSection show={true}>
            <h4>{mapDisplayName(mapRow()?.data) || NA()}</h4>
            <div class="text-center">
              <Suspense fallback={<div>Loading...</div>}>
                <Show when={mapRow()?.data}>
                  {(state) => <MapShowCanvas state={state()} width={480} height={480} />}
                </Show>
              </Suspense>
            </div>
          </FormSection>
        </div>
        <div class="container w-32-rem pt-2">
          <button class="btn btn-secondary btn-sm float-start" onClick={handleClickToMaps}>Back to Maps</button>

          <div class="d-inline-block" style={{"position":"absolute","top":"0"}}>
            <AlertBag store={ab} />
          </div>

          <Button class="btn btn-primary float-end" disabled={disableForm()} onClick={handleGotoStep2ButtonClick}>
            {loading()
              ? <LoadingIcon />
              : <FaSolidCircleArrowRight/>}{' '}Next
          </Button>
        </div>
      </div>
    </Suspense>
  )
}

export default PlayCreateLobbyPage
