import type { Weapon } from './Weapon.type'
import { WeaponTypeId } from './WeaponTypeId.enum'

const maxAmmo = 8

export default function createMediumCannonWeapon(str: number): Weapon {
  return {
    wt_id: WeaponTypeId.TankCannon,
    str,
    maxAmmo,
    ammo: maxAmmo,
    ammops: 1,
    ca: true,
  }
}
