import { Component, Resource } from 'solid-js'
import { LoadingSignal } from '../rx/signal/create_loading_signal'

interface Props {
  signal?: LoadingSignal,
  resource?: Resource<unknown>,
}

const Loader: Component<Props> = (props) => {
  return <>
    {(props?.signal?.() || props?.resource?.loading) ? <div class="loader" aria-label="loading animation">
      <div>.</div>
    </div> : null}
  </>
}

export default Loader