import { A } from '@solidjs/router'
import { Component } from 'solid-js'
import NA from '../Util/na'
import { UserProfile } from '../../rx/shared/profile/user_profile.type'
import { Nullable } from '../../typescript'

interface Props {
  user: Nullable<UserProfile>,
}

const UserLink: Component<Props> = (props) => {
  return <>
    {props.user ? <A href={`/user/${props.user?.id}`}>{props.user?.dname || props.user?.handle || props.user?.id}</A> : NA()}
  </>
}

export default UserLink
