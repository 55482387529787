import { WeaponTypeId } from './WeaponTypeId.enum'
import type { WeaponTypeMeta } from './WeaponTypeMeta.type'

export const weaponTypeMetaList: Array<WeaponTypeMeta> = [
  { id: WeaponTypeId.None, dname: 'None' },
  { id: WeaponTypeId.InfantryMachineGun, dname: 'Machine Gun (infantry)' },
  { id: WeaponTypeId.VehicleMachineGun, dname: 'Machine Gun (vehicle)' },
  { id: WeaponTypeId.AntiAirCannon, dname: 'Anti Air Canon' },
  { id: WeaponTypeId.TankCannon, dname: 'Cannon' },
  { id: WeaponTypeId.ArtillaryCannon, dname: 'Artillary' },
  { id: WeaponTypeId.Rocket, dname: 'Rocket' },
  { id: WeaponTypeId.Missile, dname: 'Missile' },
]
