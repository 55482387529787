import { createOffscreenCanvas } from '../create_canvas'
import { getCanvas2dContext } from '../get_canvas_2d_context'

export default function createFadedFrameCanvas(baseFrame: OffscreenCanvas): OffscreenCanvas {
  // const baseFrame = (frames[0] ||= makeFrame(0))
  const elem = createOffscreenCanvas(baseFrame.width, baseFrame.height)

  const ctx = getCanvas2dContext(elem)
  if (ctx) {
    ctx.filter = 'grayscale(10%) brightness(60%) saturate(33%)'
    ctx.drawImage(baseFrame, 0, 0)
    ctx.filter = 'none'
  }
  return elem
}
