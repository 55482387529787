import { literal, object, type InferOutput } from 'valibot'
import { Engine } from '../../../../engine/Engine.type'
import { MAX_PLAYERS } from '../../../../player/consts'
import { Player, PlayerSchema } from '../../../../player/Player.type'
import { ActionType } from '../ActionType'
import type { ActionLog } from '../../ActionLog.type'

export const AddPlayerActionSchema = object({
  type: literal(ActionType.Map.AddPlayer),
  player: PlayerSchema,
})

export type AddPlayerAction = InferOutput<typeof AddPlayerActionSchema>

export function createAddPlayerAction(player: Player): AddPlayerAction {
  return {
    type: ActionType.Map.AddPlayer,
    player,
  }
}

export async function handleAddPlayerAction(
  engine: Engine,
  action: AddPlayerAction,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  actionLog: ActionLog
): Promise<void> {
  const { player } = action
  if (engine.state.players.length >= MAX_PLAYERS) {
    throw new Error(`Max players (${MAX_PLAYERS}) reached`)
  }

  const players = [
    ...engine.state.players.filter((p: Player) => p.id && p.id !== player.id),
    player,
  ]
  engine.state.players = players
}
