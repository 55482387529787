import { InferOutput, integer, maxValue, minValue, number, pipe } from 'valibot'

export const STATE_WIDTH_MIN_VALUE = 8
export const STATE_WIDTH_MAX_VALUE = 32

export const StateWidthSchema = pipe(
  number(),
  integer(),
  minValue(STATE_WIDTH_MIN_VALUE),
  maxValue(STATE_WIDTH_MAX_VALUE)
)

export type StateWidth = InferOutput<typeof StateWidthSchema>
