import { abs } from '../util/math'
import type { TilePositionXY } from './TilePositionXY.type'

const minRange = 1

// Does p1 occupy one of the 4 orthogonal surrounding tiles of p2?
export default function isOrthogonallyAdjacent(
  p1: TilePositionXY | null,
  p2: TilePositionXY | null
): boolean {
  if (p1 && p2) {
    // same object is not a type of adjacency
    if (p2 === p1) return true
    // same position is not a type of adjacency
    if (p1.x == p2.x && p1.y == p2.y) return false

    // Check for orthogonal adjacency (same x or same y, and within 1 unit)
    if (
      (p1.x === p2.x && abs(p1.y - p2.y) === minRange) ||
      (p1.y === p2.y && abs(p1.x - p2.x) === minRange)
    ) {
      return true
    }
  }
  return false
}
