import type { State } from '../state/State.type'
import { floor } from '../util/math'
import { ViewCtx } from '../view_ctx'
import { TilePositionXY } from './TilePositionXY.type'

// @todo I'd like the gaps to not be detected as a position

export function positionFromPixels(
  state: State,
  viewCtx: ViewCtx,
  pixelX: number,
  pixelY: number
): TilePositionXY {
  const { tile_size, pan_x, pan_y, tile_gap_px } = viewCtx
  const tile_size_plus_gap: number = tile_size + tile_gap_px
  const tilePositionX: number = floor((pixelX - pan_x) / tile_size_plus_gap)
  const tilePositionY: number = floor((pixelY - pan_y) / tile_size_plus_gap)
  return { x: tilePositionX, y: tilePositionY } as TilePositionXY
}
