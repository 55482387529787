import { EitherRenderingContext2D } from '../canvas/get_canvas_2d_context'
import makeRenderAPC from '../canvas/sprite_sheet/terrain_l1/apc'
import makeRenderBuilding from '../canvas/sprite_sheet/terrain_l1/building'
import makeRenderDesert from '../canvas/sprite_sheet/terrain_l1/desert'
import makeRenderFactory from '../canvas/sprite_sheet/terrain_l1/factory'
import makeRenderForest from '../canvas/sprite_sheet/terrain_l1/forest'
import makeRenderHQ from '../canvas/sprite_sheet/terrain_l1/hq'
import makeRenderInfantry from '../canvas/sprite_sheet/terrain_l1/infantry'
import makeRenderLightAntiAir from '../canvas/sprite_sheet/terrain_l1/light_anti_air'
import makeRenderLightRecon from '../canvas/sprite_sheet/terrain_l1/light_recon'
import makeRenderLightTank from '../canvas/sprite_sheet/terrain_l1/light_tank'
import makeRenderMediumAntiAir from '../canvas/sprite_sheet/terrain_l1/medium_anti_air'
import makeRenderMediumArtillary from '../canvas/sprite_sheet/terrain_l1/medium_artillary'
import makeRenderMediumCopter from '../canvas/sprite_sheet/terrain_l1/medium_copter'
import makeRenderMediumRockets from '../canvas/sprite_sheet/terrain_l1/medium_rockets'
import makeRenderMediumTank from '../canvas/sprite_sheet/terrain_l1/medium_tank'
import makeRenderMountain from '../canvas/sprite_sheet/terrain_l1/mountain'
import makeRenderPlain from '../canvas/sprite_sheet/terrain_l1/plain'
import makeRenderRoad from '../canvas/sprite_sheet/terrain_l1/road'
import makeRenderSea from '../canvas/sprite_sheet/terrain_l1/sea'
import makeRenderSnow from '../canvas/sprite_sheet/terrain_l1/snow'
import makeRenderTransportCopter from '../canvas/sprite_sheet/terrain_l1/transport_copter'
import { Engine } from './engine/Engine.type'
import { Entity } from './entity'
import { EntityTypeId } from './entity/entity_type_id.enum'
import { LayerMeta } from './layer_meta'

/**
 * why is entity sent to both functions?
 * MakeRenderSpriteFunction has the option of returning
 * a RenderSpriteFunction that is generic to the entity type
 * or specific to the entity itself
 */
export type RenderSpriteFunction = (
  ctx: EitherRenderingContext2D,
  lm: LayerMeta,
  entity: Entity,
  dx: number,
  dy: number,
  tile_size: number
) => void
export type MakeRenderSpriteFunction = (engine: Engine, entity: Entity) => RenderSpriteFunction

export const renderSpriteByIdMap: Array<MakeRenderSpriteFunction> = []
renderSpriteByIdMap[EntityTypeId.Plain] = makeRenderPlain
renderSpriteByIdMap[EntityTypeId.Sea] = makeRenderSea
renderSpriteByIdMap[EntityTypeId.Snow] = makeRenderSnow
renderSpriteByIdMap[EntityTypeId.Desert] = makeRenderDesert
// renderSpriteByIdMap[EntityTypeId.Barren] = makeRenderBarren
renderSpriteByIdMap[EntityTypeId.Mountain] = makeRenderMountain
renderSpriteByIdMap[EntityTypeId.Forest] = makeRenderForest
renderSpriteByIdMap[EntityTypeId.Building] = makeRenderBuilding
renderSpriteByIdMap[EntityTypeId.Road] = makeRenderRoad
renderSpriteByIdMap[EntityTypeId.Factory] = makeRenderFactory
renderSpriteByIdMap[EntityTypeId.HQ] = makeRenderHQ
renderSpriteByIdMap[EntityTypeId.Infantry] = makeRenderInfantry
renderSpriteByIdMap[EntityTypeId.LightRecon] = makeRenderLightRecon
renderSpriteByIdMap[EntityTypeId.LightTank] = makeRenderLightTank
renderSpriteByIdMap[EntityTypeId.MediumTank] = makeRenderMediumTank
renderSpriteByIdMap[EntityTypeId.MediumArtillary] = makeRenderMediumArtillary
renderSpriteByIdMap[EntityTypeId.MediumRockets] = makeRenderMediumRockets
renderSpriteByIdMap[EntityTypeId.LightAntiAir] = makeRenderLightAntiAir
renderSpriteByIdMap[EntityTypeId.MediumAntiAir] = makeRenderMediumAntiAir
renderSpriteByIdMap[EntityTypeId.TransportCopter] = makeRenderTransportCopter
renderSpriteByIdMap[EntityTypeId.MediumCopter] = makeRenderMediumCopter
renderSpriteByIdMap[EntityTypeId.APC] = makeRenderAPC
