import { createBreakpoints } from '@solid-primitives/media'
import { createRoot } from 'solid-js'

const breakpoints = {
  xs: '0px',
  sm: '576px',
  md: '768px',
  lg: '992px',
  xl: '1200px',
  xxl: '1400px',
}

const viewportBreakpointMatches = createRoot(() => {
  const matches = createBreakpoints(breakpoints)

  // PROTIP: if SM is true, then XS is also true
  // so make sure to check largest first
  // createEffect(() => {
  //   console.log('effect.BreakpointCardWidget', {
  //     xs: viewportBreakpointMatches.xs,
  //     sm: viewportBreakpointMatches.sm,
  //     md: viewportBreakpointMatches.md,
  //     lg: viewportBreakpointMatches.lg,
  //     xl: viewportBreakpointMatches.xl,
  //     xxl: viewportBreakpointMatches.xxl,
  //   })
  // })

  return matches
})

export default viewportBreakpointMatches

