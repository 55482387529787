import { fallback, InferOutput, object } from 'valibot'
import { AnnexPointsSchema } from './AnnexPoints.type'
import { NullableEntityIdSchema } from './entity/EntityId.type'

export const HasAnnexPointsSchema = object({
  ap: AnnexPointsSchema,
  ap_ent_id: fallback(NullableEntityIdSchema, null),
})

// ap: fallback(AnnexPointsSchema, 0),
// ap_ent_id: fallback(NullableEntityIdSchema, null),

export type HasAnnexPoints = InferOutput<typeof HasAnnexPointsSchema>
