import { enum_ } from 'valibot'

export enum WeaponInvalidId {
  // Valid = 0,
  OnlyTargetsAir = 2,
  OutOfAmmo = 3,
  BelowMinRange = 4,
  AboveMaxRange = 5,
  ValueNotGreaterThanZero = 6,
  CantCounterAttack = 7,
}

export const WeaponInvalidIdSchema = enum_(WeaponInvalidId, 'Invalid Weapon Invalid ID')
