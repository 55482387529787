import type { HasPlayerId } from '../player/has_player_id'
import { max } from '../util/math'
import findRoad from './findRoad'
import getMoveCostPair from './getMoveCostPair'
import type { Entity } from './index'
import type { HasMoveTypeId } from './move_type_id.enum'

export default function getUnitMoveCostOntoPositionEntityStack(
  // the unit attempting to move
  entity: Entity,
  // all the entities at next position
  positionStack: Array<Entity>
): number | null {
  const { mtype_id } = entity as Entity & HasMoveTypeId
  if (mtype_id) {
    let moveCost: number | null = null
    const roadEnt = findRoad(positionStack)
    for (let index = positionStack.length; index--; ) {
      const item: Entity = positionStack[index]
      const mcp = getMoveCostPair(item.etype_id, mtype_id)
      if (mcp) {
        const mcpValue = mcp.value
        if (mcp.block) {
          // not traversable under any circumstance
          return null
        } else if (roadEnt && mcp.deferToRoad) {
          // defering to road...
        } else if (mcpValue) {
          moveCost = max(moveCost as number, mcpValue)
        }
      }

      // entities on seperate layers can occupy same x,y
      // .e.g a helicopter can pass over or hover over an infantry
      if (
        entity.layer_id === item.layer_id &&
        (entity as HasPlayerId).player_id !== (item as HasPlayerId).player_id
      ) {
        return null
      }
    }
    return moveCost
  }
  return null
}
