import { ParentComponent } from 'solid-js'

interface Props {
  // store: FormTextStore,
  /**
   * @link https://github.com/solidjs/solid/discussions/1737#discussioncomment-7223583
   */
  class?: string,
}

const FormText: ParentComponent<Props> = (props) => {
  return <div
    class="form-text"
    classList={{ [props.class as string]: !!props.class }}
  >
    {props.children}
  </div>
}

export default FormText