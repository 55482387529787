/**
 * This file is automatically generated.
 **/


/**
 * @link ./src/assets/svg/sprite/APC.svg
 **/
export const APC_id = "svg"
export const APC_width = 32
export const APC_height = 32
export const APC_viewBox_x = 0
export const APC_viewBox_y = 0
export const APC_viewBox_w = 32
export const APC_viewBox_h = 32
export const APC_type = "svg"
export const APC_Group1_id = "Group1"
export const APC_Group1_type = "g"
export const APC_Group1_display = "inline"
export const APC_Group1_TreadAxelBgGrey1_fill = "#333333"
export const APC_Group1_TreadAxelBgGrey1_d = new Path2D("M 10,26 H 22 L 26,24 V 22 H 6 V 24")
export const APC_Group1_TreadAxelBgGrey1_id = "TreadAxelBgGrey1"
export const APC_Group1_TreadAxelBgGrey1_sodipodiNodetypes = "cccccc"
export const APC_Group1_TreadAxelBgGrey1_type = "path"
export const APC_Group1_TreadAxelBgGrey1_strokeOpacity = 0.487465
export const APC_Group1_TreadAxelBgGrey1_strokeWidth = 0.5
export const APC_Group1_TreadBlack1_fill = "#000"
export const APC_Group1_TreadBlack1_d = new Path2D("M 5.62,25.22 5.36,25.64 9.06,27.76 9.33,27.35 Z M 26.24,25.29 22.62,27.35 22.83,27.81 26.51,25.72 Z M 10,27.5 V 28 H 21.87 V 27.5 Z")
export const APC_Group1_TreadBlack1_id = "TreadBlack1"
export const APC_Group1_TreadBlack1_sodipodiNodetypes = "ccccccccccccccc"
export const APC_Group1_TreadBlack1_type = "path"
export const APC_Group1_TreadBlack1_display = "inline"
export const APC_Group1_TreadBlack1_opacity = 1
export const APC_Group1_WheelsOuterBlack1_fill = "#000"
export const APC_Group1_WheelsOuterBlack1_d = new Path2D("M 6.5,22 A 2,2 0 0 0 4.5,24 2,2 0 0 0 6.5,26 2,2 0 0 0 8.5,24 2,2 0 0 0 6.5,22 Z M 25.5,22 A 2,2 0 0 0 23.5,24 2,2 0 0 0 25.5,26 2,2 0 0 0 27.5,24 2,2 0 0 0 25.5,22 Z M 10,24 A 2,2 0 0 0 8,26 2,2 0 0 0 10,28 2,2 0 0 0 12,26 2,2 0 0 0 10,24 Z M 12,26 A 2,2 0 0 0 14,28 2,2 0 0 0 16,26 2,2 0 0 0 14,24 2,2 0 0 0 12,26 Z M 16,26 A 2,2 0 0 0 18,28 2,2 0 0 0 20,26 2,2 0 0 0 18,24 2,2 0 0 0 16,26 Z M 20,26 A 2,2 0 0 0 22,28 2,2 0 0 0 24,26 2,2 0 0 0 22,24 2,2 0 0 0 20,26 Z")
export const APC_Group1_WheelsOuterBlack1_id = "WheelsOuterBlack1"
export const APC_Group1_WheelsOuterBlack1_type = "path"
export const APC_Group1_WheelsOuterBlack1_strokeOpacity = 0.487465
export const APC_Group1_WheelsOuterBlack1_strokeWidth = 0.5
export const APC_Group1_WheelsOuterBlack1_fillOpacity = 1
export const APC_Group1_WheelsOuterBlack1_opacity = 1
export const APC_Group1_WheelsInnerGrey1_fill = "#666666"
export const APC_Group1_WheelsInnerGrey1_d = new Path2D("M 6.5,22.5 A 1.5,1.5 0 0 0 5,24 1.5,1.5 0 0 0 6.5,25.5 1.5,1.5 0 0 0 8,24 1.5,1.5 0 0 0 6.5,22.5 Z M 25.5,22.5 A 1.5,1.5 0 0 0 24,24 1.5,1.5 0 0 0 25.5,25.5 1.5,1.5 0 0 0 27,24 1.5,1.5 0 0 0 25.5,22.5 Z M 10,24.5 A 1.5,1.5 0 0 0 8.5,26 1.5,1.5 0 0 0 10,27.5 1.5,1.5 0 0 0 11.5,26 1.5,1.5 0 0 0 10,24.5 Z M 14,24.5 A 1.5,1.5 0 0 0 12.5,26 1.5,1.5 0 0 0 14,27.5 1.5,1.5 0 0 0 15.5,26 1.5,1.5 0 0 0 14,24.5 Z M 18,24.5 A 1.5,1.5 0 0 0 16.5,26 1.5,1.5 0 0 0 18,27.5 1.5,1.5 0 0 0 19.5,26 1.5,1.5 0 0 0 18,24.5 Z M 22,24.5 A 1.5,1.5 0 0 0 20.5,26 1.5,1.5 0 0 0 22,27.5 1.5,1.5 0 0 0 23.5,26 1.5,1.5 0 0 0 22,24.5 Z")
export const APC_Group1_WheelsInnerGrey1_id = "WheelsInnerGrey1"
export const APC_Group1_WheelsInnerGrey1_type = "path"
export const APC_Group1_WheelsInnerGrey1_strokeOpacity = 0.487465
export const APC_Group1_WheelsInnerGrey1_strokeWidth = 0.375
export const APC_Group1_WheelsInnerGrey1_fillOpacity = 1
export const APC_Group1_WheelsInnerGrey1_opacity = 1
export const APC_Group1_HeadLightYellow1_fill = "#ffff00"
export const APC_Group1_HeadLightYellow1_d = new Path2D("M 23,11 V 12 H 22 V 11")
export const APC_Group1_HeadLightYellow1_id = "HeadLightYellow1"
export const APC_Group1_HeadLightYellow1_sodipodiNodetypes = "cccc"
export const APC_Group1_HeadLightYellow1_type = "path"
export const APC_Group1_HeadLightYellow1_strokeOpacity = 0.487465
export const APC_Group1_HeadLightYellow1_strokeWidth = 0.5
export const APC_Group1_BodyLowerGrey1_fill = "#b3b3b3"
export const APC_Group1_BodyLowerGrey1_d = new Path2D("M 5,11 3,15 4,22 5,24 H 27 L 29,22 V 20 L 22,11 Z")
export const APC_Group1_BodyLowerGrey1_id = "BodyLowerGrey1"
export const APC_Group1_BodyLowerGrey1_sodipodiNodetypes = "ccccccccc"
export const APC_Group1_BodyLowerGrey1_type = "path"
export const APC_Group1_BodyLowerGrey1_strokeWidth = 0.935414
export const APC_Group1_BodyLowerGrey1_display = "inline"
export const APC_Group1_BodyLowerGrey1_opacity = 1
export const APC_Group1_BodyHorizontalLineGrey1_fill = "#333333"
export const APC_Group1_BodyHorizontalLineGrey1_d = new Path2D("M 3,20 V 21 H 29 V 20 Z")
export const APC_Group1_BodyHorizontalLineGrey1_id = "BodyHorizontalLineGrey1"
export const APC_Group1_BodyHorizontalLineGrey1_sodipodiNodetypes = "ccccc"
export const APC_Group1_BodyHorizontalLineGrey1_type = "path"
export const APC_Group1_BodyHorizontalLineGrey1_strokeWidth = 1.1547
export const APC_Group1_BodyHorizontalLineGrey1_display = "inline"
export const APC_Group1_BodyLowerGrey2_fill = "#cccccc"
export const APC_Group1_BodyLowerGrey2_d = new Path2D("M 6,21 V 24 H 26 V 21 Z")
export const APC_Group1_BodyLowerGrey2_id = "BodyLowerGrey2"
export const APC_Group1_BodyLowerGrey2_sodipodiNodetypes = "ccccc"
export const APC_Group1_BodyLowerGrey2_type = "path"
export const APC_Group1_BodyLowerGrey2_strokeOpacity = 0.487465
export const APC_Group1_BodyLowerGrey2_strokeWidth = 0.5
export const APC_Group1_BodyHorizontalLineGrey2_fill = "#cccccc"
export const APC_Group1_BodyHorizontalLineGrey2_d = new Path2D("M 3,13 H 25 V 14 H 3 Z")
export const APC_Group1_BodyHorizontalLineGrey2_id = "BodyHorizontalLineGrey2"
export const APC_Group1_BodyHorizontalLineGrey2_sodipodiNodetypes = "ccccc"
export const APC_Group1_BodyHorizontalLineGrey2_type = "path"
export const APC_Group1_BodyHorizontalLineGrey2_strokeOpacity = 0.487465
export const APC_Group1_BodyHorizontalLineGrey2_strokeWidth = 0.5

/**
 * @link ./src/assets/svg/sprite/Barren.svg
 **/
export const Barren_id = "svg"
export const Barren_width = 32
export const Barren_height = 32
export const Barren_viewBox_x = 0
export const Barren_viewBox_y = 0
export const Barren_viewBox_w = 32
export const Barren_viewBox_h = 32
export const Barren_type = "svg"
export const Barren_Barren1_16x16_width = 32
export const Barren_Barren1_16x16_height = 32
export const Barren_Barren1_16x16_preserveAspectRatio = "none"
export const Barren_Barren1_16x16_xlinkHref = "ref/Barren1_16x16.png"
export const Barren_Barren1_16x16_id = "Barren1_16x16"
export const Barren_Barren1_16x16_x = 0
export const Barren_Barren1_16x16_y = 0
export const Barren_Barren1_16x16_type = "image"
export const Barren_Barren1_16x16_display = "none"
export const Barren_Barren1_16x16_imageRendering = "optimizespeed"
export const Barren_Group1_id = "Group1"
export const Barren_Group1_type = "g"
export const Barren_Group1_Light5_fill = "#dede63"
export const Barren_Group1_Light5_d = new Path2D("M 12.34,0.08 8.49,2.68 8.58,3.89 6.93,4.24 6.84,1.69 5.99,1.38 4.96,4.07 4.11,4.65 1.11,6.61 1.47,7.69 2.81,8.36 2.45,10.68 1.47,11.00 1.43,14.22 4.20,15.02 4.47,17.26 5.54,17.71 5.36,18.82 2.68,19.41 2.32,17.39 0.75,17.44 1.25,19.99 1.16,27.68 1.74,28.22 3.39,27.72 4.42,27.99 3.62,29.38 2.81,30.27 2.77,31.88 4.07,31.93 6.21,28.57 8.45,31.88 H 19.45 L 21.42,30.72 24.41,30.90 27.86,31.66 29.65,30.58 30.14,28.89 31.17,28.13 31.93,27.68 31.88,11.53 29.02,10.06 31.30,5.36 27.68,5.5 27.72,1.43 22.00,0.13 Z M 31.88,0.08 30.5,0.22 30.58,2.72 H 31.84 Z M 1.38,0.13 V 2.54 L 2.77,2.59 2.72,0.22 Z")
export const Barren_Group1_Light5_id = "Light5"
export const Barren_Group1_Light5_type = "path"
export const Barren_Group1_Light5_fillOpacity = 1
export const Barren_Group1_Light5_display = "inline"
export const Barren_Group1_Light5_opacity = 1
export const Barren_Group1_Light4_fill = "#c6bd42"
export const Barren_Group1_Light4_d = new Path2D("M 11.98,1.34 9.39,2.63 6.35,5.11 2.30,5.46 4.16,7.42 4.12,11.44 3.30,11.77 2.39,11.84 1.29,12.51 1.68,13.06 1.53,13.32 1.85,13.31 1.96,13.47 4.74,13.56 4.91,13.24 5.60,13.23 6.95,18.69 4.31,18.79 3.83,19.99 0.14,20.03 V 26.55 L 2.25,26.89 2.54,27.90 5.22,27.99 11.16,31.97 15.10,31.78 15.43,30.91 16.53,30.72 19.36,30.68 22.24,28.04 23.63,28.09 23.73,31.97 26.26,31.92 28.66,26.22 29.14,25.64 30.68,25.31 31.92,25.59 31.83,24.01 26.99,23.77 25.16,22.48 25.11,18.88 25.68,18.63 26.36,19.55 28.18,19.99 30.63,20.03 30.53,18.88 27.66,18.64 26.71,18.17 28.85,17.21 29.24,15.96 31.97,17.35 31.87,16.05 29.33,14.76 29.09,9.73 27.85,8.48 30.44,8.10 32.07,6.23 31.01,5.36 30.15,6.66 28.28,6.75 27.56,4.12 Z")
export const Barren_Group1_Light4_id = "Light4"
export const Barren_Group1_Light4_type = "path"
export const Barren_Group1_Light4_fillOpacity = 1
export const Barren_Group1_Light4_display = "inline"
export const Barren_Group1_Light4_opacity = 1
export const Barren_Group1_Green1_fill = "#adad31"
export const Barren_Group1_Green1_d = new Path2D("M 18.38,5.27 17.71,6.57 12.61,8.05 7.37,9.25 5.72,10.82 5.45,11.98 9.52,11.89 11.00,10.64 11.58,9.34 13.81,9.39 17.93,8.00 19.5,6.39 19.41,5.32 Z M 26.16,5.27 25.53,11.89 19.32,13.50 14.75,15.60 15.20,16.27 21.86,16.05 26.11,14.62 27.95,13.46 V 8.90 L 29.07,7.78 V 6.75 L 27.77,5.36 Z M 12.20,15.83 10.15,15.96 9.61,16.72 10.24,17.44 12.20,17.48 12.70,16.59 Z M 7.55,18.64 6.75,19.41 7.51,20.16 12.43,20.52 14.08,22.98 13.81,25.49 9.88,25.22 8.90,26.60 5.72,27.14 5.67,28.04 9.61,27.81 11.22,26.65 16.63,26.56 18.20,25.26 20.79,25.49 22.31,26.56 H 23.47 L 24.19,25.31 26.34,25.40 26.56,24.91 26.25,23.65 24.77,22.67 18.38,22.58 14.49,21.10 13.95,18.73 Z")
export const Barren_Group1_Green1_id = "Green1"
export const Barren_Group1_Green1_type = "path"
export const Barren_Group1_Green1_fillOpacity = 1
export const Barren_Group1_Green1_display = "inline"
export const Barren_Group1_Green1_opacity = 1
export const Barren_Group1_Green2_fill = "#9c9c21"
export const Barren_Group1_Green2_d = new Path2D("M 18.33 6.61 L 16.86 6.88 L 13.81 7.69 L 8.90 9.30 L 7.11 10.95 L 7.02 11.85 L 9.61 11.94 L 11.26 10.68 L 11.98 9.39 L 14.08 9.39 L 17.88 7.91 L 18.33 6.61 z M 27.14 7.87 L 26.52 8.31 L 24.50 12.47 L 23.43 13.46 L 20.92 13.59 L 19.99 14.84 L 17.97 14.71 L 14.84 15.92 L 21.60 16.05 L 26.07 14.53 L 27.63 13.28 L 27.77 8.90 L 27.14 7.87 z M 14.40 18.56 L 12.03 18.69 L 11.09 19.00 L 11.26 19.58 L 13.46 20.21 L 14.71 19.85 L 14.40 18.56 z M 13.86 21.15 L 13.68 22.40 L 15.42 24.06 L 23.70 24.15 L 23.83 22.58 L 18.11 22.22 L 13.86 21.15 z ")
export const Barren_Group1_Green2_id = "Green2"
export const Barren_Group1_Green2_type = "path"
export const Barren_Group1_Green2_fillOpacity = 1
export const Barren_Group1_Green2_opacity = 1
export const Barren_Group1_Light3_fill = "#d6ce4a"
export const Barren_Group1_Light3_d = new Path2D("M 17.97,1.29 17.94,2.15 17.22,1.30 12.52,1.45 12.61,2.37 11.55,2.73 9.25,3.97 7.04,5.41 7,9.15 9.01,9.25 13.14,7.99 13.15,8.03 18.5,6.5 18.32,3.29 19.46,5.32 22.96,5.27 26.31,3.93 25.98,2.73 22.81,1.39 Z M 23.59,5.21 19.41,6.79 17.41,8.23 12.89,9.53 11.26,11.02 11.36,15.96 14.71,15.82 21.54,13.48 22.03,13.32 C 22.04,13.33 22.06,13.34 22.07,13.35 L 26.58,12.30 26.52,5.40 Z M 0.09,5.32 -0.09,6.56 2.07,6.52 2.87,8.00 3.97,8.10 4.16,6.85 2.57,5.49 2.58,5.32 Z M 9.53,11.84 4.16,12.12 4.36,13.32 5.80,13.71 5.56,16.97 6.61,17.35 6.61,17.34 6.75,18.69 14.04,18.83 14.23,21.38 18.83,22.58 C 18.82,22.48 18.80,22.38 18.79,22.29 L 23.34,22.67 23.58,22.09 23.20,21.28 22.14,19.51 19.08,17.35 17.97,16.25 17.89,17.57 C 17.69,17.04 17.42,16.52 17.06,16.01 L 13.85,16.30 13.92,17.21 9.77,17.49 7.19,16.97 6.64,17.28 7.28,15.91 8.62,13.32 9.73,13.08 Z M 29.24,12.12 V 14.57 L 30.48,14.71 30.63,12.22 Z M 29.76,16.10 28.71,17.30 H 26.60 L 25.07,19.41 25.02,22.67 26.79,23.96 31.63,24.06 31.87,22.43 30.82,21.38 29.09,20.47 27.18,20.23 26.41,19.51 27.13,18.74 H 30.53 L 30.48,16.73 Z M 0.04,19.94 V 25.35 L 2.30,25.45 2.92,26.75 8.15,26.84 9.58,25.45 14.18,25.50 14.04,22.62 12.22,19.99 H 4.98 L 3.73,21.33 1.91,21.28 1.19,20.03 Z M 31.68,25.26 26.17,26.99 23.63,28.28 V 30.72 L 27.70,30.58 26.99,28.52 30.39,27.75 31.78,26.60 Z M 18.02,25.35 16.87,26.55 11.36,26.79 10.16,27.85 4.31,28.14 4.45,29.24 6.71,29.33 8.91,30.87 10.64,31.73 11.12,30.77 14.18,30.72 15.38,29.81 18.36,29.57 20.70,30.68 22.24,28.85 22.00,26.55 20.90,25.45 Z")
export const Barren_Group1_Light3_id = "Light3"
export const Barren_Group1_Light3_type = "path"
export const Barren_Group1_Light3_fillOpacity = 1
export const Barren_Group1_Light3_display = "inline"
export const Barren_Group1_Light3_opacity = 1
export const Barren_Group1_Light2_fill = "#f7e763"
export const Barren_Group1_Light2_d = new Path2D("M 17.22 1.30 L 12.52 1.45 L 12.62 8.08 L 18.5 6.5 L 18.28 2.55 L 17.22 1.30 z M 23.5 5.5 L 20.99 6.79 L 19.51 9.14 L 15.72 9.34 L 13.90 12.75 L 13.94 14.14 L 15.62 14.66 L 18.74 14.02 L 21.90 13.56 L 23.17 13.00 L 26.58 11.92 L 26 5.5 L 23.5 5.5 z M 16.20 16.15 L 13.90 16.68 L 14.57 21.38 L 16.92 20.99 L 16.20 16.15 z ")
export const Barren_Group1_Light2_id = "Light2"
export const Barren_Group1_Light2_type = "path"
export const Barren_Group1_Light2_fillOpacity = 1
export const Barren_Group1_Light2_display = "inline"
export const Barren_Group1_Light2_opacity = 1
export const Barren_Group1_Light1_fill = "#ffef84"
export const Barren_Group1_Light1_d = new Path2D("M 18.28,2.55 17.22,1.30 14.54,1.93 15.5,6.5 H 18.5 Z M 26,5.5 H 23.5 L 20.99,6.79 19.25,9.57 V 13.31 L 26.25,11.92 Z")
export const Barren_Group1_Light1_id = "Light1"
export const Barren_Group1_Light1_sodipodiNodetypes = "ccccccccccccc"
export const Barren_Group1_Light1_type = "path"
export const Barren_Group1_Light1_fillOpacity = 1
export const Barren_Group1_Light1_display = "inline"
export const Barren_Group1_Light1_opacity = 1

/**
 * @link ./src/assets/svg/sprite/Building.svg
 **/
export const Building_id = "svg"
export const Building_width = 32
export const Building_height = 64
export const Building_viewBox_x = 0
export const Building_viewBox_y = 0
export const Building_viewBox_w = 32
export const Building_viewBox_h = 64
export const Building_type = "svg"
export const Building_Group1_id = "Group1"
export const Building_Group1_type = "g"
export const Building_Group1_display = "inline"
export const Building_Group1_BuildingGrey1_fill = "#c0b8c0"
export const Building_Group1_BuildingGrey1_d = new Path2D("M 4,24 V 34 H 2 0 V 64 H 10 V 60 H 4 V 58 H 14 V 52 H 16 V 56 C 19.65,56 22.69,56 26,56 V 60 H 20 V 64 H 30 V 36 H 28 V 30 H 18 V 24 Z")
export const Building_Group1_BuildingGrey1_id = "BuildingGrey1"
export const Building_Group1_BuildingGrey1_sodipodiNodetypes = "cccccccccccccccccccccccc"
export const Building_Group1_BuildingGrey1_type = "path"
export const Building_Group1_BuildingGrey1_strokeOpacity = 0.487465
export const Building_Group1_BuildingGrey1_strokeWidth = 0.5
export const Building_Group1_BuildingGrey1_fillOpacity = 1
export const Building_Group1_BuildingGrey1_display = "inline"
export const Building_Group1_BuildingGrey1_opacity = 1
export const Building_Group1_RoofBeige1_fill = "#f0e8d0"
export const Building_Group1_RoofBeige1_d = new Path2D("M 6,26 V 30 H 16 V 26 Z M 16,32 V 38 H 26 V 32 Z M 0,34 V 62 H 10 V 60 H 2 V 36 H 4 V 42 H 14 V 36 H 4 V 34 Z M 26,48 V 60 H 20 V 62 H 28 V 48 Z")
export const Building_Group1_RoofBeige1_id = "RoofBeige1"
export const Building_Group1_RoofBeige1_sodipodiNodetypes = "cccccccccccccccccccccccccccccc"
export const Building_Group1_RoofBeige1_type = "path"
export const Building_Group1_RoofBeige1_strokeOpacity = 0.487465
export const Building_Group1_RoofBeige1_strokeWidth = 0.5
export const Building_Group1_RoofBeige1_fillOpacity = 1
export const Building_Group1_RoofBeige1_display = "inline"
export const Building_Group1_RoofBeige1_opacity = 1
export const Building_Group1_WindowDot1_fill = "#786878"
export const Building_Group1_WindowDot1_d = new Path2D("M 22,42 V 44 H 24 V 42 Z M 18,42 V 44 H 20 V 42 Z M 10,44 V 46 H 12 V 44 Z M 6,44 V 46 H 8 V 44 Z M 24,46 H 22 V 48 H 24 Z M 18,46 V 48 H 20 V 46 Z M 6,48 V 50 H 8 V 48 Z M 12,48 H 10 V 49.97 L 12,50 Z M 18,50 V 52 H 20 V 50 Z M 22,50 V 52 H 24 V 50 Z M 6,52 V 54 H 8 V 52 Z M 10,52 V 54 H 12 V 52 Z")
export const Building_Group1_WindowDot1_id = "WindowDot1"
export const Building_Group1_WindowDot1_sodipodiNodetypes = "cccccccccccccccccccccccccccccccccccccccccccccccccccccccccccc"
export const Building_Group1_WindowDot1_type = "path"
export const Building_Group1_WindowDot1_strokeOpacity = 0.487465
export const Building_Group1_WindowDot1_strokeWidth = 0.5
export const Building_Group1_WindowDot1_fillOpacity = 1
export const Building_Group1_WindowDot1_display = "inline"
export const Building_Group1_WindowDot1_opacity = 1
export const Building_Group1_Shadow1_fill = "#9888c8"
export const Building_Group1_Shadow1_d = new Path2D("M 28,36 V 62 H 20 V 64 H 30 V 60 H 32 V 42 H 30 V 36 Z M 2,50 V 60 H 4 6 V 58 H 4 V 50 Z M 14,52 V 58 H 18 V 56 H 16 V 52 Z M 0,62 V 64 H 10 14 V 62 H 10 Z")
export const Building_Group1_Shadow1_id = "Shadow1"
export const Building_Group1_Shadow1_sodipodiNodetypes = "ccccccccccccccccccccccccccccccccc"
export const Building_Group1_Shadow1_type = "path"
export const Building_Group1_Shadow1_fillOpacity = 1
export const Building_Group1_Shadow1_display = "inline"
export const Building_Group1_Shadow1_opacity = 1
export const Building_Group1_Building1_16x16_width = 32
export const Building_Group1_Building1_16x16_height = 64
export const Building_Group1_Building1_16x16_preserveAspectRatio = "none"
export const Building_Group1_Building1_16x16_xlinkHref = "ref/Building1_16x16.png"
export const Building_Group1_Building1_16x16_id = "Building1_16x16"
export const Building_Group1_Building1_16x16_x = 0
export const Building_Group1_Building1_16x16_y = 0
export const Building_Group1_Building1_16x16_type = "image"
export const Building_Group1_Building1_16x16_fill = "#c0b8c0"
export const Building_Group1_Building1_16x16_fillOpacity = 1
export const Building_Group1_Building1_16x16_display = "none"
export const Building_Group1_Building1_16x16_imageRendering = "optimizespeed"

/**
 * @link ./src/assets/svg/sprite/CursorTriangle.svg
 **/
export const CursorTriangle_id = "svg"
export const CursorTriangle_width = 32
export const CursorTriangle_height = 32
export const CursorTriangle_viewBox_x = 0
export const CursorTriangle_viewBox_y = 0
export const CursorTriangle_viewBox_w = 32
export const CursorTriangle_viewBox_h = 32
export const CursorTriangle_type = "svg"
export const CursorTriangle_Group1_id = "Group1"
export const CursorTriangle_Group1_type = "g"
export const CursorTriangle_Group1_display = "inline"
export const CursorTriangle_Group1_Grey1_fill = "#e6e6e6"
export const CursorTriangle_Group1_Grey1_d = new Path2D("M 0,0 V 32 L 11,21 V 11 H 21 L 32,0 Z")
export const CursorTriangle_Group1_Grey1_id = "Grey1"
export const CursorTriangle_Group1_Grey1_sodipodiNodetypes = "ccccccc"
export const CursorTriangle_Group1_Grey1_type = "path"
export const CursorTriangle_Group1_Grey1_strokeWidth = 0.5
export const CursorTriangle_Group1_Grey1_fillOpacity = 1
export const CursorTriangle_Group1_Black1_fill = "#000"
export const CursorTriangle_Group1_Black1_d = new Path2D("M 1,1 V 29.5 L 10,20.5 V 10 H 20.5 L 29.5,1 Z")
export const CursorTriangle_Group1_Black1_id = "Black1"
export const CursorTriangle_Group1_Black1_sodipodiNodetypes = "ccccccc"
export const CursorTriangle_Group1_Black1_type = "path"
export const CursorTriangle_Group1_Black1_strokeWidth = 0.5
export const CursorTriangle_Group1_Black1_fillOpacity = 1
export const CursorTriangle_Group1_Black1_display = "inline"
export const CursorTriangle_Group1_Yellow1_fill = "#fffd67"
export const CursorTriangle_Group1_Yellow1_d = new Path2D("M 2,2 V 27 L 9,20 V 9 H 20 L 27,2 Z")
export const CursorTriangle_Group1_Yellow1_id = "Yellow1"
export const CursorTriangle_Group1_Yellow1_sodipodiNodetypes = "ccccccc"
export const CursorTriangle_Group1_Yellow1_type = "path"
export const CursorTriangle_Group1_Yellow1_strokeWidth = 0.5
export const CursorTriangle_Group1_Yellow1_fillOpacity = 1
export const CursorTriangle_Group1_Yellow1_display = "inline"

/**
 * @link ./src/assets/svg/sprite/Eraser.svg
 **/
export const Eraser_id = "svg"
export const Eraser_width = 32
export const Eraser_height = 32
export const Eraser_viewBox_x = 0
export const Eraser_viewBox_y = 0
export const Eraser_viewBox_w = 32
export const Eraser_viewBox_h = 32
export const Eraser_type = "svg"
export const Eraser_Group1_id = "Group1"
export const Eraser_Group1_type = "g"
export const Eraser_Group1_display = "inline"
export const Eraser_Group1_image7794_width = 35.305027
export const Eraser_Group1_image7794_height = 35.305027
export const Eraser_Group1_image7794_preserveAspectRatio = "none"
export const Eraser_Group1_image7794_xlinkHref = "../../../../../../../Desktop/Screenshot%202024-02-18%20at%204.45.02%E2%80%AFPM.png"
export const Eraser_Group1_image7794_id = "image7794"
export const Eraser_Group1_image7794_x = -6.5441904
export const Eraser_Group1_image7794_y = 4.2066622
export const Eraser_Group1_image7794_transform = "rotate(-12.266742)"
export const Eraser_Group1_image7794_type = "image"
export const Eraser_Group1_image7794_display = "none"
export const Eraser_Group1_image7794_imageRendering = "optimizespeed"
export const Eraser_Group1_BodyPink1_fill = "#e3a3f3"
export const Eraser_Group1_BodyPink1_d = new Path2D("M 3.20,19.70 C 2.16,21.75 1.50,23.86 1,26 2.57,28.13 4.27,30.85 7.20,31.04 9.68,30.65 11.74,29.00 13.87,27.78 18.70,24.69 23.33,21.31 28,18 29.15,16.17 30.79,13.57 29.61,11.48 27.95,9.35 25.68,7.57 23,7 15.86,11.23 8.14,15.46 3.20,19.70 Z")
export const Eraser_Group1_BodyPink1_id = "BodyPink1"
export const Eraser_Group1_BodyPink1_sodipodiNodetypes = "cccccccc"
export const Eraser_Group1_BodyPink1_type = "path"
export const Eraser_Group1_BodyPink1_strokeWidth = 0.5
export const Eraser_Group1_BodyPink1_fillOpacity = 1
export const Eraser_Group1_BodyPink1_display = "inline"
export const Eraser_Group1_EdgeBlack1_fill = "#b3b3b3"
export const Eraser_Group1_EdgeBlack1_d = new Path2D("M 4.51,21.52 7.33,24.30")
export const Eraser_Group1_EdgeBlack1_id = "EdgeBlack1"
export const Eraser_Group1_EdgeBlack1_type = "path"
export const Eraser_Group1_EdgeBlack1_stroke = "#000"
export const Eraser_Group1_EdgeBlack1_strokeOpacity = 1
export const Eraser_Group1_EdgeBlack1_strokeWidth = 0.5
export const Eraser_Group1_EdgeBlack2_fill = "#dc9bec"
export const Eraser_Group1_EdgeBlack2_d = new Path2D("M 11.61,23.76 26.54,14.02")
export const Eraser_Group1_EdgeBlack2_id = "EdgeBlack2"
export const Eraser_Group1_EdgeBlack2_type = "path"
export const Eraser_Group1_EdgeBlack2_stroke = "#000"
export const Eraser_Group1_EdgeBlack2_strokeOpacity = 1
export const Eraser_Group1_EdgeBlack2_strokeWidth = 0.5
export const Eraser_Group1_EdgeBlack2_fillOpacity = 1
export const Eraser_Group1_EdgeBlack3_fill = "#b3b3b3"
export const Eraser_Group1_EdgeBlack3_d = new Path2D("M 9.27,25.51 7.39,29.59")
export const Eraser_Group1_EdgeBlack3_id = "EdgeBlack3"
export const Eraser_Group1_EdgeBlack3_type = "path"
export const Eraser_Group1_EdgeBlack3_stroke = "#000"
export const Eraser_Group1_EdgeBlack3_strokeOpacity = 1
export const Eraser_Group1_EdgeBlack3_strokeWidth = 0.5

/**
 * @link ./src/assets/svg/sprite/Factory.svg
 **/
export const Factory_id = "svg"
export const Factory_width = 32
export const Factory_height = 64
export const Factory_viewBox_x = 0
export const Factory_viewBox_y = 0
export const Factory_viewBox_w = 32
export const Factory_viewBox_h = 64
export const Factory_type = "svg"
export const Factory_Group1_id = "Group1"
export const Factory_Group1_type = "g"
export const Factory_Group1_display = "inline"
export const Factory_Group1_BodyGrey1_fill = "#c0b8c0"
export const Factory_Group1_BodyGrey1_d = new Path2D("M 14,32 10,35 V 31 H 2 V 48 L 5,49.4 V 58 H 30 V 32 L 22,38 V 32 L 14,38 Z")
export const Factory_Group1_BodyGrey1_id = "BodyGrey1"
export const Factory_Group1_BodyGrey1_sodipodiNodetypes = "ccccccccccccc"
export const Factory_Group1_BodyGrey1_type = "path"
export const Factory_Group1_BodyGrey1_fillOpacity = 1
export const Factory_Group1_BodyGrey1_display = "inline"
export const Factory_Group1_BodyGrey1_opacity = 1
export const Factory_Group1_BodyGrey2_fill = "#c0b8c0"
export const Factory_Group1_BodyGrey2_d = new Path2D("M 0,34 H 30 V 64 H 20 V 60 H 26 V 48 H 4 V 60 H 10 V 64 H 0 Z")
export const Factory_Group1_BodyGrey2_id = "BodyGrey2"
export const Factory_Group1_BodyGrey2_sodipodiNodetypes = "ccccccccccccc"
export const Factory_Group1_BodyGrey2_type = "path"
export const Factory_Group1_BodyGrey2_strokeWidth = 0.5
export const Factory_Group1_BodyGrey2_fillOpacity = 1
export const Factory_Group1_BodyGrey2_display = "inline"
export const Factory_Group1_BodyGrey2_opacity = 1
export const Factory_Group1_WallDots_fill = "#f0e8d0"
export const Factory_Group1_WallDots_d = new Path2D("M 16 34 L 16 36 L 16.66 36 L 18 35.00 L 18 34 L 16 34 z M 24 34 L 24 36 L 24.68 36 L 26 35.01 L 26 34 L 24 34 z M 0 36 L 0 38 L 2 38 L 2 36 L 0 36 z M 0 40 L 0 42 L 2 42 L 2 40 L 0 40 z M 0 44 L 0 46 L 2 46 L 2 44 L 0 44 z M 0 48 L 0 50 L 2 50 L 2 48 L 0 48 z M 0 52 L 0 54 L 2 54 L 2 52 L 0 52 z M 26 54 L 26 56 L 28 56 L 28 54 L 26 54 z M 0 56 L 0 58 L 2 58 L 2 56 L 0 56 z M 26 58 L 26 60 L 28 60 L 28 58 L 26 58 z M 26 60 L 24 60 L 24 62 L 26 62 L 26 60 z M 0 60 L 0 62 L 2 62 L 2 60 L 0 60 z M 4 60 L 4 62 L 6 62 L 6 60 L 4 60 z M 8 60 L 8 62 L 10 62 L 10 60 L 8 60 z M 20 60 L 20 62 L 22 62 L 22 60 L 20 60 z ")
export const Factory_Group1_WallDots_id = "WallDots"
export const Factory_Group1_WallDots_type = "path"
export const Factory_Group1_WallDots_strokeWidth = 0.5
export const Factory_Group1_WallDots_fillOpacity = 1
export const Factory_Group1_WallDots_opacity = 1
export const Factory_Group1_RoofBeige1_fill = "#f0e8d0"
export const Factory_Group1_RoofBeige1_d = new Path2D("M 13,34 6,39.25 V 47.27 L 13,42 V 40 38.75 Z M 21,34 13.99,39.25 13.96,47.27 21,42 V 40 38.75 Z M 29,34 21.99,39.25 21.96,47.27 29,42 Z")
export const Factory_Group1_RoofBeige1_id = "RoofBeige1"
export const Factory_Group1_RoofBeige1_sodipodiNodetypes = "ccccccccccccccccccc"
export const Factory_Group1_RoofBeige1_type = "path"
export const Factory_Group1_RoofBeige1_fillOpacity = 1
export const Factory_Group1_RoofBeige1_display = "inline"
export const Factory_Group1_RoofBeige1_opacity = 1
export const Factory_Group1_Window1Grey_fill = "#786878"
export const Factory_Group1_Window1Grey_d = new Path2D("M 8,50 V 52 H 14 V 50 Z M 18,50 V 52 H 24 V 50 Z")
export const Factory_Group1_Window1Grey_id = "Window1Grey"
export const Factory_Group1_Window1Grey_type = "path"
export const Factory_Group1_Window1Grey_fillOpacity = 1
export const Factory_Group1_Window1Grey_opacity = 1
export const Factory_Group1_SmokeStack3Grey_fill = "#c0b8c0"
export const Factory_Group1_SmokeStack3Grey_d = new Path2D("M10 31 A4 3 0 0 1 6 34 A4 3 0 0 1 2 31 A4 3 0 0 1 10 31 z")
export const Factory_Group1_SmokeStack3Grey_id = "SmokeStack3Grey"
export const Factory_Group1_SmokeStack3Grey_type = "path"
export const Factory_Group1_SmokeStack3Grey_strokeWidth = 0.433013
export const Factory_Group1_SmokeStack3Grey_fillOpacity = 1
export const Factory_Group1_SmokeStack3Grey_display = "inline"
export const Factory_Group1_SmokeStack3Grey_opacity = 1
export const Factory_Group1_SmokeStack3Grey_strokeDasharray = "none"
export const Factory_Group1_SmokeStack2Beige_fill = "#f0e8d0"
export const Factory_Group1_SmokeStack2Beige_d = new Path2D("M8.5 31 A2.5 2 0 0 1 6 33 A2.5 2 0 0 1 3.5 31 A2.5 2 0 0 1 8.5 31 z")
export const Factory_Group1_SmokeStack2Beige_id = "SmokeStack2Beige"
export const Factory_Group1_SmokeStack2Beige_type = "path"
export const Factory_Group1_SmokeStack2Beige_strokeWidth = 0.447214
export const Factory_Group1_SmokeStack2Beige_fillOpacity = 1
export const Factory_Group1_SmokeStack2Beige_display = "inline"
export const Factory_Group1_SmokeStack2Beige_opacity = 1
export const Factory_Group1_SmokeStack2Beige_strokeDasharray = "none"
export const Factory_Group1_SmokeStack1Grey_fill = "#c0b8c0"
export const Factory_Group1_SmokeStack1Grey_d = new Path2D("M7 31 A1 0.75 0 0 1 6 31.75 A1 0.75 0 0 1 5 31 A1 0.75 0 0 1 7 31 z")
export const Factory_Group1_SmokeStack1Grey_id = "SmokeStack1Grey"
export const Factory_Group1_SmokeStack1Grey_type = "path"
export const Factory_Group1_SmokeStack1Grey_strokeWidth = 0.433013
export const Factory_Group1_SmokeStack1Grey_fillOpacity = 1
export const Factory_Group1_SmokeStack1Grey_display = "inline"
export const Factory_Group1_SmokeStack1Grey_opacity = 1
export const Factory_Group1_SmokeStack1Grey_strokeDasharray = "none"
export const Factory_Group1_Shadow1_fill = "#9888c8"
export const Factory_Group1_Shadow1_d = new Path2D("M 14,36 V 38.03 L 16,36.51 V 36 Z M 22,36 V 37.98 L 24,36.49 V 36 Z M 30,42 V 47 L 28,48 V 62 H 20 V 64 H 30 V 60 H 32 V 42 Z M 2,47 V 60 H 5 7 V 58 H 5 V 48 Z M 10,54 V 58 H 14 V 54 Z M 18,54 V 58 H 22 V 54 Z M 0,62 V 64 H 10 14 V 62 H 10 Z")
export const Factory_Group1_Shadow1_id = "Shadow1"
export const Factory_Group1_Shadow1_sodipodiNodetypes = "cccccccccccccccccccccccccccccccccccccccccccccc"
export const Factory_Group1_Shadow1_type = "path"
export const Factory_Group1_Shadow1_strokeWidth = 0.5
export const Factory_Group1_Shadow1_fillOpacity = 1
export const Factory_Group1_Shadow1_display = "inline"
export const Factory_Group1_Shadow1_opacity = 1
export const Factory_Group1_Factory1_16x16_width = 32
export const Factory_Group1_Factory1_16x16_height = 64
export const Factory_Group1_Factory1_16x16_preserveAspectRatio = "none"
export const Factory_Group1_Factory1_16x16_xlinkHref = "ref/Factory1_16x16.png"
export const Factory_Group1_Factory1_16x16_id = "Factory1_16x16"
export const Factory_Group1_Factory1_16x16_x = 0
export const Factory_Group1_Factory1_16x16_y = 0
export const Factory_Group1_Factory1_16x16_type = "image"
export const Factory_Group1_Factory1_16x16_display = "none"
export const Factory_Group1_Factory1_16x16_imageRendering = "optimizespeed"

/**
 * @link ./src/assets/svg/sprite/HQ.svg
 **/
export const HQ_id = "svg"
export const HQ_width = 32
export const HQ_height = 64
export const HQ_viewBox_x = 0
export const HQ_viewBox_y = 0
export const HQ_viewBox_w = 32
export const HQ_viewBox_h = 64
export const HQ_type = "svg"
export const HQ_Group1_id = "Group1"
export const HQ_Group1_type = "g"
export const HQ_Group1_display = "inline"
export const HQ_Group1_BodyGrey1_fill = "#c0b8c0"
export const HQ_Group1_BodyGrey1_d = new Path2D("M 0,40 H 2 V 32 H 4 V 12 H 6 V 10 H 12 V 8 H 14 V 2 H 18 V 10 H 24 V 12 H 26 V 32 H 28 V 40 H 30 V 64 H 0 Z")
export const HQ_Group1_BodyGrey1_id = "BodyGrey1"
export const HQ_Group1_BodyGrey1_sodipodiNodetypes = "ccccccccccccccccccccccc"
export const HQ_Group1_BodyGrey1_type = "path"
export const HQ_Group1_BodyGrey1_fillOpacity = 1
export const HQ_Group1_BodyGrey1_display = "inline"
export const HQ_Group1_BodyGrey1_opacity = 1
export const HQ_Group1_FloorBeige1_fill = "#f0e8d0"
export const HQ_Group1_FloorBeige1_d = new Path2D("M 8,12 6,12 6,26 24,26 V 18 L 22,18 V 22 L 8,22 Z M 8,28 6,28 6,38 8,38 Z M 4,42 4,52 H 10 V 56 H 12 V 52 H 18 L 18,56 20,56 20,52 26,52 26,48 10,48 10,44 8,44 8,48 H 6 L 6,42 Z M 4,52 H 2 V 60 H 28 V 58 H 4 Z")
export const HQ_Group1_FloorBeige1_id = "FloorBeige1"
export const HQ_Group1_FloorBeige1_sodipodiNodetypes = "cccccccccccccccccccccccccccccccccccccccc"
export const HQ_Group1_FloorBeige1_type = "path"
export const HQ_Group1_FloorBeige1_fillOpacity = 1
export const HQ_Group1_FloorBeige1_display = "inline"
export const HQ_Group1_FloorBeige1_opacity = 1
export const HQ_Group1_FloorBeige2_fill = "#f8f8f0"
export const HQ_Group1_FloorBeige2_d = new Path2D("M 14 4 L 14 8 L 16 8 L 16 4 L 14 4 z M 6 20 L 6 26 L 12 26 L 12 24 L 8 24 L 8 20 L 6 20 z M 4 50 L 4 52 L 6 52 L 6 50 L 4 50 z ")
export const HQ_Group1_FloorBeige2_id = "FloorBeige2"
export const HQ_Group1_FloorBeige2_type = "path"
export const HQ_Group1_FloorBeige2_fillOpacity = 1
export const HQ_Group1_FloorBeige2_opacity = 1
export const HQ_Group1_RoofYellow1_fill = "#f8f0c0"
export const HQ_Group1_RoofYellow1_d = new Path2D("M 14 8 L 14 16 L 16 16 L 16 8 L 14 8 z M 10 12 L 10 20 L 20 20 L 20 18 L 12 18 L 12 12 L 10 12 z")
export const HQ_Group1_RoofYellow1_id = "RoofYellow1"
export const HQ_Group1_RoofYellow1_type = "path"
export const HQ_Group1_RoofYellow1_fillOpacity = 1
export const HQ_Group1_RoofYellow1_opacity = 1
export const HQ_Group1_Window1_fill = "#786878"
export const HQ_Group1_Window1_d = new Path2D("M 10 28 L 10 30 L 12 30 L 12 28 L 10 28 z M 14 28 L 14 30 L 16 30 L 16 28 L 14 28 z M 18 28 L 18 30 L 20 30 L 20 28 L 18 28 z M 10 32 L 10 34 L 12 34 L 12 32 L 10 32 z M 14 32 L 14 34 L 16 34 L 16 32 L 14 32 z M 18 32 L 18 34 L 20 34 L 20 32 L 18 32 z M 12 36 L 12 38 L 14 38 L 14 36 L 12 36 z M 16 36 L 16 38 L 18 38 L 18 36 L 16 36 z M 12 40 L 12 42 L 14 42 L 14 40 L 12 40 z M 16 40 L 16 42 L 18 42 L 18 40 L 16 40 z M 16 44 L 16 46 L 18 46 L 18 44 L 16 44 z M 14 44 L 12 44 L 12 46 L 14 46 L 14 44 z ")
export const HQ_Group1_Window1_id = "Window1"
export const HQ_Group1_Window1_type = "path"
export const HQ_Group1_Window1_fillOpacity = 1
export const HQ_Group1_Window1_opacity = 1
export const HQ_Group1_ShadowGrey1_fill = "#9888c8"
export const HQ_Group1_ShadowGrey1_d = new Path2D("M 30,42 32,42 32,60 30,60 Z")
export const HQ_Group1_ShadowGrey1_id = "ShadowGrey1"
export const HQ_Group1_ShadowGrey1_type = "path"
export const HQ_Group1_ShadowGrey1_fillOpacity = 1
export const HQ_Group1_ShadowGrey1_opacity = 1
export const HQ_Group1_HQ1_16x16_width = 32
export const HQ_Group1_HQ1_16x16_height = 64
export const HQ_Group1_HQ1_16x16_preserveAspectRatio = "none"
export const HQ_Group1_HQ1_16x16_xlinkHref = "ref/HQ1_16x16.png"
export const HQ_Group1_HQ1_16x16_id = "HQ1_16x16"
export const HQ_Group1_HQ1_16x16_x = 0
export const HQ_Group1_HQ1_16x16_y = 0
export const HQ_Group1_HQ1_16x16_type = "image"
export const HQ_Group1_HQ1_16x16_display = "none"
export const HQ_Group1_HQ1_16x16_imageRendering = "optimizespeed"

/**
 * @link ./src/assets/svg/sprite/Infantry.svg
 **/
export const Infantry_id = "svg"
export const Infantry_width = 32
export const Infantry_height = 32
export const Infantry_viewBox_x = 0
export const Infantry_viewBox_y = 0
export const Infantry_viewBox_w = 32
export const Infantry_viewBox_h = 32
export const Infantry_type = "svg"
export const Infantry_Group1_id = "Group1"
export const Infantry_Group1_type = "g"
export const Infantry_Group1_display = "inline"
export const Infantry_Group1_image6377_width = 32
export const Infantry_Group1_image6377_height = 32
export const Infantry_Group1_image6377_preserveAspectRatio = "none"
export const Infantry_Group1_image6377_xlinkHref = "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAQCAYAAAAf8/9hAAAABHNCSVQICAgIfAhkiAAAASJJREFU\nOI2VkqGWwjAQRS9rNrJyJRLJJ6xciYxEVlZWjqysRK7bSmQlkk/gE5CVcQ9RElIW6O6ckzMnTeb1\nzrwsmIYAjGLy0RgAFsyEjEIDTsGjsCslSaGvNeAUxe/jLd9UBJwP8FnC8kbhfJj7+Ugw4G4EHoW+\nTvkVRRJIhVd8SQq7Mi9+LTDgdLjO4mQbASnPFSeRtjGVfitJAiQpF/kVuTU6AGsctfdUq4GlXwPg\nVpbfFU8snQwstmEUMoqcYEITbZRR8P4j+G6SYmcHzn5D0VTxcaltjLG7USTHIvQ14aulxQFw9hsA\nPro99alit4eqtocjUN5CtDHDfe1E6bcCFHPs+87/eP4w0oUoEt/Evwjydew7HftO4WR/IngqNFd8\nASel8OAf12aIAAAAAElFTkSuQmCC\n"
export const Infantry_Group1_image6377_id = "image6377"
export const Infantry_Group1_image6377_x = 0
export const Infantry_Group1_image6377_y = 0
export const Infantry_Group1_image6377_type = "image"
export const Infantry_Group1_image6377_imageRendering = "pixelated"
export const Infantry_Group1_FaceSkin1_fill = "#f8b878"
export const Infantry_Group1_FaceSkin1_d = new Path2D("M20 10.5 A5.5 5.5 0 0 1 14.5 16 A5.5 5.5 0 0 1 9 10.5 A5.5 5.5 0 0 1 20 10.5 z")
export const Infantry_Group1_FaceSkin1_id = "FaceSkin1"
export const Infantry_Group1_FaceSkin1_type = "path"
export const Infantry_Group1_FaceSkin1_strokeOpacity = 1
export const Infantry_Group1_FaceSkin1_strokeWidth = 0.5
export const Infantry_Group1_FaceSkin1_fillOpacity = 1
export const Infantry_Group1_FaceSkin1_display = "inline"
export const Infantry_Group1_HelmetGrey1_fill = "#cccccc"
export const Infantry_Group1_HelmetGrey1_d = new Path2D("M23 7 A9.5 6 0 0 1 13.5 13 A9.5 6 0 0 1 4 7 A9.5 6 0 0 1 23 7 z")
export const Infantry_Group1_HelmetGrey1_id = "HelmetGrey1"
export const Infantry_Group1_HelmetGrey1_type = "path"
export const Infantry_Group1_HelmetGrey1_strokeOpacity = 1
export const Infantry_Group1_HelmetGrey1_strokeWidth = 0.5
export const Infantry_Group1_HelmetGrey1_fillOpacity = 1
export const Infantry_Group1_HelmetGrey1_display = "inline"
export const Infantry_Group1_HelmetGrey2_fill = "#999999"
export const Infantry_Group1_HelmetGrey2_d = new Path2D("M 3,0 H 21 V 2 C 11.19,3.11 5.50,5.72 5,14 H 3")
export const Infantry_Group1_HelmetGrey2_id = "HelmetGrey2"
export const Infantry_Group1_HelmetGrey2_sodipodiNodetypes = "ccccc"
export const Infantry_Group1_HelmetGrey2_type = "path"
export const Infantry_Group1_HelmetGrey2_strokeOpacity = 1
export const Infantry_Group1_HelmetGrey2_strokeWidth = 0.5
export const Infantry_Group1_HelmetGrey2_fillOpacity = 1
export const Infantry_Group1_HelmetGrey2_display = "inline"
export const Infantry_Group1_HelmetGrey3_fill = "#e6e6e6"
export const Infantry_Group1_HelmetGrey3_d = new Path2D("M 23,2 C 23.13,9.52 13.41,10.97 3,12 V 14 H 25 V 0 H 23")
export const Infantry_Group1_HelmetGrey3_id = "HelmetGrey3"
export const Infantry_Group1_HelmetGrey3_sodipodiNodetypes = "cccccc"
export const Infantry_Group1_HelmetGrey3_type = "path"
export const Infantry_Group1_HelmetGrey3_strokeOpacity = 1
export const Infantry_Group1_HelmetGrey3_strokeWidth = 0.5
export const Infantry_Group1_HelmetGrey3_fillOpacity = 1
export const Infantry_Group1_HelmetGrey3_display = "inline"
export const Infantry_Group1_BodyGrey1_fill = "#cccccc"
export const Infantry_Group1_BodyGrey1_d = new Path2D("M 10,14 8,16 V 20 L 12,24 V 30 H 18 V 28 H 16 V 22 H 20 V 16 H 16 V 14")
export const Infantry_Group1_BodyGrey1_id = "BodyGrey1"
export const Infantry_Group1_BodyGrey1_sodipodiNodetypes = "ccccccccccccc"
export const Infantry_Group1_BodyGrey1_type = "path"
export const Infantry_Group1_BodyGrey1_strokeOpacity = 1
export const Infantry_Group1_BodyGrey1_strokeWidth = 0.5
export const Infantry_Group1_BodyGrey1_fillOpacity = 1
export const Infantry_Group1_BodyGrey2_fill = "#b3b3b3"
export const Infantry_Group1_BodyGrey2_d = new Path2D("M 14,14 12,17 V 18 H 10 V 20 H 12 L 16,22 V 16 14")
export const Infantry_Group1_BodyGrey2_id = "BodyGrey2"
export const Infantry_Group1_BodyGrey2_type = "path"
export const Infantry_Group1_BodyGrey2_strokeOpacity = 1
export const Infantry_Group1_BodyGrey2_strokeWidth = 0.5
export const Infantry_Group1_BodyGrey2_fillOpacity = 1
export const Infantry_Group1_RifleGrey1_fill = "#4d4d4d"
export const Infantry_Group1_RifleGrey1_d = new Path2D("M 28,18 C 24,18 20,18 16,18 15.97,18.67 16.04,19.38 15.96,20.04 15.31,21.03 14.65,22.01 14,23 13.33,23 12.66,23 12,23 12.66,21.66 13.33,20.33 14,19 12.66,19 11.33,19 10,19 10.03,18.68 9.92,18.21 10.05,18 10.71,17.98 11.39,18.03 12.05,17.97 12.72,17.65 13.37,17.27 14.05,17 14.70,17 15.35,17 16,17 16,18 16,19 16,20 20,20 24,20 28,20")
export const Infantry_Group1_RifleGrey1_id = "RifleGrey1"
export const Infantry_Group1_RifleGrey1_sodipodiNodetypes = "ccccccccccccc"
export const Infantry_Group1_RifleGrey1_type = "path"
export const Infantry_Group1_RifleGrey1_strokeOpacity = 1
export const Infantry_Group1_RifleGrey1_strokeWidth = 0.5
export const Infantry_Group1_RifleGrey1_fillOpacity = 1
export const Infantry_Group1_RifleGrey1_display = "inline"

/**
 * @link ./src/assets/svg/sprite/InspectIcon.svg
 **/
export const InspectIcon_id = "svg"
export const InspectIcon_width = 32
export const InspectIcon_height = 32
export const InspectIcon_viewBox_x = 0
export const InspectIcon_viewBox_y = 0
export const InspectIcon_viewBox_w = 32
export const InspectIcon_viewBox_h = 32
export const InspectIcon_type = "svg"
export const InspectIcon_Group1_id = "Group1"
export const InspectIcon_Group1_type = "g"
export const InspectIcon_Group1_display = "inline"
export const InspectIcon_Group1_Handle2_fill = "#fffd67"
export const InspectIcon_Group1_Handle2_d = new Path2D("M 22,24 24,22 21,19 19,21 22,24")
export const InspectIcon_Group1_Handle2_id = "Handle2"
export const InspectIcon_Group1_Handle2_type = "path"
export const InspectIcon_Group1_Handle2_stroke = "#000"
export const InspectIcon_Group1_Handle2_strokeWidth = 0.5
export const InspectIcon_Group1_Handle2_display = "inline"
export const InspectIcon_Group1_Handle1_fill = "#fffd67"
export const InspectIcon_Group1_Handle1_d = new Path2D("M 31,28 C 30.66,29.71 29.61,30.66 28,31 L 21,24 C 21.33,22.28 22.38,21.33 24,21 Z")
export const InspectIcon_Group1_Handle1_id = "Handle1"
export const InspectIcon_Group1_Handle1_sodipodiNodetypes = "ccccc"
export const InspectIcon_Group1_Handle1_type = "path"
export const InspectIcon_Group1_Handle1_stroke = "#000"
export const InspectIcon_Group1_Handle1_strokeWidth = 0.5
export const InspectIcon_Group1_Handle1_display = "inline"
export const InspectIcon_Group1_CircleOuter1_fill = "#fffd67"
export const InspectIcon_Group1_CircleOuter1_d = new Path2D("M 12.5,1 A 11.5,11.5 0 0 0 1,12.5 11.5,11.5 0 0 0 12.5,24 11.5,11.5 0 0 0 24,12.5 11.5,11.5 0 0 0 12.5,1 Z M 12.5,3 A 9.5,9.5 0 0 1 22,12.5 9.5,9.5 0 0 1 12.5,22 9.5,9.5 0 0 1 3,12.5 9.5,9.5 0 0 1 12.5,3 Z")
export const InspectIcon_Group1_CircleOuter1_id = "CircleOuter1"
export const InspectIcon_Group1_CircleOuter1_type = "path"
export const InspectIcon_Group1_CircleOuter1_stroke = "#000"
export const InspectIcon_Group1_CircleOuter1_strokeWidth = 0.5
export const InspectIcon_Group1_CircleInner1_fill = "#fffd67"
export const InspectIcon_Group1_CircleInner1_d = new Path2D("M20 12.5 A7.5 7.5 0 0 1 12.5 20 A7.5 7.5 0 0 1 5 12.5 A7.5 7.5 0 0 1 20 12.5 z")
export const InspectIcon_Group1_CircleInner1_id = "CircleInner1"
export const InspectIcon_Group1_CircleInner1_type = "path"
export const InspectIcon_Group1_CircleInner1_stroke = "#000"
export const InspectIcon_Group1_CircleInner1_strokeWidth = 0.5
export const InspectIcon_Group1_CircleInner1_display = "inline"
export const InspectIcon_Group1_QuestionMark1_fill = "#999999"
export const InspectIcon_Group1_QuestionMark1_d = new Path2D("M 12.5,6 C 10.20,6.00 8.28,7.72 8.03,10 H 10.05 C 10.29,8.83 11.31,8.00 12.5,8 13.88,8 15,9.11 15,10.5 15,11.88 13.88,13 12.5,13 H 11.5 V 14.73 16 H 13.5 V 14.9 L 14,14.73 C 15.79,14.09 16.99,12.40 17,10.5 17,8.01 14.98,6 12.5,6 Z M 11.5,17 V 19 H 13.5 V 17 Z")
export const InspectIcon_Group1_QuestionMark1_id = "QuestionMark1"
export const InspectIcon_Group1_QuestionMark1_sodipodiNodetypes = "ccccssccccccccccccc"
export const InspectIcon_Group1_QuestionMark1_type = "path"
export const InspectIcon_Group1_QuestionMark1_strokeWidth = 0.5

/**
 * @link ./src/assets/svg/sprite/InspectIconVB.svg
 **/
export const InspectIconVB_id = "svg"
export const InspectIconVB_width = 32
export const InspectIconVB_height = 32
export const InspectIconVB_viewBox_x = 0
export const InspectIconVB_viewBox_y = 0
export const InspectIconVB_viewBox_w = 32
export const InspectIconVB_viewBox_h = 32
export const InspectIconVB_type = "svg"
export const InspectIconVB_Group1_id = "Group1"
export const InspectIconVB_Group1_type = "g"
export const InspectIconVB_Group1_display = "inline"
export const InspectIconVB_Group1_OutlineBlack1_fill = "#000"
export const InspectIconVB_Group1_OutlineBlack1_d = new Path2D("M 12.5,0 A 12.5,12.5 0 0 0 0,12.5 12.5,12.5 0 0 0 12.5,25 12.5,12.5 0 0 0 25,12.5 12.5,12.5 0 0 0 12.5,0 Z M 12.5,2.17 A 10.32,10.32 0 0 1 22.82,12.5 10.32,10.32 0 0 1 12.5,22.82 10.32,10.32 0 0 1 2.17,12.5 10.32,10.32 0 0 1 12.5,2.17 Z")
export const InspectIconVB_Group1_OutlineBlack1_id = "OutlineBlack1"
export const InspectIconVB_Group1_OutlineBlack1_type = "path"
export const InspectIconVB_Group1_OutlineBlack1_strokeWidth = 0.543478
export const InspectIconVB_Group1_OutlineBlack1_fillOpacity = 1
export const InspectIconVB_Group1_OutlineBlack1_display = "inline"
export const InspectIconVB_Group1_OutlineBlack2_fill = "#000"
export const InspectIconVB_Group1_OutlineBlack2_d = new Path2D("M 32,27.5 C 32.31,29.93 29.66,32.26 27.5,32 L 20,24.5 C 19.62,22.17 22.19,19.74 24.5,20 Z")
export const InspectIconVB_Group1_OutlineBlack2_id = "OutlineBlack2"
export const InspectIconVB_Group1_OutlineBlack2_sodipodiNodetypes = "ccccc"
export const InspectIconVB_Group1_OutlineBlack2_type = "path"
export const InspectIconVB_Group1_OutlineBlack2_strokeWidth = 0.5
export const InspectIconVB_Group1_OutlineBlack2_display = "inline"
export const InspectIconVB_Group1_OutlineBlack3_fill = "#000"
export const InspectIconVB_Group1_OutlineBlack3_d = new Path2D("M 22,24 25,22 21,18 18,21 22,25")
export const InspectIconVB_Group1_OutlineBlack3_id = "OutlineBlack3"
export const InspectIconVB_Group1_OutlineBlack3_sodipodiNodetypes = "ccccc"
export const InspectIconVB_Group1_OutlineBlack3_type = "path"
export const InspectIconVB_Group1_OutlineBlack3_strokeWidth = 0.5
export const InspectIconVB_Group1_OutlineBlack3_fillOpacity = 1
export const InspectIconVB_Group1_OutlineBlack3_display = "inline"
export const InspectIconVB_Group1_Handle2_fill = "#fffd67"
export const InspectIconVB_Group1_Handle2_d = new Path2D("M 22,24 24,22 21,19 19,21 22,24")
export const InspectIconVB_Group1_Handle2_id = "Handle2"
export const InspectIconVB_Group1_Handle2_type = "path"
export const InspectIconVB_Group1_Handle2_stroke = "#000"
export const InspectIconVB_Group1_Handle2_strokeWidth = 0.5
export const InspectIconVB_Group1_Handle2_display = "inline"
export const InspectIconVB_Group1_Handle1_fill = "#fffd67"
export const InspectIconVB_Group1_Handle1_d = new Path2D("M 31,28 C 30.66,29.71 29.61,30.66 28,31 L 21,24 C 21.33,22.28 22.38,21.33 24,21 Z")
export const InspectIconVB_Group1_Handle1_id = "Handle1"
export const InspectIconVB_Group1_Handle1_sodipodiNodetypes = "ccccc"
export const InspectIconVB_Group1_Handle1_type = "path"
export const InspectIconVB_Group1_Handle1_stroke = "#000"
export const InspectIconVB_Group1_Handle1_strokeWidth = 0.5
export const InspectIconVB_Group1_Handle1_display = "inline"
export const InspectIconVB_Group1_CircleOuter1_fill = "#fffd67"
export const InspectIconVB_Group1_CircleOuter1_d = new Path2D("M 12.5,1 A 11.5,11.5 0 0 0 1,12.5 11.5,11.5 0 0 0 12.5,24 11.5,11.5 0 0 0 24,12.5 11.5,11.5 0 0 0 12.5,1 Z M 12.5,3 A 9.5,9.5 0 0 1 22,12.5 9.5,9.5 0 0 1 12.5,22 9.5,9.5 0 0 1 3,12.5 9.5,9.5 0 0 1 12.5,3 Z")
export const InspectIconVB_Group1_CircleOuter1_id = "CircleOuter1"
export const InspectIconVB_Group1_CircleOuter1_type = "path"
export const InspectIconVB_Group1_CircleOuter1_stroke = "#000"
export const InspectIconVB_Group1_CircleOuter1_strokeWidth = 0.5
export const InspectIconVB_Group1_CircleOuter1_display = "inline"
export const InspectIconVB_Group1_CircleInner1_fill = "#fffd67"
export const InspectIconVB_Group1_CircleInner1_d = new Path2D("M20 12.5 A7.5 7.5 0 0 1 12.5 20 A7.5 7.5 0 0 1 5 12.5 A7.5 7.5 0 0 1 20 12.5 z")
export const InspectIconVB_Group1_CircleInner1_id = "CircleInner1"
export const InspectIconVB_Group1_CircleInner1_type = "path"
export const InspectIconVB_Group1_CircleInner1_stroke = "#000"
export const InspectIconVB_Group1_CircleInner1_strokeWidth = 0.5
export const InspectIconVB_Group1_CircleInner1_display = "inline"
export const InspectIconVB_Group1_QuestionMark1_fill = "#999999"
export const InspectIconVB_Group1_QuestionMark1_d = new Path2D("M 12.5,6 C 10.20,6.00 8.28,7.72 8.03,10 H 10.05 C 10.29,8.83 11.31,8.00 12.5,8 13.88,8 15,9.11 15,10.5 15,11.88 13.88,13 12.5,13 H 11.5 V 14.73 16 H 13.5 V 14.9 L 14,14.73 C 15.79,14.09 16.99,12.40 17,10.5 17,8.01 14.98,6 12.5,6 Z M 11.5,17 V 19 H 13.5 V 17 Z")
export const InspectIconVB_Group1_QuestionMark1_id = "QuestionMark1"
export const InspectIconVB_Group1_QuestionMark1_sodipodiNodetypes = "ccccssccccccccccccc"
export const InspectIconVB_Group1_QuestionMark1_type = "path"
export const InspectIconVB_Group1_QuestionMark1_strokeWidth = 0.5
export const InspectIconVB_Group1_QuestionMark1_display = "inline"

/**
 * @link ./src/assets/svg/sprite/LightAntiAir.svg
 **/
export const LightAntiAir_id = "svg"
export const LightAntiAir_width = 32
export const LightAntiAir_height = 32
export const LightAntiAir_viewBox_x = 0
export const LightAntiAir_viewBox_y = 0
export const LightAntiAir_viewBox_w = 32
export const LightAntiAir_viewBox_h = 32
export const LightAntiAir_type = "svg"
export const LightAntiAir_Group1_id = "Group1"
export const LightAntiAir_Group1_type = "g"
export const LightAntiAir_Group1_display = "inline"
export const LightAntiAir_Group1_TreadAxelBgGrey1_fill = "#333333"
export const LightAntiAir_Group1_TreadAxelBgGrey1_d = new Path2D("M 5,24 9,26 H 21 L 25,24 V 22 H 5")
export const LightAntiAir_Group1_TreadAxelBgGrey1_id = "TreadAxelBgGrey1"
export const LightAntiAir_Group1_TreadAxelBgGrey1_type = "path"
export const LightAntiAir_Group1_TreadAxelBgGrey1_strokeOpacity = 0.487465
export const LightAntiAir_Group1_TreadAxelBgGrey1_strokeWidth = 0.5
export const LightAntiAir_Group1_TreadAxelBgGrey1_fillOpacity = 1
export const LightAntiAir_Group1_TreadBlack1_fill = "#000"
export const LightAntiAir_Group1_TreadBlack1_d = new Path2D("M 4.62,25.22 4.36,25.64 8.06,27.76 8.33,27.35 Z M 25.24,25.29 21.62,27.35 21.83,27.81 25.51,25.72 Z M 9,27.5 V 28 H 20.87 V 27.5 Z")
export const LightAntiAir_Group1_TreadBlack1_id = "TreadBlack1"
export const LightAntiAir_Group1_TreadBlack1_sodipodiNodetypes = "ccccccccccccccc"
export const LightAntiAir_Group1_TreadBlack1_type = "path"
export const LightAntiAir_Group1_TreadBlack1_display = "inline"
export const LightAntiAir_Group1_TreadBlack1_opacity = 1
export const LightAntiAir_Group1_WheelsOuterBlack1_fill = "#000"
export const LightAntiAir_Group1_WheelsOuterBlack1_d = new Path2D("M 5.5,22 A 2,2 0 0 0 3.5,24 2,2 0 0 0 5.5,26 2,2 0 0 0 7.5,24 2,2 0 0 0 5.5,22 Z M 24.5,22 A 2,2 0 0 0 22.5,24 2,2 0 0 0 24.5,26 2,2 0 0 0 26.5,24 2,2 0 0 0 24.5,22 Z M 9,24 A 2,2 0 0 0 7,26 2,2 0 0 0 9,28 2,2 0 0 0 11,26 2,2 0 0 0 9,24 Z M 11,26 A 2,2 0 0 0 13,28 2,2 0 0 0 15,26 2,2 0 0 0 13,24 2,2 0 0 0 11,26 Z M 15,26 A 2,2 0 0 0 17,28 2,2 0 0 0 19,26 2,2 0 0 0 17,24 2,2 0 0 0 15,26 Z M 19,26 A 2,2 0 0 0 21,28 2,2 0 0 0 23,26 2,2 0 0 0 21,24 2,2 0 0 0 19,26 Z")
export const LightAntiAir_Group1_WheelsOuterBlack1_id = "WheelsOuterBlack1"
export const LightAntiAir_Group1_WheelsOuterBlack1_type = "path"
export const LightAntiAir_Group1_WheelsOuterBlack1_strokeOpacity = 0.487465
export const LightAntiAir_Group1_WheelsOuterBlack1_strokeWidth = 0.5
export const LightAntiAir_Group1_WheelsOuterBlack1_fillOpacity = 1
export const LightAntiAir_Group1_WheelsOuterBlack1_opacity = 1
export const LightAntiAir_Group1_WheelsInnerGrey1_fill = "#666666"
export const LightAntiAir_Group1_WheelsInnerGrey1_d = new Path2D("M 5.5,22.5 A 1.5,1.5 0 0 0 4,24 1.5,1.5 0 0 0 5.5,25.5 1.5,1.5 0 0 0 7,24 1.5,1.5 0 0 0 5.5,22.5 Z M 24.5,22.5 A 1.5,1.5 0 0 0 23,24 1.5,1.5 0 0 0 24.5,25.5 1.5,1.5 0 0 0 26,24 1.5,1.5 0 0 0 24.5,22.5 Z M 9,24.5 A 1.5,1.5 0 0 0 7.5,26 1.5,1.5 0 0 0 9,27.5 1.5,1.5 0 0 0 10.5,26 1.5,1.5 0 0 0 9,24.5 Z M 13,24.5 A 1.5,1.5 0 0 0 11.5,26 1.5,1.5 0 0 0 13,27.5 1.5,1.5 0 0 0 14.5,26 1.5,1.5 0 0 0 13,24.5 Z M 17,24.5 A 1.5,1.5 0 0 0 15.5,26 1.5,1.5 0 0 0 17,27.5 1.5,1.5 0 0 0 18.5,26 1.5,1.5 0 0 0 17,24.5 Z M 21,24.5 A 1.5,1.5 0 0 0 19.5,26 1.5,1.5 0 0 0 21,27.5 1.5,1.5 0 0 0 22.5,26 1.5,1.5 0 0 0 21,24.5 Z")
export const LightAntiAir_Group1_WheelsInnerGrey1_id = "WheelsInnerGrey1"
export const LightAntiAir_Group1_WheelsInnerGrey1_type = "path"
export const LightAntiAir_Group1_WheelsInnerGrey1_strokeOpacity = 0.487465
export const LightAntiAir_Group1_WheelsInnerGrey1_strokeWidth = 0.375
export const LightAntiAir_Group1_WheelsInnerGrey1_fillOpacity = 1
export const LightAntiAir_Group1_WheelsInnerGrey1_opacity = 1
export const LightAntiAir_Group1_BodyLowerGrey1_fill = "#b3b3b3"
export const LightAntiAir_Group1_BodyLowerGrey1_d = new Path2D("M 15,20 7,18 H 3 V 21.5 23.25 L 5,24 H 25 L 28,21.5 V 21.06 L 27,20 Z")
export const LightAntiAir_Group1_BodyLowerGrey1_id = "BodyLowerGrey1"
export const LightAntiAir_Group1_BodyLowerGrey1_sodipodiNodetypes = "ccccccccccc"
export const LightAntiAir_Group1_BodyLowerGrey1_type = "path"
export const LightAntiAir_Group1_BodyLowerGrey1_strokeWidth = 0.935414
export const LightAntiAir_Group1_BodyLowerGrey1_display = "inline"
export const LightAntiAir_Group1_BodyLowerGrey1_opacity = 1
export const LightAntiAir_Group1_BodyLowerGrey2_fill = "#999999"
export const LightAntiAir_Group1_BodyLowerGrey2_d = new Path2D("M 6,22 H 24 V 24 H 6 Z")
export const LightAntiAir_Group1_BodyLowerGrey2_id = "BodyLowerGrey2"
export const LightAntiAir_Group1_BodyLowerGrey2_sodipodiNodetypes = "ccccc"
export const LightAntiAir_Group1_BodyLowerGrey2_type = "path"
export const LightAntiAir_Group1_BodyLowerGrey2_strokeOpacity = 0.487465
export const LightAntiAir_Group1_BodyLowerGrey2_strokeWidth = 0.433013
export const LightAntiAir_Group1_BodyLowerGrey2_opacity = 1
export const LightAntiAir_Group1_BodyHorizontalLineGrey1_fill = "#333333"
export const LightAntiAir_Group1_BodyHorizontalLineGrey1_d = new Path2D("M 3,21 V 22 H 27.6 L 28,21.5 V 21 Z")
export const LightAntiAir_Group1_BodyHorizontalLineGrey1_id = "BodyHorizontalLineGrey1"
export const LightAntiAir_Group1_BodyHorizontalLineGrey1_sodipodiNodetypes = "cccccc"
export const LightAntiAir_Group1_BodyHorizontalLineGrey1_type = "path"
export const LightAntiAir_Group1_BodyHorizontalLineGrey1_strokeWidth = 1.1547
export const LightAntiAir_Group1_BodyHorizontalLineGrey1_display = "inline"
export const LightAntiAir_Group1_HeadGrey1_fill = "#b3b3b3"
export const LightAntiAir_Group1_HeadGrey1_d = new Path2D("M 10,19 9,17 10,12 H 19 L 24,13 25,18 23,19 Z")
export const LightAntiAir_Group1_HeadGrey1_id = "HeadGrey1"
export const LightAntiAir_Group1_HeadGrey1_sodipodiNodetypes = "cccccccc"
export const LightAntiAir_Group1_HeadGrey1_type = "path"
export const LightAntiAir_Group1_HeadGrey1_strokeOpacity = 0.487465
export const LightAntiAir_Group1_HeadGrey1_strokeWidth = 0.5
export const LightAntiAir_Group1_HeadGrey1_fillOpacity = 1
export const LightAntiAir_Group1_HeadGrey1_display = "inline"
export const LightAntiAir_Group1_HeadGrey1_opacity = 1
export const LightAntiAir_Group1_HeadGrey2_fill = "#808080"
export const LightAntiAir_Group1_HeadGrey2_d = new Path2D("M 9.5,18 10,19 H 23 L 25,18 Z")
export const LightAntiAir_Group1_HeadGrey2_id = "HeadGrey2"
export const LightAntiAir_Group1_HeadGrey2_sodipodiNodetypes = "ccccc"
export const LightAntiAir_Group1_HeadGrey2_type = "path"
export const LightAntiAir_Group1_HeadGrey2_strokeOpacity = 0.487465
export const LightAntiAir_Group1_HeadGrey2_strokeWidth = 0.5
export const LightAntiAir_Group1_HeadGrey2_fillOpacity = 1
export const LightAntiAir_Group1_HeadGrey2_display = "inline"
export const LightAntiAir_Group1_HeadGrey2_opacity = 1
export const LightAntiAir_Group1_GunsRaisedGrey1_fill = "#808080"
export const LightAntiAir_Group1_GunsRaisedGrey1_d = new Path2D("M 29.60,9.5 22.71,13.57 23.22,14.43 30.11,10.36 Z")
export const LightAntiAir_Group1_GunsRaisedGrey1_id = "GunsRaisedGrey1"
export const LightAntiAir_Group1_GunsRaisedGrey1_inkscapeTransformCenterX = "-3.416174"
export const LightAntiAir_Group1_GunsRaisedGrey1_inkscapeTransformCenterY = "-2.081407"
export const LightAntiAir_Group1_GunsRaisedGrey1_sodipodiNodetypes = "ccccc"
export const LightAntiAir_Group1_GunsRaisedGrey1_type = "path"
export const LightAntiAir_Group1_GunsRaisedGrey1_strokeOpacity = 0.487465
export const LightAntiAir_Group1_GunsRaisedGrey1_strokeWidth = 0.5
export const LightAntiAir_Group1_GunsLowerdGrey1_fill = "#808080"
export const LightAntiAir_Group1_GunsLowerdGrey1_d = new Path2D("M 31,16 V 15 H 23 V 16")
export const LightAntiAir_Group1_GunsLowerdGrey1_id = "GunsLowerdGrey1"
export const LightAntiAir_Group1_GunsLowerdGrey1_type = "path"
export const LightAntiAir_Group1_GunsLowerdGrey1_strokeOpacity = 0.487465
export const LightAntiAir_Group1_GunsLowerdGrey1_strokeWidth = 0.5
export const LightAntiAir_Group1_GunsLowerdGrey1_fillOpacity = 1
export const LightAntiAir_Group1_GunsLowerdGrey1_display = "inline"
export const LightAntiAir_Group1_HeadGrey3_fill = "#cccccc"
export const LightAntiAir_Group1_HeadGrey3_d = new Path2D("M 19,16 V 12 L 24,13 25,18 H 21 Z")
export const LightAntiAir_Group1_HeadGrey3_id = "HeadGrey3"
export const LightAntiAir_Group1_HeadGrey3_sodipodiNodetypes = "cccccc"
export const LightAntiAir_Group1_HeadGrey3_type = "path"
export const LightAntiAir_Group1_HeadGrey3_strokeOpacity = 0.487465
export const LightAntiAir_Group1_HeadGrey3_strokeWidth = 0.5
export const LightAntiAir_Group1_HeadGrey3_fillOpacity = 1
export const LightAntiAir_Group1_HeadGrey3_display = "inline"
export const LightAntiAir_Group1_HeadGrey3_opacity = 1
export const LightAntiAir_Group1_FixBorder1_fill = "#333333"
export const LightAntiAir_Group1_FixBorder1_d = new Path2D("M 28,8 H 30 L 31,11 H 29")
export const LightAntiAir_Group1_FixBorder1_id = "FixBorder1"
export const LightAntiAir_Group1_FixBorder1_sodipodiNodetypes = "cccc"
export const LightAntiAir_Group1_FixBorder1_type = "path"
export const LightAntiAir_Group1_FixBorder1_strokeOpacity = 0.487465
export const LightAntiAir_Group1_FixBorder1_strokeWidth = 0.5

/**
 * @link ./src/assets/svg/sprite/LightRecon.svg
 **/
export const LightRecon_id = "svg"
export const LightRecon_width = 32
export const LightRecon_height = 32
export const LightRecon_viewBox_x = 0
export const LightRecon_viewBox_y = 0
export const LightRecon_viewBox_w = 32
export const LightRecon_viewBox_h = 32
export const LightRecon_type = "svg"
export const LightRecon_Group1_id = "Group1"
export const LightRecon_Group1_type = "g"
export const LightRecon_Group1_display = "inline"
export const LightRecon_Group1_WheelAxelBgGrey1_fill = "#333333"
export const LightRecon_Group1_WheelAxelBgGrey1_d = new Path2D("M 5,24 7,26 10,25 H 20 L 23,26 25,24 24,23 H 5 Z")
export const LightRecon_Group1_WheelAxelBgGrey1_id = "WheelAxelBgGrey1"
export const LightRecon_Group1_WheelAxelBgGrey1_sodipodiNodetypes = "ccccccccc"
export const LightRecon_Group1_WheelAxelBgGrey1_type = "path"
export const LightRecon_Group1_WheelAxelBgGrey1_strokeOpacity = 0.487465
export const LightRecon_Group1_WheelAxelBgGrey1_strokeWidth = 0.5
export const LightRecon_Group1_WheelAxelBgGrey1_fillOpacity = 1
export const LightRecon_Group1_WheelsOuterBlack1_fill = "#000"
export const LightRecon_Group1_WheelsOuterBlack1_d = new Path2D("M 7,24 C 5.89,24 5,24.89 5,26 5,27.10 5.89,28 7,28 8.10,28 9,27.10 9,26 9,24.89 8.10,24 7,24 Z M 21,26 C 21,27.10 21.89,28 23,28 24.10,28 25,27.10 25,26 25,24.89 24.10,24 23,24 21.89,24 21,24.89 21,26 Z")
export const LightRecon_Group1_WheelsOuterBlack1_id = "WheelsOuterBlack1"
export const LightRecon_Group1_WheelsOuterBlack1_sodipodiNodetypes = "ssssssssss"
export const LightRecon_Group1_WheelsOuterBlack1_type = "path"
export const LightRecon_Group1_WheelsOuterBlack1_strokeOpacity = 0.487465
export const LightRecon_Group1_WheelsOuterBlack1_strokeWidth = 0.5
export const LightRecon_Group1_WheelsOuterBlack1_fillOpacity = 1
export const LightRecon_Group1_WheelsOuterBlack1_opacity = 1
export const LightRecon_Group1_WheelsInnerGrey1_fill = "#666666"
export const LightRecon_Group1_WheelsInnerGrey1_d = new Path2D("M 7,25 C 6.44,25 6,25.44 6,26 6,26.55 6.44,27 7,27 7.55,27 8,26.55 8,26 8,25.44 7.55,25 7,25 Z M 23,25 C 22.44,25 22,25.44 22,26 22,26.55 22.44,27 23,27 23.55,27 24,26.55 24,26 24,25.44 23.55,25 23,25 Z")
export const LightRecon_Group1_WheelsInnerGrey1_id = "WheelsInnerGrey1"
export const LightRecon_Group1_WheelsInnerGrey1_sodipodiNodetypes = "ssssssssss"
export const LightRecon_Group1_WheelsInnerGrey1_type = "path"
export const LightRecon_Group1_WheelsInnerGrey1_strokeOpacity = 0.487465
export const LightRecon_Group1_WheelsInnerGrey1_strokeWidth = 0.375
export const LightRecon_Group1_WheelsInnerGrey1_fillOpacity = 1
export const LightRecon_Group1_WheelsInnerGrey1_opacity = 1
export const LightRecon_Group1_AntenaGrey1_fill = "#4d4d4d"
export const LightRecon_Group1_AntenaGrey1_d = new Path2D("M 5,14 V 14 H 4.75 V 13 H 5.75 V 14 H 5.5 V 22 H 5 Z")
export const LightRecon_Group1_AntenaGrey1_id = "AntenaGrey1"
export const LightRecon_Group1_AntenaGrey1_sodipodiNodetypes = "cccccccccc"
export const LightRecon_Group1_AntenaGrey1_type = "path"
export const LightRecon_Group1_AntenaGrey1_strokeOpacity = 0.487465
export const LightRecon_Group1_AntenaGrey1_strokeWidth = 0.5
export const LightRecon_Group1_AntenaGrey1_fillOpacity = 1
export const LightRecon_Group1_BodyLowerGrey1_fill = "#b3b3b3"
export const LightRecon_Group1_BodyLowerGrey1_d = new Path2D("M 9,18 4.5,21 4,22 V 25 H 5 L 6,24 H 8 L 10,26 H 20 L 22,24 H 24 L 25,25 H 26 V 23 L 20,22 V 18 Z")
export const LightRecon_Group1_BodyLowerGrey1_id = "BodyLowerGrey1"
export const LightRecon_Group1_BodyLowerGrey1_sodipodiNodetypes = "ccccccccccccccccc"
export const LightRecon_Group1_BodyLowerGrey1_type = "path"
export const LightRecon_Group1_BodyLowerGrey1_strokeWidth = 0.935414
export const LightRecon_Group1_BodyLowerGrey1_display = "inline"
export const LightRecon_Group1_BodyLowerGrey1_opacity = 1
export const LightRecon_Group1_BodyLowerGrey1_imageRendering = "optimizespeed"
export const LightRecon_Group1_BodyDoorGrey1_fill = "#999999"
export const LightRecon_Group1_BodyDoorGrey1_d = new Path2D("M 9 19 L 9 21 L 10 25 L 14 25 L 14 19 L 9 19 z M 15 19 L 15 25 L 19 25 L 19 19 L 15 19 z ")
export const LightRecon_Group1_BodyDoorGrey1_id = "BodyDoorGrey1"
export const LightRecon_Group1_BodyDoorGrey1_type = "path"
export const LightRecon_Group1_BodyDoorGrey1_strokeOpacity = 0.487465
export const LightRecon_Group1_BodyDoorGrey1_strokeWidth = 0.433013
export const LightRecon_Group1_BodyDoorGrey1_display = "inline"
export const LightRecon_Group1_BodyDoorGrey1_opacity = 1
export const LightRecon_Group1_RoofCurveGrey1_fill = "#808080"
export const LightRecon_Group1_RoofCurveGrey1_d = new Path2D("M 7,18 V 19 H 21 V 18 Z")
export const LightRecon_Group1_RoofCurveGrey1_id = "RoofCurveGrey1"
export const LightRecon_Group1_RoofCurveGrey1_sodipodiNodetypes = "ccccc"
export const LightRecon_Group1_RoofCurveGrey1_type = "path"
export const LightRecon_Group1_RoofCurveGrey1_strokeWidth = 1.1547
export const LightRecon_Group1_RoofCurveGrey1_display = "inline"
export const LightRecon_Group1_HeadHatGrey1_fill = "#b3b3b3"
export const LightRecon_Group1_HeadHatGrey1_d = new Path2D("M 13.5,18 V 15.5 L 14,15 H 15 L 15.5,15.5 15.46,18 Z")
export const LightRecon_Group1_HeadHatGrey1_id = "HeadHatGrey1"
export const LightRecon_Group1_HeadHatGrey1_sodipodiNodetypes = "ccccccc"
export const LightRecon_Group1_HeadHatGrey1_type = "path"
export const LightRecon_Group1_HeadHatGrey1_strokeOpacity = 0.487465
export const LightRecon_Group1_HeadHatGrey1_strokeWidth = 0.5
export const LightRecon_Group1_HeadHatGrey1_fillOpacity = 1
export const LightRecon_Group1_HeadHatGrey1_display = "inline"
export const LightRecon_Group1_HeadHatGrey1_opacity = 1
export const LightRecon_Group1_HeadHatGrey2_fill = "#808080"
export const LightRecon_Group1_HeadHatGrey2_d = new Path2D("M 13.5,15.5 14,15 V 18 H 13.5 Z")
export const LightRecon_Group1_HeadHatGrey2_id = "HeadHatGrey2"
export const LightRecon_Group1_HeadHatGrey2_sodipodiNodetypes = "ccccc"
export const LightRecon_Group1_HeadHatGrey2_type = "path"
export const LightRecon_Group1_HeadHatGrey2_strokeOpacity = 0.487465
export const LightRecon_Group1_HeadHatGrey2_strokeWidth = 0.5
export const LightRecon_Group1_HeadHatGrey2_fillOpacity = 1
export const LightRecon_Group1_HeadHatGrey2_display = "inline"
export const LightRecon_Group1_HeadHatGrey2_opacity = 1
export const LightRecon_Group1_HeadMachineGunGrey1_fill = "#4d4d4d"
export const LightRecon_Group1_HeadMachineGunGrey1_d = new Path2D("M 15,15 H 20 V 16 H 15 V 15")
export const LightRecon_Group1_HeadMachineGunGrey1_id = "HeadMachineGunGrey1"
export const LightRecon_Group1_HeadMachineGunGrey1_sodipodiNodetypes = "ccccc"
export const LightRecon_Group1_HeadMachineGunGrey1_type = "path"
export const LightRecon_Group1_HeadMachineGunGrey1_strokeOpacity = 0.487465
export const LightRecon_Group1_HeadMachineGunGrey1_strokeWidth = 0.5
export const LightRecon_Group1_HeadMachineGunGrey1_display = "inline"
export const LightRecon_Group1_HeadHatGrey3_fill = "#cccccc"
export const LightRecon_Group1_HeadHatGrey3_d = new Path2D("M 14,15 13.5,15.5 H 15 V 18 H 15.5 V 15.5 L 15,15 Z")
export const LightRecon_Group1_HeadHatGrey3_id = "HeadHatGrey3"
export const LightRecon_Group1_HeadHatGrey3_sodipodiNodetypes = "cccccccc"
export const LightRecon_Group1_HeadHatGrey3_type = "path"
export const LightRecon_Group1_HeadHatGrey3_strokeOpacity = 0.487465
export const LightRecon_Group1_HeadHatGrey3_strokeWidth = 0.5
export const LightRecon_Group1_HeadHatGrey3_fillOpacity = 1
export const LightRecon_Group1_HeadHatGrey3_display = "inline"
export const LightRecon_Group1_HeadHatGrey3_opacity = 1
export const LightRecon_Group1_HeadGrey3_fill = "#cccccc"
export const LightRecon_Group1_HeadGrey3_d = new Path2D("M 9,22 V 23 H 5 V 22 Z")
export const LightRecon_Group1_HeadGrey3_id = "HeadGrey3"
export const LightRecon_Group1_HeadGrey3_sodipodiNodetypes = "ccccc"
export const LightRecon_Group1_HeadGrey3_type = "path"
export const LightRecon_Group1_HeadGrey3_strokeOpacity = 0.487465
export const LightRecon_Group1_HeadGrey3_strokeWidth = 0.5
export const LightRecon_Group1_HeadGrey3_fillOpacity = 1
export const LightRecon_Group1_HeadGrey3_display = "inline"
export const LightRecon_Group1_HeadGrey3_opacity = 1
export const LightRecon_Group1_FrontBumberGrey1_fill = "#808080"
export const LightRecon_Group1_FrontBumberGrey1_d = new Path2D("M 27,24 V 26 H 26 V 24 Z")
export const LightRecon_Group1_FrontBumberGrey1_id = "FrontBumberGrey1"
export const LightRecon_Group1_FrontBumberGrey1_type = "path"
export const LightRecon_Group1_FrontBumberGrey1_strokeOpacity = 0.487465
export const LightRecon_Group1_FrontBumberGrey1_strokeWidth = 0.5
export const LightRecon_Group1_FrontBumberGrey1_fillOpacity = 1
export const LightRecon_Group1_BodyDoorWindowBlue1_fill = "#42908a"
export const LightRecon_Group1_BodyDoorWindowBlue1_d = new Path2D("M 9,19 V 21 H 14 V 19 Z M 15,19 V 21 H 19 V 19 Z")
export const LightRecon_Group1_BodyDoorWindowBlue1_id = "BodyDoorWindowBlue1"
export const LightRecon_Group1_BodyDoorWindowBlue1_type = "path"
export const LightRecon_Group1_BodyDoorWindowBlue1_strokeOpacity = 0.487465
export const LightRecon_Group1_BodyDoorWindowBlue1_strokeWidth = 0.5
export const LightRecon_Group1_BodyDoorWindowBlue1_fillOpacity = 1
export const LightRecon_Group1_BodyDoorWindowBlue1_display = "inline"

/**
 * @link ./src/assets/svg/sprite/LightTank.svg
 **/
export const LightTank_id = "svg"
export const LightTank_width = 32
export const LightTank_height = 32
export const LightTank_viewBox_x = 0
export const LightTank_viewBox_y = 0
export const LightTank_viewBox_w = 32
export const LightTank_viewBox_h = 32
export const LightTank_type = "svg"
export const LightTank_Group1_id = "Group1"
export const LightTank_Group1_type = "g"
export const LightTank_Group1_display = "inline"
export const LightTank_Group1_TreadBlack1_fill = "#000"
export const LightTank_Group1_TreadBlack1_d = new Path2D("M 4.62,25.22 4.36,25.64 8.06,27.76 8.33,27.35 Z M 25.24,25.29 21.62,27.35 21.83,27.81 25.51,25.72 Z M 9,27.5 V 28 H 20.87 V 27.5 Z")
export const LightTank_Group1_TreadBlack1_id = "TreadBlack1"
export const LightTank_Group1_TreadBlack1_sodipodiNodetypes = "ccccccccccccccc"
export const LightTank_Group1_TreadBlack1_type = "path"
export const LightTank_Group1_TreadBlack1_display = "inline"
export const LightTank_Group1_TreadBlack1_opacity = 1
export const LightTank_Group1_WheelsOuterBlack1_fill = "#000"
export const LightTank_Group1_WheelsOuterBlack1_d = new Path2D("M 5.5,22 A 2,2 0 0 0 3.5,24 2,2 0 0 0 5.5,26 2,2 0 0 0 7.5,24 2,2 0 0 0 5.5,22 Z M 24.5,22 A 2,2 0 0 0 22.5,24 2,2 0 0 0 24.5,26 2,2 0 0 0 26.5,24 2,2 0 0 0 24.5,22 Z M 9,24 A 2,2 0 0 0 7,26 2,2 0 0 0 9,28 2,2 0 0 0 11,26 2,2 0 0 0 9,24 Z M 11,26 A 2,2 0 0 0 13,28 2,2 0 0 0 15,26 2,2 0 0 0 13,24 2,2 0 0 0 11,26 Z M 15,26 A 2,2 0 0 0 17,28 2,2 0 0 0 19,26 2,2 0 0 0 17,24 2,2 0 0 0 15,26 Z M 19,26 A 2,2 0 0 0 21,28 2,2 0 0 0 23,26 2,2 0 0 0 21,24 2,2 0 0 0 19,26 Z")
export const LightTank_Group1_WheelsOuterBlack1_id = "WheelsOuterBlack1"
export const LightTank_Group1_WheelsOuterBlack1_type = "path"
export const LightTank_Group1_WheelsOuterBlack1_strokeOpacity = 0.487465
export const LightTank_Group1_WheelsOuterBlack1_strokeWidth = 0.5
export const LightTank_Group1_WheelsOuterBlack1_fillOpacity = 1
export const LightTank_Group1_WheelsOuterBlack1_opacity = 1
export const LightTank_Group1_WheelsInnerGrey1_fill = "#666666"
export const LightTank_Group1_WheelsInnerGrey1_d = new Path2D("M 5.5,22.5 A 1.5,1.5 0 0 0 4,24 1.5,1.5 0 0 0 5.5,25.5 1.5,1.5 0 0 0 7,24 1.5,1.5 0 0 0 5.5,22.5 Z M 24.5,22.5 A 1.5,1.5 0 0 0 23,24 1.5,1.5 0 0 0 24.5,25.5 1.5,1.5 0 0 0 26,24 1.5,1.5 0 0 0 24.5,22.5 Z M 9,24.5 A 1.5,1.5 0 0 0 7.5,26 1.5,1.5 0 0 0 9,27.5 1.5,1.5 0 0 0 10.5,26 1.5,1.5 0 0 0 9,24.5 Z M 13,24.5 A 1.5,1.5 0 0 0 11.5,26 1.5,1.5 0 0 0 13,27.5 1.5,1.5 0 0 0 14.5,26 1.5,1.5 0 0 0 13,24.5 Z M 17,24.5 A 1.5,1.5 0 0 0 15.5,26 1.5,1.5 0 0 0 17,27.5 1.5,1.5 0 0 0 18.5,26 1.5,1.5 0 0 0 17,24.5 Z M 21,24.5 A 1.5,1.5 0 0 0 19.5,26 1.5,1.5 0 0 0 21,27.5 1.5,1.5 0 0 0 22.5,26 1.5,1.5 0 0 0 21,24.5 Z")
export const LightTank_Group1_WheelsInnerGrey1_id = "WheelsInnerGrey1"
export const LightTank_Group1_WheelsInnerGrey1_type = "path"
export const LightTank_Group1_WheelsInnerGrey1_strokeOpacity = 0.487465
export const LightTank_Group1_WheelsInnerGrey1_strokeWidth = 0.375
export const LightTank_Group1_WheelsInnerGrey1_fillOpacity = 1
export const LightTank_Group1_WheelsInnerGrey1_opacity = 1
export const LightTank_Group1_BodyLowerGrey1_fill = "#b3b3b3"
export const LightTank_Group1_BodyLowerGrey1_d = new Path2D("M 3,18 V 21.5 23.25 L 5,25 H 25 L 28,21.5 V 21.06 C 24.95,20.14 20.95,18.87 17.92,18 Z")
export const LightTank_Group1_BodyLowerGrey1_id = "BodyLowerGrey1"
export const LightTank_Group1_BodyLowerGrey1_type = "path"
export const LightTank_Group1_BodyLowerGrey1_strokeWidth = 0.935414
export const LightTank_Group1_BodyLowerGrey1_display = "inline"
export const LightTank_Group1_BodyLowerGrey1_opacity = 1
export const LightTank_Group1_BodyLowerGrey2_fill = "#999999"
export const LightTank_Group1_BodyLowerGrey2_d = new Path2D("M 6,22 H 24 V 25 H 6 Z")
export const LightTank_Group1_BodyLowerGrey2_id = "BodyLowerGrey2"
export const LightTank_Group1_BodyLowerGrey2_sodipodiNodetypes = "ccccc"
export const LightTank_Group1_BodyLowerGrey2_type = "path"
export const LightTank_Group1_BodyLowerGrey2_strokeOpacity = 0.487465
export const LightTank_Group1_BodyLowerGrey2_strokeWidth = 0.433013
export const LightTank_Group1_BodyLowerGrey2_opacity = 1
export const LightTank_Group1_BodyHorizontalLineGrey1_fill = "#333333"
export const LightTank_Group1_BodyHorizontalLineGrey1_d = new Path2D("M 3,21 V 22 H 27.6 L 28,21.5 V 21 Z")
export const LightTank_Group1_BodyHorizontalLineGrey1_id = "BodyHorizontalLineGrey1"
export const LightTank_Group1_BodyHorizontalLineGrey1_sodipodiNodetypes = "cccccc"
export const LightTank_Group1_BodyHorizontalLineGrey1_type = "path"
export const LightTank_Group1_BodyHorizontalLineGrey1_strokeWidth = 1.1547
export const LightTank_Group1_BodyHorizontalLineGrey1_display = "inline"
export const LightTank_Group1_HeadCannonGrey1_fill = "#999999"
export const LightTank_Group1_HeadCannonGrey1_d = new Path2D("M 20,14.5 H 30 V 16.5 H 20 Z")
export const LightTank_Group1_HeadCannonGrey1_id = "HeadCannonGrey1"
export const LightTank_Group1_HeadCannonGrey1_sodipodiNodetypes = "ccccc"
export const LightTank_Group1_HeadCannonGrey1_type = "path"
export const LightTank_Group1_HeadCannonGrey1_strokeOpacity = 0.487465
export const LightTank_Group1_HeadCannonGrey1_strokeWidth = 0.5
export const LightTank_Group1_HeadCannonGrey1_fillOpacity = 1
export const LightTank_Group1_HeadCannonGrey1_display = "inline"
export const LightTank_Group1_HeadCannonGrey1_opacity = 1
export const LightTank_Group1_HeadHatGrey1_fill = "#b3b3b3"
export const LightTank_Group1_HeadHatGrey1_d = new Path2D("M 13.5,14 V 11.5 L 14,11 H 15 L 15.5,11.5 15.46,14 Z")
export const LightTank_Group1_HeadHatGrey1_id = "HeadHatGrey1"
export const LightTank_Group1_HeadHatGrey1_sodipodiNodetypes = "ccccccc"
export const LightTank_Group1_HeadHatGrey1_type = "path"
export const LightTank_Group1_HeadHatGrey1_strokeOpacity = 0.487465
export const LightTank_Group1_HeadHatGrey1_strokeWidth = 0.5
export const LightTank_Group1_HeadHatGrey1_fillOpacity = 1
export const LightTank_Group1_HeadHatGrey1_opacity = 1
export const LightTank_Group1_HeadHatGrey2_fill = "#808080"
export const LightTank_Group1_HeadHatGrey2_d = new Path2D("M 13.5,11.5 14,11 V 14 H 13.5 Z")
export const LightTank_Group1_HeadHatGrey2_id = "HeadHatGrey2"
export const LightTank_Group1_HeadHatGrey2_sodipodiNodetypes = "ccccc"
export const LightTank_Group1_HeadHatGrey2_type = "path"
export const LightTank_Group1_HeadHatGrey2_strokeOpacity = 0.487465
export const LightTank_Group1_HeadHatGrey2_strokeWidth = 0.5
export const LightTank_Group1_HeadHatGrey2_fillOpacity = 1
export const LightTank_Group1_HeadHatGrey2_opacity = 1
export const LightTank_Group1_HeadMachineGunGrey1_fill = "#4d4d4d"
export const LightTank_Group1_HeadMachineGunGrey1_d = new Path2D("M 15,11 H 20 V 12 H 15 V 11")
export const LightTank_Group1_HeadMachineGunGrey1_id = "HeadMachineGunGrey1"
export const LightTank_Group1_HeadMachineGunGrey1_type = "path"
export const LightTank_Group1_HeadMachineGunGrey1_strokeOpacity = 0.487465
export const LightTank_Group1_HeadMachineGunGrey1_strokeWidth = 0.5
export const LightTank_Group1_HeadHatGrey3_fill = "#cccccc"
export const LightTank_Group1_HeadHatGrey3_d = new Path2D("M 14 11 L 13.5 11.5 L 15 11.5 L 15 14 L 15.5 14 L 15.5 11.5 L 15 11 L 14 11 z ")
export const LightTank_Group1_HeadHatGrey3_id = "HeadHatGrey3"
export const LightTank_Group1_HeadHatGrey3_type = "path"
export const LightTank_Group1_HeadHatGrey3_strokeOpacity = 0.487465
export const LightTank_Group1_HeadHatGrey3_strokeWidth = 0.5
export const LightTank_Group1_HeadHatGrey3_fillOpacity = 1
export const LightTank_Group1_HeadHatGrey3_opacity = 1
export const LightTank_Group1_HeadGrey1_fill = "#b3b3b3"
export const LightTank_Group1_HeadGrey1_d = new Path2D("M 10,17 4,16.00 V 13 H 13.5 L 20,14 21.5,16 20,17 Z")
export const LightTank_Group1_HeadGrey1_id = "HeadGrey1"
export const LightTank_Group1_HeadGrey1_sodipodiNodetypes = "cccccccc"
export const LightTank_Group1_HeadGrey1_type = "path"
export const LightTank_Group1_HeadGrey1_strokeOpacity = 0.487465
export const LightTank_Group1_HeadGrey1_strokeWidth = 0.5
export const LightTank_Group1_HeadGrey1_fillOpacity = 1
export const LightTank_Group1_HeadGrey1_display = "inline"
export const LightTank_Group1_HeadGrey1_opacity = 1
export const LightTank_Group1_HeadGrey2_fill = "#808080"
export const LightTank_Group1_HeadGrey2_d = new Path2D("M 4 15.93 L 4 16.00 L 10 17 L 20 17 L 21.5 16 L 21.49 15.99 L 4 15.93 z ")
export const LightTank_Group1_HeadGrey2_id = "HeadGrey2"
export const LightTank_Group1_HeadGrey2_type = "path"
export const LightTank_Group1_HeadGrey2_strokeOpacity = 0.487465
export const LightTank_Group1_HeadGrey2_strokeWidth = 0.5
export const LightTank_Group1_HeadGrey2_fillOpacity = 1
export const LightTank_Group1_HeadGrey2_display = "inline"
export const LightTank_Group1_HeadGrey2_opacity = 1
export const LightTank_Group1_HeadGrey3_fill = "#cccccc"
export const LightTank_Group1_HeadGrey3_d = new Path2D("M 13.5,13 V 17 H 10 V 13 Z")
export const LightTank_Group1_HeadGrey3_id = "HeadGrey3"
export const LightTank_Group1_HeadGrey3_sodipodiNodetypes = "ccccc"
export const LightTank_Group1_HeadGrey3_type = "path"
export const LightTank_Group1_HeadGrey3_strokeOpacity = 0.487465
export const LightTank_Group1_HeadGrey3_strokeWidth = 0.5
export const LightTank_Group1_HeadGrey3_fillOpacity = 1
export const LightTank_Group1_HeadGrey3_opacity = 1

/**
 * @link ./src/assets/svg/sprite/MediumAntiAir.svg
 **/
export const MediumAntiAir_id = "svg"
export const MediumAntiAir_width = 32
export const MediumAntiAir_height = 32
export const MediumAntiAir_viewBox_x = 0
export const MediumAntiAir_viewBox_y = 0
export const MediumAntiAir_viewBox_w = 32
export const MediumAntiAir_viewBox_h = 32
export const MediumAntiAir_type = "svg"
export const MediumAntiAir_Group1_id = "Group1"
export const MediumAntiAir_Group1_type = "g"
export const MediumAntiAir_Group1_display = "inline"
export const MediumAntiAir_Group1_WheelAxelBgGrey1_fill = "#333333"
export const MediumAntiAir_Group1_WheelAxelBgGrey1_d = new Path2D("M 6,24 7,26 H 13 L 16,25 H 21 L 24,26 26,24 V 22 H 6 Z")
export const MediumAntiAir_Group1_WheelAxelBgGrey1_id = "WheelAxelBgGrey1"
export const MediumAntiAir_Group1_WheelAxelBgGrey1_sodipodiNodetypes = "cccccccccc"
export const MediumAntiAir_Group1_WheelAxelBgGrey1_type = "path"
export const MediumAntiAir_Group1_WheelAxelBgGrey1_strokeOpacity = 0.487465
export const MediumAntiAir_Group1_WheelAxelBgGrey1_strokeWidth = 0.5
export const MediumAntiAir_Group1_WheelAxelBgGrey1_fillOpacity = 1
export const MediumAntiAir_Group1_WheelsOuterBlack1_fill = "#000"
export const MediumAntiAir_Group1_WheelsOuterBlack1_d = new Path2D("M 7.5,23 A 2.5,2.5 0 0 0 5,25.5 2.5,2.5 0 0 0 7.5,28 2.5,2.5 0 0 0 10,25.5 2.5,2.5 0 0 0 7.5,23 Z M 10,25.5 A 2.5,2.5 0 0 0 12.5,28 2.5,2.5 0 0 0 15,25.5 2.5,2.5 0 0 0 12.5,23 2.5,2.5 0 0 0 10,25.5 Z M 24.5,23 A 2.5,2.5 0 0 0 22,25.5 2.5,2.5 0 0 0 24.5,28 2.5,2.5 0 0 0 27,25.5 2.5,2.5 0 0 0 24.5,23 Z")
export const MediumAntiAir_Group1_WheelsOuterBlack1_id = "WheelsOuterBlack1"
export const MediumAntiAir_Group1_WheelsOuterBlack1_type = "path"
export const MediumAntiAir_Group1_WheelsOuterBlack1_strokeOpacity = 0.487465
export const MediumAntiAir_Group1_WheelsOuterBlack1_strokeWidth = 0.5
export const MediumAntiAir_Group1_WheelsOuterBlack1_display = "inline"
export const MediumAntiAir_Group1_WheelsInnerGrey1_fill = "#666666"
export const MediumAntiAir_Group1_WheelsInnerGrey1_d = new Path2D("M 7.5,24 A 1.5,1.5 0 0 0 6,25.5 1.5,1.5 0 0 0 7.5,27 1.5,1.5 0 0 0 9,25.5 1.5,1.5 0 0 0 7.5,24 Z M 12.5,24 A 1.5,1.5 0 0 0 11,25.5 1.5,1.5 0 0 0 12.5,27 1.5,1.5 0 0 0 14,25.5 1.5,1.5 0 0 0 12.5,24 Z M 24.5,24 A 1.5,1.5 0 0 0 23,25.5 1.5,1.5 0 0 0 24.5,27 1.5,1.5 0 0 0 26,25.5 1.5,1.5 0 0 0 24.5,24 Z")
export const MediumAntiAir_Group1_WheelsInnerGrey1_id = "WheelsInnerGrey1"
export const MediumAntiAir_Group1_WheelsInnerGrey1_type = "path"
export const MediumAntiAir_Group1_WheelsInnerGrey1_strokeOpacity = 0.487465
export const MediumAntiAir_Group1_WheelsInnerGrey1_strokeWidth = 0.5
export const MediumAntiAir_Group1_WheelsInnerGrey1_fillOpacity = 1
export const MediumAntiAir_Group1_BodyLowerGrey1_fill = "#b3b3b3"
export const MediumAntiAir_Group1_BodyLowerGrey1_d = new Path2D("M 3,21 V 26 H 4 L 6,23 H 14 L 16,26 H 21 L 23,23 H 26 L 28,25 H 29 V 22 L 27,21 Z")
export const MediumAntiAir_Group1_BodyLowerGrey1_id = "BodyLowerGrey1"
export const MediumAntiAir_Group1_BodyLowerGrey1_sodipodiNodetypes = "cccccccccccccc"
export const MediumAntiAir_Group1_BodyLowerGrey1_type = "path"
export const MediumAntiAir_Group1_BodyLowerGrey1_strokeWidth = 0.935414
export const MediumAntiAir_Group1_BodyLowerGrey1_display = "inline"
export const MediumAntiAir_Group1_BodyLowerGrey1_opacity = 1
export const MediumAntiAir_Group1_BodyLowerGrey1_imageRendering = "optimizespeed"
export const MediumAntiAir_Group1_BodyTopGrey1_fill = "#cccccc"
export const MediumAntiAir_Group1_BodyTopGrey1_d = new Path2D("M 3,19 7,17 H 23 L 27,21 H 3")
export const MediumAntiAir_Group1_BodyTopGrey1_id = "BodyTopGrey1"
export const MediumAntiAir_Group1_BodyTopGrey1_sodipodiNodetypes = "ccccc"
export const MediumAntiAir_Group1_BodyTopGrey1_type = "path"
export const MediumAntiAir_Group1_BodyTopGrey1_strokeOpacity = 0.487465
export const MediumAntiAir_Group1_BodyTopGrey1_strokeWidth = 0.5
export const MediumAntiAir_Group1_BodyTopGrey1_fillOpacity = 1
export const MediumAntiAir_Group1_BodyDoorWindowBlue1_fill = "#42908a"
export const MediumAntiAir_Group1_BodyDoorWindowBlue1_d = new Path2D("M 22,18 V 20 H 26 L 24,18 Z")
export const MediumAntiAir_Group1_BodyDoorWindowBlue1_id = "BodyDoorWindowBlue1"
export const MediumAntiAir_Group1_BodyDoorWindowBlue1_sodipodiNodetypes = "ccccc"
export const MediumAntiAir_Group1_BodyDoorWindowBlue1_type = "path"
export const MediumAntiAir_Group1_BodyDoorWindowBlue1_strokeOpacity = 0.487465
export const MediumAntiAir_Group1_BodyDoorWindowBlue1_strokeWidth = 0.5
export const MediumAntiAir_Group1_BodyDoorWindowBlue1_fillOpacity = 1
export const MediumAntiAir_Group1_BodyDoorWindowBlue1_display = "inline"
export const MediumAntiAir_Group1_RackPoleGrey1_fill = "#b3b3b3"
export const MediumAntiAir_Group1_RackPoleGrey1_d = new Path2D("M 14,10 H 16 V 17 H 14")
export const MediumAntiAir_Group1_RackPoleGrey1_id = "RackPoleGrey1"
export const MediumAntiAir_Group1_RackPoleGrey1_type = "path"
export const MediumAntiAir_Group1_RackPoleGrey1_strokeOpacity = 0.487465
export const MediumAntiAir_Group1_RackPoleGrey1_strokeWidth = 0.5
export const MediumAntiAir_Group1_RackPoleGrey1_fillOpacity = 1
export const MediumAntiAir_Group1_TopMissileGrey1_fill = "#cccccc"
export const MediumAntiAir_Group1_TopMissileGrey1_d = new Path2D("M 21,7 9,9.5 V 12 L 21,9.5")
export const MediumAntiAir_Group1_TopMissileGrey1_id = "TopMissileGrey1"
export const MediumAntiAir_Group1_TopMissileGrey1_sodipodiNodetypes = "cccc"
export const MediumAntiAir_Group1_TopMissileGrey1_type = "path"
export const MediumAntiAir_Group1_TopMissileGrey1_strokeOpacity = 0.487465
export const MediumAntiAir_Group1_TopMissileGrey1_strokeWidth = 0.559017
export const MediumAntiAir_Group1_TopMissileGrey1_fillOpacity = 1
export const MediumAntiAir_Group1_BottomMissileGrey1_fill = "#cccccc"
export const MediumAntiAir_Group1_BottomMissileGrey1_d = new Path2D("M 21,11 9,13.5 V 16 L 21,13.5")
export const MediumAntiAir_Group1_BottomMissileGrey1_id = "BottomMissileGrey1"
export const MediumAntiAir_Group1_BottomMissileGrey1_sodipodiNodetypes = "cccc"
export const MediumAntiAir_Group1_BottomMissileGrey1_type = "path"
export const MediumAntiAir_Group1_BottomMissileGrey1_strokeOpacity = 0.487465
export const MediumAntiAir_Group1_BottomMissileGrey1_strokeWidth = 0.559017
export const MediumAntiAir_Group1_BottomMissileGrey1_fillOpacity = 1

/**
 * @link ./src/assets/svg/sprite/MediumArtillary.svg
 **/
export const MediumArtillary_id = "svg"
export const MediumArtillary_width = 32
export const MediumArtillary_height = 32
export const MediumArtillary_viewBox_x = 0
export const MediumArtillary_viewBox_y = 0
export const MediumArtillary_viewBox_w = 32
export const MediumArtillary_viewBox_h = 32
export const MediumArtillary_type = "svg"
export const MediumArtillary_Group1_id = "Group1"
export const MediumArtillary_Group1_type = "g"
export const MediumArtillary_Group1_display = "inline"
export const MediumArtillary_Group1_TreadBlack1_fill = "#000"
export const MediumArtillary_Group1_TreadBlack1_d = new Path2D("M 4.62,25.22 4.36,25.64 8.06,27.76 8.33,27.35 Z M 25.24,25.29 21.62,27.35 21.83,27.81 25.51,25.72 Z M 9,27.5 V 28 H 20.87 V 27.5 Z")
export const MediumArtillary_Group1_TreadBlack1_id = "TreadBlack1"
export const MediumArtillary_Group1_TreadBlack1_sodipodiNodetypes = "ccccccccccccccc"
export const MediumArtillary_Group1_TreadBlack1_type = "path"
export const MediumArtillary_Group1_TreadBlack1_display = "inline"
export const MediumArtillary_Group1_TreadBlack1_opacity = 1
export const MediumArtillary_Group1_WheelsOuterBlack1_fill = "#000"
export const MediumArtillary_Group1_WheelsOuterBlack1_d = new Path2D("M 5.5,22 A 2,2 0 0 0 3.5,24 2,2 0 0 0 5.5,26 2,2 0 0 0 7.5,24 2,2 0 0 0 5.5,22 Z M 24.5,22 A 2,2 0 0 0 22.5,24 2,2 0 0 0 24.5,26 2,2 0 0 0 26.5,24 2,2 0 0 0 24.5,22 Z M 9,24 A 2,2 0 0 0 7,26 2,2 0 0 0 9,28 2,2 0 0 0 11,26 2,2 0 0 0 9,24 Z M 11,26 A 2,2 0 0 0 13,28 2,2 0 0 0 15,26 2,2 0 0 0 13,24 2,2 0 0 0 11,26 Z M 15,26 A 2,2 0 0 0 17,28 2,2 0 0 0 19,26 2,2 0 0 0 17,24 2,2 0 0 0 15,26 Z M 19,26 A 2,2 0 0 0 21,28 2,2 0 0 0 23,26 2,2 0 0 0 21,24 2,2 0 0 0 19,26 Z")
export const MediumArtillary_Group1_WheelsOuterBlack1_id = "WheelsOuterBlack1"
export const MediumArtillary_Group1_WheelsOuterBlack1_type = "path"
export const MediumArtillary_Group1_WheelsOuterBlack1_strokeOpacity = 0.487465
export const MediumArtillary_Group1_WheelsOuterBlack1_strokeWidth = 0.5
export const MediumArtillary_Group1_WheelsOuterBlack1_fillOpacity = 1
export const MediumArtillary_Group1_WheelsOuterBlack1_opacity = 1
export const MediumArtillary_Group1_WheelsInnerGrey1_fill = "#666666"
export const MediumArtillary_Group1_WheelsInnerGrey1_d = new Path2D("M 5.5,22.5 A 1.5,1.5 0 0 0 4,24 1.5,1.5 0 0 0 5.5,25.5 1.5,1.5 0 0 0 7,24 1.5,1.5 0 0 0 5.5,22.5 Z M 24.5,22.5 A 1.5,1.5 0 0 0 23,24 1.5,1.5 0 0 0 24.5,25.5 1.5,1.5 0 0 0 26,24 1.5,1.5 0 0 0 24.5,22.5 Z M 9,24.5 A 1.5,1.5 0 0 0 7.5,26 1.5,1.5 0 0 0 9,27.5 1.5,1.5 0 0 0 10.5,26 1.5,1.5 0 0 0 9,24.5 Z M 13,24.5 A 1.5,1.5 0 0 0 11.5,26 1.5,1.5 0 0 0 13,27.5 1.5,1.5 0 0 0 14.5,26 1.5,1.5 0 0 0 13,24.5 Z M 17,24.5 A 1.5,1.5 0 0 0 15.5,26 1.5,1.5 0 0 0 17,27.5 1.5,1.5 0 0 0 18.5,26 1.5,1.5 0 0 0 17,24.5 Z M 21,24.5 A 1.5,1.5 0 0 0 19.5,26 1.5,1.5 0 0 0 21,27.5 1.5,1.5 0 0 0 22.5,26 1.5,1.5 0 0 0 21,24.5 Z")
export const MediumArtillary_Group1_WheelsInnerGrey1_id = "WheelsInnerGrey1"
export const MediumArtillary_Group1_WheelsInnerGrey1_type = "path"
export const MediumArtillary_Group1_WheelsInnerGrey1_strokeOpacity = 0.487465
export const MediumArtillary_Group1_WheelsInnerGrey1_strokeWidth = 0.375
export const MediumArtillary_Group1_WheelsInnerGrey1_fillOpacity = 1
export const MediumArtillary_Group1_WheelsInnerGrey1_opacity = 1
export const MediumArtillary_Group1_BodyLowerGrey1_fill = "#b3b3b3"
export const MediumArtillary_Group1_BodyLowerGrey1_d = new Path2D("M 3,18 V 21.5 23.25 L 5,25 H 25 L 28,21.5 V 20 L 23,18 Z")
export const MediumArtillary_Group1_BodyLowerGrey1_id = "BodyLowerGrey1"
export const MediumArtillary_Group1_BodyLowerGrey1_sodipodiNodetypes = "ccccccccc"
export const MediumArtillary_Group1_BodyLowerGrey1_type = "path"
export const MediumArtillary_Group1_BodyLowerGrey1_strokeWidth = 0.935414
export const MediumArtillary_Group1_BodyLowerGrey1_display = "inline"
export const MediumArtillary_Group1_BodyLowerGrey1_opacity = 1
export const MediumArtillary_Group1_BodyLowerGrey2_fill = "#999999"
export const MediumArtillary_Group1_BodyLowerGrey2_d = new Path2D("M 8,19 H 22 V 25 H 8 Z")
export const MediumArtillary_Group1_BodyLowerGrey2_id = "BodyLowerGrey2"
export const MediumArtillary_Group1_BodyLowerGrey2_sodipodiNodetypes = "ccccc"
export const MediumArtillary_Group1_BodyLowerGrey2_type = "path"
export const MediumArtillary_Group1_BodyLowerGrey2_strokeOpacity = 0.487465
export const MediumArtillary_Group1_BodyLowerGrey2_strokeWidth = 0.433013
export const MediumArtillary_Group1_BodyLowerGrey2_opacity = 1
export const MediumArtillary_Group1_BodyHorizontalLineGrey1_fill = "#333333"
export const MediumArtillary_Group1_BodyHorizontalLineGrey1_d = new Path2D("M 3,18 V 19 H 26 V 18 Z")
export const MediumArtillary_Group1_BodyHorizontalLineGrey1_id = "BodyHorizontalLineGrey1"
export const MediumArtillary_Group1_BodyHorizontalLineGrey1_sodipodiNodetypes = "ccccc"
export const MediumArtillary_Group1_BodyHorizontalLineGrey1_type = "path"
export const MediumArtillary_Group1_BodyHorizontalLineGrey1_strokeWidth = 1.1547
export const MediumArtillary_Group1_BodyHorizontalLineGrey1_display = "inline"
export const MediumArtillary_Group1_HeadCannonGrey1_fill = "#999999"
export const MediumArtillary_Group1_HeadCannonGrey1_d = new Path2D("M 11,15 25,8 26,10 13,17 Z")
export const MediumArtillary_Group1_HeadCannonGrey1_id = "HeadCannonGrey1"
export const MediumArtillary_Group1_HeadCannonGrey1_sodipodiNodetypes = "ccccc"
export const MediumArtillary_Group1_HeadCannonGrey1_type = "path"
export const MediumArtillary_Group1_HeadCannonGrey1_strokeOpacity = 0.487465
export const MediumArtillary_Group1_HeadCannonGrey1_strokeWidth = 0.5
export const MediumArtillary_Group1_HeadCannonGrey1_fillOpacity = 1
export const MediumArtillary_Group1_HeadCannonGrey1_display = "inline"
export const MediumArtillary_Group1_HeadCannonGrey1_opacity = 1
export const MediumArtillary_Group1_HeadGrey1_fill = "#b3b3b3"
export const MediumArtillary_Group1_HeadGrey1_d = new Path2D("M 3,18 2,16 3,12 H 10 V 11 H 11 V 12 H 12 L 15,13 16,17 14,18 Z")
export const MediumArtillary_Group1_HeadGrey1_id = "HeadGrey1"
export const MediumArtillary_Group1_HeadGrey1_sodipodiNodetypes = "cccccccccccc"
export const MediumArtillary_Group1_HeadGrey1_type = "path"
export const MediumArtillary_Group1_HeadGrey1_strokeOpacity = 0.487465
export const MediumArtillary_Group1_HeadGrey1_strokeWidth = 0.5
export const MediumArtillary_Group1_HeadGrey1_fillOpacity = 1
export const MediumArtillary_Group1_HeadGrey1_display = "inline"
export const MediumArtillary_Group1_HeadGrey1_opacity = 1
export const MediumArtillary_Group1_HeadCannonGrey2_fill = "#808080"
export const MediumArtillary_Group1_HeadCannonGrey2_d = new Path2D("M 24.5,7.5 25.5,7 27,10 26,10.5")
export const MediumArtillary_Group1_HeadCannonGrey2_id = "HeadCannonGrey2"
export const MediumArtillary_Group1_HeadCannonGrey2_sodipodiNodetypes = "cccc"
export const MediumArtillary_Group1_HeadCannonGrey2_type = "path"
export const MediumArtillary_Group1_HeadCannonGrey2_strokeOpacity = 0.487465
export const MediumArtillary_Group1_HeadCannonGrey2_strokeWidth = 0.5
export const MediumArtillary_Group1_HeadGrey2_fill = "#808080"
export const MediumArtillary_Group1_HeadGrey2_d = new Path2D("M 8,12 H 6 V 18 H 8 Z")
export const MediumArtillary_Group1_HeadGrey2_id = "HeadGrey2"
export const MediumArtillary_Group1_HeadGrey2_sodipodiNodetypes = "ccccc"
export const MediumArtillary_Group1_HeadGrey2_type = "path"
export const MediumArtillary_Group1_HeadGrey2_strokeOpacity = 0.487465
export const MediumArtillary_Group1_HeadGrey2_strokeWidth = 0.5
export const MediumArtillary_Group1_HeadGrey2_fillOpacity = 1
export const MediumArtillary_Group1_HeadGrey2_display = "inline"
export const MediumArtillary_Group1_HeadGrey2_opacity = 1
export const MediumArtillary_Group1_HeadGrey3_fill = "#cccccc"
export const MediumArtillary_Group1_HeadGrey3_d = new Path2D("M 7,13 V 17 H 4 V 13 Z")
export const MediumArtillary_Group1_HeadGrey3_id = "HeadGrey3"
export const MediumArtillary_Group1_HeadGrey3_sodipodiNodetypes = "ccccc"
export const MediumArtillary_Group1_HeadGrey3_type = "path"
export const MediumArtillary_Group1_HeadGrey3_strokeOpacity = 0.487465
export const MediumArtillary_Group1_HeadGrey3_strokeWidth = 0.5
export const MediumArtillary_Group1_HeadGrey3_fillOpacity = 1
export const MediumArtillary_Group1_HeadGrey3_opacity = 1
export const MediumArtillary_Group1_FixBlackBorder1_fill = "#000"
export const MediumArtillary_Group1_FixBlackBorder1_d = new Path2D("M 24 6 L 24 7 L 27 7 L 27 6 L 24 6 z M 9 10 L 9 11 L 12 11 L 12 10 L 9 10 z ")
export const MediumArtillary_Group1_FixBlackBorder1_id = "FixBlackBorder1"
export const MediumArtillary_Group1_FixBlackBorder1_type = "path"
export const MediumArtillary_Group1_FixBlackBorder1_strokeOpacity = 0.487465
export const MediumArtillary_Group1_FixBlackBorder1_strokeWidth = 0.5

/**
 * @link ./src/assets/svg/sprite/MediumCopter.svg
 **/
export const MediumCopter_id = "svg"
export const MediumCopter_width = 32
export const MediumCopter_height = 32
export const MediumCopter_viewBox_x = 0
export const MediumCopter_viewBox_y = 0
export const MediumCopter_viewBox_w = 32
export const MediumCopter_viewBox_h = 32
export const MediumCopter_type = "svg"
export const MediumCopter_Group1_id = "Group1"
export const MediumCopter_Group1_type = "g"
export const MediumCopter_Group1_display = "inline"
export const MediumCopter_Group1_WheelStrutGrey1_fill = "#333333"
export const MediumCopter_Group1_WheelStrutGrey1_d = new Path2D("M 3.25 18 L 2.25 20 L 3.25 20 L 4.25 18 L 3.25 18 z M 24 20 L 23 24 L 24 24 L 25 20 L 24 20 z ")
export const MediumCopter_Group1_WheelStrutGrey1_id = "WheelStrutGrey1"
export const MediumCopter_Group1_WheelStrutGrey1_type = "path"
export const MediumCopter_Group1_WheelStrutGrey1_strokeOpacity = 0.487465
export const MediumCopter_Group1_WheelStrutGrey1_strokeWidth = 0.5
export const MediumCopter_Group1_RotorStrut1_fill = "#333333"
export const MediumCopter_Group1_RotorStrut1_d = new Path2D("M 17,10 V 12 H 20 V 10 Z")
export const MediumCopter_Group1_RotorStrut1_id = "RotorStrut1"
export const MediumCopter_Group1_RotorStrut1_sodipodiNodetypes = "ccccc"
export const MediumCopter_Group1_RotorStrut1_type = "path"
export const MediumCopter_Group1_RotorStrut1_strokeOpacity = 0.487465
export const MediumCopter_Group1_RotorStrut1_strokeWidth = 0.5
export const MediumCopter_Group1_MachineGunGrey1_fill = "#333333"
export const MediumCopter_Group1_MachineGunGrey1_d = new Path2D("M 26,22 V 24 H 30 V 25 H 25 V 22")
export const MediumCopter_Group1_MachineGunGrey1_id = "MachineGunGrey1"
export const MediumCopter_Group1_MachineGunGrey1_type = "path"
export const MediumCopter_Group1_MachineGunGrey1_strokeOpacity = 1
export const MediumCopter_Group1_MachineGunGrey1_strokeWidth = 0.5
export const MediumCopter_Group1_MachineGunGrey1_fillOpacity = 1
export const MediumCopter_Group1_WheelsOuterBlack1_id = "WheelsOuterBlack1"
export const MediumCopter_Group1_WheelsOuterBlack1_d = new Path2D("M 2.5 19.5 C 1.94 19.5 1.5 19.94 1.5 20.5 C 1.5 21.05 1.94 21.5 2.5 21.5 C 3.05 21.5 3.5 21.05 3.5 20.5 C 3.5 19.94 3.05 19.5 2.5 19.5 z M 23 23 A 1.5 1.5 0 0 0 21.5 24.5 A 1.5 1.5 0 0 0 23 26 A 1.5 1.5 0 0 0 24.5 24.5 A 1.5 1.5 0 0 0 23 23 z ")
export const MediumCopter_Group1_WheelsOuterBlack1_type = "path"
export const MediumCopter_Group1_WheelsOuterBlack1_strokeWidth = 0.75
export const MediumCopter_Group1_WheelsInnerGrey1_fill = "#666666"
export const MediumCopter_Group1_WheelsInnerGrey1_d = new Path2D("M 2.5 20 A 0.5 0.5 0 0 0 2 20.5 A 0.5 0.5 0 0 0 2.5 21 A 0.5 0.5 0 0 0 3 20.5 A 0.5 0.5 0 0 0 2.5 20 z M 23 23.5 A 1 1 0 0 0 22 24.5 A 1 1 0 0 0 23 25.5 A 1 1 0 0 0 24 24.5 A 1 1 0 0 0 23 23.5 z ")
export const MediumCopter_Group1_WheelsInnerGrey1_id = "WheelsInnerGrey1"
export const MediumCopter_Group1_WheelsInnerGrey1_type = "path"
export const MediumCopter_Group1_WheelsInnerGrey1_strokeWidth = 0.5
export const MediumCopter_Group1_TailWingGrey1_fill = "#999999"
export const MediumCopter_Group1_TailWingGrey1_d = new Path2D("M 1,14 2,17 H 4 L 3,14")
export const MediumCopter_Group1_TailWingGrey1_id = "TailWingGrey1"
export const MediumCopter_Group1_TailWingGrey1_type = "path"
export const MediumCopter_Group1_TailWingGrey1_strokeOpacity = 1
export const MediumCopter_Group1_TailWingGrey1_strokeWidth = 0.5
export const MediumCopter_Group1_TailWingGrey1_fillOpacity = 1
export const MediumCopter_Group1_BodyLowerGrey1_fill = "#b3b3b3"
export const MediumCopter_Group1_BodyLowerGrey1_d = new Path2D("M 21,12 H 15 L 13,14 11,16 H 5.5 L 4,10 H 1 L 3,19 H 10 L 15,22 H 25 L 31,21 V 19 L 29,18 28,15 Z")
export const MediumCopter_Group1_BodyLowerGrey1_id = "BodyLowerGrey1"
export const MediumCopter_Group1_BodyLowerGrey1_sodipodiNodetypes = "cccccccccccccccc"
export const MediumCopter_Group1_BodyLowerGrey1_type = "path"
export const MediumCopter_Group1_BodyLowerGrey1_strokeWidth = 0.935414
export const MediumCopter_Group1_BodyLowerGrey1_display = "inline"
export const MediumCopter_Group1_BodyLowerGrey1_opacity = 1
export const MediumCopter_Group1_BodyFrontGrey1_fill = "#999999"
export const MediumCopter_Group1_BodyFrontGrey1_d = new Path2D("M 19,12 20,17 31,21 V 19 L 29,18 28,15 21,12")
export const MediumCopter_Group1_BodyFrontGrey1_id = "BodyFrontGrey1"
export const MediumCopter_Group1_BodyFrontGrey1_sodipodiNodetypes = "ccccccc"
export const MediumCopter_Group1_BodyFrontGrey1_type = "path"
export const MediumCopter_Group1_BodyFrontGrey1_strokeOpacity = 1
export const MediumCopter_Group1_BodyFrontGrey1_strokeWidth = 0.5
export const MediumCopter_Group1_BodyFrontGrey1_fillOpacity = 1
export const MediumCopter_Group1_BodyFrontGrey1_display = "inline"
export const MediumCopter_Group1_WindowBlue1_fill = "#42908a"
export const MediumCopter_Group1_WindowBlue1_d = new Path2D("M 21,12 22,16 25,17 V 12 Z M 26,14 V 17 L 29,18 V 14 Z")
export const MediumCopter_Group1_WindowBlue1_id = "WindowBlue1"
export const MediumCopter_Group1_WindowBlue1_sodipodiNodetypes = "cccccccccc"
export const MediumCopter_Group1_WindowBlue1_type = "path"
export const MediumCopter_Group1_WindowBlue1_strokeOpacity = 1
export const MediumCopter_Group1_WindowBlue1_strokeWidth = 0.5
export const MediumCopter_Group1_WindowBlue1_fillOpacity = 1
export const MediumCopter_Group1_WindowBlue1_display = "inline"
export const MediumCopter_Group1_FixBlack1_fill = "#000"
export const MediumCopter_Group1_FixBlack1_d = new Path2D("M 14.5,19 H 23.5 L 20,26 H 11")
export const MediumCopter_Group1_FixBlack1_id = "FixBlack1"
export const MediumCopter_Group1_FixBlack1_sodipodiNodetypes = "cccc"
export const MediumCopter_Group1_FixBlack1_type = "path"
export const MediumCopter_Group1_FixBlack1_strokeOpacity = 1
export const MediumCopter_Group1_FixBlack1_strokeWidth = 0.5
export const MediumCopter_Group1_FixBlack1_fillOpacity = 1
export const MediumCopter_Group1_MissileGrey1_fill = "#4d4d4d"
export const MediumCopter_Group1_MissileGrey1_d = new Path2D("M 15,20 H 22 L 21,22 H 14")
export const MediumCopter_Group1_MissileGrey1_id = "MissileGrey1"
export const MediumCopter_Group1_MissileGrey1_sodipodiNodetypes = "cccc"
export const MediumCopter_Group1_MissileGrey1_type = "path"
export const MediumCopter_Group1_MissileGrey1_strokeOpacity = 1
export const MediumCopter_Group1_MissileGrey1_strokeWidth = 0.5
export const MediumCopter_Group1_MissileGrey1_fillOpacity = 1
export const MediumCopter_Group1_MissileGrey2_fill = "#4d4d4d"
export const MediumCopter_Group1_MissileGrey2_d = new Path2D("M 20.5,23 19.5,25 H 12.5 L 13.5,23")
export const MediumCopter_Group1_MissileGrey2_id = "MissileGrey2"
export const MediumCopter_Group1_MissileGrey2_sodipodiNodetypes = "cccc"
export const MediumCopter_Group1_MissileGrey2_type = "path"
export const MediumCopter_Group1_MissileGrey2_strokeOpacity = 1
export const MediumCopter_Group1_MissileGrey2_strokeWidth = 0.5
export const MediumCopter_Group1_MissileGrey2_fillOpacity = 1
export const MediumCopter_Group1_WingGrey1_fill = "#999999"
export const MediumCopter_Group1_WingGrey1_d = new Path2D("M 21,18 17,26 H 14 L 18,18")
export const MediumCopter_Group1_WingGrey1_id = "WingGrey1"
export const MediumCopter_Group1_WingGrey1_sodipodiNodetypes = "cccc"
export const MediumCopter_Group1_WingGrey1_type = "path"
export const MediumCopter_Group1_WingGrey1_strokeOpacity = 1
export const MediumCopter_Group1_WingGrey1_strokeWidth = 0.5
export const MediumCopter_Group1_WingGrey1_fillOpacity = 1
export const MediumCopter_Group1_CylinderGrey1_fill = "#999999"
export const MediumCopter_Group1_CylinderGrey1_d = new Path2D("M 17,13 V 16 H 12 V 13")
export const MediumCopter_Group1_CylinderGrey1_id = "CylinderGrey1"
export const MediumCopter_Group1_CylinderGrey1_type = "path"
export const MediumCopter_Group1_CylinderGrey1_strokeOpacity = 1
export const MediumCopter_Group1_CylinderGrey1_strokeWidth = 0.5
export const MediumCopter_Group1_CylinderGrey1_fillOpacity = 1
export const MediumCopter_Group1_TailWingGrey2_fill = "#999999"
export const MediumCopter_Group1_TailWingGrey2_d = new Path2D("M 4,17 H 2 L 1,20 H 3")
export const MediumCopter_Group1_TailWingGrey2_id = "TailWingGrey2"
export const MediumCopter_Group1_TailWingGrey2_type = "path"
export const MediumCopter_Group1_TailWingGrey2_strokeOpacity = 1
export const MediumCopter_Group1_TailWingGrey2_strokeWidth = 0.5
export const MediumCopter_Group1_TailWingGrey2_fillOpacity = 1
export const MediumCopter_Group1_RotorRingPurple1_id = "RotorRingPurple1"
export const MediumCopter_Group1_RotorRingPurple1_d = new Path2D("M31 10.5 A12.5 4.5 0 0 1 18.5 15 A12.5 4.5 0 0 1 6 10.5 A12.5 4.5 0 0 1 31 10.5 z")
export const MediumCopter_Group1_RotorRingPurple1_type = "path"
export const MediumCopter_Group1_RotorRingPurple1_stroke = "#800080"
export const MediumCopter_Group1_RotorRingPurple1_strokeOpacity = 1
export const MediumCopter_Group1_RotorRingPurple1_strokeWidth = 0.5
export const MediumCopter_Group1_RotorRingPurple1_fillOpacity = 1
export const MediumCopter_Group1_TailRotorRingPurple1_id = "TailRotorRingPurple1"
export const MediumCopter_Group1_TailRotorRingPurple1_d = new Path2D("M6 10.5 A3 3.5 0 0 1 3 14 A3 3.5 0 0 1 0 10.5 A3 3.5 0 0 1 6 10.5 z")
export const MediumCopter_Group1_TailRotorRingPurple1_type = "path"
export const MediumCopter_Group1_TailRotorRingPurple1_stroke = "#800080"
export const MediumCopter_Group1_TailRotorRingPurple1_strokeOpacity = 1
export const MediumCopter_Group1_TailRotorRingPurple1_strokeWidth = 0.5
export const MediumCopter_Group1_TailRotorRingPurple1_fillOpacity = 1
export const MediumCopter_Group1_RotorBladeFrame1_inkscapeGroupmode = "layer"
export const MediumCopter_Group1_RotorBladeFrame1_id = "RotorBladeFrame1"
export const MediumCopter_Group1_RotorBladeFrame1_type = "g"
export const MediumCopter_Group1_RotorBladeFrame1_display = "none"
export const MediumCopter_Group1_RotorBladeFrame1_Black1_fill = "#000"
export const MediumCopter_Group1_RotorBladeFrame1_Black1_d = new Path2D("M 25,4.46 19,9 H 18 L 11.89,4.59 9.27,7.12 15,11 9.23,13.66 11.64,16.35 18,13 H 19 L 25.07,16.33 27.71,13.78 22,11 27.71,7.16 Z M 1.5,7 V 14 H 4.5 V 7 Z")
export const MediumCopter_Group1_RotorBladeFrame1_Black1_id = "Black1"
export const MediumCopter_Group1_RotorBladeFrame1_Black1_type = "path"
export const MediumCopter_Group1_RotorBladeFrame1_Black1_strokeOpacity = 1
export const MediumCopter_Group1_RotorBladeFrame1_Black1_strokeWidth = 0.5
export const MediumCopter_Group1_RotorBladeFrame1_Black1_fillOpacity = 1
export const MediumCopter_Group1_RotorBladeFrame1_Black1_display = "inline"
export const MediumCopter_Group1_RotorBladeFrame1_Grey1_fill = "#999999"
export const MediumCopter_Group1_RotorBladeFrame1_Grey1_d = new Path2D("M 12,6 11,7 17,11 11,14 12,15 18,12 H 19 L 25,15 26,14 20,11 26,7 25,6 19,10 H 18 Z M 2.5,8 V 13 H 3.5 V 8 Z")
export const MediumCopter_Group1_RotorBladeFrame1_Grey1_id = "Grey1"
export const MediumCopter_Group1_RotorBladeFrame1_Grey1_type = "path"
export const MediumCopter_Group1_RotorBladeFrame1_Grey1_strokeOpacity = 1
export const MediumCopter_Group1_RotorBladeFrame1_Grey1_strokeWidth = 0.5
export const MediumCopter_Group1_RotorBladeFrame1_Grey1_fillOpacity = 1
export const MediumCopter_Group1_RotorBladeFrame1_Grey1_display = "inline"
export const MediumCopter_Group1_RotorBladeFrame2_inkscapeGroupmode = "layer"
export const MediumCopter_Group1_RotorBladeFrame2_id = "RotorBladeFrame2"
export const MediumCopter_Group1_RotorBladeFrame2_type = "g"
export const MediumCopter_Group1_RotorBladeFrame2_display = "none"
export const MediumCopter_Group1_RotorBladeFrame2_Black1_fill = "#000"
export const MediumCopter_Group1_RotorBladeFrame2_Black1_d = new Path2D("M 17,4 V 9 H 8 V 12 H 17 V 17 H 20 V 12 H 29 V 9 H 20 V 4 Z M 0,9 V 12 H 6 V 9 Z")
export const MediumCopter_Group1_RotorBladeFrame2_Black1_id = "Black1"
export const MediumCopter_Group1_RotorBladeFrame2_Black1_type = "path"
export const MediumCopter_Group1_RotorBladeFrame2_Black1_strokeOpacity = 1
export const MediumCopter_Group1_RotorBladeFrame2_Black1_strokeWidth = 0.5
export const MediumCopter_Group1_RotorBladeFrame2_Black1_fillOpacity = 1
export const MediumCopter_Group1_RotorBladeFrame2_Grey1_fill = "#999999"
export const MediumCopter_Group1_RotorBladeFrame2_Grey1_d = new Path2D("M 18,5 V 10 H 9 V 11 H 18 V 16 H 19 V 11 H 28 V 10 H 19 V 5 Z M 1,10 V 11 H 5 V 10 Z")
export const MediumCopter_Group1_RotorBladeFrame2_Grey1_id = "Grey1"
export const MediumCopter_Group1_RotorBladeFrame2_Grey1_type = "path"
export const MediumCopter_Group1_RotorBladeFrame2_Grey1_strokeOpacity = 1
export const MediumCopter_Group1_RotorBladeFrame2_Grey1_strokeWidth = 0.5
export const MediumCopter_Group1_RotorBladeFrame2_Grey1_fillOpacity = 1

/**
 * @link ./src/assets/svg/sprite/MediumRockets.svg
 **/
export const MediumRockets_id = "svg"
export const MediumRockets_width = 32
export const MediumRockets_height = 32
export const MediumRockets_viewBox_x = 0
export const MediumRockets_viewBox_y = 0
export const MediumRockets_viewBox_w = 32
export const MediumRockets_viewBox_h = 32
export const MediumRockets_type = "svg"
export const MediumRockets_Group1_id = "Group1"
export const MediumRockets_Group1_type = "g"
export const MediumRockets_Group1_display = "inline"
export const MediumRockets_Group1_TreadAxelBgGrey1_fill = "#333333"
export const MediumRockets_Group1_TreadAxelBgGrey1_d = new Path2D("M 7,20 H 29 V 23 L 27,25 24,26 H 12 L 9,25 7,23")
export const MediumRockets_Group1_TreadAxelBgGrey1_id = "TreadAxelBgGrey1"
export const MediumRockets_Group1_TreadAxelBgGrey1_sodipodiNodetypes = "cccccccc"
export const MediumRockets_Group1_TreadAxelBgGrey1_type = "path"
export const MediumRockets_Group1_TreadAxelBgGrey1_strokeOpacity = 0.487465
export const MediumRockets_Group1_TreadAxelBgGrey1_strokeWidth = 0.5
export const MediumRockets_Group1_TreadBlack1_fill = "#000"
export const MediumRockets_Group1_TreadBlack1_d = new Path2D("M 8.5,22 V 22.5 H 27.5 V 22 Z M 7.62,25.22 7.35,25.64 11.06,27.76 11.33,27.35 Z M 28.24,25.29 24.63,27.35 24.83,27.81 28.51,25.72 Z M 12,27.5 V 28 H 23.87 V 27.5 Z")
export const MediumRockets_Group1_TreadBlack1_id = "TreadBlack1"
export const MediumRockets_Group1_TreadBlack1_type = "path"
export const MediumRockets_Group1_TreadBlack1_display = "inline"
export const MediumRockets_Group1_TreadBlack1_opacity = 1
export const MediumRockets_Group1_WheelsOuterBlack1_fill = "#000"
export const MediumRockets_Group1_WheelsOuterBlack1_d = new Path2D("M 8.5,22 A 2,2 0 0 0 6.5,24 2,2 0 0 0 8.5,26 2,2 0 0 0 10.5,24 2,2 0 0 0 8.5,22 Z M 14,22 A 1,1 0 0 0 13,23 1,1 0 0 0 14,24 1,1 0 0 0 15,23 1,1 0 0 0 14,22 Z M 22,22 A 1,1 0 0 0 21,23 1,1 0 0 0 22,24 1,1 0 0 0 23,23 1,1 0 0 0 22,22 Z M 27.5,22 A 2,2 0 0 0 25.5,24 2,2 0 0 0 27.5,26 2,2 0 0 0 29.5,24 2,2 0 0 0 27.5,22 Z M 12,24 A 2,2 0 0 0 10,26 2,2 0 0 0 12,28 2,2 0 0 0 14,26 2,2 0 0 0 12,24 Z M 14,26 A 2,2 0 0 0 16,28 2,2 0 0 0 18,26 2,2 0 0 0 16,24 2,2 0 0 0 14,26 Z M 18,26 A 2,2 0 0 0 20,28 2,2 0 0 0 22,26 2,2 0 0 0 20,24 2,2 0 0 0 18,26 Z M 22,26 A 2,2 0 0 0 24,28 2,2 0 0 0 26,26 2,2 0 0 0 24,24 2,2 0 0 0 22,26 Z")
export const MediumRockets_Group1_WheelsOuterBlack1_id = "WheelsOuterBlack1"
export const MediumRockets_Group1_WheelsOuterBlack1_type = "path"
export const MediumRockets_Group1_WheelsOuterBlack1_strokeOpacity = 0.487465
export const MediumRockets_Group1_WheelsOuterBlack1_strokeWidth = 0.5
export const MediumRockets_Group1_WheelsOuterBlack1_fillOpacity = 1
export const MediumRockets_Group1_WheelsOuterBlack1_opacity = 1
export const MediumRockets_Group1_WheelsInnerGrey1_fill = "#666666"
export const MediumRockets_Group1_WheelsInnerGrey1_d = new Path2D("M 8.5,22.5 A 1.5,1.5 0 0 0 7,24 1.5,1.5 0 0 0 8.5,25.5 1.5,1.5 0 0 0 10,24 1.5,1.5 0 0 0 8.5,22.5 Z M 14,22.5 A 0.5,0.5 0 0 0 13.5,23 0.5,0.5 0 0 0 14,23.5 0.5,0.5 0 0 0 14.5,23 0.5,0.5 0 0 0 14,22.5 Z M 22,22.5 A 0.5,0.5 0 0 0 21.5,23 0.5,0.5 0 0 0 22,23.5 0.5,0.5 0 0 0 22.5,23 0.5,0.5 0 0 0 22,22.5 Z M 27.5,22.5 A 1.5,1.5 0 0 0 26,24 1.5,1.5 0 0 0 27.5,25.5 1.5,1.5 0 0 0 29,24 1.5,1.5 0 0 0 27.5,22.5 Z M 12,24.5 A 1.5,1.5 0 0 0 10.5,26 1.5,1.5 0 0 0 12,27.5 1.5,1.5 0 0 0 13.5,26 1.5,1.5 0 0 0 12,24.5 Z M 16,24.5 A 1.5,1.5 0 0 0 14.5,26 1.5,1.5 0 0 0 16,27.5 1.5,1.5 0 0 0 17.5,26 1.5,1.5 0 0 0 16,24.5 Z M 20,24.5 A 1.5,1.5 0 0 0 18.5,26 1.5,1.5 0 0 0 20,27.5 1.5,1.5 0 0 0 21.5,26 1.5,1.5 0 0 0 20,24.5 Z M 24,24.5 A 1.5,1.5 0 0 0 22.5,26 1.5,1.5 0 0 0 24,27.5 1.5,1.5 0 0 0 25.5,26 1.5,1.5 0 0 0 24,24.5 Z")
export const MediumRockets_Group1_WheelsInnerGrey1_id = "WheelsInnerGrey1"
export const MediumRockets_Group1_WheelsInnerGrey1_type = "path"
export const MediumRockets_Group1_WheelsInnerGrey1_strokeOpacity = 0.487465
export const MediumRockets_Group1_WheelsInnerGrey1_strokeWidth = 0.375
export const MediumRockets_Group1_WheelsInnerGrey1_fillOpacity = 1
export const MediumRockets_Group1_WheelsInnerGrey1_opacity = 1
export const MediumRockets_Group1_BodyLowerGrey1_fill = "#b3b3b3"
export const MediumRockets_Group1_BodyLowerGrey1_d = new Path2D("M 31,21 H 4 V 23 H 6 L 7,22 H 29 L 30,23 H 31 Z")
export const MediumRockets_Group1_BodyLowerGrey1_id = "BodyLowerGrey1"
export const MediumRockets_Group1_BodyLowerGrey1_sodipodiNodetypes = "ccccccccc"
export const MediumRockets_Group1_BodyLowerGrey1_type = "path"
export const MediumRockets_Group1_BodyLowerGrey1_strokeWidth = 0.935414
export const MediumRockets_Group1_BodyLowerGrey1_display = "inline"
export const MediumRockets_Group1_BodyLowerGrey1_opacity = 1
export const MediumRockets_Group1_BodyFrontGrey1_fill = "#b3b3b3"
export const MediumRockets_Group1_BodyFrontGrey1_d = new Path2D("M 25,14 H 29 L 31,18 V 21 H 25 Z")
export const MediumRockets_Group1_BodyFrontGrey1_id = "BodyFrontGrey1"
export const MediumRockets_Group1_BodyFrontGrey1_sodipodiNodetypes = "cccccc"
export const MediumRockets_Group1_BodyFrontGrey1_type = "path"
export const MediumRockets_Group1_BodyFrontGrey1_strokeOpacity = 0.487465
export const MediumRockets_Group1_BodyFrontGrey1_strokeWidth = 0.433013
export const MediumRockets_Group1_BodyFrontGrey1_fillOpacity = 1
export const MediumRockets_Group1_BodyFrontGrey1_display = "inline"
export const MediumRockets_Group1_BodyFrontGrey1_opacity = 1
export const MediumRockets_Group1_BodyDoorWindowBlue1_fill = "#42908a"
export const MediumRockets_Group1_BodyDoorWindowBlue1_d = new Path2D("M 26,15 H 28.5 L 29.5,17 H 26")
export const MediumRockets_Group1_BodyDoorWindowBlue1_id = "BodyDoorWindowBlue1"
export const MediumRockets_Group1_BodyDoorWindowBlue1_sodipodiNodetypes = "cccc"
export const MediumRockets_Group1_BodyDoorWindowBlue1_type = "path"
export const MediumRockets_Group1_BodyDoorWindowBlue1_strokeOpacity = 0.487465
export const MediumRockets_Group1_BodyDoorWindowBlue1_strokeWidth = 0.5
export const MediumRockets_Group1_BodyDoorWindowBlue1_fillOpacity = 1
export const MediumRockets_Group1_AntenaGrey1_fill = "#4d4d4d"
export const MediumRockets_Group1_AntenaGrey1_d = new Path2D("M 25,9 H 26 V 14 H 25 V 9")
export const MediumRockets_Group1_AntenaGrey1_id = "AntenaGrey1"
export const MediumRockets_Group1_AntenaGrey1_type = "path"
export const MediumRockets_Group1_AntenaGrey1_strokeOpacity = 0.487465
export const MediumRockets_Group1_AntenaGrey1_strokeWidth = 0.5
export const MediumRockets_Group1_AntenaGrey1_fillOpacity = 1
export const MediumRockets_Group1_BodyMiddleGrey2_fill = "#999999"
export const MediumRockets_Group1_BodyMiddleGrey2_d = new Path2D("M 25,16 H 21 V 20 H 25")
export const MediumRockets_Group1_BodyMiddleGrey2_id = "BodyMiddleGrey2"
export const MediumRockets_Group1_BodyMiddleGrey2_type = "path"
export const MediumRockets_Group1_BodyMiddleGrey2_strokeOpacity = 0.487465
export const MediumRockets_Group1_BodyMiddleGrey2_strokeWidth = 0.5
export const MediumRockets_Group1_BodyMiddleGrey2_fillOpacity = 1
export const MediumRockets_Group1_BedGrey1_fill = "#4d4d4d"
export const MediumRockets_Group1_BedGrey1_d = new Path2D("M 4,20 V 21 H 25 V 20 H 4")
export const MediumRockets_Group1_BedGrey1_id = "BedGrey1"
export const MediumRockets_Group1_BedGrey1_type = "path"
export const MediumRockets_Group1_BedGrey1_strokeOpacity = 0.487465
export const MediumRockets_Group1_BedGrey1_strokeWidth = 0.5
export const MediumRockets_Group1_BedGrey1_fillOpacity = 1
export const MediumRockets_Group1_BedGrey1_display = "inline"
export const MediumRockets_Group1_PodSupportGrey1_fill = "#4d4d4d"
export const MediumRockets_Group1_PodSupportGrey1_d = new Path2D("M 15,13 21,20 20,21 14,14")
export const MediumRockets_Group1_PodSupportGrey1_id = "PodSupportGrey1"
export const MediumRockets_Group1_PodSupportGrey1_sodipodiNodetypes = "cccc"
export const MediumRockets_Group1_PodSupportGrey1_type = "path"
export const MediumRockets_Group1_PodSupportGrey1_strokeOpacity = 0.487465
export const MediumRockets_Group1_PodSupportGrey1_strokeWidth = 0.5
export const MediumRockets_Group1_PodSupportGrey1_fillOpacity = 1
export const MediumRockets_Group1_PodGrey1_fill = "#b3b3b3"
export const MediumRockets_Group1_PodGrey1_d = new Path2D("M 15,6 H 16 V 7 L 18,11 V 12 L 5,20 H 4 L 1,16 V 15 L 15,7 Z")
export const MediumRockets_Group1_PodGrey1_id = "PodGrey1"
export const MediumRockets_Group1_PodGrey1_sodipodiNodetypes = "ccccccccccc"
export const MediumRockets_Group1_PodGrey1_inkscapeTransformCenterX = "-5.5452684"
export const MediumRockets_Group1_PodGrey1_inkscapeTransformCenterY = "-6.5"
export const MediumRockets_Group1_PodGrey1_type = "path"
export const MediumRockets_Group1_PodGrey1_strokeOpacity = 0.487465
export const MediumRockets_Group1_PodGrey1_strokeWidth = 0.5
export const MediumRockets_Group1_PodGrey1_fillOpacity = 1
export const MediumRockets_Group1_PodGrey1_display = "inline"
export const MediumRockets_Group1_FixBorder1_fill = "#000"
export const MediumRockets_Group1_FixBorder1_d = new Path2D("M 15,5 H 16 L 17,6 H 14")
export const MediumRockets_Group1_FixBorder1_id = "FixBorder1"
export const MediumRockets_Group1_FixBorder1_sodipodiNodetypes = "cccc"
export const MediumRockets_Group1_FixBorder1_type = "path"
export const MediumRockets_Group1_FixBorder1_strokeOpacity = 0.487465
export const MediumRockets_Group1_FixBorder1_strokeWidth = 0.5

/**
 * @link ./src/assets/svg/sprite/MediumTank.svg
 **/
export const MediumTank_id = "svg"
export const MediumTank_width = 32
export const MediumTank_height = 32
export const MediumTank_viewBox_x = 0
export const MediumTank_viewBox_y = 0
export const MediumTank_viewBox_w = 32
export const MediumTank_viewBox_h = 32
export const MediumTank_type = "svg"
export const MediumTank_Group1_id = "Group1"
export const MediumTank_Group1_type = "g"
export const MediumTank_Group1_display = "inline"
export const MediumTank_Group1_TreadBlack1_fill = "#000"
export const MediumTank_Group1_TreadBlack1_d = new Path2D("M 2.62,25.22 2.36,25.64 6.06,27.76 6.33,27.35 Z M 27.24,25.29 23.62,27.35 23.83,27.81 27.51,25.72 Z M 7,27.5 V 28 H 22.87 V 27.5 Z")
export const MediumTank_Group1_TreadBlack1_id = "TreadBlack1"
export const MediumTank_Group1_TreadBlack1_sodipodiNodetypes = "ccccccccccccccc"
export const MediumTank_Group1_TreadBlack1_type = "path"
export const MediumTank_Group1_TreadBlack1_display = "inline"
export const MediumTank_Group1_TreadBlack1_opacity = 1
export const MediumTank_Group1_WheelsOuterBlack1_fill = "#000"
export const MediumTank_Group1_WheelsOuterBlack1_d = new Path2D("M 3.5,26 C 2.39,26 1.5,25.10 1.5,24 1.5,22.89 2.39,22 3.5,22 4.60,22 5.5,22.89 5.5,24 5.5,25.10 4.60,26 3.5,26 Z M 26.5,22 C 25.39,22 24.5,22.89 24.5,24 24.5,25.10 25.39,26 26.5,26 27.60,26 28.5,25.10 28.5,24 28.5,22.89 27.60,22 26.5,22 Z M 11,24 C 9.89,24 9,24.89 9,26 9,27.10 9.89,28 11,28 12.10,28 13,27.10 13,26 13,24.89 12.10,24 11,24 Z M 13,26 C 13,27.10 13.89,28 15,28 16.10,28 17,27.10 17,26 17,24.89 16.10,24 15,24 13.89,24 13,24.89 13,26 Z M 17,26 C 17,27.10 17.89,28 19,28 20.10,28 21,27.10 21,26 21,24.89 20.10,24 19,24 17.89,24 17,24.89 17,26 Z M 21,26 C 21,27.10 21.89,28 23,28 24.10,28 25,27.10 25,26 25,24.89 24.10,24 23,24 21.89,24 21,24.89 21,26 Z M 7,24 C 5.89,24 5,24.89 5,26 5,27.10 5.89,28 7,28 8.10,28 9,27.10 9,26 9,24.89 8.10,24 7,24 Z")
export const MediumTank_Group1_WheelsOuterBlack1_id = "WheelsOuterBlack1"
export const MediumTank_Group1_WheelsOuterBlack1_sodipodiNodetypes = "sssssssssssssssssssssssssssssssssss"
export const MediumTank_Group1_WheelsOuterBlack1_type = "path"
export const MediumTank_Group1_WheelsOuterBlack1_strokeOpacity = 0.487465
export const MediumTank_Group1_WheelsOuterBlack1_strokeWidth = 0.5
export const MediumTank_Group1_WheelsOuterBlack1_fillOpacity = 1
export const MediumTank_Group1_WheelsOuterBlack1_opacity = 1
export const MediumTank_Group1_WheelsInnerGrey1_fill = "#666666"
export const MediumTank_Group1_WheelsInnerGrey1_d = new Path2D("M 3.5,22.5 C 2.67,22.5 2,23.17 2,24 2,24.82 2.67,25.5 3.5,25.5 4.32,25.5 5,24.82 5,24 5,23.17 4.32,22.5 3.5,22.5 Z M 26.5,22.5 C 25.67,22.5 25,23.17 25,24 25,24.82 25.67,25.5 26.5,25.5 27.32,25.5 28,24.82 28,24 28,23.17 27.32,22.5 26.5,22.5 Z M 11,24.5 C 10.17,24.5 9.5,25.17 9.5,26 9.5,26.82 10.17,27.5 11,27.5 11.82,27.5 12.5,26.82 12.5,26 12.5,25.17 11.82,24.5 11,24.5 Z M 15,24.5 C 14.17,24.5 13.5,25.17 13.5,26 13.5,26.82 14.17,27.5 15,27.5 15.82,27.5 16.5,26.82 16.5,26 16.5,25.17 15.82,24.5 15,24.5 Z M 19,24.5 C 18.17,24.5 17.5,25.17 17.5,26 17.5,26.82 18.17,27.5 19,27.5 19.82,27.5 20.5,26.82 20.5,26 20.5,25.17 19.82,24.5 19,24.5 Z M 23,24.5 C 22.17,24.5 21.5,25.17 21.5,26 21.5,26.82 22.17,27.5 23,27.5 23.82,27.5 24.5,26.82 24.5,26 24.5,25.17 23.82,24.5 23,24.5 Z M 7,24.5 C 6.17,24.5 5.5,25.17 5.5,26 5.5,26.82 6.17,27.5 7,27.5 7.82,27.5 8.5,26.82 8.5,26 8.5,25.17 7.82,24.5 7,24.5 Z")
export const MediumTank_Group1_WheelsInnerGrey1_id = "WheelsInnerGrey1"
export const MediumTank_Group1_WheelsInnerGrey1_sodipodiNodetypes = "sssssssssssssssssssssssssssssssssss"
export const MediumTank_Group1_WheelsInnerGrey1_type = "path"
export const MediumTank_Group1_WheelsInnerGrey1_strokeOpacity = 0.487465
export const MediumTank_Group1_WheelsInnerGrey1_strokeWidth = 0.375
export const MediumTank_Group1_WheelsInnerGrey1_fillOpacity = 1
export const MediumTank_Group1_WheelsInnerGrey1_opacity = 1
export const MediumTank_Group1_HeadBottomBackgroundGrey1_fill = "#333333"
export const MediumTank_Group1_HeadBottomBackgroundGrey1_d = new Path2D("M 12,19 10,16 16,19 Z")
export const MediumTank_Group1_HeadBottomBackgroundGrey1_id = "HeadBottomBackgroundGrey1"
export const MediumTank_Group1_HeadBottomBackgroundGrey1_type = "path"
export const MediumTank_Group1_HeadBottomBackgroundGrey1_strokeOpacity = 0.487465
export const MediumTank_Group1_HeadBottomBackgroundGrey1_strokeWidth = 0.5
export const MediumTank_Group1_HeadBottomBackgroundGrey1_fillOpacity = 1
export const MediumTank_Group1_BodyLowerGrey1_fill = "#b3b3b3"
export const MediumTank_Group1_BodyLowerGrey1_d = new Path2D("M 1,17 V 23 H 4 L 4.5,24.5 5.5,25 H 27 L 30,22 V 20 L 25,19 H 21 13 L 9,17 Z")
export const MediumTank_Group1_BodyLowerGrey1_id = "BodyLowerGrey1"
export const MediumTank_Group1_BodyLowerGrey1_sodipodiNodetypes = "ccccccccccccc"
export const MediumTank_Group1_BodyLowerGrey1_type = "path"
export const MediumTank_Group1_BodyLowerGrey1_strokeWidth = 0.935414
export const MediumTank_Group1_BodyLowerGrey1_display = "inline"
export const MediumTank_Group1_BodyLowerGrey1_opacity = 1
export const MediumTank_Group1_BodyLowerGrey2_fill = "#999999"
export const MediumTank_Group1_BodyLowerGrey2_d = new Path2D("M 25,19 30,20 V 21 L 25,20 Z")
export const MediumTank_Group1_BodyLowerGrey2_id = "BodyLowerGrey2"
export const MediumTank_Group1_BodyLowerGrey2_sodipodiNodetypes = "ccccc"
export const MediumTank_Group1_BodyLowerGrey2_type = "path"
export const MediumTank_Group1_BodyLowerGrey2_strokeOpacity = 0.487465
export const MediumTank_Group1_BodyLowerGrey2_strokeWidth = 0.433013
export const MediumTank_Group1_BodyLowerGrey2_opacity = 1
export const MediumTank_Group1_HeadCannonGrey1_fill = "#999999"
export const MediumTank_Group1_HeadCannonGrey1_d = new Path2D("M 20,13.5 H 31 V 16.5 H 20 Z")
export const MediumTank_Group1_HeadCannonGrey1_id = "HeadCannonGrey1"
export const MediumTank_Group1_HeadCannonGrey1_sodipodiNodetypes = "ccccc"
export const MediumTank_Group1_HeadCannonGrey1_type = "path"
export const MediumTank_Group1_HeadCannonGrey1_strokeOpacity = 0.487465
export const MediumTank_Group1_HeadCannonGrey1_strokeWidth = 0.5
export const MediumTank_Group1_HeadCannonGrey1_fillOpacity = 1
export const MediumTank_Group1_HeadCannonGrey1_display = "inline"
export const MediumTank_Group1_HeadCannonGrey1_opacity = 1
export const MediumTank_Group1_HeadHatGrey1_fill = "#b3b3b3"
export const MediumTank_Group1_HeadHatGrey1_d = new Path2D("M 17.5,13 V 10.5 L 18,10 H 19 L 19.5,10.5 19.46,13 Z")
export const MediumTank_Group1_HeadHatGrey1_id = "HeadHatGrey1"
export const MediumTank_Group1_HeadHatGrey1_sodipodiNodetypes = "ccccccc"
export const MediumTank_Group1_HeadHatGrey1_type = "path"
export const MediumTank_Group1_HeadHatGrey1_strokeOpacity = 0.487465
export const MediumTank_Group1_HeadHatGrey1_strokeWidth = 0.5
export const MediumTank_Group1_HeadHatGrey1_fillOpacity = 1
export const MediumTank_Group1_HeadHatGrey1_opacity = 1
export const MediumTank_Group1_HeadHatGrey2_fill = "#808080"
export const MediumTank_Group1_HeadHatGrey2_d = new Path2D("M 17.5,10.50 18,10 V 13.00 H 17.5 Z")
export const MediumTank_Group1_HeadHatGrey2_id = "HeadHatGrey2"
export const MediumTank_Group1_HeadHatGrey2_sodipodiNodetypes = "ccccc"
export const MediumTank_Group1_HeadHatGrey2_type = "path"
export const MediumTank_Group1_HeadHatGrey2_strokeOpacity = 0.487465
export const MediumTank_Group1_HeadHatGrey2_strokeWidth = 0.5
export const MediumTank_Group1_HeadHatGrey2_fillOpacity = 1
export const MediumTank_Group1_HeadHatGrey2_opacity = 1
export const MediumTank_Group1_HeadMachineGunGrey1_fill = "#4d4d4d"
export const MediumTank_Group1_HeadMachineGunGrey1_d = new Path2D("M 19,10 H 24 V 11 H 19 V 10")
export const MediumTank_Group1_HeadMachineGunGrey1_id = "HeadMachineGunGrey1"
export const MediumTank_Group1_HeadMachineGunGrey1_sodipodiNodetypes = "ccccc"
export const MediumTank_Group1_HeadMachineGunGrey1_type = "path"
export const MediumTank_Group1_HeadMachineGunGrey1_strokeOpacity = 0.487465
export const MediumTank_Group1_HeadMachineGunGrey1_strokeWidth = 0.5
export const MediumTank_Group1_HeadHatGrey3_fill = "#cccccc"
export const MediumTank_Group1_HeadHatGrey3_d = new Path2D("M 18,10 17.5,10.5 H 19 V 13 H 19.5 V 10.5 L 19,10 Z")
export const MediumTank_Group1_HeadHatGrey3_id = "HeadHatGrey3"
export const MediumTank_Group1_HeadHatGrey3_sodipodiNodetypes = "cccccccc"
export const MediumTank_Group1_HeadHatGrey3_type = "path"
export const MediumTank_Group1_HeadHatGrey3_strokeOpacity = 0.487465
export const MediumTank_Group1_HeadHatGrey3_strokeWidth = 0.5
export const MediumTank_Group1_HeadHatGrey3_fillOpacity = 1
export const MediumTank_Group1_HeadHatGrey3_opacity = 1
export const MediumTank_Group1_HeadGrey1_fill = "#b3b3b3"
export const MediumTank_Group1_HeadGrey1_d = new Path2D("M 24,19 H 15 L 9,16 H 3 V 12 H 5 V 11 H 7 V 12 H 13 17.5 L 24,14 25,17 Z")
export const MediumTank_Group1_HeadGrey1_id = "HeadGrey1"
export const MediumTank_Group1_HeadGrey1_sodipodiNodetypes = "cccccccccccccc"
export const MediumTank_Group1_HeadGrey1_type = "path"
export const MediumTank_Group1_HeadGrey1_strokeOpacity = 0.487465
export const MediumTank_Group1_HeadGrey1_strokeWidth = 0.5
export const MediumTank_Group1_HeadGrey1_fillOpacity = 1
export const MediumTank_Group1_HeadGrey1_display = "inline"
export const MediumTank_Group1_HeadGrey1_opacity = 1
export const MediumTank_Group1_BodyHorizontalLineGrey1_fill = "#333333"
export const MediumTank_Group1_BodyHorizontalLineGrey1_d = new Path2D("M 13,18 15,19 H 24 L 24.5,18 Z")
export const MediumTank_Group1_BodyHorizontalLineGrey1_id = "BodyHorizontalLineGrey1"
export const MediumTank_Group1_BodyHorizontalLineGrey1_sodipodiNodetypes = "ccccc"
export const MediumTank_Group1_BodyHorizontalLineGrey1_type = "path"
export const MediumTank_Group1_BodyHorizontalLineGrey1_strokeWidth = 1.1547
export const MediumTank_Group1_BodyHorizontalLineGrey1_fillOpacity = 1
export const MediumTank_Group1_BodyHorizontalLineGrey1_display = "inline"
export const MediumTank_Group1_HeadGrey2_fill = "#808080"
export const MediumTank_Group1_HeadGrey2_d = new Path2D("M 17.5,12 H 16 V 11 H 12 V 12 H 17 L 20,15 21,18 H 24.5 L 25,17 24,14 Z")
export const MediumTank_Group1_HeadGrey2_id = "HeadGrey2"
export const MediumTank_Group1_HeadGrey2_sodipodiNodetypes = "cccccccccccc"
export const MediumTank_Group1_HeadGrey2_type = "path"
export const MediumTank_Group1_HeadGrey2_strokeOpacity = 0.487465
export const MediumTank_Group1_HeadGrey2_strokeWidth = 0.5
export const MediumTank_Group1_HeadGrey2_fillOpacity = 1
export const MediumTank_Group1_HeadGrey2_display = "inline"
export const MediumTank_Group1_HeadGrey2_opacity = 1
export const MediumTank_Group1_HeadGrey3_fill = "#e6e6e6"
export const MediumTank_Group1_HeadGrey3_d = new Path2D("M 16,12 V 16 H 12 V 12 Z")
export const MediumTank_Group1_HeadGrey3_id = "HeadGrey3"
export const MediumTank_Group1_HeadGrey3_sodipodiNodetypes = "ccccc"
export const MediumTank_Group1_HeadGrey3_type = "path"
export const MediumTank_Group1_HeadGrey3_strokeOpacity = 0.487465
export const MediumTank_Group1_HeadGrey3_strokeWidth = 0.5
export const MediumTank_Group1_HeadGrey3_fillOpacity = 1
export const MediumTank_Group1_HeadGrey3_display = "inline"
export const MediumTank_Group1_HeadGrey3_opacity = 1
export const MediumTank_Group1_image1351_width = 32
export const MediumTank_Group1_image1351_height = 32
export const MediumTank_Group1_image1351_preserveAspectRatio = "none"
export const MediumTank_Group1_image1351_xlinkHref = "../../../../../../../Downloads/LightTank2.png"
export const MediumTank_Group1_image1351_id = "image1351"
export const MediumTank_Group1_image1351_x = 0
export const MediumTank_Group1_image1351_y = 0
export const MediumTank_Group1_image1351_type = "image"
export const MediumTank_Group1_image1351_display = "none"
export const MediumTank_Group1_image1351_imageRendering = "optimizespeed"
export const MediumTank_Group1_image1915_width = 222
export const MediumTank_Group1_image1915_height = 64
export const MediumTank_Group1_image1915_preserveAspectRatio = "none"
export const MediumTank_Group1_image1915_xlinkHref = "ref/LightTank1_16x16.png"
export const MediumTank_Group1_image1915_id = "image1915"
export const MediumTank_Group1_image1915_x = 1
export const MediumTank_Group1_image1915_y = 0
export const MediumTank_Group1_image1915_type = "image"
export const MediumTank_Group1_image1915_display = "none"
export const MediumTank_Group1_image1915_opacity = 0.3
export const MediumTank_Group1_image1915_imageRendering = "optimizespeed"

/**
 * @link ./src/assets/svg/sprite/Mountain.svg
 **/
export const Mountain_id = "svg"
export const Mountain_width = 32
export const Mountain_height = 32
export const Mountain_viewBox_x = 0
export const Mountain_viewBox_y = 0
export const Mountain_viewBox_w = 32
export const Mountain_viewBox_h = 32
export const Mountain_type = "svg"
export const Mountain_Group1_id = "Group1"
export const Mountain_Group1_type = "g"
export const Mountain_Group1_display = "inline"
export const Mountain_Group1_Brown1_fill = "#b06850"
export const Mountain_Group1_Brown1_d = new Path2D("M 12.08 0 L 12.08 0.00 L 11.35 0.00 L 4.94 6.05 L 5.18 6.81 L 0 11.93 L 0.03 22.02 L 4.14 26.08 L 9.90 27.98 L 12.02 29.94 L 18.13 29.97 L 20.03 28.01 L 25.98 25.98 L 29.97 21.99 L 31.96 15.92 L 15.98 0 L 14.23 0 L 12.08 0 z ")
export const Mountain_Group1_Brown1_id = "Brown1"
export const Mountain_Group1_Brown1_type = "path"
export const Mountain_Group1_Brown1_fillOpacity = 1
export const Mountain_Group1_Brown1_display = "inline"
export const Mountain_Group1_Brown1_opacity = 1
export const Mountain_Group1_Green4_fill = "#586028"
export const Mountain_Group1_Green4_d = new Path2D("M 24.02,8.53 21.87,15.01 25.94,26.86 31.21,21.89 31.47,19.77 32.00,20.80 31.99,15.40 32,15.39 31.99,15.39 31.99,14.94 31.94,14.74 31.68,15.12 Z")
export const Mountain_Group1_Green4_id = "Green4"
export const Mountain_Group1_Green4_sodipodiNodetypes = "ccccccccccccc"
export const Mountain_Group1_Green4_type = "path"
export const Mountain_Group1_Green4_fillOpacity = 1
export const Mountain_Group1_Green4_display = "inline"
export const Mountain_Group1_Green4_opacity = 1
export const Mountain_Group1_Green3_fill = "#709048"
export const Mountain_Group1_Green3_d = new Path2D("M 0.00 11.08 L 0 22.17 L 4.02 27.08 L 12.02 29.94 L 19.06 29.95 L 19.01 26.35 L 21.41 24.18 L 21.13 16.54 L 18.98 14.95 L 18.87 15.11 L 18.87 14.78 L 14.07 16.03 L 7.94 15.99 L 0.00 11.08 z ")
export const Mountain_Group1_Green3_id = "Green3"
export const Mountain_Group1_Green3_type = "path"
export const Mountain_Group1_Green3_fillOpacity = 1
export const Mountain_Group1_Green3_display = "inline"
export const Mountain_Group1_Green3_opacity = 1
export const Mountain_Group1_Yellow1_fill = "#f8e858"
export const Mountain_Group1_Yellow1_d = new Path2D("M 0.64,11.38 10.55,1.40 16.96,1.33 16.79,15.90 9.35,17.63 2.97,14.89 Z")
export const Mountain_Group1_Yellow1_id = "Yellow1"
export const Mountain_Group1_Yellow1_sodipodiNodetypes = "ccccccc"
export const Mountain_Group1_Yellow1_type = "path"
export const Mountain_Group1_Yellow1_fillOpacity = 1
export const Mountain_Group1_Yellow1_display = "inline"
export const Mountain_Group1_Yellow1_opacity = 1
export const Mountain_Group1_Green1_fill = "#d0e830"
export const Mountain_Group1_Green1_d = new Path2D("M 8.38,7.78 7.71,9.61 9.55,10.22 10.22,8.44 Z M 4.70,13.01 2.36,10.45 0.88,11.97 0.88,21.93 4.64,26.22 12.25,28.93 12.92,28.94 16.46,16.76 17.47,11.96 13.99,11.99 6.67,14.10 5.66,15.97 3.56,15.03 Z")
export const Mountain_Group1_Green1_id = "Green1"
export const Mountain_Group1_Green1_sodipodiNodetypes = "ccccccccccccccccccc"
export const Mountain_Group1_Green1_type = "path"
export const Mountain_Group1_Green1_fillOpacity = 1
export const Mountain_Group1_Green1_display = "inline"
export const Mountain_Group1_Green1_opacity = 1
export const Mountain_Group1_Orange1_fill = "#f8c030"
export const Mountain_Group1_Orange1_d = new Path2D("M 16.01,0.37 13.36,3.02 15.40,4.91 13.95,6.60 14.86,8.75 16.88,9.44 19.78,3.73 Z M 10.35,3.05 7.25,6.21 9.00,6.91 11,4.89 Z M 10.5,9.93 8.00,15.08 8.03,19.41 10.57,17.01 11.96,13.05 11.37,10.03 Z")
export const Mountain_Group1_Orange1_id = "Orange1"
export const Mountain_Group1_Orange1_sodipodiNodetypes = "cccccccccccccccccccc"
export const Mountain_Group1_Orange1_type = "path"
export const Mountain_Group1_Orange1_fillOpacity = 1
export const Mountain_Group1_Orange1_display = "inline"
export const Mountain_Group1_Orange1_opacity = 1
export const Mountain_Group1_Brown2_fill = "#d08848"
export const Mountain_Group1_Brown2_d = new Path2D("M 17.07,3.98 15.45,6.01 15.51,7.97 16.60,15.95 17.60,15.98 18.72,8.00 20.65,9.97 H 21.99 L 21.96,8.47 Z M 11.06,11.96 7.72,20.00 9.72,19.97 11.93,14.83 Z")
export const Mountain_Group1_Brown2_id = "Brown2"
export const Mountain_Group1_Brown2_type = "path"
export const Mountain_Group1_Brown2_fillOpacity = 1
export const Mountain_Group1_Brown2_opacity = 1
export const Mountain_Group1_Blue1_fill = "#405860"
export const Mountain_Group1_Blue1_d = new Path2D("M 17.41,0.03 32.00,14.79 32.00,15.96 31.06,15.95 15.95,0.90 15.98,0 Z")
export const Mountain_Group1_Blue1_id = "Blue1"
export const Mountain_Group1_Blue1_sodipodiNodetypes = "ccccccc"
export const Mountain_Group1_Blue1_type = "path"
export const Mountain_Group1_Blue1_fillOpacity = 1
export const Mountain_Group1_Blue1_display = "inline"
export const Mountain_Group1_Blue1_opacity = 1
export const Mountain_Group1_Blue2_fill = "#586880"
export const Mountain_Group1_Blue2_d = new Path2D("M 5.97,6.01 4.98,6.01 0.00,10.92 0.01,11.97 1.05,11.99 5.95,6.88 Z M 24.89,11.99 20.97,15.04 18.78,13.99 17.91,15.01 20.09,17.23 20.00,23.02 13.99,28.97 V 29.94 L 19.16,29.97 25.95,26.89 26.01,22.99 23.96,20.90 23.99,19.00 24.86,17.94 26.98,18.00 31.18,21.93 32.00,20.81 V 18.69 Z M 17.41,17.44 16.57,17.48 15.79,20.16 18.57,20.25 Z")
export const Mountain_Group1_Blue2_id = "Blue2"
export const Mountain_Group1_Blue2_sodipodiNodetypes = "ccccccccccccccccccccccccccccccc"
export const Mountain_Group1_Blue2_type = "path"
export const Mountain_Group1_Blue2_fillOpacity = 1
export const Mountain_Group1_Blue2_display = "inline"
export const Mountain_Group1_Blue2_opacity = 1
export const Mountain_Group1_Green2_fill = "#88c818"
export const Mountain_Group1_Green2_d = new Path2D("M 11.93,14.83 9.65,19.87 7.78,19.86 3.52,24.96 4.63,26.22 6.16,24.64 7.75,25.22 7.28,27.16 9.39,27.92 10.70,22.40 12.16,22.82 12.26,28.93 12.93,28.94 16.29,17.41 13.74,14.83 Z M 0.47,19.96 0.48,22.06 1.94,23.81 1.92,21.50 Z")
export const Mountain_Group1_Green2_id = "Green2"
export const Mountain_Group1_Green2_type = "path"
export const Mountain_Group1_Green2_fillOpacity = 1
export const Mountain_Group1_Green2_display = "inline"
export const Mountain_Group1_Green2_opacity = 1
export const Mountain_Group1_Mountain1_16x16_width = 32
export const Mountain_Group1_Mountain1_16x16_height = 32
export const Mountain_Group1_Mountain1_16x16_preserveAspectRatio = "none"
export const Mountain_Group1_Mountain1_16x16_xlinkHref = "ref/Mountain1_16x16.png"
export const Mountain_Group1_Mountain1_16x16_id = "Mountain1_16x16"
export const Mountain_Group1_Mountain1_16x16_x = 0
export const Mountain_Group1_Mountain1_16x16_y = 0
export const Mountain_Group1_Mountain1_16x16_type = "image"
export const Mountain_Group1_Mountain1_16x16_display = "none"
export const Mountain_Group1_Mountain1_16x16_imageRendering = "optimizespeed"

/**
 * @link ./src/assets/svg/sprite/PanCursor.svg
 **/
export const PanCursor_id = "svg"
export const PanCursor_width = 64
export const PanCursor_height = 64
export const PanCursor_viewBox_x = 0
export const PanCursor_viewBox_y = 0
export const PanCursor_viewBox_w = 64
export const PanCursor_viewBox_h = 64
export const PanCursor_type = "svg"
export const PanCursor_Group1_id = "Group1"
export const PanCursor_Group1_type = "g"
export const PanCursor_Group1_display = "inline"
export const PanCursor_Group1_ReferenceSquare1_fill = "#e3a3f3"
export const PanCursor_Group1_ReferenceSquare1_d = new Path2D("M16 16 H48 V48 H16 V16 z")
export const PanCursor_Group1_ReferenceSquare1_id = "ReferenceSquare1"
export const PanCursor_Group1_ReferenceSquare1_type = "path"
export const PanCursor_Group1_ReferenceSquare1_stroke = "#000"
export const PanCursor_Group1_ReferenceSquare1_strokeWidth = 0.5
export const PanCursor_Group1_TriangleRight1_fill = "#fffd67"
export const PanCursor_Group1_TriangleRight1_d = new Path2D("M 50,20 V 44 L 62,32 Z")
export const PanCursor_Group1_TriangleRight1_id = "TriangleRight1"
export const PanCursor_Group1_TriangleRight1_sodipodiNodetypes = "cccc"
export const PanCursor_Group1_TriangleRight1_type = "path"
export const PanCursor_Group1_TriangleRight1_stroke = "#000"
export const PanCursor_Group1_TriangleRight1_strokeWidth = 0.5
export const PanCursor_Group1_TriangleRight1_fillOpacity = 1
export const PanCursor_Group1_TriangleLeft1_fill = "#fffd67"
export const PanCursor_Group1_TriangleLeft1_d = new Path2D("M 14,44 2,32 14,20 V 44")
export const PanCursor_Group1_TriangleLeft1_id = "TriangleLeft1"
export const PanCursor_Group1_TriangleLeft1_type = "path"
export const PanCursor_Group1_TriangleLeft1_stroke = "#000"
export const PanCursor_Group1_TriangleLeft1_strokeWidth = 0.5
export const PanCursor_Group1_TriangleLeft1_fillOpacity = 1
export const PanCursor_Group1_TriangleTop1_fill = "#fffd67"
export const PanCursor_Group1_TriangleTop1_d = new Path2D("M 20,14 32,2 44,14 Z")
export const PanCursor_Group1_TriangleTop1_id = "TriangleTop1"
export const PanCursor_Group1_TriangleTop1_type = "path"
export const PanCursor_Group1_TriangleTop1_stroke = "#000"
export const PanCursor_Group1_TriangleTop1_strokeWidth = 0.5
export const PanCursor_Group1_TriangleTop1_fillOpacity = 1
export const PanCursor_Group1_TriangleBottom1_fill = "#fffd67"
export const PanCursor_Group1_TriangleBottom1_d = new Path2D("M 20,50 H 44 L 32,62 Z")
export const PanCursor_Group1_TriangleBottom1_id = "TriangleBottom1"
export const PanCursor_Group1_TriangleBottom1_type = "path"
export const PanCursor_Group1_TriangleBottom1_stroke = "#000"
export const PanCursor_Group1_TriangleBottom1_strokeWidth = 0.5
export const PanCursor_Group1_TriangleBottom1_fillOpacity = 1

/**
 * @link ./src/assets/svg/sprite/Road.svg
 **/
export const Road_id = "svg"
export const Road_width = 96
export const Road_height = 160
export const Road_viewBox_x = 0
export const Road_viewBox_y = 0
export const Road_viewBox_w = 96
export const Road_viewBox_h = 160
export const Road_type = "svg"
export const Road_Group2_id = "Group2"
export const Road_Group2_type = "g"
export const Road_Group2_display = "none"
export const Road_Group2_rect1246_fill = "#98a0b8"
export const Road_Group2_rect1246_d = new Path2D("M0 96 H64 V160 H0 V96 z")
export const Road_Group2_rect1246_id = "rect1246"
export const Road_Group2_rect1246_type = "path"
export const Road_Group2_rect1246_fillOpacity = 1
export const Road_Group2_rect1246_display = "inline"
export const Road_Group2_rect1246_opacity = 1
export const Road_Group1_id = "Group1"
export const Road_Group1_type = "g"
export const Road_Group1_display = "inline"
export const Road_Group1_GreenEdge1_fill = "#688088"
export const Road_Group1_GreenEdge1_d = new Path2D("M 22,0 A 22,22 0 0 0 0,22 V 74 A 22,22 0 0 0 22,96 H 74 A 22,22 0 0 0 96,74 V 22 A 22,22 0 0 0 74,0 Z")
export const Road_Group1_GreenEdge1_id = "GreenEdge1"
export const Road_Group1_GreenEdge1_type = "path"
export const Road_Group1_GreenEdge1_strokeWidth = 2
export const Road_Group1_GreenEdge1_fillOpacity = 1
export const Road_Group1_GreenEdge1_display = "inline"
export const Road_Group1_GreenEdge1_opacity = 1
export const Road_Group1_GreyLine1_fill = "#98a0b8"
export const Road_Group1_GreyLine1_d = new Path2D("M 22.54,1 A 21.54,21.54 0 0 0 1,22.54 V 73.45 A 21.54,21.54 0 0 0 22.54,95 H 73.45 A 21.54,21.54 0 0 0 95,73.45 V 22.54 A 21.54,21.54 0 0 0 73.45,1 Z")
export const Road_Group1_GreyLine1_id = "GreyLine1"
export const Road_Group1_GreyLine1_type = "path"
export const Road_Group1_GreyLine1_strokeWidth = 1.95834
export const Road_Group1_GreyLine1_fillOpacity = 1
export const Road_Group1_GreyLine1_display = "inline"
export const Road_Group1_GreyLine1_opacity = 1
export const Road_Group1_WhiteLine1_fill = "#b0b8c0"
export const Road_Group1_WhiteLine1_d = new Path2D("M 23.08,2 A 21.08,21.08 0 0 0 2,23.08 V 72.91 A 21.08,21.08 0 0 0 23.08,94 H 72.91 A 21.08,21.08 0 0 0 94,72.91 V 23.08 A 21.08,21.08 0 0 0 72.91,2 Z")
export const Road_Group1_WhiteLine1_id = "WhiteLine1"
export const Road_Group1_WhiteLine1_type = "path"
export const Road_Group1_WhiteLine1_strokeWidth = 1.91667
export const Road_Group1_WhiteLine1_fillOpacity = 1
export const Road_Group1_WhiteLine1_display = "inline"
export const Road_Group1_WhiteLine1_opacity = 1
export const Road_Group1_GreyBody1_fill = "#98a0b8"
export const Road_Group1_GreyBody1_d = new Path2D("M 23.62,3 A 20.62,20.62 0 0 0 3,23.62 V 72.37 A 20.62,20.62 0 0 0 23.62,93 H 72.37 A 20.62,20.62 0 0 0 93,72.37 V 23.62 A 20.62,20.62 0 0 0 72.37,3 Z")
export const Road_Group1_GreyBody1_id = "GreyBody1"
export const Road_Group1_GreyBody1_type = "path"
export const Road_Group1_GreyBody1_strokeWidth = 1.875
export const Road_Group1_GreyBody1_fillOpacity = 1
export const Road_Group1_GreyBody1_display = "inline"
export const Road_Group1_GreyBody1_opacity = 1
export const Road_Road1_16x16_width = 96
export const Road_Road1_16x16_height = 160
export const Road_Road1_16x16_preserveAspectRatio = "none"
export const Road_Road1_16x16_xlinkHref = "ref/Road1_16x16.png"
export const Road_Road1_16x16_id = "Road1_16x16"
export const Road_Road1_16x16_x = 0
export const Road_Road1_16x16_y = 0
export const Road_Road1_16x16_type = "image"
export const Road_Road1_16x16_display = "none"
export const Road_Road1_16x16_imageRendering = "optimizespeed"
export const Road_PinkReferenceRect_fill = "#ff00ff"
export const Road_PinkReferenceRect_d = new Path2D("M32 32 H64 V64 H32 V32 z")
export const Road_PinkReferenceRect_id = "PinkReferenceRect"
export const Road_PinkReferenceRect_type = "path"
export const Road_PinkReferenceRect_fillOpacity = 1
export const Road_PinkReferenceRect_display = "inline"
export const Road_PinkReferenceRect_opacity = 1
export const Road_WhiteDottedLine1_fill = "#d0d8c8"
export const Road_WhiteDottedLine1_d = new Path2D("M 21,15 V 17 H 28 V 15 Z M 37,15 V 17 H 44 V 15 Z M 52,15 V 17 H 59 V 15 Z M 68,15 V 17 H 75 V 15 Z M 15,21 V 28 H 17 V 21 Z M 47,21 V 28 H 49 V 21 Z M 79,21 V 28 H 81 V 21 Z M 15,37 V 44 H 17 V 37 Z M 47,37 V 44 H 49 V 37 Z M 79,37 V 44 H 81 V 37 Z M 21,47 V 49 H 28 V 47 Z M 37,47 V 49 H 44 V 47 Z M 52,47 V 49 H 59 V 47 Z M 68,47 V 49 H 75 V 47 Z M 15,52 V 59 H 17 V 52 Z M 47,52 V 59 H 49 V 52 Z M 79,52 V 59 H 81 V 52 Z M 15,68 V 75 H 17 V 68 Z M 47,68 V 75 H 49 V 68 Z M 79,68 V 75 H 81 V 68 Z M 21,79 V 81 H 28 V 79 Z M 37,79 V 81 H 44 V 79 Z M 52,79 V 81 H 59 V 79 Z M 68,79 V 81 H 75 V 79 Z")
export const Road_WhiteDottedLine1_id = "WhiteDottedLine1"
export const Road_WhiteDottedLine1_sodipodiNodetypes = "cccccccccccccccccccccccccccccccccccccccccccccccccccccccccccccccccccccccccccccccccccccccccccccccccccccccccccccccccccccccc"
export const Road_WhiteDottedLine1_type = "path"
export const Road_WhiteDottedLine1_strokeWidth = 1.02906
export const Road_WhiteDottedLine1_fillOpacity = 1
export const Road_WhiteDottedLine1_display = "inline"
export const Road_WhiteDottedLine1_opacity = 1
export const Road_g144_id = "g144"
export const Road_g144_type = "g"
export const Road_Dots_inkscapeGroupmode = "layer"
export const Road_Dots_id = "Dots"
export const Road_Dots_type = "g"
export const Road_Dots_path418_fill = "#b0b8c0"
export const Road_Dots_path418_d = new Path2D("M35 32 A3 3 0 0 1 32 35 A3 3 0 0 1 29 32 A3 3 0 0 1 35 32 z")
export const Road_Dots_path418_id = "path418"
export const Road_Dots_path418_type = "path"
export const Road_Dots_path418_strokeWidth = 0.918225
export const Road_Dots_path418_display = "inline"
export const Road_Dots_path418_opacity = 1
export const Road_Dots_path419_fill = "#98a0b8"
export const Road_Dots_path419_d = new Path2D("M34 32 A2 2 0 0 1 32 34 A2 2 0 0 1 30 32 A2 2 0 0 1 34 32 z")
export const Road_Dots_path419_id = "path419"
export const Road_Dots_path419_type = "path"
export const Road_Dots_path419_strokeWidth = 0.918225
export const Road_Dots_path419_display = "inline"
export const Road_Dots_path419_opacity = 1
export const Road_Dots_path420_fill = "#688088"
export const Road_Dots_path420_d = new Path2D("M33 32 A1 1 0 0 1 32 33 A1 1 0 0 1 31 32 A1 1 0 0 1 33 32 z")
export const Road_Dots_path420_id = "path420"
export const Road_Dots_path420_type = "path"
export const Road_Dots_path420_strokeWidth = 0.918225
export const Road_Dots_path420_fillOpacity = 1
export const Road_Dots_path420_display = "inline"
export const Road_Dots_path420_opacity = 1
export const Road_Dots_path422_fill = "#b0b8c0"
export const Road_Dots_path422_d = new Path2D("M67 32 A3 3 0 0 1 64 35 A3 3 0 0 1 61 32 A3 3 0 0 1 67 32 z")
export const Road_Dots_path422_id = "path422"
export const Road_Dots_path422_type = "path"
export const Road_Dots_path422_strokeWidth = 0.918225
export const Road_Dots_path422_display = "inline"
export const Road_Dots_path422_opacity = 1
export const Road_Dots_path423_fill = "#98a0b8"
export const Road_Dots_path423_d = new Path2D("M66 32 A2 2 0 0 1 64 34 A2 2 0 0 1 62 32 A2 2 0 0 1 66 32 z")
export const Road_Dots_path423_id = "path423"
export const Road_Dots_path423_type = "path"
export const Road_Dots_path423_strokeWidth = 0.918225
export const Road_Dots_path423_display = "inline"
export const Road_Dots_path423_opacity = 1
export const Road_Dots_path424_fill = "#688088"
export const Road_Dots_path424_d = new Path2D("M65 32 A1 1 0 0 1 64 33 A1 1 0 0 1 63 32 A1 1 0 0 1 65 32 z")
export const Road_Dots_path424_id = "path424"
export const Road_Dots_path424_type = "path"
export const Road_Dots_path424_strokeWidth = 0.918225
export const Road_Dots_path424_fillOpacity = 1
export const Road_Dots_path424_display = "inline"
export const Road_Dots_path424_opacity = 1
export const Road_Dots_path518_fill = "#b0b8c0"
export const Road_Dots_path518_d = new Path2D("M35 64 A3 3 0 0 1 32 67 A3 3 0 0 1 29 64 A3 3 0 0 1 35 64 z")
export const Road_Dots_path518_id = "path518"
export const Road_Dots_path518_type = "path"
export const Road_Dots_path518_strokeWidth = 0.918225
export const Road_Dots_path518_display = "inline"
export const Road_Dots_path518_opacity = 1
export const Road_Dots_path519_fill = "#98a0b8"
export const Road_Dots_path519_d = new Path2D("M34 64 A2 2 0 0 1 32 66 A2 2 0 0 1 30 64 A2 2 0 0 1 34 64 z")
export const Road_Dots_path519_id = "path519"
export const Road_Dots_path519_type = "path"
export const Road_Dots_path519_strokeWidth = 0.918225
export const Road_Dots_path519_display = "inline"
export const Road_Dots_path519_opacity = 1
export const Road_Dots_path520_fill = "#688088"
export const Road_Dots_path520_d = new Path2D("M33 64 A1 1 0 0 1 32 65 A1 1 0 0 1 31 64 A1 1 0 0 1 33 64 z")
export const Road_Dots_path520_id = "path520"
export const Road_Dots_path520_type = "path"
export const Road_Dots_path520_strokeWidth = 0.918225
export const Road_Dots_path520_fillOpacity = 1
export const Road_Dots_path520_display = "inline"
export const Road_Dots_path520_opacity = 1
export const Road_Dots_path522_fill = "#b0b8c0"
export const Road_Dots_path522_d = new Path2D("M67 64 A3 3 0 0 1 64 67 A3 3 0 0 1 61 64 A3 3 0 0 1 67 64 z")
export const Road_Dots_path522_id = "path522"
export const Road_Dots_path522_type = "path"
export const Road_Dots_path522_strokeWidth = 0.918225
export const Road_Dots_path522_display = "inline"
export const Road_Dots_path522_opacity = 1
export const Road_Dots_path523_fill = "#98a0b8"
export const Road_Dots_path523_d = new Path2D("M66 64 A2 2 0 0 1 64 66 A2 2 0 0 1 62 64 A2 2 0 0 1 66 64 z")
export const Road_Dots_path523_id = "path523"
export const Road_Dots_path523_type = "path"
export const Road_Dots_path523_strokeWidth = 0.918225
export const Road_Dots_path523_display = "inline"
export const Road_Dots_path523_opacity = 1
export const Road_Dots_path524_fill = "#688088"
export const Road_Dots_path524_d = new Path2D("M65 64 A1 1 0 0 1 64 65 A1 1 0 0 1 63 64 A1 1 0 0 1 65 64 z")
export const Road_Dots_path524_id = "path524"
export const Road_Dots_path524_type = "path"
export const Road_Dots_path524_strokeWidth = 0.918225
export const Road_Dots_path524_fillOpacity = 1
export const Road_Dots_path524_display = "inline"
export const Road_Dots_path524_opacity = 1

/**
 * @link ./src/assets/svg/sprite/TransportCopter.svg
 **/
export const TransportCopter_id = "svg"
export const TransportCopter_width = 32
export const TransportCopter_height = 32
export const TransportCopter_viewBox_x = 0
export const TransportCopter_viewBox_y = 0
export const TransportCopter_viewBox_w = 32
export const TransportCopter_viewBox_h = 32
export const TransportCopter_type = "svg"
export const TransportCopter_Group1_id = "Group1"
export const TransportCopter_Group1_type = "g"
export const TransportCopter_Group1_display = "inline"
export const TransportCopter_Group1_WheelStrutGrey1_fill = "#333333"
export const TransportCopter_Group1_WheelStrutGrey1_d = new Path2D("M 6,20 V 25 H 7 V 20 Z M 25,20 V 25 H 26 V 20 Z")
export const TransportCopter_Group1_WheelStrutGrey1_id = "WheelStrutGrey1"
export const TransportCopter_Group1_WheelStrutGrey1_sodipodiNodetypes = "cccccccccc"
export const TransportCopter_Group1_WheelStrutGrey1_type = "path"
export const TransportCopter_Group1_WheelStrutGrey1_strokeOpacity = 0.487465
export const TransportCopter_Group1_WheelStrutGrey1_strokeWidth = 0.5
export const TransportCopter_Group1_WheelsOuterBlack1_fill = "#000"
export const TransportCopter_Group1_WheelsOuterBlack1_d = new Path2D("M 6 24 A 2 2 0 0 0 4 26 A 2 2 0 0 0 6 28 A 2 2 0 0 0 8 26 A 2 2 0 0 0 6 24 z M 26 24 A 2 2 0 0 0 24 26 A 2 2 0 0 0 26 28 A 2 2 0 0 0 28 26 A 2 2 0 0 0 26 24 z ")
export const TransportCopter_Group1_WheelsOuterBlack1_id = "WheelsOuterBlack1"
export const TransportCopter_Group1_WheelsOuterBlack1_type = "path"
export const TransportCopter_Group1_WheelsOuterBlack1_strokeOpacity = 0.487465
export const TransportCopter_Group1_WheelsOuterBlack1_strokeWidth = 0.5
export const TransportCopter_Group1_WheelsInnerBlack1_fill = "#666666"
export const TransportCopter_Group1_WheelsInnerBlack1_d = new Path2D("M 6 24.5 A 1.5 1.5 0 0 0 4.5 26 A 1.5 1.5 0 0 0 6 27.5 A 1.5 1.5 0 0 0 7.5 26 A 1.5 1.5 0 0 0 6 24.5 z M 26 24.5 A 1.5 1.5 0 0 0 24.5 26 A 1.5 1.5 0 0 0 26 27.5 A 1.5 1.5 0 0 0 27.5 26 A 1.5 1.5 0 0 0 26 24.5 z ")
export const TransportCopter_Group1_WheelsInnerBlack1_id = "WheelsInnerBlack1"
export const TransportCopter_Group1_WheelsInnerBlack1_type = "path"
export const TransportCopter_Group1_WheelsInnerBlack1_strokeOpacity = 0.487465
export const TransportCopter_Group1_WheelsInnerBlack1_strokeWidth = 0.5
export const TransportCopter_Group1_RotorStrut1_fill = "#333333"
export const TransportCopter_Group1_RotorStrut1_d = new Path2D("M 5,8 V 10 H 8 V 8 Z M 24,10 V 12 H 27 V 10 Z")
export const TransportCopter_Group1_RotorStrut1_id = "RotorStrut1"
export const TransportCopter_Group1_RotorStrut1_sodipodiNodetypes = "cccccccccc"
export const TransportCopter_Group1_RotorStrut1_type = "path"
export const TransportCopter_Group1_RotorStrut1_strokeOpacity = 0.487465
export const TransportCopter_Group1_RotorStrut1_strokeWidth = 0.5
export const TransportCopter_Group1_BodyLowerGrey1_fill = "#b3b3b3"
export const TransportCopter_Group1_BodyLowerGrey1_d = new Path2D("M 26,11 22,12 V 14 H 12 L 10,10 6.5,9 1,10 V 19 L 7,22 H 26 L 31,21 V 19 L 29,18 28,15 27.92,12 Z")
export const TransportCopter_Group1_BodyLowerGrey1_id = "BodyLowerGrey1"
export const TransportCopter_Group1_BodyLowerGrey1_sodipodiNodetypes = "cccccccccccccccc"
export const TransportCopter_Group1_BodyLowerGrey1_type = "path"
export const TransportCopter_Group1_BodyLowerGrey1_strokeWidth = 0.935414
export const TransportCopter_Group1_BodyLowerGrey1_display = "inline"
export const TransportCopter_Group1_BodyLowerGrey1_opacity = 1
export const TransportCopter_Group1_WindowBlue1_fill = "#42908a"
export const TransportCopter_Group1_WindowBlue1_d = new Path2D("M 26 14 L 26 18 L 29 18 L 28 15 L 28 14 L 26 14 z M 9.5 15 A 1.5 1.5 0 0 0 8 16.5 A 1.5 1.5 0 0 0 9.5 18 A 1.5 1.5 0 0 0 11 16.5 A 1.5 1.5 0 0 0 9.5 15 z M 15.5 15 A 1.5 1.5 0 0 0 14 16.5 A 1.5 1.5 0 0 0 15.5 18 A 1.5 1.5 0 0 0 17 16.5 A 1.5 1.5 0 0 0 15.5 15 z M 21.5 15 A 1.5 1.5 0 0 0 20 16.5 A 1.5 1.5 0 0 0 21.5 18 A 1.5 1.5 0 0 0 23 16.5 A 1.5 1.5 0 0 0 21.5 15 z ")
export const TransportCopter_Group1_WindowBlue1_id = "WindowBlue1"
export const TransportCopter_Group1_WindowBlue1_type = "path"
export const TransportCopter_Group1_WindowBlue1_strokeOpacity = 1
export const TransportCopter_Group1_WindowBlue1_strokeWidth = 0.5
export const TransportCopter_Group1_WindowBlue1_fillOpacity = 1
export const TransportCopter_Group1_TopGrey1_fill = "#cccccc"
export const TransportCopter_Group1_TopGrey1_d = new Path2D("M 28,14 H 26 V 15 H 5 L 1,16 V 10 L 6.5,9 10,10 12,14 H 22 V 12 L 26,11 28,12")
export const TransportCopter_Group1_TopGrey1_id = "TopGrey1"
export const TransportCopter_Group1_TopGrey1_sodipodiNodetypes = "ccccccccccccc"
export const TransportCopter_Group1_TopGrey1_type = "path"
export const TransportCopter_Group1_TopGrey1_strokeOpacity = 1
export const TransportCopter_Group1_TopGrey1_strokeWidth = 0.5
export const TransportCopter_Group1_TopGrey1_fillOpacity = 1
export const TransportCopter_Group1_BottomGrey1_fill = "#999999"
export const TransportCopter_Group1_BottomGrey1_d = new Path2D("M 1,18 H 20 V 20 L 19,19 H 3 L 7,21 H 19 L 20,20 V 18 L 21,19 V 21 L 20,22 V 22 H 7 L 1,19")
export const TransportCopter_Group1_BottomGrey1_id = "BottomGrey1"
export const TransportCopter_Group1_BottomGrey1_sodipodiNodetypes = "ccccccccccccccc"
export const TransportCopter_Group1_BottomGrey1_type = "path"
export const TransportCopter_Group1_BottomGrey1_strokeOpacity = 1
export const TransportCopter_Group1_BottomGrey1_strokeWidth = 0.5
export const TransportCopter_Group1_BottomGrey1_fillOpacity = 1
export const TransportCopter_Group1_BottomGrey1_display = "inline"
export const TransportCopter_Group1_MiddleGrey1_fill = "#cccccc"
export const TransportCopter_Group1_MiddleGrey1_d = new Path2D("M 19,19 H 3 L 5,20 H 20")
export const TransportCopter_Group1_MiddleGrey1_id = "MiddleGrey1"
export const TransportCopter_Group1_MiddleGrey1_sodipodiNodetypes = "cccc"
export const TransportCopter_Group1_MiddleGrey1_type = "path"
export const TransportCopter_Group1_MiddleGrey1_strokeOpacity = 1
export const TransportCopter_Group1_MiddleGrey1_strokeWidth = 0.5
export const TransportCopter_Group1_MiddleGrey1_fillOpacity = 1
export const TransportCopter_Group1_CylinderThingGrey1_fill = "#666666"
export const TransportCopter_Group1_CylinderThingGrey1_d = new Path2D("M 11,13 V 15 H 6 V 13")
export const TransportCopter_Group1_CylinderThingGrey1_id = "CylinderThingGrey1"
export const TransportCopter_Group1_CylinderThingGrey1_sodipodiNodetypes = "cccc"
export const TransportCopter_Group1_CylinderThingGrey1_type = "path"
export const TransportCopter_Group1_CylinderThingGrey1_strokeOpacity = 1
export const TransportCopter_Group1_CylinderThingGrey1_strokeWidth = 0.5
export const TransportCopter_Group1_CylinderThingGrey1_fillOpacity = 1
export const TransportCopter_Group1_RotorRing1_id = "RotorRing1"
export const TransportCopter_Group1_RotorRing1_d = new Path2D("M12.52 8 A5.97 1.97 0 0 1 6.54 9.97 A5.97 1.97 0 0 1 0.56 8 A5.97 1.97 0 0 1 12.52 8 z")
export const TransportCopter_Group1_RotorRing1_type = "path"
export const TransportCopter_Group1_RotorRing1_stroke = "#800080"
export const TransportCopter_Group1_RotorRing1_strokeOpacity = 1
export const TransportCopter_Group1_RotorRing1_strokeWidth = 0.54589
export const TransportCopter_Group1_RotorRing1_fillOpacity = 1
export const TransportCopter_Group1_RotorRing2_id = "RotorRing2"
export const TransportCopter_Group1_RotorRing2_d = new Path2D("M30.41 10 A4.95 2 0 0 1 25.45 12 A4.95 2 0 0 1 20.50 10 A4.95 2 0 0 1 30.41 10 z")
export const TransportCopter_Group1_RotorRing2_type = "path"
export const TransportCopter_Group1_RotorRing2_stroke = "#800080"
export const TransportCopter_Group1_RotorRing2_strokeOpacity = 1
export const TransportCopter_Group1_RotorRing2_strokeWidth = 0.5
export const TransportCopter_Group1_RotorRing2_fillOpacity = 1
export const TransportCopter_Group1_RotorBladeFrame1_id = "RotorBladeFrame1"
export const TransportCopter_Group1_RotorBladeFrame1_type = "g"
export const TransportCopter_Group1_RotorBladeFrame1_Black1_fill = "#000"
export const TransportCopter_Group1_RotorBladeFrame1_Black1_d = new Path2D("M 5,4 V 7 L 0.26,8.87 2.76,11.25 6,10 H 7 L 10.83,11.02 12.58,8.67 8,7 V 4")
export const TransportCopter_Group1_RotorBladeFrame1_Black1_id = "Black1"
export const TransportCopter_Group1_RotorBladeFrame1_Black1_sodipodiNodetypes = "cccccccccc"
export const TransportCopter_Group1_RotorBladeFrame1_Black1_type = "path"
export const TransportCopter_Group1_RotorBladeFrame1_Black1_strokeOpacity = 1
export const TransportCopter_Group1_RotorBladeFrame1_Black1_strokeWidth = 0.5
export const TransportCopter_Group1_RotorBladeFrame1_Black1_fillOpacity = 1
export const TransportCopter_Group1_RotorBladeFrame1_Grey1_fill = "#b3b3b3"
export const TransportCopter_Group1_RotorBladeFrame1_Grey1_d = new Path2D("M 6,5 V 8 L 2,9.20 2.88,10 6,9 H 7 L 10.32,10 11,9.02 7,8 V 5")
export const TransportCopter_Group1_RotorBladeFrame1_Grey1_id = "Grey1"
export const TransportCopter_Group1_RotorBladeFrame1_Grey1_sodipodiNodetypes = "cccccccccc"
export const TransportCopter_Group1_RotorBladeFrame1_Grey1_type = "path"
export const TransportCopter_Group1_RotorBladeFrame1_Grey1_strokeOpacity = 1
export const TransportCopter_Group1_RotorBladeFrame1_Grey1_strokeWidth = 0.5
export const TransportCopter_Group1_RotorBladeFrame1_Grey1_fillOpacity = 1
export const TransportCopter_Group1_RotorBladeFrame1_Grey1_display = "inline"
export const TransportCopter_Group1_RotorBladeFrame1_Black2_fill = "#000"
export const TransportCopter_Group1_RotorBladeFrame1_Black2_d = new Path2D("M 24,6 V 9 L 20.28,10.59 22.77,13.25 25,12 H 26 L 28.05,13.27 30.63,10.81 27,9 V 6")
export const TransportCopter_Group1_RotorBladeFrame1_Black2_id = "Black2"
export const TransportCopter_Group1_RotorBladeFrame1_Black2_sodipodiNodetypes = "cccccccccc"
export const TransportCopter_Group1_RotorBladeFrame1_Black2_type = "path"
export const TransportCopter_Group1_RotorBladeFrame1_Black2_strokeOpacity = 1
export const TransportCopter_Group1_RotorBladeFrame1_Black2_strokeWidth = 0.5
export const TransportCopter_Group1_RotorBladeFrame1_Black2_fillOpacity = 1
export const TransportCopter_Group1_RotorBladeFrame1_Grey2_fill = "#b3b3b3"
export const TransportCopter_Group1_RotorBladeFrame1_Grey2_d = new Path2D("M 25,7 V 10 L 22,11 23,12 25,11 H 26 L 28,12 29,11 26,10 V 7")
export const TransportCopter_Group1_RotorBladeFrame1_Grey2_id = "Grey2"
export const TransportCopter_Group1_RotorBladeFrame1_Grey2_sodipodiNodetypes = "cccccccccc"
export const TransportCopter_Group1_RotorBladeFrame1_Grey2_type = "path"
export const TransportCopter_Group1_RotorBladeFrame1_Grey2_strokeOpacity = 1
export const TransportCopter_Group1_RotorBladeFrame1_Grey2_strokeWidth = 0.5
export const TransportCopter_Group1_RotorBladeFrame1_Grey2_fillOpacity = 1
export const TransportCopter_Group1_RotorBladeFrame1_Grey2_display = "inline"
export const TransportCopter_Group1_RotorBladeFrame2_id = "RotorBladeFrame2"
export const TransportCopter_Group1_RotorBladeFrame2_type = "g"
export const TransportCopter_Group1_RotorBladeFrame2_Black1_fill = "#000"
export const TransportCopter_Group1_RotorBladeFrame2_Black1_d = new Path2D("M 7.84,11.25 V 8.25 L 12.58,6.38 10.08,4 6.84,5.25 H 5.84 L 2.01,4.22 0.26,6.58 4.84,8.25 V 11.25")
export const TransportCopter_Group1_RotorBladeFrame2_Black1_id = "Black1"
export const TransportCopter_Group1_RotorBladeFrame2_Black1_sodipodiNodetypes = "cccccccccc"
export const TransportCopter_Group1_RotorBladeFrame2_Black1_type = "path"
export const TransportCopter_Group1_RotorBladeFrame2_Black1_strokeOpacity = 1
export const TransportCopter_Group1_RotorBladeFrame2_Black1_strokeWidth = 0.5
export const TransportCopter_Group1_RotorBladeFrame2_Black1_fillOpacity = 1
export const TransportCopter_Group1_RotorBladeFrame2_Grey1_fill = "#b3b3b3"
export const TransportCopter_Group1_RotorBladeFrame2_Grey1_d = new Path2D("M 7,10 V 7 L 11,5.79 10.11,5 7,6 H 6 L 2.67,5 2,5.97 6,7 V 10")
export const TransportCopter_Group1_RotorBladeFrame2_Grey1_id = "Grey1"
export const TransportCopter_Group1_RotorBladeFrame2_Grey1_sodipodiNodetypes = "cccccccccc"
export const TransportCopter_Group1_RotorBladeFrame2_Grey1_type = "path"
export const TransportCopter_Group1_RotorBladeFrame2_Grey1_strokeOpacity = 1
export const TransportCopter_Group1_RotorBladeFrame2_Grey1_strokeWidth = 0.5
export const TransportCopter_Group1_RotorBladeFrame2_Grey1_fillOpacity = 1
export const TransportCopter_Group1_RotorBladeFrame2_Grey1_display = "inline"
export const TransportCopter_Group1_RotorBladeFrame2_Black2_fill = "#000"
export const TransportCopter_Group1_RotorBladeFrame2_Black2_d = new Path2D("M 26.92,13.27 V 10.27 L 30.63,8.68 28.15,6.02 25.92,7.27 H 24.92 L 22.87,6 20.28,8.46 23.92,10.27 V 13.27")
export const TransportCopter_Group1_RotorBladeFrame2_Black2_id = "Black2"
export const TransportCopter_Group1_RotorBladeFrame2_Black2_sodipodiNodetypes = "cccccccccc"
export const TransportCopter_Group1_RotorBladeFrame2_Black2_type = "path"
export const TransportCopter_Group1_RotorBladeFrame2_Black2_strokeOpacity = 1
export const TransportCopter_Group1_RotorBladeFrame2_Black2_strokeWidth = 0.5
export const TransportCopter_Group1_RotorBladeFrame2_Black2_fillOpacity = 1
export const TransportCopter_Group1_RotorBladeFrame2_Grey2_fill = "#b3b3b3"
export const TransportCopter_Group1_RotorBladeFrame2_Grey2_d = new Path2D("M 26,12 V 9 L 29,8 28,7 26,8 H 25 L 23,7 22,8 25,9 V 12")
export const TransportCopter_Group1_RotorBladeFrame2_Grey2_id = "Grey2"
export const TransportCopter_Group1_RotorBladeFrame2_Grey2_sodipodiNodetypes = "cccccccccc"
export const TransportCopter_Group1_RotorBladeFrame2_Grey2_type = "path"
export const TransportCopter_Group1_RotorBladeFrame2_Grey2_strokeOpacity = 1
export const TransportCopter_Group1_RotorBladeFrame2_Grey2_strokeWidth = 0.5
export const TransportCopter_Group1_RotorBladeFrame2_Grey2_fillOpacity = 1
export const TransportCopter_Group1_RotorBladeFrame2_Grey2_display = "inline"