import { Nullable } from '../../../../typescript'
import { Engine } from '../../../core/engine/Engine.type'
import { Entity } from '../../../core/entity/index'
import { LayerMeta } from '../../../core/layer_meta'
import {
  MakeRenderSpriteFunction,
  renderSpriteByIdMap,
  RenderSpriteFunction,
} from '../../../core/render_sprite'
import { TilePositionXY } from '../../../core/tile_position_xy/TilePositionXY.type'
import { min, sin } from '../../../core/util/math'
import { EitherRenderingContext2D } from '../../get_canvas_2d_context'
import defaultErrorRender from '../default_render_error'

export default function drawPaintTileSelectHoverGhostEntityOnPosition(
  engine: Engine,
  ctx: EitherRenderingContext2D,
  entity: Entity,
  lm: LayerMeta,
  position: TilePositionXY,
  tile_size: number,
  canvas_edge_buffer: number,
  tile_size_plus_gap: number,
  timeStamp: number
) {
  // todo
  const dx: number = canvas_edge_buffer + position.x * tile_size_plus_gap
  const dy: number = canvas_edge_buffer + position.y * tile_size_plus_gap
  // const dw: number = tile_size + 2 * tile_size_plus_gap
  // const dh: number = tile_size + 2 * tile_size_plus_gap

  const makeRenderSprite: Nullable<MakeRenderSpriteFunction> = renderSpriteByIdMap[entity.etype_id]
  const renderFn: RenderSpriteFunction = makeRenderSprite
    ? makeRenderSprite(engine, entity)
    : defaultErrorRender

  lm.until = min(lm.until, timeStamp + 66)

  ctx.globalAlpha = 0.7 + 0.2 * sin(timeStamp / 250)

  // console.log("ctx.globalAlpha", ctx.globalAlpha);
  // renderFn(ctx, lm, entity, position.x, position.y, tile_size);
  renderFn(ctx, lm, entity, dx, dy, tile_size)
  // ctx.drawImage(cursorElem, 0, 0, cursorElem.width, cursorElem.height, dx, dy, dw, dh)
  ctx.globalAlpha = 1
}
