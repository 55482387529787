import { array, number, object, optional, type InferOutput } from "valibot"
import { ArmorSchema } from "../Armor.type"
import { AttackedThisTurnSchema } from "../AttackedThisTurn"
import { FuelSchema } from "../fuel.type"
import { HPSchema } from "../hp.type"
import { OptionalLiteralTrueSchema } from "../LiteralTrue"
import { MobilityNumberSchema } from "../MobilityNumber.type"
import { PlayerIdOrNullSchema } from "../player/PlayerIdOrNull"
import { VisionSchema } from "../vision.type"
import { WaitedThisTurnSchema } from "../WaitedThisTurn"
import { WasBuiltThisTurnSchema } from "../WasBuiltThisTurn"
import { EntityLayerIdSchema } from "./entity_layer_id.enum"
import { EntityTypeIdSchema } from "./entity_type_id.enum"
import { EntityIdSchema, OptionalNullableEntityIdSchema } from "./EntityId.type"
import { MoveTypeIdSchema } from "./move_type_id.enum"
import { WeaponSchema } from "./Weapon/Weapon.type"
import { EntityIdListSchema } from "./EntityIdList.type"

export const BaseEntitySchema = object({
  etype_id: EntityTypeIdSchema,
  layer_id: EntityLayerIdSchema,
  id: EntityIdSchema,
  player_id: optional(PlayerIdOrNullSchema),
  hp: optional(HPSchema),
  fuel: optional(FuelSchema),
  vision: optional(VisionSchema),
  armor: optional(ArmorSchema),
  x: optional(number()),
  y: optional(number()),
  mobility: optional(MobilityNumberSchema),
  mtype_id: optional(MoveTypeIdSchema),
  builtThisTurn: WasBuiltThisTurnSchema,
  attackedThisTurn: AttackedThisTurnSchema,
  waitedThisTurn: WaitedThisTurnSchema,
  weapons: optional(array(WeaponSchema)),
  // transport and cargo
  cargo: optional(EntityIdListSchema),
  taxi_id: OptionalNullableEntityIdSchema,
  destroyed: OptionalLiteralTrueSchema,
  annexed: OptionalLiteralTrueSchema,
})

export type BaseEntity = InferOutput<typeof BaseEntitySchema>
