import { A, useBeforeLeave } from '@solidjs/router'
import { Modal } from 'solid-bootstrap'
import { Component, ParentComponent, Show, createMemo, type JSXElement } from 'solid-js'
import createIsModalMenuOpenSignal from '../rx/shared/ModalMenu/createIsModalMenuOpenSignal'
import createAuthProfileSignal from '../rx/shared/profile/createAuthProfileSignal'
import { createSessionSignal } from '../rx/shared/session/createSessionSignal'
import SignInButton from './SignInButton'
import SignOutButton from './SignOutButton'

interface ListItemProps {
  children: JSXElement
}

const ListItem: ParentComponent<ListItemProps> = (props) => {
  return <li class="list-group-item text-center">{props.children}</li>
}

const ModalMainMenu: Component = () => {
  const session = createSessionSignal()[0]

  const [authProfile] = createAuthProfileSignal()

  const gamesPath = createMemo(() => {
    const authId = authProfile()?.id
    if (authId) {
      return `/games?player_profile_id=${authId}`
    }
    return "/games"
  })

  const [isMenuOpen, setIsMenuOpen] = createIsModalMenuOpenSignal()
  // const handleClickToggleIsMenuOpen = (event: Event) => {
  //   setIsMenuOpen(!isMenuOpen())
  //   preventDefault(event)
  // }
  const closeMenu = () => setIsMenuOpen(false)

  useBeforeLeave(closeMenu)

  return (
    <Modal show={isMenuOpen()} onHide={closeMenu} centered>
      <Modal.Body>
        <div class="float-end text-muted" onClick={closeMenu}>x</div>
        <h2 class="text-center w-100">Simple Tactics</h2>
        <ul class="list-group list-group-flush">
          <Show when={!session()}>
            <ListItem>
              <SignInButton />
            </ListItem>
          </Show>
          <ListItem>
            <A href={gamesPath()}>Games</A>
          </ListItem>
          <ListItem>
            <A href="/maps">Maps</A>
          </ListItem>
          <Show when={session()}>
            <ListItem>
              <A href="/auth/profile">Account</A>
            </ListItem>
          </Show>
          <ListItem>
            <A href="/codex">Codex</A>
          </ListItem>
        </ul>
        <hr />
        <Show when={session()}>
          <ListItem>
            <SignOutButton />
          </ListItem>
        </Show>
      </Modal.Body>
      {/* <Modal.Footer>
        <button class="btn btn-secondary" onClick={closeMenu}>Cancel</button>
        <button class="btn btn-primary" onClick={closeMenu}>Save New Rule</button>
      </Modal.Footer> */}
    </Modal>
  )
}

export default ModalMainMenu
