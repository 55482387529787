import { FaSolidUser } from 'solid-icons/fa'
import { Component, createEffect } from 'solid-js'
import createFileIdToObjectUrlSignal from '../lib/sb/storage/createFileIdToObjectUrlSignal'
import type { UserProfile } from '../rx/shared/profile/user_profile.type'
import { Nullable } from '../typescript'

interface Props {
  size: number,
  profile: Nullable<UserProfile>,
}

const ShowAvatar: Component<Props> = (props) => {
  const [avatarBlob, setAvatarId] = createFileIdToObjectUrlSignal()

  createEffect(() => {
    setAvatarId(props.profile?.avatar_id)
  })

  const altText = () => `${props.profile?.dname || 'unknown'}'s Avatar`

  return (
    <div class="avatar" style={{ width: `${props.size}px` }} title={altText()}>
      {avatarBlob() ? (
        <img
          src={avatarBlob()!}
          alt={altText()}
          class="img-thumbnail"
          style={{ height: `${props.size}px`, width: `${props.size}px` }}
        />
      ) : (
        <div
          class="avatar no-image"
        >
          <FaSolidUser
            class="m-1 p-2"
            style={{ height: `${props.size}px`, width: `${props.size}px` }}
          />
        </div>
      )}
      {/* <Loader resource={props.loading} /> */}
    </div>
  )
}

export default ShowAvatar
