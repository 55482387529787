import { BASE_TILE_SIZE } from '../../core/view_ctx'
import {
  InspectIcon_Group1_Handle2_d,
  InspectIcon_Group1_Handle1_d,
  InspectIcon_Group1_CircleOuter1_d,
  InspectIcon_Group1_CircleInner1_d,
  InspectIcon_Group1_QuestionMark1_d,
} from '../../svg/sprites_data'
import { createOffscreenCanvas } from '../create_canvas'
import { disableCanvasSmoothing } from '../disable_canvas_smoothing'
import { addBackgroundBorder } from './add_background_border'

export default function createInspectIconOffscreenCanvas(
  color: string,
  renderHeightPx: number,
  renderWidthPx: number
): OffscreenCanvas {
  const elem = createOffscreenCanvas(renderWidthPx, renderHeightPx)

  const sRenderMult = renderHeightPx / BASE_TILE_SIZE
  const sRenderMultInv = BASE_TILE_SIZE / renderHeightPx

  // const ctx = getCanvas2dContext(elem)
  // this one needs willReadFrequently
  // for some reason
  const ctx = elem.getContext('2d', {
    willReadFrequently: true,
  })
  if (ctx) {
    disableCanvasSmoothing(ctx)
    ctx.scale(sRenderMult, sRenderMult)

    // ctx.fillStyle = InspectIcon_Group1_OutlineBlack1_fill
    // ctx.fill(InspectIcon_Group1_OutlineBlack1_d)

    // ctx.fillStyle = InspectIcon_Group1_OutlineBlack2_fill
    // ctx.fill(InspectIcon_Group1_OutlineBlack2_d)

    // ctx.fillStyle = InspectIcon_Group1_OutlineBlack3_fill
    // ctx.fill(InspectIcon_Group1_OutlineBlack3_d)

    ctx.fillStyle = color
    ctx.fill(InspectIcon_Group1_Handle2_d)

    ctx.fillStyle = color
    ctx.fill(InspectIcon_Group1_Handle1_d)

    ctx.fillStyle = color
    ctx.fill(InspectIcon_Group1_CircleOuter1_d)

    ctx.globalAlpha = 0.25
    ctx.fillStyle = 'cyan'
    ctx.fill(InspectIcon_Group1_CircleInner1_d)
    ctx.globalAlpha = 1

    ctx.fillStyle = color
    ctx.fill(InspectIcon_Group1_QuestionMark1_d)

    ctx.scale(sRenderMultInv, sRenderMultInv)

    // black border
    ctx.globalAlpha = 0.75
    addBackgroundBorder(ctx, elem, 0, 0, 0, 255, 1)

    // white border
    ctx.globalAlpha = 0.5
    addBackgroundBorder(ctx, elem, 255, 255, 255, 255, 1)
    ctx.globalAlpha = 1
  }
  return elem
}
