import { ArmorTypeId } from "../entity/Armor/ArmorTypeId.enum"
import { max } from "../util/math"
import armorMultFromType from "./armorMultFromType"

const defaultModfier = 6 / 7
const defValueMod = 2

export default function calcWeaponDmg(
  waPairValue: number,
  defValue: number,
  armorValue: number,
  at_id: ArmorTypeId,
  weaponValue: number,
  unitHp: number,
  uwtPairValue: number
): number {
  const armorTypeValue = armorMultFromType(at_id)
  defValue = 10 / (10 + defValue * defValueMod)
  // more armor = less damage
  // armorValue = max(0.1, armorValue) / 10
  armorValue = 10 / max(0.1, armorValue)
  weaponValue = (1 + weaponValue) / 10
  const result =
    uwtPairValue *
    waPairValue *
    defValue *
    armorValue *
    armorTypeValue *
    defaultModfier *
    weaponValue *
    unitHp

  // if (unitHp === 10) {
  //   console.table([
  //     {
  //       waPairValue,
  //       defValue,
  //       armorValue,
  //       armorTypeValue,
  //       weaponValue,
  //       unitHp,
  //       // defaultModfier,
  //       result,
  //     },
  //   ])
  // }

  return result
}
