// our object for storing user's zoom levels, offsets, etc.

import { ZoomQueue } from './ZoomQueue.type'

// import { Setter, createEffect, onCleanup, onMount } from "solid-js"
// import { Store, createStore, unwrap } from "solid-js/store"

export const BASE_TILE_SIZE = 32
// export const RENDER_TILE_SIZE = 64

// // these need to be variable based on zoom
// export const RENDER_MULT = RENDER_TILE_SIZE/BASE_TILE_SIZE
// export const RENDER_MULT_INVERSE = BASE_TILE_SIZE/RENDER_TILE_SIZE

export type ViewCtx = {
  tile_size: number
  tile_gap_px: number
  pan_x_f: number
  pan_y_f: number
  pan_x: number
  pan_y: number
  pan_vx: number,
  pan_vy: number,
  lastZoomAt: number
  lastPanAt: number
  /**
   * mapWidthPx is ((tileSize + tileGapPx) * state.width) - tileGapPx
   */
  mapWidthPx: number
  /**
   * mapWidthPx is ((tileSize + tileGapPx) * state.height) - tileGapPx
   */
  mapHeightPx: number
  /**
   * renderWidthPx is the size of the target rendering viewpoirt canvas.
   * - normally this bound directly the screen/window
   * - this might be a smaller size for things like mini-map or thumbnails of maps
   */
  renderWidthPx: number
  /**
   * renderHeightPx is the size of the target rendering viewpoirt canvas.
   * - normally this bound directly the screen/window
   * - this might be a smaller size for things like mini-map or thumbnails of maps
   */
  renderHeightPx: number
  /**
   * canvas_edge_buffer is the number of pixels to add to the canvas's width and height
   * to allow extra width/tall entities to have their extraneous pixels rendered.
   */
  canvas_edge_buffer: number
  // level_of_detail: number,
  /**
   * zIndex is the base zIndex for all of an engine's layers
   */
  zIndex: number
  /**
   * zoom is a percentage from 25 to 400.
   */
  zoom: number
  /**
   * Zooms will be smoothed over multiple frames.
   */
  zoomQueue: Array<ZoomQueue>
}

const defaultValue: Readonly<ViewCtx> = {
  tile_size: BASE_TILE_SIZE,
  tile_gap_px: 1,
  pan_x_f: 0,
  pan_y_f: 0,
  pan_x: 0,
  pan_y: 0,
  pan_vx: 0,
  pan_vy: 0,
  lastPanAt: 0,
  lastZoomAt: 0,
  renderWidthPx: 400,
  renderHeightPx: 300,
  mapWidthPx: 400,
  mapHeightPx: 300,
  canvas_edge_buffer: BASE_TILE_SIZE,
  // level_of_detail: 0,
  zIndex: 1,
  zoom: 100,
  zoomQueue: null as unknown as Array<ZoomQueue>,
}

export function initViewCtx(): ViewCtx {
  return {
    ...defaultValue,
    zoomQueue: [],
  }
}
