import { Accessor, createEffect, createSignal, type JSXElement } from 'solid-js'
import NA from '../../components/Util/na'
import { compress } from '../../lib/compress'
import { humanFileSize } from '../../lib/human_file_size'
import { Nullable } from '../../typescript'
import type { BackendSessionPayload } from '@sg/backend/src/api/auth/BackendAuthSession.type'

export type SessionSignal = ReturnType<typeof createDataSizeTextSignal>
export type SessionAccessor = Accessor<Nullable<BackendSessionPayload>>

export function createDataSizeTextSignal<T>(fn: () => T) {
  // eslint-disable-next-line solid/reactivity
  const signal = createSignal<JSXElement>()
  const setDataSizeText = signal[1]

  // eslint-disable-next-line solid/reactivity
  createEffect(async () => {
    const value : T = fn()
    if (value) {
      const json = JSON.stringify(value)
      const size = humanFileSize(json?.length || 0)
      const compressedJsonArrayBuffer = await compress(json)
      const sizeGz = humanFileSize(compressedJsonArrayBuffer.byteLength)
      setDataSizeText(<>{size} <span class="text-muted small">({sizeGz} gzipped)</span></>)
    } else {
      setDataSizeText(NA())
    }
  })
  return signal
}