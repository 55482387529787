import { Entity } from '../../entity'
import { State } from '../../state/State.type'
import { randomId } from '../../util/rand'
import { SymmetryScriptOption } from './SymmetryScriptOption.type'

const MirrorFourCornersFlipped: SymmetryScriptOption = {
  dname: 'Mirror Four Corners Flipped',
  run(prevState: State): State {
    const { width, height } = prevState
    const rng = { ...prevState.rng }
    const halfWidth = parseInt((width / 2) as unknown as string)
    const halfWidthStartsAt = width - halfWidth
    const halfHeight = parseInt((height / 2) as unknown as string)
    const halfHeightStartsAt = height - halfHeight

    // remove all entities that will be copied over
    const ents = prevState.ents.filter((ent: Entity) => {
      if (ent.x >= halfWidthStartsAt) {
        return false
      }
      if (ent.y >= halfHeightStartsAt) {
        return false
      }
      return true
    })
    ents.concat().forEach((ent: Entity) => {
      if (ent.x < halfWidth) {
        ents.push({
          ...ent,
          x: width - 1 - ent.x,
          id: randomId(ents, rng),
        })
        ents.push({
          ...ent,
          y: height - 1 - ent.y,
          id: randomId(ents, rng),
        })
        ents.push({
          ...ent,
          x: width - 1 - ent.x,
          y: height - 1 - ent.y,
          id: randomId(ents, rng),
        })
      }
    })

    return {
      ...prevState,
      ents,
      rng,
    }
  },
}

export default MirrorFourCornersFlipped
