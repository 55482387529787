import { ParentComponent, createEffect, type JSX } from "solid-js"
import { disableElement, enableElement } from "./disabled"

export interface ButtonProps {
  disabled?: boolean
  name?: string
  class?: string
  ariaLabel?: string
  classList?: JSX.CustomAttributes<HTMLButtonElement>['classList']
  onClick: (event: MouseEvent) => void
  ref?: (event: HTMLButtonElement) => void
}
const Button: ParentComponent<ButtonProps> = (props) => {
  // console.log('Button.props', props)

  // onMount(onDisabledChanged)

  return <button
    ref={(elem : HTMLButtonElement) => {
      function onDisabledChanged() {
        // console.log('onDisabledChanged', props.disabled, elem)
        if (props.disabled) {
          if (elem) {
            disableElement(elem)
          }
        } else if (elem) {
          enableElement(elem)
        }
      }
      createEffect(onDisabledChanged)
      props.ref?.(elem)
    }}
    class={props.class}
    classList={props.classList}
    name={props.name}
    onClick={(event: MouseEvent) => props.onClick(event)}
    aria-label={props.ariaLabel}
  >{props.children}</button>
}

export default Button