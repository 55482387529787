import { useBeforeLeave } from '@solidjs/router'
import { Modal } from 'solid-bootstrap'
import { Component, createMemo, For, Show, useContext, type Accessor } from 'solid-js'
import type { Engine } from '../lib/core/engine/Engine.type'
import { createInfantryEntity } from '../lib/core/entity/EntityType/Infantry'
import findById from '../lib/core/findById'
import playerDisplayName from '../lib/core/player/playerDisplayName'
import type { ActionLog } from '../lib/core/state/flux/ActionLog.type'
import type { ActionLogList } from '../lib/core/state/flux/ActionLogList.type'
import useIsActionLogListModalOpenSignal from '../rx/shared/ModalMenu/useIsActionLogListModalOpenSignal'
import ActionLogDescription from './ActionLogDescription'
import EngineContext from './EngineContext'
import SingleSpriteIcon from './MapEditor/SingleSpriteIcon'
import TimeAgo from './Time/TimeAgo'

const GameActionLogListModal: Component = () => {
  const engine : Engine = useContext(EngineContext)

  const [isActionLogListModalOpen, setIsActionLogListModalOpen] = useIsActionLogListModalOpenSignal()

  // const handleClickToggleIsMenuOpen = (event: Event) => {
  //   setIsMenuOpen(!isMenuOpen())
  //   preventDefault(event)
  // }
  const closeMenu = () => setIsActionLogListModalOpen(false)

  useBeforeLeave(closeMenu)

  // createEffect(() => {
  //   console.log('page', deepClone(page()))
  //   console.log('page', deepClone(engine.actionLogs))
  //   // console.log('page', deepClone(engine.actionLogs.toReversed().slice(0, 10)))
  // })

  const page = createMemo(() => {
    if (isActionLogListModalOpen()) {
      // console.log('memo.page()')
      return engine.actionLogs.slice(-10).reverse()
    }
  })

  return (
    <Modal show={isActionLogListModalOpen()} onHide={closeMenu} centered contentClass="mbgo">
      <Modal.Body>
        <Show when={page()} children={(page : Accessor<ActionLogList>) => {
          return (<>
            <h2 class="text-center w-100">Event Log</h2>
            <table class="table table-sm">
              <thead>
                <tr>
                  <td class="text-center">🕒</td>
                  <td class="small text-end">Round</td>
                  <td class="small text-center" colspan="2">Player</td>
                  <td class="small">Action</td>
                </tr>
              </thead>
              <tbody>
                <For each={page()} fallback={<div>Zero Logs</div>}
                  children={(log : ActionLog) => {
                    const player = findById(engine.state.players, log.player_id)
                    return (
                      <tr>
                        <td class="px-0 small text-muted">
                          <TimeAgo value={log.at}/>
                        </td>
                        <td class="px-0 text-end">{log.round}</td>
                        <td class="px-0 small">
                          <Show when={player} children={(player) => {
                            return <SingleSpriteIcon
                              entity={createInfantryEntity(1, 0, 0, player().id)}
                              noBg
                            />
                          }}/>
                        </td>
                        <td class="px-0 small">{playerDisplayName(player)}</td>
                        <td class="px-0 small text-muted">
                          <ActionLogDescription value={log}/>
                        </td>
                      </tr>
                    )
                  }}
                />
              </tbody>
            </table>
          </>)
        }} />
      </Modal.Body>
    </Modal>
  )
}

export default GameActionLogListModal
