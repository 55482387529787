import { JSX } from 'solid-js'
import { Nullable } from '../../typescript'

export default function setStyleAttributes(
  elem: HTMLElement,
  newStyles: Nullable<JSX.CSSProperties>
): void {
  if (newStyles) {
    let styleKey: keyof JSX.CSSProperties
    for (styleKey in newStyles) {
      const value = newStyles[styleKey]
      elem.style[styleKey as unknown as number] = (value as string) || ''
    }
  }
}
