import { StateOnline } from './state_online.type'
import { ONE_DAY_SECONDS, SEVEN_DAYS_SECONDS } from '../util/number'

export default function createDefaultStateOnline(): StateOnline {
  return {
    id: null,
    dname: null,
    bio: null,
    passphrase: null,

    /**
     * At the start of the game,
     * a player is given this much time to delay.
     * Once their timer runs out, they are declared
     * loser via inactivity.
     */
    initialTime: SEVEN_DAYS_SECONDS,
    /**
     * At the start of a player's turn,
     * additional time is added to their timer.
     */
    extraTimePerTurn: ONE_DAY_SECONDS,
    /**
     * After the previous player's turn has ended,
     * the next player has this amount of time to make their move.
     * If the time is exceeded, the player's turn is automatically ended
     * and the next player's turn will start.
     */
    maxTimePerTurn: SEVEN_DAYS_SECONDS,
  }
}
