import { FaSolidArrowRotateLeft, FaSolidUpload } from 'solid-icons/fa'
import { createMemo } from 'solid-js'
import type { StateOnlineId } from '../../lib/core/state/StateOnlineId.type'
import { createSessionSignal } from '../../rx/shared/session/createSessionSignal'
import { LoadingSignal, createLoadingSignal } from '../../rx/signal/create_loading_signal'
import { AlertBagStore, createAlertBagStore } from '../../rx/store/create_alert_bag_store'
import { Nullable } from '../../typescript'
import { GetAlertBagSuccessMessageFunction, GetItemValueFunction, OnChangeFunction, onClickMutateTimestampFunctionFactory } from './onClickMutateTimestampFunctionFactory'
import LoadingIcon from '../LoadingIcon'

interface Publishable {
  id: StateOnlineId
  published_at: string | null,
}

interface Props {
  disabled: Nullable<boolean>,
  item: Publishable,
  noun: string,
  // ariaLabel: string,
  onChange: OnChangeFunction,
  ab: AlertBagStore,
}

const getItemValue: GetItemValueFunction = function (item: Publishable) {
  return item?.published_at
} as GetItemValueFunction

const getAlertBagSuccessMessage: GetAlertBagSuccessMessageFunction = function (newValue: string | null, noun: string): Nullable<string> {
  return newValue ? `${noun} published.` : `${noun} unpublished.`
} as GetAlertBagSuccessMessageFunction

const timestampKey = 'published_at'

const PublishButton = (props: Props) => {
  const loading: LoadingSignal = createLoadingSignal()
  // eslint-disable-next-line solid/reactivity
  const ab = props.ab || createAlertBagStore()
  const session = createSessionSignal()[0]

  const ariaLabel = createMemo(() => {
    return props.item && (getItemValue(props.item) ? `Unpublish ${props.noun}` : `Publish ${props.noun}`)
  })

  return (<button
    class={`btn ${(getItemValue(props.item) && props.item?.id) ? 'btn-warning' : 'btn-primary'} btn-sm`}
    disabled={!props.item?.id || props.disabled || loading()}
    aria-label={ariaLabel()}
    title={ariaLabel()}
    onClick={onClickMutateTimestampFunctionFactory(
      props, timestampKey, ab, session, loading,
      getAlertBagSuccessMessage, getItemValue)}
  >
    {(props.item?.id)
      ? (loading()
        ? (getItemValue(props.item)
          ? <><LoadingIcon /> Unpublishing</>
          : <><LoadingIcon /> Publishing</>)
        : (getItemValue(props.item)
          ? <><FaSolidArrowRotateLeft /> Unpublish</>
          : <><FaSolidUpload /> Publish</>))
      : (<><FaSolidUpload /> Publish</>)}
  </button>)
}

export default PublishButton


