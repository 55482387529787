import type { Entity } from '../entity/index'
import type { HasPlayerId } from '../player/has_player_id'
import type { EntityGridXY } from './EntityGridXY.type'
import createLazyReadOnlyGridXY from './createLazyReadOnlyGridXY'

type MergableOccupantValueOrNull = Entity & HasPlayerId | null

// an mergable is a unit of the same player_id
// and etype_id as the current unit unit
// TODO allow a same-team merge
// which is essentially like donating
// a damaged unit's remaining HP
export default function createLazyMergableValueGrid(
  // full state width
  gridWidth: number,
  // full state height
  gridHeight: number,
  // full state ents as GridXY
  grid: EntityGridXY,
  // unit attempting to move
  unit: Entity
) {
  const unitPlayerId = (unit as HasPlayerId).player_id
  if (!unitPlayerId) {
    return () => null
  }
  const lazyGrid = createLazyReadOnlyGridXY<MergableOccupantValueOrNull>(
    gridWidth,
    gridHeight,
    function mergableValueGridGetter(x: number, y: number): MergableOccupantValueOrNull {
      const positionStack = grid?.[x]?.[y]
      if (positionStack) {
        for (let index = 0; index < positionStack.length; index++) {
          const ent = positionStack[index]
          if (ent.etype_id === unit.etype_id) {
            if ((ent as HasPlayerId).player_id === unitPlayerId) {
              return ent as Entity & HasPlayerId
            }
          }
        }
      }
      return null
    }
  )
  return lazyGrid
}
