import { InferOutput, literal, nullable, optional } from 'valibot'

export const LiteralTrueSchema = literal(true)

export type LiteralTrue = InferOutput<typeof LiteralTrueSchema>

export const NullableLiteralTrueSchema = nullable(LiteralTrueSchema)

export type NullableLiteralTrue = InferOutput<typeof NullableLiteralTrueSchema>

export const OptionalLiteralTrueSchema = optional(LiteralTrueSchema)

export type OptionalLiteralTrue = InferOutput<typeof OptionalLiteralTrueSchema>
