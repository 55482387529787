import { check, InferOutput, pipe } from 'valibot'
import { StringOrNumberAsNumberSchema } from './StringOrNumberAsNumber'

export const StringOrIntegerAsIntegerSchema = pipe(StringOrNumberAsNumberSchema, check((value : number) => {
  const int = parseInt(value as unknown as string)
  if (isNaN(int)) {
    return false
  }
  return int === parseFloat(value as unknown as string)
}))

export type StringOrIntegerAsInteger = InferOutput<typeof StringOrIntegerAsIntegerSchema>
