import { Icon } from "solid-heroicons"
import { checkCircle, exclamationCircle, exclamationTriangle, informationCircle } from "solid-heroicons/outline"
import { Component } from 'solid-js'
import alertBagValueToString from "../lib/AlertBag/alertBagValueToString"
import { AlertBagStore } from '../rx/store/create_alert_bag_store'

interface Props {
  store: AlertBagStore,
}

const AlertBag: Component<Props> = (props) => {
  return <>
    {props.store[0].e && <div class="alert alert-danger" role="alert" aria-label="danger alert">
      <div>
        <Icon path={exclamationCircle} class="icon" />
        {alertBagValueToString(props.store[0].e)}
      </div>
    </div>}
    {props.store[0].w && <div class="alert alert-warning" role="alert" aria-label="warning alert">
      <div>
        <Icon path={exclamationTriangle} class="icon" />
        {alertBagValueToString(props.store[0].w)}
      </div>
    </div>}
    {props.store[0].s && <div class="alert alert-success" role="alert" aria-label="success alert">
      <div>
        <Icon path={checkCircle} class="icon" />
        {alertBagValueToString(props.store[0].s)}
      </div>
    </div>}
    {props.store[0].i && <div class="alert alert-info" role="alert" aria-label="info alert">
      <div>
        <Icon path={informationCircle} class="icon" />
        {alertBagValueToString(props.store[0].i)}
      </div>
    </div>}
  </>
}

export default AlertBag