export type ColorNamePair = {
  color1: string
  color2: string
  dname: string
}

function makeColorNamePair(color1: string, color2: string, dname: string): ColorNamePair {
  return { color1, color2, dname }
}

// these default names are well suited for map-making
export const colorNamesPool: Readonly<Array<ColorNamePair>> = [
  makeColorNamePair('#48b848', '#78e078', 'Green'),
  makeColorNamePair('#7060e8', '#88d0f8', 'Blue'),
  makeColorNamePair('#f00', '#f00', 'Red'),

  makeColorNamePair('#f80', '#f80', 'Orange'),
  makeColorNamePair('#80f', '#80f', 'Purple'),

  makeColorNamePair('#f0f', '#f0f', 'Pink'),
  makeColorNamePair('#0ff', '#0ff', 'Cyan'),
  makeColorNamePair('#ff0', '#ff0', 'Yellow'),
]
