interface HasId {
  id: number
}

// Object.entries returns an array of key-value pairs.
type MapEntriesTuple<K extends HasId, V> = [K, V]

function byIndexZeroAsc<K extends HasId, V>(a: MapEntriesTuple<K, V>, b: MapEntriesTuple<K, V>) {
  return a[0].id - b[0].id
}
function byIndexZeroDesc<K extends HasId, V>(a: MapEntriesTuple<K, V>, b: MapEntriesTuple<K, V>) {
  return b[0].id - a[0].id
}

export function sortMapWhereKeyHasIdAsc<K extends HasId, V>(data: Map<K, V>): Map<K, V> {
  return new Map<K, V>([...data].sort(byIndexZeroAsc))
}

export function sortMapWhereKeyHasIdDesc<K extends HasId, V>(data: Map<K, V>): Map<K, V> {
  return new Map<K, V>([...data].sort(byIndexZeroDesc))
}
