import type { Nullable } from "../../../typescript"
import type { State } from "./State.type"

export default function mapDisplayName(
  state: Nullable<State>,
): Nullable<string> {
  if (state) {
    return state.map.dname || `Untitled Map [${state.map.id}]`
  }
}
