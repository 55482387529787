import { Component, createEffect, useContext } from "solid-js"
import bindRenderFunctionToEngine from "../../lib/canvas/bind_render_function_to_engine"
import { createCanvasElement } from "../../lib/canvas/create_canvas"
import { disableCanvasSmoothing } from "../../lib/canvas/disable_canvas_smoothing"
import defaultErrorRender from "../../lib/canvas/draw/default_render_error"
import { getCanvas2dContext } from "../../lib/canvas/get_canvas_2d_context"
import createLayerMeta from "../../lib/core/create_layer_meta"
import { Engine } from "../../lib/core/engine/Engine.type"
import { Entity } from "../../lib/core/entity/index"
import { EXPIRE_NEVER } from "../../lib/core/layer_meta"
import { BLACK_HEX, WHITE_HEX } from "../../lib/core/player/player_color"
import { MakeRenderSpriteFunction, renderSpriteByIdMap, RenderSpriteFunction } from "../../lib/core/render_sprite"
import { BASE_TILE_SIZE } from "../../lib/core/view_ctx"
import type { Nullable } from "../../typescript"
import EngineContext from "../EngineContext"

interface Props {
  entity: Entity,
}

const d_tile_size = BASE_TILE_SIZE * 2

const SingleSpriteInspect: Component<Props> = (props: Props) => {
  const engine : Engine = useContext(EngineContext)
  let entity : Nullable<Entity> = null
  createEffect(() => {
    entity = props.entity
  })
  const lm = createLayerMeta()
  const canvas = createCanvasElement()
  canvas.width = d_tile_size
  canvas.height = d_tile_size * 2
  // const track = engine.cache[0]

  function render(timeStamp: number) {
    // console.log('SingleSpriteInspect.render', entity)
    const ctx = getCanvas2dContext(canvas)
    lm.ts = timeStamp

    // frames have indicated they
    // plan on rendering the same image
    if (lm.until > timeStamp) {
      return
    }
    lm.until = timeStamp + EXPIRE_NEVER

    if (ctx) {
      ctx.clearRect(0, 0, canvas.width, canvas.height)
      disableCanvasSmoothing(ctx)

      ctx.globalAlpha = 1
      ctx.fillStyle = WHITE_HEX
      ctx.strokeRect(0, d_tile_size, canvas.width, d_tile_size)

      ctx.globalAlpha = 0.25
      ctx.strokeStyle = BLACK_HEX
      ctx.fillRect(0, d_tile_size, d_tile_size, d_tile_size)

      ctx.globalAlpha = 1
      if (entity) {
        let renderSprite: RenderSpriteFunction = defaultErrorRender
        const makeRenderSprite: MakeRenderSpriteFunction = renderSpriteByIdMap[entity.etype_id]
        if (makeRenderSprite) {
          renderSprite = makeRenderSprite(engine, { ...entity })
        }

        renderSprite(ctx, lm, entity, 0, d_tile_size, d_tile_size)
      }
    }
  }

  bindRenderFunctionToEngine(engine, render)

  return canvas
}

export default SingleSpriteInspect
