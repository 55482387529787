import { array, boolean, object, type InferOutput } from 'valibot'
import type { HasTaxiID } from '../entity/HasTaxiID'
import canUnitDirectAttackEntity from '../entity/canUnitDirectAttackEntity'
import { EntitySchema, type Entity } from '../entity/index'
import type { HasHP } from '../has_hp'
import type { HasPlayerId } from '../player/has_player_id'
import type { EntityGridXY } from './EntityGridXY.type'
import createLazyReadOnlyGridXY from './createLazyReadOnlyGridXY'
import canUnitRangedAttackEntity from '../entity/canUnitRangedAttackEntity'

type EnemyEntity = Entity & HasPlayerId & HasHP

export const EnemyListRowSchema = object({
  canDirectAttack: boolean(),
  canRangedAttack: boolean(),
  ent: EntitySchema, // Entity & HasPlayerId & HasHP
})

export type EnemyListRow = InferOutput<typeof EnemyListRowSchema>

export const EnemyListSchema = array(EnemyListRowSchema)

export type EnemyList = InferOutput<typeof EnemyListSchema>

export default function createLazyEnemyListGrid(
  // full state width
  gridWidth: number,
  // full state height
  gridHeight: number,
  // full state ents as GridXY
  grid: EntityGridXY,
  // unit attempting to approach enemies
  unit: Entity
) {
  const lazyGrid = createLazyReadOnlyGridXY<EnemyList>(
    gridWidth,
    gridHeight,
    function enemyListGridGetter(x: number, y: number): EnemyList {
      const positionStack = grid?.[x]?.[y]
      const list : EnemyList = []
      if (positionStack) {
        const unitPlayerId = (unit as HasPlayerId).player_id
        for (let index = 0; index < positionStack.length; index++) {
          const enemyEnt = positionStack[index];
          if ((enemyEnt as HasPlayerId).player_id !== unitPlayerId) {
            if ('hp' in enemyEnt) {
              if ((enemyEnt as HasTaxiID).taxi_id) {
                // ignore cargo, taxi will be used
                continue
              }
              list.push({
                canDirectAttack: canUnitDirectAttackEntity(unit, enemyEnt),
                canRangedAttack: canUnitRangedAttackEntity(unit, enemyEnt),
                ent: enemyEnt as EnemyEntity,
              })
            }
          }
        }
      }
      return list
    }
  )
  return lazyGrid
}
