import { now } from '@sg/shared/src/lib/Date'
import { modifyMutable, produce } from 'solid-js/store'
import calculateDestPositionHoverPositionPaths from '../../core/draft_move/calculateDestPositionHoverPositionPaths'
import calculateDraftMoveAttackEstimates from '../../core/draft_move/calculateDraftMoveAttackEstimates'
import { Engine } from '../../core/engine/Engine.type'
import calculateSelectedPositionEnts from '../../core/entity/calculateSelectedPositionEnts'
import { SelectedToolId } from '../../core/map_editor/SelectedToolId.enum'
import { createEraseTileAction } from '../../core/state/flux/action/Map/EraseTileAction'
import { createPaintTileAction } from '../../core/state/flux/action/Map/PaintTileAction'
import dispatchClient from '../../core/state/flux/dispatchClient'
import isPositionInBounds from '../../core/tile_position_xy/isPositionInBounds'
import { positionFromPixels } from '../../core/tile_position_xy/positionFromPixels'
import { samePosition } from '../../core/tile_position_xy/samePosition'
import { floor, sqrt } from '../../core/util/math'
import onElementEvent from '../../onElementEvent'

/**
 * @var zoomFactor
 * smaller - zoom faster
 * larger - zoom slower
 */
const zoomFactor = -0.16

export default function registerTouchEventsV2(engine: Engine, elem: HTMLElement) {
  const { viewCtx } = engine
  // const dirty = engine.cache[1]
  let lastTouchDistance: number | null = null

  function onTouchStart(event: TouchEvent) {
    // this preventDefault stops the browser from interpretting
    // swipe left/right as navigate history forward/backward
    // it also breaks touching regular DOM elements as touch events
    // but htmlbody{overscroll-behavior-x: none} appears to have worked.
    // event.preventDefault()

    event.stopPropagation()
    // if (wasTargetNotCanvas(event.target)) {
    //   onTouchCancel(event)
    //   return
    // }
    const touchesLen = event.touches.length
    if (touchesLen === 1) {
      // console.log('onTouchStart.single', event)
      // const touch: Touch = event.touches[0]
      // const { clientX, clientY } = touch
      // const touchPosition = positionFromPixels(state, viewCtx, clientX, clientY)
      // engine.hoveredPosition = touchPosition
      // engine.selectedPosition = touchPosition
      // calculateSelectedPositionEnts(engine)

      const touch: Touch = event.touches[0]
      const { clientX, clientY } = touch

      engine.selectedPosition = null
      calculateSelectedPositionEnts(engine)
      engine.touchStartEvent = event
      const position = positionFromPixels(engine.state, engine.viewCtx, clientX, clientY)
      if (!samePosition(engine.touchStartPosition, position)) {
        engine.touchStartPosition = position
      }
      engine.touchEndEvent = null
      engine.touchDragList = []
      // dirty(EngineCache.Cursor)
    }
    engine.lastTouchMoveEvent = null
  }

  function onTouchMove(event: TouchEvent) {
    event.preventDefault()
    event.stopPropagation()
    // if (wasTargetNotCanvas(event.target)) {
    //   onTouchCancel(event)
    //   return
    // }

    const lastTouchMoveEvent = engine.lastTouchMoveEvent
    const touchesLen: number = event.touches.length
    const prevTouchesLen: number = lastTouchMoveEvent?.touches.length || 0

    if (touchesLen !== prevTouchesLen) {
      // if a finger drops or is added, do nothing
      // let next event continue
    } else if (touchesLen === 1) {
      // Single touch - pan
      const touch: Touch = event.touches[0]
      const { clientX, clientY } = touch
      const devicePixelRatio = window.devicePixelRatio || 1
      const previousTouch = engine.lastTouchMoveEvent?.touches[0]

      if (previousTouch) {
        const movementX = clientX - previousTouch.clientX
        const movementY = clientY - previousTouch.clientY

        viewCtx.pan_x_f += movementX / devicePixelRatio
        viewCtx.pan_y_f += movementY / devicePixelRatio
        viewCtx.pan_x = floor(viewCtx.pan_x_f)
        viewCtx.pan_y = floor(viewCtx.pan_y_f)
        viewCtx.lastPanAt = now()
        // dirty(EngineCache.ViewCtx)
      }

      // const touchPosition = positionFromPixels(state, viewCtx, clientX, clientY)
      // engine.hoveredPosition = touchPosition
      // engine.selectedPosition = touchPosition
      // calculateSelectedPositionEnts(engine)
    } else if (touchesLen === 2) {
      // Multi-touch - pinch zoom
      const touch1 = event.touches[0]
      const touch2 = event.touches[1]

      const dx = touch1.clientX - touch2.clientX
      const dy = touch1.clientY - touch2.clientY
      const distance = sqrt((dx * dx) + (dy * dy))

      if (lastTouchDistance !== null) {
        const deltaDistance = (distance - lastTouchDistance) / zoomFactor

        engine.viewCtx.zoomQueue.push({
          clientX: (touch1.clientX + touch2.clientX) / 2,
          clientY: (touch1.clientY + touch2.clientY) / 2,
          deltaY: deltaDistance, // Inverted delta to simulate pinch zoom
          prevMapWidthPx: viewCtx.mapWidthPx,
          prevMapHeightPx: viewCtx.mapHeightPx,
          prevPanX: viewCtx.pan_x,
          prevPanY: viewCtx.pan_y,
        })
        // dirty(EngineCache.ViewCtx)
      }

      lastTouchDistance = distance
    }

    if (touchesLen !== 2) {
      lastTouchDistance = null
    }

    engine.lastTouchMoveEvent = event
  }

  function onTouchEnd(event: TouchEvent) {
    // event.preventDefault()
    event.stopPropagation()
    // if (wasTargetNotCanvas(event.target)) {
    //   onTouchCancel(event)
    //   return
    // }
    const touchesLen = event.touches.length
    // if (touchesLen === 1) {
    //   console.log('onTouchEnd.single', event)
    //   const touch: Touch = event.touches[0]
    //   const { clientX, clientY } = touch
    //   const touchPosition = positionFromPixels(state, viewCtx, clientX, clientY)
    //   engine.hoveredPosition = touchPosition
    //   engine.selectedPosition = touchPosition
    //   calculateSelectedPositionEnts(engine)
    // }
    if (touchesLen === 1) {
      // console.log('onTouchEnd.single', event)
      const touch: Touch = event.touches[0]
      engine.touchEndEvent = event
      // const { offsetX, offsetY } = event
      const { clientX, clientY } = touch
      const { touchStartEvent, touchStartPosition } = engine

      // const isDrag: boolean = touchDragMetThreshold(engine.touchDragList, viewCtx.tile_size)
      const isDrag: boolean = false
      // console.log('touch.isDrag', isDrag)

      // a long press should be treated like a right click
      // right click is undefined behavior
      // @todo make isLongPress not true for pans
      const clickDuration = touchStartEvent ? event.timeStamp - touchStartEvent.timeStamp : null
      const isLongPress = clickDuration ? clickDuration > 500 : false
      // a click is a mouse down + mouse up event that occurs in less than 500 ms
      const isClick = touchStartEvent && !isLongPress && !isDrag

      const { selectedTool } = engine
      const position = positionFromPixels(engine.state, viewCtx, clientX, clientY)
      // console.log('onMouseUp.position', position)
      if (isClick) {
        if (selectedTool === SelectedToolId.Inspect) {
          if (isPositionInBounds(position, engine) && samePosition(touchStartPosition, position)) {
            // console.log('engine.selectedPosition =', position)
            engine.selectedPosition = position
          } else {
            // console.log('engine.selectedPosition = to NULL')
            engine.selectedPosition = null
          }
          calculateSelectedPositionEnts(engine)
          // dirty(EngineCache.SelectedPosition)
        } else if (selectedTool === SelectedToolId.Paint) {
          // console.log('position', position)
          if (position && samePosition(touchStartPosition, position)) {
            dispatchClient(
              engine,
              createPaintTileAction(engine.paintToolOption, position, engine.selectedPlayerId)
            )
          }
        } else if (selectedTool === SelectedToolId.Erase) {
          if (position && samePosition(touchStartPosition, position)) {
            dispatchClient(engine, createEraseTileAction(engine.eraseToolOption, position))
          }
        }
      }

      engine.viewCtx.lastPanAt = 0

      // dirty(EngineCache.Cursor)

      engine.touchStartEvent = null
      engine.touchStartPosition = null
      engine.touchDragList = null
    }
    if (touchesLen < 2) {
      lastTouchDistance = null
      engine.lastTouchMoveEvent = null
    }
  }

  function onTouchCancel() {
    lastTouchDistance = null
    modifyMutable(engine, produce((engine) => {
      engine.lastTouchMoveEvent = null
      engine.hoveredPosition = null
      calculateDestPositionHoverPositionPaths(engine)
      calculateDraftMoveAttackEstimates(engine)
    }))
  }

  onElementEvent(elem, 'touchstart', onTouchStart, { passive: false })
  onElementEvent(elem, 'touchmove', onTouchMove, { passive: false })
  onElementEvent(elem, 'touchend', onTouchEnd)
  onElementEvent(elem, 'touchcancel', onTouchCancel)
}

// we'll come back to this if we find a bug.
// function touchDragMetThreshold(
//   touchDragList: Readonly<Array<TouchEvent>> | null,
//   tile_size: number
// ) {
//   const threshMult = 6
//   const agg = touchDragListToAgg(touchDragList)
//   if (!agg) return false
//   if (abs(agg.x * threshMult) > tile_size) return true
//   if (abs(agg.y * threshMult) > tile_size) return true
//   return false
// }

// function touchDragListToAgg(
//   touchDragList: Readonly<Array<TouchEvent>> | null
// ): Readonly<TouchDragAgg | null> {
//   const list = touchDragList
//   if (!(list && list.length > 0)) return null
//   let pan_x = 0,
//     pan_y = 0
//   for (let index = 0; index < list.length; index++) {
//     const mmEvent = list[index]
//     // TODO figure out a definition for movement on TouchEvent
//     pan_x += mmEvent.movementX
//     pan_y += mmEvent.movementY
//   }
//   return { x: pan_x, y: pan_y }
// }
