import { DisplayNameSchema } from '@sg/shared/src/valibot/schema/DisplayName.type'
import { HandleSchema } from '@sg/shared/src/valibot/schema/Handle.type'
import { isServer } from 'solid-js/web'
import { literal, nullable, object, type InferOutput } from 'valibot'
import { Engine } from '../../../../engine/Engine.type'
import { PlayerKind } from '../../../../player/PlayerKind.enum'
import { ProfileIdSchema } from '../../../../ProfileId.type'
import createDefaultStateOnline from '../../../create_default_state_online'
import randomStateIdValue from '../../../randomStateIdValue'
import { StateType } from '../../../state_type.enum'
import type { ActionLog } from '../../ActionLog.type'
import { ActionType } from '../ActionType'

// type ProfileLike = Pick<AuthProfile, 'id' | 'dname' | 'handle'>

const ProfileLikeSchema = object({
  id: ProfileIdSchema,
  dname: nullable(DisplayNameSchema),
  handle: nullable(HandleSchema),
})

export type ProfileLike = InferOutput<typeof ProfileLikeSchema>

export const MapToLobbyActionSchema = object({
  type: literal(ActionType.Map.MapToLobby),
  profile: ProfileLikeSchema,
})

export type MapToLobbyAction = InferOutput<typeof MapToLobbyActionSchema>

export function createMapToLobbyAction(profile: ProfileLike): MapToLobbyAction {
  return {
    type: ActionType.Map.MapToLobby,
    profile,
  }
}

export async function handleMapToLobbyAction(
  engine: Engine,
  action: MapToLobbyAction,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  actionLog: ActionLog
): Promise<void> {
  // console.log('handleMapToLobbyAction', action)

  if (isServer) {
    engine.state.type = StateType.Lobby
    const player = engine.state.players[0]
    if (action.profile?.id) {
      player.profile_id = action.profile.id
      player.dname = action.profile.dname
      player.kind = PlayerKind.Human
    }
    if (!engine.state.online?.id) {
      player.is_local = true
    }
    engine.state.online ||= createDefaultStateOnline()
    engine.state.online.id = randomStateIdValue()
  } else {
    console.log('TODO !isServer')
    // probably nothing, unless is a local game
    // user will be redirected to a formal lobby page
  }
}
