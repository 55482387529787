import { array, InferOutput, literal, object } from 'valibot'
import { ArmorSchema } from '../../Armor.type'
import { AttackedThisTurnSchema } from '../../AttackedThisTurn'
import { entityTypeDefaults, EntityTypeMeta } from '../../EntityTypeMeta'
import { FuelSchema } from '../../fuel.type'
import { HasMobilitySchema } from '../../has_mobility'
import { HasXYSchema } from '../../has_xy'
import { HPSchema } from '../../hp.type'
import { PlayerIdOrNullSchema } from '../../player/PlayerIdOrNull'
import { VisionSchema } from '../../vision.type'
import { WasBuiltThisTurnSchema } from '../../WasBuiltThisTurn'
import { ArmorTypeId } from '../Armor/ArmorTypeId.enum'
import { EntityLayerId } from '../entity_layer_id.enum'
import { EntityTypeId } from '../entity_type_id.enum'
import { EntityIdSchema } from '../EntityId.type'
import { MoveTypeId } from '../move_type_id.enum'
import createMLRSWeapon from '../Weapon/createMLRSWeapon'
import { WeaponSchema } from '../Weapon/Weapon.type'
import { BaseEntitySchema } from '../BaseEntity'

const entDefaults = {
  etype_id: EntityTypeId.MediumRockets,
  layer_id: EntityLayerId.Unit2,
  hp: 10,
  fuel: 50,
  vision: 1,
  mtype_id: MoveTypeId.Tires,
  mobility: 5,
  // range: 3-5
  armor: 5,
} as const

function defaultWeapons() {
  return [createMLRSWeapon(10)]
}

export const MediumRocketsEntitySchema = object({
  ...BaseEntitySchema.entries,
  etype_id: literal(EntityTypeId.MediumRockets),
  layer_id: literal(EntityLayerId.Unit2),
  id: EntityIdSchema,
  player_id: PlayerIdOrNullSchema,
  hp: HPSchema,
  fuel: FuelSchema,
  vision: VisionSchema,
  armor: ArmorSchema,
  ...HasXYSchema.entries,
  ...HasMobilitySchema.entries,
  builtThisTurn: WasBuiltThisTurnSchema,
  attackedThisTurn: AttackedThisTurnSchema,
  weapons: array(WeaponSchema),
})

export type MediumRocketsEntity = InferOutput<typeof MediumRocketsEntitySchema>

export const MediumRocketsEntityTypeMeta: EntityTypeMeta = {
  ...entityTypeDefaults,
  id: EntityTypeId.MediumRockets,
  dname: 'Medium Rockets',
  layer_id: EntityLayerId.Unit2,
  price: 150,
  entDefaults,
  defaultWeapons,
  armor_type: ArmorTypeId.LightVehicle,
} as const

export function createMediumRocketsEntity(
  id: number,
  x: number,
  y: number,
  player_id: number | null
): MediumRocketsEntity {
  return {
    ...entDefaults,
    weapons: defaultWeapons(),
    id,
    x,
    y,
    player_id,
  }
}
