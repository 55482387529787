import type { HasPlayerId } from '../player/has_player_id'
import type { State } from '../state/State.type'
import type { Entity } from './index'

export default function inSameTeam(state: State, ent1: Entity, ent2: Entity): boolean {
  const player1Id = (ent1 as HasPlayerId).player_id
  if (player1Id) {
    const player2Id = (ent2 as HasPlayerId).player_id
    if (player2Id) {
      if (player1Id === player2Id) {
        return true
      }
      // TODO implement allied players/teams
    }
  }
  return false
}
