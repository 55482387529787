import { modifyMutable, produce } from 'solid-js/store'
import { Engine } from '../../core/engine/Engine.type'
import calculateSelectedPositionEnts from '../../core/entity/calculateSelectedPositionEnts'
import { SelectedToolId } from '../../core/map_editor/SelectedToolId.enum'
import { createEraseTileAction } from '../../core/state/flux/action/Map/EraseTileAction'
import { createPaintTileAction } from '../../core/state/flux/action/Map/PaintTileAction'
import dispatchClient from '../../core/state/flux/dispatchClient'
import isPositionInBounds from '../../core/tile_position_xy/isPositionInBounds'
import { positionFromPixels } from '../../core/tile_position_xy/positionFromPixels'
import { samePosition } from '../../core/tile_position_xy/samePosition'
import { abs } from '../../core/util/math'
import MouseDragAgg from '../../core/view_ctx/MouseDragAgg'
import { deepClone } from '../../deep_clone'
import onWindowEvent from '../../onWindowEvent'

export default function registerMouseUpEvent(engine: Engine) {
  // const dirty = engine.cache[1]
  function onMouseUp(event: MouseEvent) {
    const { viewCtx, state } = engine
    // console.log('onMouseUp', event, event.defaultPrevented)
    engine.mouseUpEvent = event
    const { offsetX, offsetY } = event
    const { draftMove, mouseDownEvent, mouseDownPosition } = engine

    // const isMap = wasEventTargetMap(event)
    const isDrag: boolean = mouseDragMetThreshold(engine.mouseDragList, viewCtx.tile_size)

    // a long press should be treated like a right click
    // right click is undefined behavior
    // @todo make isLongPress not true for pans
    const clickDuration = mouseDownEvent ? event.timeStamp - mouseDownEvent.timeStamp : null
    const isLongPress = clickDuration ? clickDuration > 500 : false
    // a click is a mouse down + mouse up event that occurs in less than 500 ms
    const isClick = mouseDownEvent && !isLongPress && !isDrag
    // console.log('isClick', isClick)

    const { selectedTool } = engine
    const position = positionFromPixels(state, viewCtx, offsetX, offsetY)
    // console.log('onMouseUp.position', position)
    if (isClick) {
      if (draftMove) {
        console.log('isClick.draftMove', deepClone(draftMove))
        // if (draftMove.destPosition)
      } else if (selectedTool === SelectedToolId.Inspect) {
        console.log('isClick.SelectedToolId.Inspect')
        modifyMutable(
          engine,
          produce((engine: Engine) => {
            if (isPositionInBounds(position, engine) && samePosition(mouseDownPosition, position)) {
              // console.log('engine.selectedPosition', position)
              engine.selectedPosition = position
            } else {
              // console.log('engine.selectedPosition to NULL')
              engine.selectedPosition = null
            }
            calculateSelectedPositionEnts(engine)
          })
        )
      } else if (selectedTool === SelectedToolId.Paint) {
        console.log('isClick.SelectedToolId.Paint')
        // console.log('position', position)
        if (position && samePosition(mouseDownPosition, position)) {
          dispatchClient(
            engine,
            createPaintTileAction(engine.paintToolOption, position, engine.selectedPlayerId)
          )
        }
      } else if (selectedTool === SelectedToolId.Erase) {
        console.log('isClick.SelectedToolId.Erase')
        if (position && samePosition(mouseDownPosition, position)) {
          dispatchClient(engine, createEraseTileAction(engine.eraseToolOption, position))
        }
      } else {
        console.log('isClick.SelectedToolId.Else', selectedTool)
      }
    }

    modifyMutable(
      engine,
      produce((engine: Engine) => {
        engine.viewCtx.lastPanAt = 0

        engine.mouseDownEvent = null
        engine.mouseDownPosition = null
        engine.mouseDragList = null
      })
    )
  }

  onWindowEvent('mouseup', onMouseUp)
}

function mouseDragMetThreshold(
  mouseDragList: Readonly<Array<MouseEvent>> | null,
  tile_size: number
) {
  const threshMult = 6
  const agg = mouseDragListToAgg(mouseDragList)
  if (!agg) return false
  if (abs(agg.x * threshMult) > tile_size) return true
  if (abs(agg.y * threshMult) > tile_size) return true
  return false
}

function mouseDragListToAgg(
  mouseDragList: Readonly<Array<MouseEvent>> | null
): Readonly<MouseDragAgg | null> {
  const list = mouseDragList
  if (!(list && list.length > 0)) return null
  let pan_x = 0,
    pan_y = 0
  for (let index = 0; index < list.length; index++) {
    const mmEvent = list[index]
    pan_x += mmEvent.movementX
    pan_y += mmEvent.movementY
  }
  return { x: pan_x, y: pan_y }
}
