import createMapEditorPlayer from '../../../../player/createMapEditorPlayer'
import { createNewRNGConfig } from '../../../../rng'
import createDefaultStateConfig from '../../../../state/create_default_state_config'
import createDefaultStateMap from '../../../../state/create_default_state_map'
import { State } from '../../../../state/State.type'
import { StateType } from '../../../../state/state_type.enum'
import { random } from '../../../../util/math'
import { MapScript_FormData } from '../FormData.type'
import { MapScript_FormData_Stage } from '../Stage.type'
import runFormDataStage from './runFormDataStage'

export default function runFormData(prevState: Partial<State>, fd: MapScript_FormData): State {
  // console.log('runFormData', fd)
  const width: number = prevState.width || 1
  const height: number = prevState.height || 1

  const rng = prevState.rng || createNewRNGConfig()
  rng.seed = fd.seed || -1
  if (rng.seed === -1) {
    rng.seed = random()
  }
  rng.state = rng.seed

  const state: State = {
    // id: randomUid(),
    round: 1,
    config: createDefaultStateConfig(),
    map: createDefaultStateMap(),
    players: [],
    turnPlayerId: null,
    ...prevState,
    type: StateType.Map,
    width,
    height,
    ents: [],
    rng, // technically a map doesn't need this
  }

  state.players.push(createMapEditorPlayer(state, rng))
  state.players.push(createMapEditorPlayer(state, rng))

  fd.stages?.forEach((stage: MapScript_FormData_Stage) => runFormDataStage(state, stage))

  return state
}
