import { modifyMutable, produce } from 'solid-js/store'
import calculateDraftMoveAttackEstimates from '../../core/draft_move/calculateDraftMoveAttackEstimates'
import setDraftMoveDestPositionAndAttackPositionOneTurnTowardsPosition from '../../core/draft_move/setDraftMoveDestPositionAndAttackPositionOneTurnTowardsPosition'
import { Engine } from '../../core/engine/Engine.type'
import calculateSelectedPositionEnts from '../../core/entity/calculateSelectedPositionEnts'
import { createMoveUnitAction } from '../../core/state/flux/action/Game/MoveUnitAction'
import dispatchClient from '../../core/state/flux/dispatchClient'
import { positionFromPixels } from '../../core/tile_position_xy/positionFromPixels'
import { samePosition } from '../../core/tile_position_xy/samePosition'
import { deepClone } from '../../deep_clone'
import onWindowEvent from '../../onWindowEvent'

type MightHaveSourceCapabilities = {
  sourceCapabilities?: {
    firesTouchEvents?: boolean
  }
}

export default function registerMouseDownEvent(engine: Engine) {
  function onMouseDown(event: MouseEvent) {
    // alert('onMouseDown'+JSON.stringify({
    //   buttons: event.buttons,
    //   button: event.button,
    //   type: event.type,
    //   detail: event.detail,
    //   which: event.which,
    //   'sourceCapabilities.firesTouchEvents': event.sourceCapabilities.firesTouchEvents,
    // }))
    const wasNotTouchEvent = (event as MightHaveSourceCapabilities).sourceCapabilities?.firesTouchEvents === false
    console.log('onMouseDown', event)
    // const isMap = wasEventTargetMap(event)
    // modifyMutable(
    //   engine,
    //   produce((engine: Engine) => {
    const mouseDownPosition = positionFromPixels(
      engine.state,
      engine.viewCtx,
      event.offsetX,
      event.offsetY
    )
    const { draftMove } = engine
    if (draftMove) {
      const { startPosition, destPosition, attackPosition, unload } = draftMove
      console.log('onMouseDown.draftMove', deepClone(draftMove))
      if (samePosition(mouseDownPosition, startPosition)) {
        console.log('onMouseDown.draftMove', 'samePosition(mouseDownPosition, draftMove.startPosition)')
      } else if (unload) {
        console.log('onMouseDown.draftMove.unload')
        if (unload.paths.find((path) => samePosition(path, mouseDownPosition))) {
          dispatchClient(engine, createMoveUnitAction({
            ...draftMove,
            unloadPosition: mouseDownPosition,
          }))
        }
      } else if (!destPosition) {
        console.log('onMouseDown.draftMove.!destPosition')
        modifyMutable(engine, produce((engine) => {
          setDraftMoveDestPositionAndAttackPositionOneTurnTowardsPosition(engine, draftMove, mouseDownPosition)
          calculateDraftMoveAttackEstimates(engine)
        }))
        // the hover gave the player a movement path and an attack estimate,
        // they clicked on an enemy,
        // the mouse click represents the user wanting to finalize an attack
        if (!attackPosition && draftMove.attackPosition && !draftMove.hoveredPositionPaths?.lastStep?.occupant) {
          dispatchClient(engine, createMoveUnitAction(draftMove))
        }
        // else {
        // // else they were probably picking a specific tile to attack from
        //}
      } else if (destPosition) {
        console.log('onMouseDown.draftMove.destPosition')
        const attackEstimate = draftMove.attackEstimates.find(ae => samePosition(ae.target, mouseDownPosition) && samePosition(ae.unitPosition, destPosition))
        // if it's an attack, they had the estimates already, let's move forward
        if (attackEstimate) {
          console.log('onMouseDown.draftMove.destPosition with attackEstimate', deepClone({
            destPosition, attackEstimate, wasNotTouchEvent
          }))
          if (wasNotTouchEvent || samePosition(draftMove.attackPosition, mouseDownPosition)) {
            // if we are 100% certain they used a mouse,
            // then they were able to use the hover to see the estimates
            dispatchClient(engine, createMoveUnitAction({
              ...draftMove,
              attackPosition: mouseDownPosition,
              target: attackEstimate.target,
            }))
          } else {
            // if we are not 100% certain they used a mouse,
            // then will assume they are attempting to see the estimates
            draftMove.attackPosition = mouseDownPosition
          }
        } else {
          console.log('onMouseDown.draftMove.destPosition !attackEstimate')
          // else they were probably picking a difference destPosition tile
          modifyMutable(engine, produce((engine) => {
            setDraftMoveDestPositionAndAttackPositionOneTurnTowardsPosition(engine, draftMove, mouseDownPosition)
            calculateDraftMoveAttackEstimates(engine)
          }))
        }
      } else {
        console.log('onMouseDown.draftMove.else // do nothing')
        // draftMove.attackPosition = mouseDownPosition
      }
    } else {
      console.log('onMouseDown.!draftMove')
      engine.selectedPosition = null
      calculateSelectedPositionEnts(engine)
    }
    engine.mouseDownEvent = event
    if (!samePosition(engine.mouseDownPosition, mouseDownPosition)) {
      engine.mouseDownPosition = mouseDownPosition
    }
    engine.mouseUpEvent = null
    engine.mouseDragList = []
  }

  onWindowEvent('mousedown', onMouseDown)
}
