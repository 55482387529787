import type { Entity } from '../../entity/index'
import type { HasMobility } from '../../has_mobility'
import createLazyMovementCostGrid from '../createLazyMovementCostGrid'
import createReadWriteGridXY from '../createReadWriteGridXY'
import { dijkstraDirections } from '../dijkstraDirections'
import type { EntityGridXY } from '../EntityGridXY.type'
import type { TilePositionXY } from '../TilePositionXY.type'
import byTotalCostAsc from './byTotalCostAsc'
import type { IsPositionReachableInOneTurn } from './IsPositionReachableInOneTurn'

// at 100 turns, throw error
const maxSteps = 100

export default function createIsPositionReachableInOneTurnV2(
  grid: EntityGridXY,
  gridWidth: number,
  gridHeight: number,
  unit: Entity
): (x: number, y: number) => boolean {
  // console.log('')
  // console.log('createIsPositionReachableInOneTurnV2.start', { gridWidth, gridHeight })
  // const gridWidth = grid.length
  // const gridHeight = grid[0].length
  // const unitEntityTypeMeta = findByIdOrThrow(entityTypeMetaList, unit.etype_id)
  // const movePointsPerTurn: number | undefined = (unitEntityTypeMeta.entDefaults as HasMobility).mobility
  const movePointsLeftInFirstTurn: number | undefined = (unit as HasMobility).mobility

  // we are only concerned about 1 turn
  const movePointsPerTurn = movePointsLeftInFirstTurn
  if (!movePointsPerTurn) {
    // console.log('createIsPositionReachableInOneTurnV2', '!movePointsPerTurn')
    return () => false
  }

  // start x and y
  const { x, y } = unit
  const unitPosition: TilePositionXY = { x, y }

  const costGrid = createLazyMovementCostGrid(gridWidth, gridHeight, grid, unit)
  const [getBest, setBest] = createReadWriteGridXY<IsPositionReachableInOneTurn | null>(
    gridWidth,
    gridHeight,
    null
  )
  const [isReachableInOneTurn, setIsReachableInOneTurn] = createReadWriteGridXY<boolean>(
    gridWidth,
    gridHeight,
    false
  )

  const pathZero: IsPositionReachableInOneTurn = {
    totalCost: 0,
    start: unitPosition,
    end: unitPosition,
    steps: [],
  }
  setBest(unitPosition.x, unitPosition.y, pathZero)

  const queue: Array<IsPositionReachableInOneTurn> = [pathZero]

  let iterationsRemaining = 100 * gridWidth * gridHeight
  let previousPath: IsPositionReachableInOneTurn | undefined
  while ((previousPath = queue.shift())) {
    if (!(iterationsRemaining-- > 0)) {
      throw new Error('Max iterations exceeded')
    }

    // console.log('while(queue.shift) previousPath', previousPath)
    // const previousPath: Path = queue.shift()
    const {
      start: prevStart,
      end: prevEnd,
      steps: prevSteps,
      totalCost: prevTotalCost,
    } = previousPath
    for (const { dx, dy } of dijkstraDirections) {
      const nextX = prevEnd.x + dx
      const nextY = prevEnd.y + dy
      const inBounds = nextX >= 0 && nextY >= 0 && nextX < gridWidth && nextY < gridHeight
      // console.log({ nextX, nextY, inBounds })
      // console.log(
      //   'inBounds',
      //   `${nextX} >= 0 && ${nextY} >= 0 && ${nextX} < ${gridWidth} && ${nextY} < ${gridHeight}`
      // )
      if (inBounds) {
        const stepCost = costGrid(nextX, nextY)
        // console.log({ nextX, nextY, stepCost })
        // if stepCost is null, the next tile is impassible
        if (stepCost) {
          const nextTotalCost = prevTotalCost + stepCost
          const currentBestPath = getBest(nextX, nextY)
          // console.log({ nextTotalCost, currentBestTotalCost: currentBestPath?.totalCost })
          // make sure this newPath is actually good
          // before blowing out the gc
          const isNewBest = !(currentBestPath && nextTotalCost >= currentBestPath.totalCost)
          // console.log(
          //   JSON.stringify({
          //     isNewBest,
          //     hasCurrentBest: !!currentBestPath,
          //     'nextTotalCost >= currentBestPath.totalCost':
          //       nextTotalCost >= (currentBestPath?.totalCost || 0),
          //   })
          // )
          if (isNewBest) {
            const newStep = { x: nextX, y: nextY, cost: stepCost }
            // console.log('branch: prevLastTurn')
            const prevTurnCost = prevSteps.reduce((sum: number, step): number => sum + step.cost, 0)
            if (prevTurnCost < movePointsPerTurn) {
              setIsReachableInOneTurn(nextX, nextY, true)
            }

            if (prevSteps.length > maxSteps) {
              throw new Error(`maxSteps (${maxSteps}) exceeded`)
            }
            const newPath: IsPositionReachableInOneTurn = {
              totalCost: nextTotalCost,
              start: prevStart,
              end: { x: nextX, y: nextY },
              steps: [...prevSteps, newStep],
            }
            // console.log('newPath(setBest+queue.push)', JSON.stringify(newPath).replaceAll('"', ''))
            setBest(nextX, nextY, newPath)
            queue.push(newPath)
            // explore shorter paths first
            // for efficiency
            queue.sort(byTotalCostAsc)
          }
        } else {
          setIsReachableInOneTurn(nextX, nextY, false)
        }
      }
    }
  }

  return isReachableInOneTurn
}

