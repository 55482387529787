import { unwrap } from 'solid-js/store'
import { backendAxios } from '../../../axios'
import { Nullable } from '../../../typescript'
import createDefaultWait from '../../createDefaultWait'
import { deepClone } from '../../deep_clone'
import { Engine } from '../engine/Engine.type'
import { StateMapDisk } from './state_map_disk.enum'

let lastPromise: Nullable<Promise<void>> = null

async function saveStateChangesInner(engine: Engine) {
  // for whatever reason, resist spamming the server
  const minimumWaitBetweenCalls = createDefaultWait(2000)
  try {
    const state = unwrap(engine.state)
    const mapId = state.map?.id
    if (!mapId) {
      throw new Error('Unexpected !mapId')
    }

    if (state.map.disk === StateMapDisk.Cloud) {
      await backendAxios.post(`/st1/map/${mapId}`, { data: state })

      engine.prevState = deepClone(state)
      const msg = 'Map successfully saved.'
      if (engine.toast) {
        engine.toast.success(msg)
      } else {
        console.log(msg)
      }
      // dirty(EngineCache.State)
    } else {
      const err = new Error('unknown disk')
      if (engine.toast) {
        engine.toast.error(err.message)
      } else {
        throw err
      }
    }
  } catch (err) {
    console.error(err)
    if (engine.toast) {
      engine.toast.error(String(err))
    }
  } finally {
    await minimumWaitBetweenCalls
  }
}
export default async function saveStateChanges(engine: Engine) {
  if (JSON.stringify(engine.state) === JSON.stringify(engine.prevState)) {
    // console.log('no changes to save')
    return
  }
  await lastPromise
  lastPromise = saveStateChangesInner(engine)
  return lastPromise
}
