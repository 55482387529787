import { StateConfig } from './state_config'

export const WinMapControlPercentDefault = 90
export const WinMapControlPercentMin = 10
export const WinMapControlPercentMax = 95

export const MaxUnitsPerPlayerDefault = null
export const MaxUnitsPerPlayerMin = 5
export const MaxUnitsPerPlayerMax = 50

export const WinMaxDaysDefault = null
export const WinMaxDaysMin = 10
export const WinMaxDaysMax = 100

export const WinAnnexAnyHqDefault = 0

export default function createDefaultStateConfig(): StateConfig {
  return {
    fow: 0,
    incomeMult: 1,
    startIncome: 0,
    winMapControlPercent: WinMapControlPercentDefault,
    winAnnexAnyHq: WinAnnexAnyHqDefault,
    requireCompleteKills: 0,
    winMaxDays: WinMaxDaysDefault,
    maxUnitsPerPlayer: MaxUnitsPerPlayerDefault,
    restrictedUnits: [],
  }
}
