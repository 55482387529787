export interface HasY {
  y: number
}

export function byYDesc (a : HasY, b : HasY) {
  return b.y - a.y
}

export function byYAsc (a : HasY, b : HasY) {
  return a.y - b.y
}