import findByIdOrThrow from '../findByIdOrThrow'
import type { HasPlayerId } from '../player/has_player_id'
import entityTypeMetaList from './entityTypeMetaList.generated'
import type { Entity } from './index'

type PlayerEntity = Entity & HasPlayerId

export default function getPerTurnIncomeFromPlayerEnts(playerEnts: Array<PlayerEntity>): number {
  let credits = 0

  for (let index = playerEnts.length; index--; ) {
    const entity = playerEnts[index]
    const entityMeta = findByIdOrThrow(entityTypeMetaList, entity.etype_id)
    const { income } = entityMeta
    // give player money for owning properties
    if (income) {
      credits += income
    }
  }

  return credits
}
