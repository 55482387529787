import { Engine } from '../../../core/engine/Engine.type'
import { Entity } from '../../../core/entity'
import { LayerMeta } from '../../../core/layer_meta'
import { RenderSpriteFunction } from '../../../core/render_sprite'
import { nextIntRange } from '../../../core/rng'
import tmpRng from '../../../core/rng/tmpRng'
import { BASE_TILE_SIZE } from '../../../core/view_ctx'
import { createOffscreenCanvas } from '../../create_canvas'
import { EitherRenderingContext2D, getCanvas2dContext } from '../../get_canvas_2d_context'

const color1 = '#ced65b' // rgb(206, 214, 91)
// const color2 = '#c6ce5b' // rgb(198, 206, 91)
const color2r = 198
const color2g = 206
// const color2b = 91

const spriteSheetWidth = 8 * BASE_TILE_SIZE
const spriteSheetHeight = 8 * BASE_TILE_SIZE
const elem = createOffscreenCanvas(spriteSheetWidth, spriteSheetHeight)

const ctx = getCanvas2dContext(elem)
if (ctx) {
  tmpRng.state = 0
  ctx.fillStyle = color1
  ctx.fillRect(0, 0, elem.width, elem.height)

  const imgData = ctx.getImageData(0, 0, elem.width, elem.height)
  for (let i = 0, imgDataLen = imgData.data.length; i < imgDataLen; i += 4) {
    if (nextIntRange(0, 3, tmpRng) > 1) {
      imgData.data[i] = color2r // red
      // imgData.data[i] = color2b // red
    }
    if (nextIntRange(0, 3, tmpRng) > 1) {
      imgData.data[i + 1] = color2g // green
      // imgData.data[i+1] = color2b // green
    }
  }
  ctx.putImageData(imgData, 0, 0)
}

const sWidth = BASE_TILE_SIZE
const sHeight = BASE_TILE_SIZE

const minForRand = spriteSheetWidth - BASE_TILE_SIZE

let renderFnCache: RenderSpriteFunction | undefined

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export default function makeRenderPlain(engine: Engine, entity: Entity): RenderSpriteFunction {
  if (!renderFnCache) {
    renderFnCache = function renderPlain(
      ctx: EitherRenderingContext2D,
      lm: LayerMeta,
      entity: Entity,
      dx: number,
      dy: number,
      tile_size: number
    ) {
      tmpRng.state = entity.id
      const sx = nextIntRange(0, minForRand, tmpRng)
      const sy = nextIntRange(0, minForRand, tmpRng)
      const dWidth = tile_size
      const dHeight = tile_size
      ctx.drawImage(elem, sx, sy, sWidth, sHeight, dx, dy, dWidth, dHeight)
    }
  }
  return renderFnCache
}
