import { Scope, init } from '@sentry/browser'
import getAuthProfileValueFromLocalStorage from './rx/shared/profile/getAuthProfileValueFromLocalStorage'

if (import.meta.env.VITE_SENTRY_DSN) {
  init({
    dsn: import.meta.env.VITE_SENTRY_DSN,
    // integrations: [
    //   //
    // ],
    initialScope(scope: Scope) {
      const profile = getAuthProfileValueFromLocalStorage()
      scope.setUser({
        dname: profile?.dname,
        handle: profile?.handle,
        id: profile?.id,
      })
      return scope
    },
  })

  // console.log('captureException', captureException(new Error('test')))

  // setTimeout(() => {
  //   console.log('profile', getAuthProfileValueFromLocalStorage())
  // })
}

// @todo upload source-maps
// @link https://docs.sentry.io/platforms/javascript/guides/solid/#add-readable-stack-traces-to-errors
