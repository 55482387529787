import type { Nullable } from '../../typescript'
import type { BaseEntity } from './entity/BaseEntity'
import type { Entity } from './entity/index'
import type { HasHP } from './has_hp'
import type { HasPlayerId } from './player/has_player_id'
import type { PlayerIdOrNull } from './player/PlayerIdOrNull'

type AttackableEntity = Entity & HasHP & HasPlayerId

export default function findAttackableEntity<T extends Entity>(list: Nullable<Array<T>>, unitPlayerId: PlayerIdOrNull): Nullable<AttackableEntity> {
  if (unitPlayerId && list) {
    for (let index = 0; index < list.length; index++) {
      const item = list[index] as BaseEntity
      if (!item.taxi_id) {
        if (item.player_id !== unitPlayerId) {
          if ((item.hp as number) > 0) {
            return item as AttackableEntity
          }
        }
      }
    }
  }
}
