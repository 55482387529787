import type { RNGConfig } from '../rng/RNGConfig.type'
import type { State } from '../state/State.type'
import { randomId } from '../util/rand'
import nextMapEditorPlayer from './nextMapEditorPlayer'
import type { Player } from './Player.type'
import { PlayerGameStatus } from './PlayerGameStatus'
import { PlayerKind } from './PlayerKind.enum'
import { PlayerTurnStatus } from './PlayerTurnStatus'

export default function createMapEditorPlayer(state: State, rng: RNGConfig): Player {
  const id: number = randomId(state.players, rng)
  const cnp = nextMapEditorPlayer(state)
  const player: Player = {
    id,
    kind: PlayerKind.OpenSlot,
    money: 0,
    turn_status: PlayerTurnStatus.Waiting,
    dname: null,
    dname_placeholder: cnp.dname,
    color1: cnp.color1,
    color2: cnp.color2,
    game_status: PlayerGameStatus.Active,
  }
  return player
}
