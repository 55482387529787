import { nullable, number, object, optional, type InferOutput } from 'valibot'
import { FactoryEntitySchema } from '../../entity/EntityType/Factory'
import { OptionalNullableEntitySchema } from '../../entity/index'
import { PlayerIdOrNullSchema } from '../../player/PlayerIdOrNull'
import { OptionalNullableTilePositionXYListSchema } from '../../tile_position_xy/TilePositionXYList.type'
import { ActionVariantsSchema } from './actionSchemasList.generated'
import { ClientRefIDSchema } from './nextClientReferenceId'
// import Action from './action/Action.type'

export const ActionLogSchema = object({
  // client side reference ID
  // so the client knows if it already has applied this action
  round: number(),
  cref: ClientRefIDSchema,
  at: number(),
  // action: ActionUnionSchema,
  player_id: PlayerIdOrNullSchema,
  action: ActionVariantsSchema,
  unit0: OptionalNullableEntitySchema,
  unit1: OptionalNullableEntitySchema,
  // unitStack: OptionalNullableEntityListSchema,
  target0: OptionalNullableEntitySchema,
  target1: OptionalNullableEntitySchema,
  // targetStack: OptionalNullableEntityListSchema,
  destPositionSteps: OptionalNullableTilePositionXYListSchema,
  annexed0: OptionalNullableEntitySchema,
  annexed1: OptionalNullableEntitySchema,
  cargo0: OptionalNullableEntitySchema,
  cargo1: OptionalNullableEntitySchema,
  taxi0: OptionalNullableEntitySchema,
  taxi1: OptionalNullableEntitySchema,
  merge0: OptionalNullableEntitySchema,
  merge1: OptionalNullableEntitySchema,
  factory0: optional(nullable(FactoryEntitySchema)),
})

export type ActionLog = InferOutput<typeof ActionLogSchema>
