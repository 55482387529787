import { createStore } from 'solid-js/store'
import { Nullable } from '../../typescript'
import { type PostgrestError } from '@supabase/supabase-js'
import { ResourceReturn, createEffect } from 'solid-js'

export type AlertBagValue = Error | PostgrestError | string
export type AlertBag = {
  // error
  e: Nullable<AlertBagValue>
  // warning
  w: Nullable<AlertBagValue>
  // success
  s: Nullable<AlertBagValue>
  // info
  i: Nullable<AlertBagValue>
}

export function createAlertBagStore() {
  // eslint-disable-next-line solid/reactivity
  const store = createStore<AlertBag>({} as AlertBag)
  return store
}
export type AlertBagStore = ReturnType<typeof createAlertBagStore>

export function setAlertBagError(ab: AlertBagStore, value: Nullable<AlertBagValue>) {
  return ab[1]('e', value)
}

export function setAlertBagSuccess(ab: AlertBagStore, value: Nullable<AlertBagValue>) {
  return ab[1]('s', value)
}

export function setAlertBagWarning(ab: AlertBagStore, value: Nullable<AlertBagValue>) {
  return ab[1]('w', value)
}

export function setAlertBagInfo(ab: AlertBagStore, value: Nullable<AlertBagValue>) {
  return ab[1]('i', value)
}

export function registerAlertBagResource<T>(ab: AlertBagStore, resource: ResourceReturn<T>): void {
  createEffect(() => {
    const error = resource[0].error
    setAlertBagError(ab, error)
    if (error) {
      console.error(error)
    }
  })
}
