
/**
 * @ param strength 1 <= n <= 4
 */

import ConvolutionFilter from "./convolution_filter"

export default function GaussianBlur(srcImageData: ImageData, strength: number) {
  let size, matrix, divisor

  switch (strength) {
    case 2:
      size = 5
      matrix = [
        1, 1, 2, 1, 1,
        1, 2, 4, 2, 1,
        2, 4, 8, 4, 2,
        1, 2, 4, 2, 1,
        1, 1, 2, 1, 1
      ]
      divisor = 52
      break
    case 3:
      size = 7
      matrix = [
        1, 1, 2, 2, 2, 1, 1,
        1, 2, 2, 4, 2, 2, 1,
        2, 2, 4, 8, 4, 2, 2,
        2, 4, 8, 16, 8, 4, 2,
        2, 2, 4, 8, 4, 2, 2,
        1, 2, 2, 4, 2, 2, 1,
        1, 1, 2, 2, 2, 1, 1
      ]
      divisor = 140
      break
    case 4:
      size = 15
      matrix = [
        2, 2, 3, 4, 5, 5, 6, 6, 6, 5, 5, 4, 3, 2, 2,
        2, 3, 4, 5, 7, 7, 8, 8, 8, 7, 7, 5, 4, 3, 2,
        3, 4, 6, 7, 9, 10, 10, 11, 10, 10, 9, 7, 6, 4, 3,
        4, 5, 7, 9, 10, 12, 13, 13, 13, 12, 10, 9, 7, 5, 4,
        5, 7, 9, 11, 13, 14, 15, 16, 15, 14, 13, 11, 9, 7, 5,
        5, 7, 10, 12, 14, 16, 17, 18, 17, 16, 14, 12, 10, 7, 5,
        6, 8, 10, 13, 15, 17, 19, 19, 19, 17, 15, 13, 10, 8, 6,
        6, 8, 11, 13, 16, 18, 19, 20, 19, 18, 16, 13, 11, 8, 6,
        6, 8, 10, 13, 15, 17, 19, 19, 19, 17, 15, 13, 10, 8, 6,
        5, 7, 10, 12, 14, 16, 17, 18, 17, 16, 14, 12, 10, 7, 5,
        5, 7, 9, 11, 13, 14, 15, 16, 15, 14, 13, 11, 9, 7, 5,
        4, 5, 7, 9, 10, 12, 13, 13, 13, 12, 10, 9, 7, 5, 4,
        3, 4, 6, 7, 9, 10, 10, 11, 10, 10, 9, 7, 6, 4, 3,
        2, 3, 4, 5, 7, 7, 8, 8, 8, 7, 7, 5, 4, 3, 2,
        2, 2, 3, 4, 5, 5, 6, 6, 6, 5, 5, 4, 3, 2, 2
      ]
      divisor = 2044
      break
    default:
      size = 3
      matrix = [
        1, 2, 1,
        2, 4, 2,
        1, 2, 1
      ]
      divisor = 16
      break
  }
  return ConvolutionFilter(srcImageData, size, size, matrix, divisor, 0, false)
}