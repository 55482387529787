import { Entity } from '../../entity'
import { State } from '../../state/State.type'
import { randomId } from '../../util/rand'
import { SymmetryScriptOption } from './SymmetryScriptOption.type'

const MirrorLeftToRightOption: SymmetryScriptOption = {
  dname: 'Mirror Left To Right',
  run(prevState: State): State {
    const { width } = prevState
    const rng = { ...prevState.rng }
    const halfWidth = parseInt((width / 2) as unknown as string)
    const halfWidthStartsAt = width - halfWidth

    // remove all entities that will be copied over
    const ents = prevState.ents.filter((ent: Entity) => {
      if (ent.x >= halfWidthStartsAt) {
        return false
      }
      return true
    })
    ents.concat().forEach((ent: Entity) => {
      if (ent.x < halfWidth) {
        ents.push({
          ...ent,
          x: width - 1 - ent.x,
          id: randomId(ents, rng),
        })
      }
    })

    return {
      ...prevState,
      ents,
      rng,
    }
  },
}

export default MirrorLeftToRightOption
