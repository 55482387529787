import identity from '../../../ldsh/identity'
import { SymmetryScriptOption } from './SymmetryScriptOption.type'

// the user has the option to create a non-symmetric map,
// although that's bad for multiplayer games.
const NoneSymmetryScriptOption: SymmetryScriptOption = {
  dname: 'None',
  // the none option does nothing
  run: identity as SymmetryScriptOption['run'],
}

export default NoneSymmetryScriptOption
