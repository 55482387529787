import { FaSolidCentSign } from 'solid-icons/fa'
import { Component, type JSX } from 'solid-js'
import { createBuildingEntity } from '../../lib/core/entity/EntityType/Building'
import formatGameMoney from '../../lib/core/player/formatGameMoney'
import type { Player } from '../../lib/core/player/Player.type'
import SingleSpriteIcon from '../MapEditor/SingleSpriteIcon'

interface Props {
  player: Player
  value: number
  onClick?: JSX.EventHandlerUnion<HTMLDivElement, MouseEvent> | undefined
}

const PerTurnIncome: Component<Props> = (props) => {
  // const engine : Engine = useContext(EngineContext)
  return (
    <div class="d-flex align-items-end justify-content-between text-monospace" onClick={
      // eslint-disable-next-line solid/reactivity
      props.onClick
    }>
      <div>
        <SingleSpriteIcon
          entity={createBuildingEntity(1, 0, 0, props.player.id)}
          noBg
        />
        <span class="text-muted ps-1"><FaSolidCentSign /></span>
        {formatGameMoney(props.value)}
        <span class="text-muted small">/turn</span>
      </div>
      <div>
        💰
        <span class="text-muted ps-1"><FaSolidCentSign /></span>
        {formatGameMoney(props.player.money)}
      </div>
    </div>
  )
}

export default PerTurnIncome
