import { literal, object, parse, type InferOutput } from 'valibot'
import { deepClone } from '../../../../../deep_clone'
import NotYourTurn from '../../../../../Exception/NotYourTurn.class'
import isNil from '../../../../../ldsh/isNil'
import { Engine } from '../../../../engine/Engine.type'
import getFactoryBuildUnitsStatus, {
  FactoryBuildUnitsStatus,
} from '../../../../entity/canFactoryBuildUnits'
import createFactoryUnitEntity from '../../../../entity/createFactoryUnitEntity'
import { EntityTypeIdSchema, type EntityTypeId } from '../../../../entity/entity_type_id.enum'
import { EntityIdSchema } from '../../../../entity/EntityId.type'
import { FactoryEntitySchema, type FactoryEntity } from '../../../../entity/EntityType/Factory'
import entityTypeMetaList from '../../../../entity/entityTypeMetaList.generated'
import findEntityById from '../../../../entity/findEntityById'
import getEntitiesAtPosition from '../../../../entity/getEntitiesAtPosition'
import type { Entity } from '../../../../entity/index'
import nextEntityIdFromEngine from '../../../../entity/next_entity_id_from_engine'
import type { EntityTypeMeta } from '../../../../EntityTypeMeta'
import findByIdOrThrow from '../../../../findByIdOrThrow'
import type { HasPrice } from '../../../../HasPrice'
import { hasWasBuiltThisTurn } from '../../../../hasWasBuiltThisTurn'
import { PlayerTurnStatus } from '../../../../player/PlayerTurnStatus'
import { samePosition } from '../../../../tile_position_xy/samePosition'
import type { ActionLog } from '../../ActionLog.type'
import { ActionType } from '../ActionType'

export const PlaceFactoryOrderActionSchema = object({
  type: literal(ActionType.Game.PlaceFactoryOrder),
  factory_id: EntityIdSchema,
  etype_id: EntityTypeIdSchema,
})

export type PlaceFactoryOrderAction = InferOutput<typeof PlaceFactoryOrderActionSchema>

export function createPlaceFactoryOrderAction(
  factoryEntity: FactoryEntity,
  etype_id: EntityTypeId
): PlaceFactoryOrderAction {
  return {
    type: ActionType.Game.PlaceFactoryOrder,
    factory_id: factoryEntity.id,
    etype_id,
  }
}

export async function handlePlaceFactoryOrderAction(
  engine: Engine,
  action: PlaceFactoryOrderAction,
  actionLog: ActionLog
): Promise<void> {
  const { factory_id, etype_id } = action
  const { state } = engine
  const { ents, players, turnPlayerId } = state

  const factoryEntity = parse(
    FactoryEntitySchema,
    findEntityById(ents, factory_id)
  )

  const entsAtPosition = getEntitiesAtPosition(ents, factoryEntity)

  const { player_id } = factoryEntity
  const player = findByIdOrThrow(players, player_id)
  if (player.turn_status !== PlayerTurnStatus.Playing) {
    throw new NotYourTurn
  }

  actionLog.factory0 = deepClone(factoryEntity)

  const entityTypeMeta = findByIdOrThrow(entityTypeMetaList, etype_id)
  const price = entityTypeMeta.price
  // rule out undefined
  if (isNil(price)) {
    throw new Error('Not For Sale')
  }
  let budget = player.money
  // console.log('budget', budget)
  // console.log('price', price)

  const createdEntity = createFactoryUnitEntity(
    factoryEntity,
    entityTypeMeta as EntityTypeMeta & HasPrice,
    nextEntityIdFromEngine(state)
  )

  if (createdEntity.player_id !== turnPlayerId) {
    throw new Error('Entity Not In Turn')
  }

  if (factoryEntity.player_id !== turnPlayerId) {
    throw new Error('Factory Not In Turn')
  }
  const factoryStatus = getFactoryBuildUnitsStatus(factoryEntity, entsAtPosition)
  if (factoryStatus === FactoryBuildUnitsStatus.OrderPlaced) {
    // replace current order
    const oldOrderEntity = entsAtPosition.find(hasWasBuiltThisTurn)
    if (!oldOrderEntity) {
      throw new Error('Unexpected !oldOrderEntity')
    }
    const oldEntityTypeMeta = findByIdOrThrow(entityTypeMetaList, oldOrderEntity.etype_id)
    const refundAmount = oldEntityTypeMeta.price
    // rule out undefined
    if (isNil(refundAmount)) {
      throw new Error('No Refunds')
    }

    // refund
    budget += refundAmount
    // console.log('refundAmount', refundAmount, 'budget', budget)

    if (!(budget >= price)) {
      throw new Error('Not Enough Money')
    }
    // console.log('budget', budget, 'price', price)
    budget -= price

    // place new order
    state.ents = [
      ...ents.filter((ent: Entity) => {
        return !(hasWasBuiltThisTurn(ent) && samePosition(ent, factoryEntity))
      }),
      createdEntity,
    ]
    actionLog.unit0 = deepClone(createdEntity)
    player.money = budget
  } else if (factoryStatus === FactoryBuildUnitsStatus.NotFactory) {
    throw new Error('Not Factory')
  } else if (factoryStatus === FactoryBuildUnitsStatus.Blocked) {
    throw new Error('Factory Blocked')
  } else if (factoryStatus === FactoryBuildUnitsStatus.WaitingForOrder) {
    if (!(budget >= price)) {
      throw new Error('Not Enough Money')
    }
    // console.log('budget', budget, 'price', price)
    budget -= price

    // place new order
    ents.push(createdEntity)
    actionLog.unit0 = deepClone(createdEntity)
    player.money = budget
  } else {
    throw new Error('unknown scenario')
  }
}
