import { InferOutput, literal, object } from 'valibot'
import { EntityTypeMeta, entityTypeDefaults } from '../../EntityTypeMeta'
import { HasXYSchema } from '../../has_xy'
import { EntityLayerId } from '../entity_layer_id.enum'
import { EntityTypeId } from '../entity_type_id.enum'
import { EntityIdSchema } from '../EntityId.type'
import { BaseEntitySchema } from '../BaseEntity'

const entDefaults = {
  etype_id: EntityTypeId.Forest,
  layer_id: EntityLayerId.TerrainL2,
} as const

export const ForestEntitySchema = object({
  ...BaseEntitySchema.entries,
  etype_id: literal(EntityTypeId.Forest),
  layer_id: literal(EntityLayerId.TerrainL2),
  id: EntityIdSchema,
  ...HasXYSchema.entries,
})

export type ForestEntity = InferOutput<typeof ForestEntitySchema>

export const ForestEntityTypeMeta: EntityTypeMeta = {
  ...entityTypeDefaults,
  id: EntityTypeId.Forest,
  dname: 'Forest',
  layer_id: EntityLayerId.TerrainL2,
  defense: 2,
  blocksVision: true,
  entDefaults,
}

export function createForestEntity(id: number, x: number, y: number): ForestEntity {
  return {
    ...entDefaults,
    id,
    x,
    y,
  }
}
