import { fallback, InferOutput, literal, object, optional, void as voidType } from 'valibot'
import { ArmorSchema } from '../../Armor.type'
import { entityTypeDefaults, EntityTypeMeta } from '../../EntityTypeMeta'
import { FuelSchema } from '../../fuel.type'
import { HasMobilitySchema } from '../../has_mobility'
import { HasXYSchema } from '../../has_xy'
import { HPSchema } from '../../hp.type'
import { PlayerIdOrNullSchema } from '../../player/PlayerIdOrNull'
import { VisionSchema } from '../../vision.type'
import { ArmorTypeId } from '../Armor/ArmorTypeId.enum'
import { BaseEntitySchema } from '../BaseEntity'
import { EntityLayerId } from '../entity_layer_id.enum'
import { EntityTypeId } from '../entity_type_id.enum'
import { MoveTypeId } from '../move_type_id.enum'
import { EntityIdListSchema } from '../EntityIdList.type'

const entDefaults = {
  etype_id: EntityTypeId.APC,
  layer_id: EntityLayerId.Unit2,
  hp: 10,
  fuel: 60,
  // Tire A
  vision: 1,
  // road bonus
  mtype_id: MoveTypeId.Treads,
  mobility: 6,
  armor: 10,
} as const

export const APCEntitySchema = object({
  ...BaseEntitySchema.entries,
  etype_id: literal(EntityTypeId.APC),
  layer_id: literal(EntityLayerId.Unit2),
  player_id: PlayerIdOrNullSchema,
  hp: HPSchema,
  fuel: FuelSchema,
  armor: ArmorSchema,
  vision: VisionSchema,
  ...HasXYSchema.entries,
  ...HasMobilitySchema.entries,
  weapons: optional(voidType()), // undefined?
  attackedThisTurn: optional(voidType()), // undefined?
  // weapons: optional(voidType()), // undefined?
  cargo: fallback(EntityIdListSchema, () => [])
})

// export const APCEntitySchema = object({
//   etype_id: literal(EntityTypeId.APC),
//   layer_id: literal(EntityLayerId.Unit2),
//   id: EntityIdSchema,
//   player_id: PlayerIdOrNullSchema,
//   hp: HPSchema,
//   fuel: FuelSchema,
//   armor: ArmorSchema,
//   vision: VisionSchema,
//   ...HasXYSchema.entries,
//   ...HasMobilitySchema.entries,
//   builtThisTurn: WasBuiltThisTurnSchema,
//   // attackedThisTurn: AttackedThisTurnSchema,
// })

export type APCEntity = InferOutput<typeof APCEntitySchema>

export const APCEntityTypeMeta: EntityTypeMeta = {
  ...entityTypeDefaults,
  id: EntityTypeId.APC,
  dname: 'APC',
  layer_id: EntityLayerId.Unit2,
  price: 50,
  transports: [EntityTypeId.Infantry],
  resupplies: true,
  entDefaults,
  armor_type: ArmorTypeId.Tank,
  cargoLimit: 1,
}

export function createAPCEntity(
  id: number,
  x: number,
  y: number,
  player_id: number | null
): APCEntity {
  return {
    ...entDefaults,
    etype_id: EntityTypeId.APC,
    id,
    x,
    y,
    player_id,
    cargo: [],
  }
}
