import noop from '../../../ldsh/noop'
import type { DraftMove } from '../../draft_move/DraftMove.type'
import findByXY from '../../findByXY'
import createLazyReadOnlyGridXY from '../createLazyReadOnlyGridXY'

type XYToBool = (x: number, y: number) => boolean

export default function createIsPositionInDraftMoveRangedAttackRange(
  draftMove: DraftMove,
  gridWidth: number,
  gridHeight: number
): (x: number, y: number) => boolean {

  const { rangedEstimates } = draftMove
  if (!rangedEstimates) {
    return noop as unknown as XYToBool
  }
  const isPositionInRangedAttackRange = createLazyReadOnlyGridXY(gridWidth, gridHeight, (x, y) => {
    if (findByXY(rangedEstimates, x, y)) {
      return true
    }
    return false
  })
  return isPositionInRangedAttackRange
}

