import { Component, JSX, createEffect, useContext } from "solid-js"
import { unwrap } from "solid-js/store"
import bindRenderFunctionToEngine from "../../lib/canvas/bind_render_function_to_engine"
import { createCanvasElement } from "../../lib/canvas/create_canvas"
import { disableCanvasSmoothing } from "../../lib/canvas/disable_canvas_smoothing"
import defaultErrorRender from "../../lib/canvas/draw/default_render_error"
import { getCanvas2dContext } from "../../lib/canvas/get_canvas_2d_context"
import createLayerMeta from "../../lib/core/create_layer_meta"
import { Engine } from "../../lib/core/engine/Engine.type"
import { Entity } from "../../lib/core/entity/index"
import { BLACK_HEX, WHITE_HEX } from "../../lib/core/player/player_color"
import { MakeRenderSpriteFunction, RenderSpriteFunction, renderSpriteByIdMap } from "../../lib/core/render_sprite"
import { byLayerIdAsc } from "../../lib/core/util/id"
import { BASE_TILE_SIZE } from "../../lib/core/view_ctx"
import setStyleAttributes from "../../lib/dom/setStyleAttributes"
import type { Nullable } from "../../typescript"
import EngineContext from "../EngineContext"

interface Props {
  ents: Array<Entity>,
  tileSize?: number,
  style?: JSX.CSSProperties,
  noBg?: boolean,
}

const default_d_tile_size = BASE_TILE_SIZE / 2

const SpriteStackIcon: Component<Props> = (props: Props) => {
  // console.log('SpriteStackIcon', props)
  const engine : Engine = useContext(EngineContext)
  const lm = createLayerMeta()
  const canvas = createCanvasElement()
  canvas.classList.add('sprite-icon')
  // used by render function, no need to be in signal
  let d_tile_size = default_d_tile_size

  let ents : Nullable<Array<Entity>> = null
  createEffect(() => {
    ents = unwrap(props.ents).sort(byLayerIdAsc)
    // entity = props.entity
  })
  createEffect(() => {
    d_tile_size = props.tileSize || default_d_tile_size
    canvas.width = d_tile_size
    canvas.height = d_tile_size * 2
    canvas.style.marginTop = `-${d_tile_size}px`
    setStyleAttributes(canvas, props.style)
  })

  function render(timeStamp: number): void {
    // console.log('SpriteStackIcon.render', timeStamp)
    // console.log('SpriteStackIcon.d_tile_size', d_tile_size)
    const ctx = getCanvas2dContext(canvas)

    lm.ts = timeStamp

    // frames have indicated they
    // plan on rendering the same image
    if (lm.until > timeStamp) {
      return
    }

    // this line causes the image not to loop
    // lm.until = timeStamp + EXPIRE_NEVER

    if (ctx) {
      ctx.clearRect(0, 0, canvas.width, canvas.height)
      disableCanvasSmoothing(ctx)

      if (!props.noBg) {
        ctx.globalAlpha = 1
        ctx.fillStyle = WHITE_HEX
        ctx.strokeRect(0, d_tile_size, canvas.width, d_tile_size)

        ctx.globalAlpha = 0.25
        ctx.strokeStyle = BLACK_HEX
        ctx.fillRect(0, d_tile_size, d_tile_size, d_tile_size)
        ctx.globalAlpha = 1
      }

      if (ents) {
        for (let index = 0; index < ents.length; index++) {
          const ent = ents[index];
          if (ent) {
            let renderSprite: RenderSpriteFunction = defaultErrorRender
            const makeRenderSprite: MakeRenderSpriteFunction = renderSpriteByIdMap[ent.etype_id]
            if (makeRenderSprite) {
              renderSprite = makeRenderSprite(engine, { ...ent })
            }
    
            renderSprite(ctx, lm, ent, 0, d_tile_size, d_tile_size)
          }
        }
      }
    }
  }

  // eslint-disable-next-line solid/reactivity
  bindRenderFunctionToEngine(engine, render)

  return canvas
}

export default SpriteStackIcon
