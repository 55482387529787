import { createEffect, on } from "solid-js"
import type { Engine } from "../../../core/engine/Engine.type"
import type { LayerMeta } from "../../../core/layer_meta"
import { floor } from "../../../core/util/math"
import { ClassicPlus_viewBox_d, ClassicPlus_viewBox_h, ClassicPlus_viewBox_w } from "../../../svg/sprites_data_manual"
import { getCanvas2dContext } from "../../get_canvas_2d_context"

export default function createRenderClassicPlusIconCanvasBuffer (engine : Engine, lm: LayerMeta, buffer: OffscreenCanvas) {
  createEffect(on(() => engine.viewCtx.tile_size, (tile_size: number) => {
    buffer.width = tile_size
    buffer.height = tile_size
    lm.until = 0

    const ctx = getCanvas2dContext(buffer)
    if (ctx) {
      ctx.clearRect(0, 0, tile_size, tile_size)

      const dWidth = floor(tile_size / 2.1)
      const dHeight = dWidth
      const flagBoxX = 1
      const flagBoxY = floor(tile_size / 2)
      const flagBoxSize = floor(tile_size / 2.1)
      const flagSize = floor((flagBoxSize * 3) / 5)

      ctx.clearRect(0, 0, tile_size, tile_size)
      ctx.fillStyle = '#999'
      ctx.fillRect(flagBoxX, flagBoxY, flagBoxSize, flagBoxSize)
      ctx.lineWidth = 2
      ctx.strokeStyle = '#444'
      ctx.strokeRect(flagBoxX, flagBoxY, dWidth, dHeight)
      ctx.translate(
        flagBoxX + (flagBoxSize - flagSize) / 2,
        flagBoxY + (flagBoxSize - flagSize) / 2
      )
      ctx.scale(flagSize / ClassicPlus_viewBox_w, flagSize / ClassicPlus_viewBox_h)
  
      ctx.filter = `drop-shadow(1px 1px #bbb)`
      ctx.fillStyle = '#fff'
      ctx.fill(ClassicPlus_viewBox_d)
      ctx.filter = 'none'
  
      ctx.fillStyle = '#444'
      ctx.fill(ClassicPlus_viewBox_d)
    }
  }))
}