import type { Nullable } from '../../typescript'
import type { HasXY } from './has_xy'
import type { TilePositionXY } from './tile_position_xy/TilePositionXY.type'

export default function findByPosition<T extends HasXY>(list: Array<T>, position: Nullable<TilePositionXY>): T | null {
  if (position) {
    for (let index = 0; index < list.length; index++) {
      const item = list[index]
      if (item.x === position.x && item.y === position.y) {
        return item
      }
    }
  }
  return null
}
