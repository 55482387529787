import { enum_ } from 'valibot'

export enum PlayerGameStatus {
  // The player is actively participating in or has not yet completed the game.
  Active = 1,

  Forfeited = 20,

  // The player has resigned from the game.
  Resigned = 21,

  // The player has lost the game by standard means.
  Lost = 22,

  // The player has won the game.
  Won = 40,

  // The game has ended in a draw for this player.
  Draw = 41,
}

export const PlayerGameStatusSchema = enum_(PlayerGameStatus, 'Invalid Player Game Status');
