import type { Accessor } from 'solid-js'
import {
  getDotPath,
  type BaseIssue,
  type BaseSchema,
  type BaseSchemaAsync,
  type SafeParseResult,
} from 'valibot'

export default function createIsDotKeyInvalidDerivedSignal(
  formDataValidation: Accessor<
    SafeParseResult<
      | BaseSchema<unknown, unknown, BaseIssue<unknown>>
      | BaseSchemaAsync<unknown, unknown, BaseIssue<unknown>>
    >
  >
) {
  return function isDotPathInvalid(dotPath: string): boolean {
    return formDataValidation().issues?.find(
      (issue) => getDotPath(issue) === dotPath
    ) as unknown as boolean
  }
}
