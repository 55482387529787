export type EncodeOption = 'deflate' | 'deflate-raw' | 'gzip'

// type CompressionStream = any
// type DecompressionStream = any

// export async function compressJson(json : any, encoding : EncodeOption = 'gzip') {
//   return compress(JSON.stringify(json), encoding)
// }

// export async function decompressJson(byteArray : ArrayBuffer, encoding : EncodeOption = 'gzip') {
//   console.log('decompressJson.byteArray', byteArray)
//   const plaintext =  await decompress(byteArray, encoding)
//   console.log('decompressJson.plaintext', plaintext)
//   return JSON.parse(plaintext)
// }

export function compress(plaintext: string, encoding: EncodeOption = 'gzip') {
  const byteArray = new TextEncoder().encode(plaintext)
  const cs = new CompressionStream(encoding)
  const writer = cs.writable.getWriter()
  writer.write(byteArray)
  writer.close()
  return new Response(cs.readable).arrayBuffer()
}

export function decompress(byteArray: ArrayBuffer, encoding: EncodeOption = 'gzip') {
  const cs = new DecompressionStream(encoding)
  const writer = cs.writable.getWriter()
  writer.write(byteArray)
  writer.close()
  return new Response(cs.readable).arrayBuffer().then(function (arrayBuffer) {
    return new TextDecoder().decode(arrayBuffer)
  })
}

// async function testCompression(plaintext : string, encoding : EncodeOption = 'deflate') {
//   console.log(encoding + ':');
//   console.time('compress');
//   const compressedData = await compress(plaintext, encoding);
//   console.timeEnd('compress');
//   console.log('compressed length:', compressedData.byteLength, 'bytes');
//   console.time('decompress');
//   const decompressedText = await decompress(compressedData, encoding);
//   console.timeEnd('decompress');
//   console.log('decompressed length:', decompressedText.length, 'characters');
//   console.assert(plaintext === decompressedText);
// }

// async function testCompressJson(plaintext : string, encoding : EncodeOption = 'deflate') {
//   console.log(encoding + ':');
//   console.time('compressJson');
//   const compressedData = await compressJson(plaintext, encoding);
//   console.timeEnd('compressJson');
//   console.log('compressed length:', compressedData.byteLength, 'bytes');
//   console.time('decompressJson');
//   const decompressedText = await decompressJson(compressedData, encoding);
//   console.timeEnd('decompressJson');
//   console.log('decompressed length:', JSON.stringify(decompressedText).length, 'characters');
//   console.log(plaintext, decompressedText);
//   console.log(JSON.stringify(plaintext), JSON.stringify(decompressedText));
//   console.assert(JSON.stringify(plaintext) === JSON.stringify(decompressedText));
// }

// (async function () {
//   const test = "http://www.ScriptCompress.com - Simple Packer/Minify/Compress JavaScript Minify, Fixify & Prettify 75 JS Obfuscators In 1 App 25 JS Compressors (Gzip, Bzip, LZMA, etc) PHP, HTML & JS Packers In 1 App PHP Source Code Packers Text Packer HTML Packer or v2 or v3 or LZW Twitter Compress or More Words DNA & Base64 Packer (freq tool) or v2 JS JavaScript Code Golfer Encode Between Quotes Decode Almost Anything Password Protect Scripts HTML Minifier v2 or Encoder or Escaper CSS Minifier or Compressor v2 SVG Image Shrinker HTML To: SVG or SVGZ (Gzipped) HTML To: PNG or v2 2015 JS Packer v2 v3 Embedded File Generator Extreme Packer or version 2 Our Blog DemoScene JS Packer Basic JS Packer or New Version Asciify JavaScript Escape JavaScript Characters UnPacker Packed JS JavaScript Minify/Uglify Text Splitter/Chunker Twitter, Use More Characters Base64 Drag 'n Drop Redirect URL DataURI Get Words Repeated LZMA Archiver ZIP Read/Extract/Make BEAUTIFIER & CODE FIXER WHAK-A-SCRIPT JAVASCRIPT MANGLER 30 STRING ENCODERS CONVERTERS, ENCRYPTION & ENCODERS 43 Byte 1px GIF Generator Steganography PNG Generator WEB APPS VIA DATAURL OLD VERSION OF WHAK PAKr Fun Text Encrypt Our Google";
//   await testCompression(test, 'deflate');
//   await testCompression(test, 'gzip');

//   const obj = {
//     dname: 'cool object',
//     values: [1, 2, 2, 2, 2, 4, 4, 2, 2, 2, 2, 5, 6, 6, 2, 2, 2],
//     values2: [1, 2, 2, 2, 2, 4, 4, 2, 2, 2, 2, 5, 6, 6, 2, 2, 2],
//     values3: [1, 2, 2, 2, 2, 4, 4, 2, 2, 2, 2, 5, 6, 6, 2, 2, 2],
//     eggs: {
//       cool: true,
//     }
//   };

//   await testCompressJson(obj, 'deflate');
//   await testCompressJson(obj, 'gzip');
// }());
