import { Entity } from '.'
import type { HasAttackedThisTurn } from '../AttackedThisTurn'
import type { HasWaitedThisTurn } from '../WaitedThisTurn'
import type { HasWasBuiltThisTurn } from '../WasBuiltThisTurn'
import isEntityOutOfMoves from './isEntityOutOfMoves'

export default function shouldEntityShowFadedFrames(entity: Entity): boolean {
  if ((entity as HasWaitedThisTurn).waitedThisTurn) {
    return true
  }
  if ((entity as HasWasBuiltThisTurn).builtThisTurn) {
    return true
  }
  if ((entity as HasAttackedThisTurn).attackedThisTurn) {
    return true
  }
  if (isEntityOutOfMoves(entity)) {
    return true
  }
  return false
}
