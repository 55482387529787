import { HasID } from '../../has_id'

export default function idsAreUnique(input: Array<HasID>): boolean {
  // console.log('idsAreUnique', input)
  const len = (input as Array<HasID>)?.length
  const set = new Set<number>()
  for (let index = 0; index < len; index++) {
    const value = (input as Array<HasID>)[index]?.id
    if (set.has(value)) {
      // console.log(
      //   'idsAreUniq',
      //   'value',
      //   value,
      //   'Duplicate Items',
      //   (input as Array<HasID>).filter((e) => e.id === value)
      // )
      return false
    }
    if (!(value > 0)) {
      return false
    }
    set.add(value)
  }
  return true
}
