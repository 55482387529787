// @link https://validatejs.org/#validate-single

import { Nullable } from "../typescript"

export const SLUG_MAX_LEN = 20
export const SLUG_MIN_LEN = 3

export function isProbablySlug(input: Nullable<string>): input is string {
  if (typeof input !== 'string') return false
  const len = input.length
  if (len < SLUG_MIN_LEN) return false
  if (SLUG_MAX_LEN < len) return false
  return true
}

export function toSlug(str?: string | null) {
  if (str) {
    return str.toLowerCase()
      .replace(/[^a-zA-Z0-9]+/g, '-') // Replace non-alphanumeric characters with hyphens
      .replace(/^-+|-+$/g, '') // Remove leading and trailing hyphens
  }
}