import type { Nullable } from "../../../../../typescript"
import type { Engine } from "../../../engine/Engine.type"
import type { AnnexableEntityUnion } from "../../../entity/AnnexableEntityUnion.type"
import { isEntityHQ } from "../../../entity/entityTypeMetaList.generated"
import findByIdOrThrow from "../../../findByIdOrThrow"
import { PlayerGameStatus } from "../../../player/PlayerGameStatus"
import type { PlayerId } from "../../../player/PlayerId"

export default function checkLostHqWinCondition(
  engine: Engine,
  losingPlayerId: Nullable<PlayerId>,
  annexedEnt: AnnexableEntityUnion
) {
  if (!losingPlayerId) {
    return
  }
  if (isEntityHQ(annexedEnt)) {
    const losingPlayer = findByIdOrThrow(engine.state.players, losingPlayerId)
    const { state } = engine
    const { config, ents } = state
    const { winAnnexAnyHq } = config

    if (winAnnexAnyHq) {
      // losing any HQ is enough to declare losingPlayerId as lost
      // we can declare losingPlayerId as lost now
      losingPlayer.game_status = PlayerGameStatus.Lost
    } else {
      // losingPlayer must lose ALL of their HQs to be declared as lost
      const hqEnts = ents.filter(ent => isEntityHQ(ent) && ent.player_id === losingPlayerId)
      if (hqEnts.length === 0) {
        // we can declare losingPlayerId as lost now
        losingPlayer.game_status = PlayerGameStatus.Lost
      }
    }
  }
}