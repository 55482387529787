import { onCleanup, onMount } from 'solid-js'
import removeItem from '../array/remove_item'
import { Engine } from '../core/engine/Engine.type'

export default function bindCanvasToEngine(
  engine: Engine,
  elem: HTMLCanvasElement,
  render: FrameRequestCallback
) {
  engine.canvasList.push(elem)
  onMount(() => {
    engine.renderFns.push(render)
  })
  onCleanup(() => {
    if (engine) {
      removeItem(engine.canvasList, elem)
      removeItem(engine.renderFns, render)
    }
  })
}
