import { backendAxios } from "../../../axios"
import { VITE_ALLOWS_GUEST_ACCESS_TOKEN } from "./auth_session_key"
import { updateSessionValue } from "./sessionValue"

export default async function fetchGuestAccessToken() {
  // console.log('fetchGuestAccessToken')
  if (VITE_ALLOWS_GUEST_ACCESS_TOKEN) {
    await new Promise(resolve => setTimeout(resolve, 10000))
    const response = await backendAxios.post('/auth/guest')
    // console.log('fetchGuestAccessToken', response.data)
    updateSessionValue(response.data)
    // TODO update JWT
    // throw new Error('Not Implemented')
  } else {
    throw new Error('Not Implemented (redirect to login)')
  }
}
