import { Component, createEffect, createMemo, createSignal, Match, Switch, useContext } from 'solid-js'
import type { Engine } from '../../lib/core/engine/Engine.type'
import findByPosition from '../../lib/core/findByPosition'
import { samePosition } from '../../lib/core/tile_position_xy/samePosition'
import EngineContext from '../EngineContext'
import AttackPositionCornerWidget from './AttackPositionCornerWidget'
import PositionStackStats from './PositionStackStats'

const TopLeftEngineToolCornerWidget: Component = () => {
  const engine: Engine = useContext(EngineContext)
  const [isExpanded, setIsExpanded] = createSignal<boolean>(false)
  let prevSelectedPosition: Engine['selectedPosition'] = null
  createEffect(() => {
    const nextSelectedPosition = engine.selectedPosition
    if (nextSelectedPosition && !samePosition(nextSelectedPosition, prevSelectedPosition)) {
      setIsExpanded(false)
    }
    prevSelectedPosition = nextSelectedPosition
  })
  // createEffect(() => {
  //   console.log('engine.selectedPosition', deepClone(engine.selectedPosition))
  //   console.log('engine.selectedPositionEnts', deepClone(engine.selectedPositionEnts))
  // })
  // createEffect(() => {
  //   console.log('engine.draftMove?.attackEstimates', deepClone(engine.draftMove?.attackEstimates))
  // })

  const hasAnyEstimates = createMemo(() => {
    const { draftMove, hoveredPosition } = engine
    if (draftMove) {
      const { rangedEstimates, attackEstimates } = draftMove
      if (hoveredPosition && rangedEstimates?.length) {
        return findByPosition(rangedEstimates, hoveredPosition)?.estimate
      }
      // if (selectedTool === SelectedToolId.RangedAttackRadius) {
      //   return rangedEstimates?.length
      // }
      return attackEstimates.length
    }
  })

  return (
    <Switch>
      <Match when={hasAnyEstimates()}>
        <AttackPositionCornerWidget />
      </Match>
      <Match when={engine.selectedPosition}>
        <div
          class="cw cw-sm-br cw-sp float-end"
          classList={{ 'expanded': isExpanded() }}
          onClick={() => setIsExpanded(!isExpanded())}
        >
          <PositionStackStats ents={engine.selectedPositionEnts} x={isExpanded()} />
        </div>
      </Match>
    </Switch>
  )
}

export default TopLeftEngineToolCornerWidget
