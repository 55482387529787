import type { Weapon } from './Weapon.type'
import { WeaponTypeId } from './WeaponTypeId.enum'

export default function createVehicleMachineGunWeapon(str: number): Weapon {
  return {
    wt_id: WeaponTypeId.VehicleMachineGun,
    str,
    ca: true,
  }
}
