import { InferOutput } from 'valibot'
import { OptionalLiteralTrueSchema } from './LiteralTrue'

export const WaitedThisTurnSchema = OptionalLiteralTrueSchema

export type WaitedThisTurn = InferOutput<typeof WaitedThisTurnSchema>

export type HasWaitedThisTurn = {
  waitedThisTurn?: WaitedThisTurn
}
