import { BackendSessionPayloadSchema, type BackendSessionPayload } from '@sg/backend/src/api/auth/BackendAuthSession.type'
import { nullable, safeParse } from 'valibot'
import { getJsonOrNullFromLocalStorage } from '../../../lib/localStorage'
import { Nullable } from '../../../typescript'
import VITE_BACKEND_JWT_LS_KEY from './auth_session_key'

const NullableSessionSchema = nullable(BackendSessionPayloadSchema)

export default function getBackendSessionPayloadFromLocalStorage(): Nullable<BackendSessionPayload> {
  const result = safeParse(NullableSessionSchema, getJsonOrNullFromLocalStorage(VITE_BACKEND_JWT_LS_KEY))
  if (result.success) {
    return result.output
  }
}
