import { OptionalNullableDisplayNameSchema } from '@sg/shared/src/valibot/schema/DisplayName.type'
import { InferOutput, boolean, fallback, nullable, object, optional, string } from 'valibot'
import { OptionalNullableProfileIDSchema } from '../ProfileId.type'
import { PlayerGameStatus, PlayerGameStatusSchema } from './PlayerGameStatus'
import { PlayerIdSchema } from './PlayerId'
import { PlayerKindSchema } from './PlayerKind.enum'
import { PlayerMoneySchema } from './PlayerMoney.type'
import { PlayerTurnStatus, PlayerTurnStatusSchema } from './PlayerTurnStatus'

export const PlayerSchema = object({
  id: PlayerIdSchema,
  profile_id: OptionalNullableProfileIDSchema,
  invite_id: optional(nullable(string())),
  bot_id: optional(nullable(string())),
  is_local: optional(nullable(boolean())),
  kind: PlayerKindSchema,
  dname: OptionalNullableDisplayNameSchema,
  dname_placeholder: OptionalNullableDisplayNameSchema,
  color1: string(),
  color2: string(),
  money: fallback(PlayerMoneySchema, 0),
  turn_status: fallback(PlayerTurnStatusSchema, PlayerTurnStatus.Waiting),
  game_status: fallback(PlayerGameStatusSchema, PlayerGameStatus.Active),
})

export type Player = InferOutput<typeof PlayerSchema>
