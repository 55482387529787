import type { Entity } from '../entity/index'
import type { EntityGridXY } from './EntityGridXY.type'
import createLazyReadOnlyGridXY from './createLazyReadOnlyGridXY'
import findMostEfficientPathToTileV2 from './findMostEfficientPathToTileV2/findMostEfficientPathToTileV2'

export type LazyEfficientPathGrid_Result = ReturnType<typeof createLazyEfficientPathGrid>

export default function createLazyEfficientPathGrid(
  // full state width
  gridWidth: number,
  // full state height
  gridHeight: number,
  // full state ents as GridXY
  grid: EntityGridXY,
  // unit attempting to move
  unit: Entity
) {
  const lazyCostGrid = createLazyReadOnlyGridXY(
    gridWidth,
    gridHeight,
    function getEfficientPath(x: number, y: number) {
      return findMostEfficientPathToTileV2(grid, unit, gridWidth, gridHeight, x, y)
    }
  )
  return lazyCostGrid
}
