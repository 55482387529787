import { MapRowValue } from '@sg/backend/src/lib/db/getMapOr404'
import { Component } from 'solid-js'
import { createDataSizeTextSignal } from '../rx/signal/createDataSizeTextSignal'
import MapSizeText from './Text/MapSizeText'
import DateFormat from './Time/DateFormat'
import UserLink from './User/UserLink'
import NA from './Util/na'
import TR from './Util/tr'

interface Props {
  row: MapRowValue,
}

const MapShowTable: Component<Props> = (props) => {
  const [dataSizeText] = createDataSizeTextSignal(() => props.row?.data)

  // createEffect(() => {
  //   console.log('MapShowTable.createEffect.map.deleted_at', props.row.deleted_at)
  // })

  return <table class="table table-sm">
    <tbody>
      {TR('ID', <small>{props.row.id}</small>)}
      {TR('Creator ID', <UserLink user={props.row.creator} />)}
      {TR('Size', <MapSizeText state={props.row.data} />)}
      {TR('Created At', <DateFormat value={props.row.created_at} />)}
      {TR('Updated At', <DateFormat value={props.row.updated_at} />)}
      {TR('Published At', <DateFormat value={props.row.published_at} />)}
      {TR('Deleted At', <DateFormat value={props.row.deleted_at} />)}
      {TR('Data Size', dataSizeText() || NA())}
    </tbody>
  </table>
}

export default MapShowTable
