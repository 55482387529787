import { ArmorTypeId } from '../Armor/ArmorTypeId.enum'
import type { WeaponTypeArmorTypePair } from './WeaponTypeArmorTypePair.type'
import { WeaponTypeId } from './WeaponTypeId.enum'

const weaponTypeArmorTypePairList: Array<WeaponTypeArmorTypePair> = [
  a(WeaponTypeId.InfantryMachineGun, ArmorTypeId.Infantry, 6 / 8.571),
  a(WeaponTypeId.InfantryMachineGun, ArmorTypeId.Tank, 0.95 / 4.286),
  a(WeaponTypeId.InfantryMachineGun, ArmorTypeId.LightVehicle, 1.65 / 8.6),

  a(WeaponTypeId.TankCannon, ArmorTypeId.Infantry, 0.1),
  // a(WeaponTypeId.TankCannon, ArmorTypeId.Tank, 1),

  a(WeaponTypeId.VehicleMachineGun, ArmorTypeId.Infantry, 8.00001 / 12),
  a(WeaponTypeId.VehicleMachineGun, ArmorTypeId.Tank, 0.5),

  a(WeaponTypeId.AntiAirCannon, ArmorTypeId.Infantry, 4.9 / 18.9),
  // a(WeaponTypeId.AntiAirCannon, ArmorTypeId.Tank, 3.25 / 5.9),
  a(WeaponTypeId.AntiAirCannon, ArmorTypeId.Tank, 4.4 / 5.9),
  a(WeaponTypeId.AntiAirCannon, ArmorTypeId.LightVehicle, 6.5 / 18.9),
]

export default weaponTypeArmorTypePairList

function a(wt: WeaponTypeId, at: ArmorTypeId, v: number): WeaponTypeArmorTypePair {
  return { wt, at, v }
}
