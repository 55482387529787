import { array, InferOutput, literal, object, optional } from 'valibot'
import { ArmorSchema } from '../../Armor.type'
import { AttackedThisTurnSchema } from '../../AttackedThisTurn'
import { entityTypeDefaults, EntityTypeMeta } from '../../EntityTypeMeta'
import { FuelSchema } from '../../fuel.type'
import { HasMobilitySchema } from '../../has_mobility'
import { HasXYSchema } from '../../has_xy'
import { HPSchema } from '../../hp.type'
import { PlayerIdOrNullSchema } from '../../player/PlayerIdOrNull'
import { VisionSchema } from '../../vision.type'
import { WasBuiltThisTurnSchema } from '../../WasBuiltThisTurn'
import { ArmorTypeId } from '../Armor/ArmorTypeId.enum'
import { EntityLayerId } from '../entity_layer_id.enum'
import { EntityTypeId } from '../entity_type_id.enum'
import { EntityIdSchema } from '../EntityId.type'
import { MoveTypeId } from '../move_type_id.enum'
import createInfantryMachineGunWeapon from '../Weapon/createInfantryMachineGunWeapon'
import { WeaponSchema } from '../Weapon/Weapon.type'
import { BaseEntitySchema } from '../BaseEntity'

const entDefaults = {
  etype_id: EntityTypeId.Infantry,
  layer_id: EntityLayerId.Unit2,
  hp: 10,
  fuel: 99,
  vision: 2,
  mtype_id: MoveTypeId.Foot,
  mobility: 3,
  armor: 5,
} as const

function defaultWeapons() {
  return [createInfantryMachineGunWeapon(4)]
}

export const InfantryEntitySchema = object({
  ...BaseEntitySchema.entries,
  etype_id: literal(EntityTypeId.Infantry),
  layer_id: literal(EntityLayerId.Unit2),
  id: EntityIdSchema,
  player_id: PlayerIdOrNullSchema,
  hp: HPSchema,
  fuel: FuelSchema,
  vision: VisionSchema,
  armor: ArmorSchema,
  ...HasXYSchema.entries,
  ...HasMobilitySchema.entries,
  builtThisTurn: WasBuiltThisTurnSchema,
  attackedThisTurn: AttackedThisTurnSchema,
  weapons: array(WeaponSchema),
  taxi_id: optional(EntityIdSchema),
})

export type InfantryEntity = InferOutput<typeof InfantryEntitySchema>

export const InfantryEntityTypeMeta: EntityTypeMeta = {
  ...entityTypeDefaults,
  id: EntityTypeId.Infantry,
  dname: 'Infantry',
  layer_id: EntityLayerId.Unit2,
  entDefaults,
  price: 10,
  defaultWeapons,
  armor_type: ArmorTypeId.Infantry,
}

export function createInfantryEntity(
  id: number,
  x: number,
  y: number,
  player_id: number | null
): InfantryEntity {
  return {
    ...entDefaults,
    weapons: defaultWeapons(),
    id,
    x,
    y,
    player_id,
  }
}
