import type { BackendSessionPayload } from '@sg/backend/src/api/auth/BackendAuthSession.type'
import { Accessor, Signal, createRoot, createSignal } from 'solid-js'
import { mountListener } from '../../../lib/event/event'
import { Nullable } from '../../../typescript'
import { getSessionValue, sessionValueUpdatedEvent } from './sessionValue'

export type SessionSignal = ReturnType<typeof createSessionSignal>
export type SessionAccessor = Accessor<Nullable<BackendSessionPayload>>

const signal: Signal<Nullable<BackendSessionPayload>> = createRoot(() => {
  // eslint-disable-next-line solid/reactivity
  const signal = createSignal<Nullable<BackendSessionPayload>>(getSessionValue())

  mountListener(sessionValueUpdatedEvent, signal[1])

  // createEffect(() => {
  //   console.log('Session signal updated', signal[0]())
  // })

  // setTimeout(() => {
  //   const v = signal[0]()
  //   signal[1](v ? {
  //     ...v,
  //   } : null)
  // }, 5000)

  return signal
})

export function createSessionSignal(): Signal<Nullable<BackendSessionPayload>> {
  // if (!signal) {
  //   throw new Error('Session signal not initialized')
  // }
  // const signal = createSignal<Nullable<BackendSessionPayload>>(getSessionValue())

  // mountListener(sessionValueUpdatedEvent, signal[1])

  return signal as Signal<Nullable<BackendSessionPayload>>
}
