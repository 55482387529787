import { modifyMutable, produce } from 'solid-js/store'
import { Engine } from '../../core/engine/Engine.type'
import resetEngineToDefaultSelectedTool from '../../core/engine/resetEngineToDefaultSelectedTool'
import { SelectedToolId } from '../../core/map_editor/SelectedToolId.enum'
import { createSetStateAction } from '../../core/state/flux/action/Map/SetStateAction'
import dispatchClient from '../../core/state/flux/dispatchClient'
import saveStateChanges from '../../core/state/save_state_changes'
import { centerPanOnPosition } from '../../core/view_ctx/centerPanOnPosition'
import onWindowEvent from '../../onWindowEvent'
import preventDefault from './prevent_default'

export default function registerMapEditKeyboardEvents(engine: Engine, isMapEdit: boolean) {
  // const dirty = engine.cache[1]
  // const ab = createAlertBagStore()
  function onMapEditKeyDown(event: KeyboardEvent) {
    // console.log('onMapEditKeyDown', event)
    if (shouldIgnoreHotKeys(event)) {
      return
    }
    const { key } = event
    if (key === 's') {
      if (event.metaKey || event.ctrlKey) {
        // console.log('Save Hotkey!')
        preventDefault(event)
        saveStateChanges(engine)
        dispatchClient(engine, createSetStateAction(engine.state))
        return
      }
    }
    if (isMapEdit) {
      if (key === 'z') {
        if (event.metaKey || event.ctrlKey) {
          preventDefault(event)
          if (event.shiftKey) {
            // console.log('Redo Event!')
          } else {
            // console.log('Undo Event!')
          }
          return
        }
      }
      if (key === 'p') {
        preventDefault(event)
        engine.selectedTool = SelectedToolId.Paint
        return
      }
      if (key === 'e') {
        preventDefault(event)
        engine.selectedTool = SelectedToolId.Erase
        return
      }
      if (key === 'f') {
        preventDefault(event)
        engine.selectedTool = SelectedToolId.File
        return
      }
      if (key === 'l') {
        if (event.shiftKey) {
          console.log('[shift+l] pressed')
          preventDefault(event)
          engine.selectedTool = SelectedToolId.Players
          return
        }
      }
      if (key === 'i') {
        preventDefault(event)
        engine.selectedTool = SelectedToolId.Inspect
        return
      }
      if (key === 'Escape') {
        console.log('[esc] pressed')
        preventDefault(event)
        modifyMutable(
          engine,
          produce((engine) => {
            resetEngineToDefaultSelectedTool(engine)
          })
        )
        return
      }
      if (key === 'g' && event.ctrlKey) {
        console.log('[ctrl+g] pressed')
        preventDefault(event)
        engine.viewCtx.tile_gap_px = engine.viewCtx.tile_gap_px === 0 ? 1 : 0
        return
      }
      // center camera on selected position
      if (key === "'") {
        console.log('[SingleQuote] pressed')
        preventDefault(event)
        const centerToPosition = engine.selectedPosition
        if (centerToPosition) {
          centerPanOnPosition(engine, centerToPosition)
        }
        return
      }

      // console.log(`[${key}] pressed`, 'No key handler returned early', event)
    }

    // when it's possible for Ctrl - Z to undo this
    // if (key === 'r') {
    //   if (event.shiftKey && (event.metaKey || event.ctrlKey)) {
    //     console.log('Revert to PrevState!')
    //     preventDefault(event)
    //     engine.state = structuredClone(unwrap(engine.prevState))
    //     return
    //   }
    // }
  }

  // function onMapEditKeyUp() {
  //   // console.log('onMapEditKeyUp', event)
  //   // if (key === 's') {
  //   //   if (event.metaKey || event.ctrlKey) {
  //   //     console.log('Save Hotkey!')
  //   //     preventDefault(event)
  //   //     saveStateChanges(engine)
  //   //     return
  //   //   }
  //   // }
  // }

  // onWindowEvent('keyup', onMapEditKeyUp)
  onWindowEvent('keydown', onMapEditKeyDown)
}

function shouldIgnoreHotKeys(event: KeyboardEvent): boolean {
  if (event.metaKey || event.ctrlKey) {
    return false
  }

  const target = event.target as HTMLElement | null
  const targetNodeName = target?.nodeName
  // console.log('targetNodeName', targetNodeName)
  return (targetNodeName === 'INPUT' ||
    targetNodeName === 'TEXTAREA' ||
    target?.isContentEditable) as boolean
}
