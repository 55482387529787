import { createEffect } from 'solid-js'
import createLayerMeta from '../../../core/create_layer_meta'
import { Engine } from '../../../core/engine/Engine.type.ts'
import { EXPIRE_NEVER } from '../../../core/layer_meta'
import { SelectedToolId } from '../../../core/map_editor/SelectedToolId.enum.ts'
import { ViewCtx } from '../../../core/view_ctx'
import bindCanvasToEngine from '../../bind_canvas_to_engine.ts'
import { createCanvasElement } from '../../create_canvas.ts'
import { disableCanvasSmoothing } from '../../disable_canvas_smoothing.ts'
import makeEraseHoverCursorRenderFn from '../../draw/cursor/makeEraseHoverCursorRenderFn.ts'
import makeInspectHoverCursorRenderFn from '../../draw/cursor/makeInspectHoverCursorRenderFn.ts'
import makeInspectSelectedCursorRenderFn from '../../draw/cursor/makeInspectSelectedCursorRenderFn.ts'
import makePaintHoverCursorRenderFn from '../../draw/cursor/makePaintHoverCursorRenderFn.ts'
import { getCanvas2dContext } from '../../get_canvas_2d_context'

const name = 'Cursor'
const relZIndex = 0

const { now } = Date

export default function addCursorCanvas(engine: Engine) {
  // console.log(`${name}.add`)
  const lm = createLayerMeta()
  const elem = createCanvasElement()
  const buffer1 = createCanvasElement()
  elem.setAttribute('name', `${name}`)
  const { viewCtx } = engine
  // const track = engine.cache[0]
  elem.style.zIndex = (viewCtx.zIndex + relZIndex).toString()

  let selectedTool = engine.selectedTool
  let hoveredPosition: Engine['hoveredPosition'] = null
  let mouseDownPosition: Engine['mouseDownPosition'] = null
  let selectedPosition: Engine['selectedPosition'] = null
  let lastMouseMoveEvent: Engine['lastMouseMoveEvent'] = null

  let renderWidthPx: ViewCtx['renderWidthPx'] = 0
  let renderHeightPx: ViewCtx['renderHeightPx'] = 0
  let tile_size: ViewCtx['tile_size'] = 0
  let lastPanAt: ViewCtx['lastPanAt'] = 0

  createEffect(() => {
    // track(EngineCache.Cursor)
    selectedTool = engine.selectedTool
    hoveredPosition = engine.hoveredPosition
    mouseDownPosition = engine.mouseDownPosition
    selectedPosition = engine.selectedPosition
    lastMouseMoveEvent = engine.lastMouseMoveEvent
    const viewCtx = engine.viewCtx

    renderWidthPx = viewCtx.renderWidthPx
    renderHeightPx = viewCtx.renderHeightPx
    tile_size = viewCtx.tile_size
    lastPanAt = viewCtx.lastPanAt

    // const { renderWidthPx, renderHeightPx, tile_size, tile_gap_px, lastPanAt } = viewCtx
    // const tile_size_plus_gap: number = tile_size + tile_gap_px

    lm.until = 0
  })

  const inspectSelectedCursorRenderFn = makeInspectSelectedCursorRenderFn(engine)
  const inspectHoverCursorRenderFn = makeInspectHoverCursorRenderFn(engine)
  // const paintSelectedCursorRenderFn = makePaintSelectedCursorRenderFn(engine)
  const paintHoverCursorRenderFn = makePaintHoverCursorRenderFn(engine)
  // const eraseSelectedCursorRenderFn = makeEraseSelectedCursorRenderFn(engine)
  const eraseHoverCursorRenderFn = makeEraseHoverCursorRenderFn(engine)

  function render(timeStamp: number) {
    // console.log(`${name}.render`)
    lm.ts = timeStamp

    // frames have indicated they
    // plan on rendering the same image
    if (lm.until > timeStamp) {
      return
    }
    lm.until = timeStamp + EXPIRE_NEVER
    // console.log(`${name}.render`)

    const ctx1 = getCanvas2dContext(buffer1)
    if (!ctx1) return
    const ctx0 = getCanvas2dContext(elem)
    if (!ctx0) return

    // reset buffer1
    buffer1.width = renderWidthPx
    buffer1.height = renderHeightPx
    ctx1.clearRect(0, 0, renderWidthPx, renderHeightPx)
    disableCanvasSmoothing(ctx1)

    if (lastPanAt) {
      if (lastPanAt + 500 >= now()) {
        if (lastMouseMoveEvent !== null && mouseDownPosition !== null) {
          // drawPanCursorOnPosition(ctx1, lastMouseMoveEvent, viewCtx)
        }
      } else {
        engine.viewCtx.lastPanAt = 0
      }
    } else if (selectedTool === SelectedToolId.Inspect) {
      // TODO both selected and hover should not render if they are the same position
      if (hoveredPosition !== null && mouseDownPosition === null) {
        inspectHoverCursorRenderFn(ctx1, lm, hoveredPosition, tile_size)
      }

      if (mouseDownPosition !== null || selectedPosition !== null) {
        const position = mouseDownPosition || selectedPosition
        if (position) {
          inspectSelectedCursorRenderFn(ctx1, lm, position, tile_size)
        }
      }
    } else if (selectedTool === SelectedToolId.Paint) {
      if (hoveredPosition !== null && mouseDownPosition === null) {
        const entity = engine.paintToolOption.entity
        if (entity) {
          // drawPaintTileSelectHoverGhostEntityOnPosition(engine, ctx1, entity, lm, hoveredPosition, tile_size, canvas_edge_buffer, tile_size_plus_gap, timeStamp)
        }
        paintHoverCursorRenderFn(ctx1, lm, hoveredPosition, tile_size)
      }
      // if (mouseDownPosition !== null || selectedPosition !== null) {
      //   const position = mouseDownPosition || selectedPosition
      //   if (position) {
      //     drawSelectedPaintCursorOnPosition(ctx1, position, tile_size, canvas_edge_buffer, tile_size_plus_gap)
      //   }
      // }
    } else if (selectedTool === SelectedToolId.Erase) {
      if (hoveredPosition !== null /* && mouseDownPosition === null*/) {
        eraseHoverCursorRenderFn(ctx1, lm, hoveredPosition, tile_size)
      }
      // if (mouseDownPosition !== null || selectedPosition !== null) {
      //   const position = mouseDownPosition || selectedPosition
      //   if (position) {
      //     drawSelectedEraseCursorOnPosition(ctx1, position, tile_size, canvas_edge_buffer, tile_size_plus_gap)
      //   }
      // }
    }

    // reset screen
    elem.width = renderWidthPx
    elem.height = renderHeightPx
    ctx0.clearRect(0, 0, renderWidthPx, renderHeightPx)
    disableCanvasSmoothing(ctx0)

    // write buffer1 to screen
    ctx0.drawImage(buffer1, 0, 0)
  }

  bindCanvasToEngine(engine, elem, render)
}
