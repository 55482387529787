import type { Engine } from "../engine/Engine.type"
import findUnloadableCargoTileCollection from "../tile_position_xy/findUnloadableCargoTileCollection"

// if the destPosition is changed
// then the unload property needs to be refreshed
export default function updateDraftMoveUnloadableCargoPath(engine: Engine): void {
  // console.log('updateDraftMoveUnloadableCargoPath', deepClone({draftMove: engine.draftMove}))
  const { draftMove } = engine
  if (draftMove) {
    const oldGroup = draftMove.unload
    if (oldGroup) {
      const groupList = findUnloadableCargoTileCollection(engine, draftMove)
      for (let index = 0; index < groupList.length; index++) {
        const group = groupList[index]
        if (oldGroup.cargo_id === group.cargo_id) {
          draftMove.unload = group
          return
        }
      }
    }
  }
}