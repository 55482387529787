import MirrorFourCornersFlipped from './MirrorFourCornersFlipped'
import MirrorLeftToRightOption from './MirrorLeftToRightOption'
import MirrorTopToBottomOption from './MirrorTopToButtomOption'
import NoneSymmetryScriptOption from './NoneOption'
import { SymmetryScriptOption } from './SymmetryScriptOption.type'

const symmetryScriptOptionsList: Array<SymmetryScriptOption> = [
  NoneSymmetryScriptOption,
  MirrorLeftToRightOption,
  MirrorTopToBottomOption,
  MirrorFourCornersFlipped,
]

export default symmetryScriptOptionsList
