import { GameRowValue } from '@sg/backend/src/lib/db/getGameOr404.ts'
import { createElementSize } from "@solid-primitives/resize-observer"
import { Component, createEffect, createSignal } from 'solid-js'
import { createMutable } from 'solid-js/store'
import bindEngineToComponent from '../lib/canvas/bind_engine_to_component'
import addBgGradientCanvas from '../lib/canvas/layer/bg_gradient'
import addDrawEntsByKindCanvas from '../lib/canvas/layer/ents_by_type'
import createEngineForUI from '../lib/core/engine/createEngineForUI'
import { Engine } from '../lib/core/engine/Engine.type'
import syncEngineWithPropsRow from '../lib/core/engine/syncEngineWithPropsRow'
import { EntityLayerId } from '../lib/core/entity/entity_layer_id.enum'
import createCanvasListStyleEffect from '../rx/effect/createCanvasListStyleEffect'
import createSetEngineAuthPlayerIdFromAuthProfileSignalEffect from '../rx/effect/createSetEngineAuthPlayerIdFromAuthProfileSignalEffect'
import createAuthProfileSignal from '../rx/shared/profile/createAuthProfileSignal'
import EngineContext from './EngineContext'
import ZoomCardWidget from './Hud/ZoomCardWidget'

interface Props {
  row: GameRowValue,
  width: number,
  height: number,
  minWidth?: number,
  minHeight?: number,
}

const GameShowCanvas: Component<Props> = (props) => {
  const engine: Engine = createMutable(createEngineForUI())
  // engine.state = createRandomMap(30, 20)

  createEffect(() => {
    syncEngineWithPropsRow(engine, props.row)
  })

  const [authProfile] = createAuthProfileSignal()
  createSetEngineAuthPlayerIdFromAuthProfileSignalEffect(engine, authProfile)

  // const biggestSideTileCount = max(engine.state.width, engine.state.height)

  // bindViewCtxToWindow(engine)
  // createCenterPanOnMount(engine)

  addBgGradientCanvas(engine)
  // addTerrainGridCanvas(engine)
  addDrawEntsByKindCanvas(engine, [EntityLayerId.TerrainL1])
  addDrawEntsByKindCanvas(engine, [EntityLayerId.TerrainL2, EntityLayerId.Unit2])

  const [containerStyles, setContainerStyles] = createSignal<Partial<CSSStyleDeclaration>>({})

  const [target, setTarget] = createSignal<HTMLElement>();

  const elementSize = createElementSize(target);

  createCanvasListStyleEffect(engine, props, elementSize, setContainerStyles)

  bindEngineToComponent(engine)

  return <EngineContext.Provider value={engine}>
    <div class="game-show-container" ref={setTarget} style={containerStyles() as string}>
      {engine.canvasList}
      <ZoomCardWidget/>
    </div>
  </EngineContext.Provider>
}

export default GameShowCanvas