// import { enum } from 'valibot'

import { enum_ } from 'valibot'

export enum StateType {
  Map = 1,
  Lobby = 2,
  Game = 3,
  Replay = 4,
}

export const StateTypeSchema = enum_(StateType, 'Invalid State Type')
