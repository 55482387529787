import { InferOutput, object } from 'valibot'
import { MoveTypeIdSchema } from './entity/move_type_id.enum'
import { MobilityNumberSchema } from './MobilityNumber.type'

export const HasMobilitySchema = object({
  mobility: MobilityNumberSchema,
  mtype_id: MoveTypeIdSchema,
})

export type HasMobility = InferOutput<typeof HasMobilitySchema>
