import { createEffect, on } from "solid-js"
import type { Engine } from "../../../core/engine/Engine.type"
import type { LayerMeta } from "../../../core/layer_meta"
import { BASE_TILE_SIZE } from "../../../core/view_ctx"
import { SolidShoePrints_viewBox_d } from "../../../svg/sprites_data_manual"
import { getCanvas2dContext } from "../../get_canvas_2d_context"

export default function createRenderMoveTileLinearGradientCanvasBuffer (engine : Engine, lm: LayerMeta, buffer: OffscreenCanvas) {
  createEffect(on(() => engine.viewCtx.tile_size, (tile_size: number) => {
    buffer.width = tile_size
    buffer.height = tile_size
    lm.until = 0

    const ctx = getCanvas2dContext(buffer)
    if (ctx) {

      // blue bg
      ctx.clearRect(0, 0, tile_size, tile_size)

      ctx.fillStyle = 'blue'

      const gradient = ctx.createLinearGradient(0, tile_size, tile_size, 0)
      gradient.addColorStop(0, `rgba(0, 0, 255, 0.5)`)
      gradient.addColorStop(0.38, `rgba(0, 255, 255, 0.55)`)
      gradient.addColorStop(0.42, `rgba(0, 255, 255, 0.5)`)
      gradient.addColorStop(0.6, `rgba(0, 255, 255, 0.4)`)
      gradient.addColorStop(1, `rgba(0, 0, 255, 0.5)`)
      ctx.fillStyle = gradient
      ctx.fillRect(0, 0, tile_size, tile_size)

      // shoe prints
      const scaleFactor = tile_size / BASE_TILE_SIZE / 3
      // const offsetX1 = min(2, tile_size / 8)
      // const offsetY1 = min(2, tile_size / 8)
      const offsetX1 = (1 * tile_size) / 9 + 1
      const offsetY1 = (1 * tile_size) / 9 + 1
      const offsetX2 = (4.1 * tile_size) / 9 + 1
      const offsetY2 = (4.1 * tile_size) / 9 + 1
      // const offsetX3 = (4 * tile_size) / 7
      // const offsetY3 = (4 * tile_size) / 7

      ctx.globalAlpha = 0.1
      ctx.fillStyle = 'purple'

      ctx.translate(offsetX1, offsetY1)
      ctx.scale(scaleFactor, scaleFactor)
      ctx.fill(SolidShoePrints_viewBox_d)
      ctx.resetTransform()

      ctx.translate(offsetX2, offsetY2)
      ctx.scale(scaleFactor, scaleFactor)
      ctx.fill(SolidShoePrints_viewBox_d)
      ctx.resetTransform()

      // ctx.translate(dx + offsetX3, dy + offsetY3)
      // ctx.scale(scaleFactor, scaleFactor)
      // ctx.fill(SolidShoePrints_viewBox_d)
      // ctx.resetTransform()
    }
  }))
}