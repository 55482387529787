import type { State } from '../state/State.type'
import { type ColorNamePair, colorNamesPool } from './colorNamesPool'

export default function nextMapEditorPlayer(state: State): ColorNamePair {
  // console.log('nextPlayerColor')
  const usedColors: string[] = state.players.map((p) => p.color1).filter(Boolean)
  // console.log('usedColors', usedColors)
  const notUsedPoolColors = colorNamesPool.filter(
    (cn: ColorNamePair) => !usedColors.includes(cn.color1)
  )
  // console.log('notUsedPoolColors', notUsedPoolColors)
  if (notUsedPoolColors.length > 0) return notUsedPoolColors[0]
  // a future game with more than 8 players could use this:
  // const playerColor = playerId % 4095
  throw new Error('ran out of colors')
}
