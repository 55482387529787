import { onCleanup, onMount } from 'solid-js'

export default function onDocumentEvent<K extends keyof DocumentEventMap>(
  name: K,
  fn: (this: Document, ev: DocumentEventMap[K]) => void | null | boolean,
  options?: boolean | AddEventListenerOptions
): void {
  onMount(() => {
    document.addEventListener(name, fn, options)
  })
  onCleanup(() => {
    document.removeEventListener(name, fn, options)
  })
}
