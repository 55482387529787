import toast from 'solid-toast'
import { AlertBagValue } from '../../rx/store/create_alert_bag_store'
import alertBagValueToString from './alertBagValueToString'

export default function toastError(error: unknown): void {
  console.log(error)
  console.log(alertBagValueToString(error as AlertBagValue))
  const result = toast.error(alertBagValueToString(error as AlertBagValue))
  console.log('toastError.result', result)
}
