import { useNavigate } from '@solidjs/router'
import { createEffect, on, type Accessor } from 'solid-js'
import { StateType } from '../../lib/core/state/state_type.enum'
import type { StateOnlineId } from '../../lib/core/state/StateOnlineId.type'
import type { Nullable } from '../../typescript'

export default function createRedirectIfWrongStateTypeEffect(
  expectedStateType: StateType,
  stateType: Accessor<Nullable<StateType>>,
  stateId: Accessor<Nullable<StateOnlineId>>
): void {
  const navigate = useNavigate()

  createEffect(
    on(stateType, (actualStateType: Nullable<StateType>) => {
      // console.log(
      //   'expectedStateType',
      //   expectedStateType,
      //   'actualStateType',
      //   actualStateType,
      // )
      if (!actualStateType) {
        // do nothing, probably loading
      } else if (expectedStateType === actualStateType) {
        // do nothing, everything is awesome
      } else {
        const stateIdValue = stateId()
        if (!stateIdValue) {
          // do nothing, probably loading
        } else if (StateType.Map === actualStateType) {
          navigate(`/map?map_id=${stateIdValue}`)
        } else if (StateType.Lobby === actualStateType) {
          navigate(`/game/create?game_id=${stateIdValue}`)
        } else if (StateType.Game === actualStateType) {
          navigate(`/game/play?game_id=${stateIdValue}`)
        } else if (StateType.Replay === actualStateType) {
          navigate(`/replay?game_id=${stateIdValue}`)
        }
      }
    })
  )
}
