import { nullable, number, object, optional, type InferOutput } from 'valibot'

export const TilePositionXYSchema = object({
  x: number(),
  y: number(),
})

export type TilePositionXY = InferOutput<typeof TilePositionXYSchema>

export const NullableTilePositionXYSchema = nullable(TilePositionXYSchema)

export type NullableTilePositionXY = InferOutput<typeof NullableTilePositionXYSchema>

export const OptionalNullableTilePositionXYSchema = optional(NullableTilePositionXYSchema)

export type OptionalNullableTilePositionXY = InferOutput<typeof OptionalNullableTilePositionXYSchema>
