import { createEffect, on } from "solid-js"
import type { Engine } from "../../../core/engine/Engine.type"
import type { LayerMeta } from "../../../core/layer_meta"
import { getCanvas2dContext } from "../../get_canvas_2d_context"

export default function createRenderDirectAttackRedTileBuffer (engine : Engine, lm: LayerMeta, buffer: OffscreenCanvas) {
  createEffect(on(() => engine.viewCtx.tile_size, (tile_size: number) => {
    buffer.width = tile_size
    buffer.height = tile_size
    lm.until = 0

    const ctx = getCanvas2dContext(buffer)
    if (ctx) {
      // red bg
      ctx.clearRect(0, 0, tile_size, tile_size)
        const gradient = ctx.createLinearGradient(
          0,
          tile_size,
          tile_size,
          0
        )

        // gradient.addColorStop(0, `rgba(0, 0, 255, 0.5)`)
        // gradient.addColorStop(0.38, `rgba(0, 255, 255, 0.55)`)
        // gradient.addColorStop(0.42, `rgba(0, 255, 255, 0.5)`)
        // gradient.addColorStop(0.6, `rgba(0, 255, 255, 0.4)`)
        // gradient.addColorStop(1, `rgba(0, 0, 255, 0.5)`)

        gradient.addColorStop(0, `rgba(255, 0, 32, 0.5)`)
        gradient.addColorStop(0.38, `rgba(255, 32, 32, 0.55)`)
        gradient.addColorStop(0.42, `rgba(255, 32, 32, 0.5)`)
        gradient.addColorStop(0.6, `rgba(255, 32, 32, 0.4)`)
        gradient.addColorStop(1, `rgba(255, 0, 32, 0.5)`)

        ctx.fillStyle = gradient
        // ctxDirectAttackLinearGradient.fillStyle = 'red'
        ctx.fillRect(0, 0, tile_size, tile_size)
    }
  }))
}