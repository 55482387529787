import isNil from '../../ldsh/isNil'
import getUnitMoveCostOntoPositionEntityStack from '../entity/getUnitMoveCostOntoPositionEntityStack'
import type { Entity } from '../entity/index'
import type { EntityGridXY } from './EntityGridXY.type'
import createLazyReadOnlyGridXY from './createLazyReadOnlyGridXY'

type ImpassValueOrNull = boolean

export default function createLazyImpassValueGrid(
  // full state width
  gridWidth: number,
  // full state height
  gridHeight: number,
  // full state ents as GridXY
  grid: EntityGridXY,
  // unit attempting to move
  unit: Entity
) {
  const lazyCostGrid = createLazyReadOnlyGridXY<ImpassValueOrNull>(
    gridWidth,
    gridHeight,
    function blockedGridGetter(x: number, y: number): boolean {
      const positionStack = grid?.[x]?.[y]
      if (positionStack) {
        const mc = getUnitMoveCostOntoPositionEntityStack(unit, positionStack)
        // a tile is only passible
        // if the unit has a known cost for moving onto it.
        return isNil(mc)
      }
      // for now, a void tile is impassible
      return true
    }
  )
  return lazyCostGrid
}
