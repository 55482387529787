import createGridXY, { type GridXY } from './createGridXy'

export default function createLazyReadOnlyGridXY<T>(
  gridWidth: number,
  gridHeight: number,
  fn: (x: number, y: number) => T
) {
  const data: GridXY<T | undefined> = createGridXY<T | undefined>(gridWidth, gridHeight, void 0)
  return (x: number, y: number): T => {
    if (x>=0 && y >= 0 && x < gridWidth && y < gridHeight) {
      let value = data[x][y]
      if (value === void 0) {
        value = data[x][y] = fn(x, y)
      }
      return value
    }
    return fn(x, y)
  }
}
