import { modifyMutable, produce } from "solid-js/store"
import type { Nullable } from "vite-node"
import type { Engine } from "../../engine/Engine.type"
import resetEngineToDefaultSelectedTool from "../../engine/resetEngineToDefaultSelectedTool"
import getTransportableDestPositionEntity from "../../entity/getTransportableDestPositionEntity"
import { createMoveUnitAction } from "../../state/flux/action/Game/MoveUnitAction"
import dispatchClient from "../../state/flux/dispatchClient"
import type { PathTurnSteps_Step } from "../../tile_position_xy/findMostEfficientPathToTileV2/PathTurnSteps_Step.type"

export default async function handleUnitLoadIntoEntityAction(engine: Engine) {
  // console.log('handleUnitLoadIntoEntityAction')

  const { draftMove } = engine
  if (draftMove) {
    const cargoEnt = getTransportableDestPositionEntity(engine.state.ents, draftMove.unit, draftMove.destPosition)
    if (cargoEnt) {
      dispatchClient(engine, createMoveUnitAction({...draftMove, pickup: cargoEnt }))
    }

    const lastStep : Nullable<PathTurnSteps_Step> = draftMove.destPositionPaths?.lastStep

    const taxi = lastStep?.taxis?.[0]

    if (taxi) {
      await dispatchClient(engine, createMoveUnitAction({
        ...draftMove,
        taxi,
      }))
      modifyMutable(engine, produce((engine) => {
        resetEngineToDefaultSelectedTool(engine)
      }))
    }
  }
}
