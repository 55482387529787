import { object, InferOutput, enum_ } from 'valibot'

export enum MoveTypeId {
  None = 0, // can't move
  Foot = 1, // infantry
  Boot = 2, // mech

  // Sea = 20, // do boats really only have one?
  // Lander = 21,

  Tires = 40, // recon
  Treads = 41, // tank, APC

  // Hover = 60,
  // Drone = 61,
  Helicopter = 62,
  // Zeppelin = 63,
  // JetEngine = 64,
  // Missle = 1, // cruise missle, ICBM?
}

export const MoveTypeIdSchema = enum_(MoveTypeId, 'Invalid Move Type ID')

export const HasMoveTypeIdSchema = object({
  mtype_id: MoveTypeIdSchema,
})

export type HasMoveTypeId = InferOutput<typeof HasMoveTypeIdSchema>
