import { A } from '@solidjs/router'
import { JSX, ParentComponent, createMemo } from 'solid-js'

const Breadcrumbs: ParentComponent = (props) => {
  const children = createMemo<Array<JSX.Element>>(() => {
    const children1: Array<HTMLElement> = props.children instanceof HTMLElement
      ? [props.children]
      : ((props.children || []) as Array<HTMLElement>)
    // console.log('children1', children1)
    const lastIndex = children1.length - 1
    const children2 = children1.map((child: HTMLElement, index: number) => {
      const isLastIndex = index === lastIndex
      const elem = <li classList={{
        'breadcrumb-item': true,
        'active': isLastIndex,
      }}
        aria-current={isLastIndex ? "page" : void 0}
      >{isLastIndex ? child.innerHTML : child}</li>
      return elem
    })
    children2.unshift(<li class='breadcrumb-item'><A href="/">Home</A></li>)
    return children2
  })

  return <nav aria-label="breadcrumb">
    <ol class="breadcrumb">
      {children()}
    </ol>
  </nav>
}

export default Breadcrumbs