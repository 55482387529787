import type { BaseEntity } from "../entity/BaseEntity"
import type { EntityId } from "../entity/EntityId.type"

// valibot's types are inscrutable
type SimpleAddIssue = (obj : { message : string } ) => void

export default function verifyRelationshipBetweenTransportsAndCargo(
  ents: Map<EntityId, BaseEntity>,
  addIssue: SimpleAddIssue): void {
  // To track cargo entity relationships
  const cargoItemOwners = new Map<EntityId, EntityId>() // Map of cargo id to entity id that owns it

  ents.forEach((entity, entityId) => {
    // Verify that entities with cargo do not have a taxi_id
    if (entity.cargo && entity.taxi_id) {
      addIssue({ message: `Entity{id=${entityId}} has both cargo and taxi_id.` })
      return
    }

    // If the entity has a taxi_id, verify the taxi_id exists in the entity map
    if (entity.taxi_id) {
      const taxiEntity = ents.get(entity.taxi_id)
      if (!taxiEntity) {
        addIssue({ message: `Entity ${entityId} has a taxi_id that does not correspond to a valid entity.` })
        return
      }

      // Verify that the taxi entity (if it has cargo) belongs to the same player
      if (taxiEntity.cargo && entity.player_id !== taxiEntity.player_id) {
        addIssue({ message: `Entity ${entityId} has taxi_id ${entity.taxi_id}, but player_id does not match its taxi entity.` })
        return
      }
    }

    // Verify that all values in cargo are unique across all entities
    if (entity.cargo) {
      entity.cargo.forEach((cargoId) => {
        if (cargoItemOwners.has(cargoId)) {
          addIssue({ message: `Cargo item ${cargoId} is assigned to more than one entity.` })
          return
        }
        cargoItemOwners.set(cargoId, entityId) // Record that this cargo belongs to this entity
      })
    }
  })

  // Verify that every cargo entity is the only one related to a taxi entity
  cargoItemOwners.forEach((ownerId, cargoId) => {
    const ownerEntity = ents.get(ownerId)
    if (!ownerEntity) {
      addIssue({ message: `Entity ${ownerId} with cargo ${cargoId} does not exist.` })
      return
    }

    // Ensure that each entity with cargo is the only entity related to a taxi entity
    if (ownerEntity.taxi_id) {
      const taxiEntity = ents.get(ownerEntity.taxi_id)
      if (!taxiEntity) {
        addIssue({ message: `Taxi entity ${ownerEntity.taxi_id} for entity ${ownerId} does not exist.` })
        return
      }

      if (ownerEntity.player_id !== taxiEntity.player_id) {
        addIssue({ message: `Entity ${ownerId} with cargo has a taxi_id ${ownerEntity.taxi_id}, but player_id does not match.` })
        return
      }
    }
  })
}
