import { InferOutput, maxValue, minValue, number, object, pipe } from 'valibot'
import { calculateMaxBitFlagSum } from '../calculateMaxBitFlagSum'

export enum RoadKind {
  // None = 0, // not a valid road

  Up = 1 << 0,
  Down = 1 << 1,
  Left = 1 << 2,
  Right = 1 << 3,

  // Each variant could also be blocked
  // UpBlocked = 1 << 4,
  // DownBlocked = 1 << 5,
  // LeftBlocked = 1 << 6,
  // RightBlocked = 1 << 7,

  // directionless variants
  // Potholes = 1 << 8,
  // Gravel = 1 << 9,
  // Dirt = 1 << 10,
  // RightBlocked = 1 << 11,
}

export const RoadKindSchema = pipe(number(), minValue(0), maxValue(calculateMaxBitFlagSum(3)))
// export const RoadKindSchema = enum_(RoadKind, 'Invalid Entity Layer ID')

export const HasRoadKindSchema = object({
  layer_id: RoadKindSchema,
})

export type HasRoadKind = InferOutput<typeof HasRoadKindSchema>
