import { Accessor, createMemo, createRoot } from 'solid-js'
import { createSessionSignal } from './createSessionSignal'

const isGuestMemo: Accessor<boolean> = createRoot(() => {
  const session = createSessionSignal()[0]

  const isGuestMemo = createMemo((): boolean => {
    return (session() as { isGuest: boolean } | null)?.isGuest as boolean
  })

  return isGuestMemo
})

export function createIsGuestMemo(): Accessor<boolean> {
  return isGuestMemo
}
