import type { LazyEfficientPathGrid_Result } from "./createLazyEfficientPathGrid"

export default function createCanUnitDirectAttackThisTurn(getEfficientPathToTile : LazyEfficientPathGrid_Result | null) {
  return function canUnitDirectAttackThisTurn (x: number, y: number) {
    if (getEfficientPathToTile) {
      const upts = getEfficientPathToTile(x, y)
      // upts.turns.length === 1 : unit can walk up to the unit and attack
      // upts.turns.length === 0 : unit is orthogonally adjacent
      // if (upts?.attack && upts.turns.length <= 1) {
      if (upts && upts.turns.length <= 1) {
        // if (samePosition(upts.end, coord(3,3)) || samePosition(upts.lastStep, coord(3,3))) {
        //   console.log('upts', deepClone(upts))
        // }
        const { targetStep } = upts
        if (targetStep) {
          for (let index = 0; index < targetStep.enemies.length; index++) {
            const enemy = targetStep.enemies[index]
            if (enemy.canDirectAttack) {
              return true
            }
          }
        }
      }
    }
    return false
  }
}
