import { modifyMutable, produce } from 'solid-js/store'
import { Engine } from '../engine/Engine.type'
import type { TilePositionXY } from '../tile_position_xy/TilePositionXY.type'
import { floor } from '../util/math'

/**
 * Adjusts the `pan_x` and `pan_y` values of the engine's `viewCtx` to center the visible area
 * of the canvas on a specific tile position.
 *
 * The function calculates the offset required to center the canvas content (game grid) 
 * on the given tile position, taking into account:
 * - Tile size and gaps between tiles (`tile_size` and `tile_gap_px`)
 * - The dimensions of the grid (`width` and `height`)
 * - Extra buffer space around the canvas (`canvas_edge_buffer`)
 * - The rendering area dimensions (`renderWidthPx` and `renderHeightPx`)
 * - The position of the tile to center on (`position.x` and `position.y`)
 *
 * @param {Engine} engine - The game engine object containing the `viewCtx` and state properties.
 * @param {TilePositionXY} position - The tile position `{ x, y }` to center the camera on.
 */
export function centerPanOnPosition(engine: Engine, position: TilePositionXY): void {
  modifyMutable(
    engine,
    produce((engine: Engine) => {
      const { viewCtx } = engine
      const { renderWidthPx, renderHeightPx, tile_size, tile_gap_px, canvas_edge_buffer } = viewCtx

      // Calculate the pixel position of the center of the target tile
      const tileCenterX = position.x * (tile_size + tile_gap_px) + tile_size / 2
      const tileCenterY = position.y * (tile_size + tile_gap_px) + tile_size / 2

      // Offset the view context so the target tile is centered
      viewCtx.pan_x_f = renderWidthPx / 2 - tileCenterX + canvas_edge_buffer
      viewCtx.pan_y_f = renderHeightPx / 2 - tileCenterY + canvas_edge_buffer

      // Floor the values for pixel alignment
      viewCtx.pan_x = floor(viewCtx.pan_x_f)
      viewCtx.pan_y = floor(viewCtx.pan_y_f)
    })
  )
}
