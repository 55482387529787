import type { HasWeapons } from '../HasWeapons'
import type { Entity } from './index'

export default function unitHasRangedAttackWeapon(unit: Entity): unit is Entity & HasWeapons {
  if ('weapons' in unit && unit.weapons) {
    for (let index = 0; index < unit.weapons.length; index++) {
      const weapon = unit.weapons[index]
      if (weapon.maxRange) {
        return true
      }
    }
  }
  return false
}
