import { Component } from 'solid-js'
import type { StateType } from "../../lib/core/state/state_type.enum"
import stateTypeMetaList from '../../lib/core/state/stateTypeMetaList.type'

interface Props {
  type: StateType,
}

const StateTypeText: Component<Props> = (props) => {
  return <span>{stateTypeMetaList.find(stm => stm.id===props.type)?.dname || 'invalid'}</span>
}

export default StateTypeText