import preventDefault from '../dom/event/prevent_default'

export function createCanvasElement(): HTMLCanvasElement {
  const canvas = document.createElement('canvas')
  canvas.addEventListener('selectstart', preventDefault)
  return canvas
}

export function createOffscreenCanvas(width: number, height: number): OffscreenCanvas {
  const canvas = new OffscreenCanvas(width || 1, height || 1)
  return canvas
}
