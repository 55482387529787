import Unexpected from '../../Exception/Unexpected.class'
import findByIdOrThrow from '../findByIdOrThrow'
import entityTypeMetaList from './entityTypeMetaList.generated'
import type { Entity } from './index'

export default function entityDisplayName(entity: Entity): string {
  const entityTypeMeta = findByIdOrThrow(entityTypeMetaList, entity.etype_id)
  const dname = entityTypeMeta.dname
  if (!dname) {
    throw new Unexpected('!etm.dname')
  }
  return dname
}
