import type { MyProfilePayload } from '@sg/backend/src/api/profile/handleGetMe'
import { addListener, emit, EventListenerList } from '../../../lib/event/event'
import ls from '../../../lib/localStorage'
import { Nullable } from '../../../typescript'
import { getSessionValue, sessionValueUpdatedEvent } from '../session/sessionValue'
import fetchMyAuthProfile from './fetchMyAuthProfile'
import getAuthProfileValueFromLocalStorage, {
  PROFILE_VALUE_LS_KEY,
} from './getAuthProfileValueFromLocalStorage'

export const profileValueUpdatedEvent: EventListenerList<Nullable<MyProfilePayload>> = [
  (newValue) => {
    ls.setItem(PROFILE_VALUE_LS_KEY, JSON.stringify(newValue || null))
  },
]

addListener(sessionValueUpdatedEvent, (sessionValue) => {
  const sub = sessionValue?.sub
  if (sub !== profileValue?.id) {
    fetchMyAuthProfile(sub).then(updateAuthProfileValue)
  }
})

let profileValue: Nullable<MyProfilePayload> = getAuthProfileValueFromLocalStorage()

export function getProfileValue() {
  return profileValue
}

if (!profileValue) {
  fetchMyAuthProfile(getSessionValue()?.sub).then(updateAuthProfileValue)
}

export function updateAuthProfileValue(newValue: Nullable<MyProfilePayload>): void {
  profileValue = newValue
  emit(profileValueUpdatedEvent, newValue)
}

window.addEventListener('storage', function (event) {
  if (event.key === PROFILE_VALUE_LS_KEY) {
    const newValue: Nullable<MyProfilePayload> = event.newValue ? JSON.parse(event.newValue) : null
    updateAuthProfileValue(newValue)
  }
})
