import { nextCounter } from '@sg/shared/src/util/numbers'

import { createEffect, JSXElement, ParentComponent } from 'solid-js'
import { unwrap } from 'solid-js/store'

const FormGroup: ParentComponent = (props) => {

  // this worked earlier without createEffect,
  // not sure the fastest/cleanest/best way
  // to do this
  createEffect(() => {
    const children = unwrap(props.children) as Array<JSXElement>

    if (children && 'find' in children) {
      (children as Array<JSXElement>).find((inputElem: JSXElement) => {
        if (inputElem instanceof HTMLInputElement) {
          ; (children as Array<JSXElement>).find((labelElem: JSXElement) => {
            if (labelElem instanceof HTMLLabelElement) {
              labelElem.setAttribute('for', inputElem.id || (inputElem.id = `i${nextCounter()}`))
            }
          })
            ; (children as Array<JSXElement>).find((helpElem: JSXElement) => {
              if (helpElem instanceof HTMLDivElement && helpElem.classList.contains('form-text')) {
                inputElem.setAttribute('aria-describedby', helpElem.id || (helpElem.id = `h${nextCounter()}`))
              }
            })
        }
      })
    }
  })

  return <div class="form-group mb-3">
    {props.children}
  </div>
}

export default FormGroup