import { Nullable } from '../../../../typescript'
import { Engine } from '../../../core/engine/Engine.type'
import { Entity } from '../../../core/entity'
import shouldEntityShowFadedFrames from '../../../core/entity/shouldEntityShowFadedFrames'
import { LayerMeta } from '../../../core/layer_meta'
import getEntityPlayer from '../../../core/player/get_entity_player'
import { Player } from '../../../core/player/Player.type'
import { RenderSpriteFunction } from '../../../core/render_sprite'
import { min } from '../../../core/util/math'
import { BASE_TILE_SIZE } from '../../../core/view_ctx'
import {
  MediumCopter_Group1_BodyFrontGrey1_d,
  MediumCopter_Group1_BodyFrontGrey1_fill,
  MediumCopter_Group1_BodyLowerGrey1_d,
  MediumCopter_Group1_BodyLowerGrey1_fill,
  MediumCopter_Group1_CylinderGrey1_d,
  MediumCopter_Group1_CylinderGrey1_fill,
  MediumCopter_Group1_FixBlack1_d,
  MediumCopter_Group1_FixBlack1_fill,
  MediumCopter_Group1_MachineGunGrey1_d,
  MediumCopter_Group1_MachineGunGrey1_fill,
  MediumCopter_Group1_MissileGrey1_d,
  MediumCopter_Group1_MissileGrey1_fill,
  MediumCopter_Group1_MissileGrey2_d,
  MediumCopter_Group1_MissileGrey2_fill,
  MediumCopter_Group1_RotorBladeFrame1_Black1_d,
  MediumCopter_Group1_RotorBladeFrame1_Black1_fill,
  MediumCopter_Group1_RotorBladeFrame1_Grey1_d,
  MediumCopter_Group1_RotorBladeFrame1_Grey1_fill,
  MediumCopter_Group1_RotorBladeFrame2_Black1_d,
  MediumCopter_Group1_RotorBladeFrame2_Black1_fill,
  MediumCopter_Group1_RotorBladeFrame2_Grey1_d,
  MediumCopter_Group1_RotorBladeFrame2_Grey1_fill,
  MediumCopter_Group1_RotorStrut1_d,
  MediumCopter_Group1_RotorStrut1_fill,
  MediumCopter_Group1_TailWingGrey1_d,
  MediumCopter_Group1_TailWingGrey1_fill,
  MediumCopter_Group1_TailWingGrey2_d,
  MediumCopter_Group1_TailWingGrey2_fill,
  MediumCopter_Group1_WheelsInnerGrey1_d,
  MediumCopter_Group1_WheelsInnerGrey1_fill,
  MediumCopter_Group1_WheelStrutGrey1_d,
  MediumCopter_Group1_WheelStrutGrey1_fill,
  MediumCopter_Group1_WindowBlue1_d,
  MediumCopter_Group1_WindowBlue1_fill,
  MediumCopter_Group1_WingGrey1_d,
  MediumCopter_Group1_WingGrey1_fill,
  MediumCopter_height,
  MediumCopter_width,
} from '../../../svg/sprites_data'
import { DEFAULT_GLOBAL_COMPOSITE_OPERATION, SOURCE_ATOP } from '../../canvas'
import { createOffscreenCanvas } from '../../create_canvas'
import { addBackgroundBorder } from '../../draw/add_background_border'
import createFadedFrameCanvas from '../../draw/createFadedFrameCanvas'
import { EitherRenderingContext2D, getCanvas2dContext } from '../../get_canvas_2d_context'

const totalLoopMs = 500
const frameDurationMs = totalLoopMs / 2

export default function makeRenderMediumCopter(
  engine: Engine,
  entity: Entity
): RenderSpriteFunction {
  // console.log('makeRenderMediumCopter')
  const tile_size = engine.viewCtx.tile_size
  const player: Nullable<Player> = getEntityPlayer(engine, entity)

  const sRenderMult = tile_size / BASE_TILE_SIZE
  const sRenderMultInv = BASE_TILE_SIZE / tile_size
  const sWidth = MediumCopter_width * sRenderMult
  const sHeight = MediumCopter_height * sRenderMult

  const frames: Array<OffscreenCanvas> = []
  const fadedFrames: Array<OffscreenCanvas> = []

  function makeFrame(frameIndex: number): OffscreenCanvas {
    const elem = createOffscreenCanvas(sWidth, sHeight)

    const ctx = getCanvas2dContext(elem)
    if (ctx) {
      ctx.scale(sRenderMult, sRenderMult)
      ctx.fillStyle = MediumCopter_Group1_WheelStrutGrey1_fill
      ctx.fill(MediumCopter_Group1_WheelStrutGrey1_d)

      ctx.fillStyle = MediumCopter_Group1_RotorStrut1_fill
      ctx.fill(MediumCopter_Group1_RotorStrut1_d)

      ctx.fillStyle = MediumCopter_Group1_WheelsInnerGrey1_fill
      ctx.fill(MediumCopter_Group1_WheelsInnerGrey1_d)

      ctx.fillStyle = player?.color2 || MediumCopter_Group1_TailWingGrey1_fill
      ctx.fill(MediumCopter_Group1_TailWingGrey1_d)

      ctx.fillStyle = player?.color2 || MediumCopter_Group1_BodyLowerGrey1_fill
      ctx.fill(MediumCopter_Group1_BodyLowerGrey1_d)

      ctx.fillStyle = player?.color1 || MediumCopter_Group1_BodyFrontGrey1_fill
      ctx.fill(MediumCopter_Group1_BodyFrontGrey1_d)

      ctx.globalCompositeOperation = SOURCE_ATOP
      ctx.fillStyle = MediumCopter_Group1_WindowBlue1_fill
      ctx.fill(MediumCopter_Group1_WindowBlue1_d)
      ctx.globalCompositeOperation = DEFAULT_GLOBAL_COMPOSITE_OPERATION

      ctx.globalAlpha = 0.75
      ctx.fillStyle = MediumCopter_Group1_FixBlack1_fill
      ctx.fill(MediumCopter_Group1_FixBlack1_d)
      ctx.globalAlpha = 1

      ctx.fillStyle = player?.color2 || MediumCopter_Group1_MissileGrey1_fill
      ctx.fill(MediumCopter_Group1_MissileGrey1_d)

      ctx.fillStyle = player?.color2 || MediumCopter_Group1_MissileGrey2_fill
      ctx.fill(MediumCopter_Group1_MissileGrey2_d)

      ctx.fillStyle = player?.color1 || MediumCopter_Group1_WingGrey1_fill
      ctx.fill(MediumCopter_Group1_WingGrey1_d)

      ctx.fillStyle = player?.color1 || MediumCopter_Group1_CylinderGrey1_fill
      ctx.fill(MediumCopter_Group1_CylinderGrey1_d)

      ctx.fillStyle = player?.color1 || MediumCopter_Group1_TailWingGrey2_fill
      ctx.fill(MediumCopter_Group1_TailWingGrey2_d)

      ctx.scale(sRenderMultInv, sRenderMultInv)
      addBackgroundBorder(ctx, elem, 0, 0, 0, 255, 1)
      ctx.scale(sRenderMult, sRenderMult)

      ctx.fillStyle = MediumCopter_Group1_MachineGunGrey1_fill
      ctx.fill(MediumCopter_Group1_MachineGunGrey1_d)

      if (frameIndex === 0) {
        ctx.fillStyle = MediumCopter_Group1_RotorBladeFrame1_Black1_fill
        ctx.fill(MediumCopter_Group1_RotorBladeFrame1_Black1_d)

        ctx.fillStyle = player?.color1 || MediumCopter_Group1_RotorBladeFrame1_Grey1_fill
        ctx.fill(MediumCopter_Group1_RotorBladeFrame1_Grey1_d)
      } else {
        ctx.fillStyle = MediumCopter_Group1_RotorBladeFrame2_Black1_fill
        ctx.fill(MediumCopter_Group1_RotorBladeFrame2_Black1_d)

        ctx.fillStyle = player?.color1 || MediumCopter_Group1_RotorBladeFrame2_Grey1_fill
        ctx.fill(MediumCopter_Group1_RotorBladeFrame2_Grey1_d)
      }
    }
    return elem
  }

  const fn = function renderMediumCopter(
    ctx: EitherRenderingContext2D,
    lm: LayerMeta,
    entity: Entity,
    dx: number,
    dy: number,
    tile_size: number
  ): void {
    // console.log('renderMediumCopter', Math.floor(lm.ts), Math.floor(lm.until))
    let frameIndex = 0
    let frame: Nullable<OffscreenCanvas>

    if (shouldEntityShowFadedFrames(entity)) {
      // if the unit was built, or is out of moves
      // it shall be faded instead of bright and animated
      fadedFrames[0] ||= createFadedFrameCanvas((frames[0] ||= makeFrame(0)))
      frame = fadedFrames[0]
    } else if (player) {
      // unit is bouncy
      const mod = lm.ts % totalLoopMs
      if (mod > frameDurationMs) {
        frameIndex = 1
      }
      lm.until = min(lm.until, lm.ts + (frameDurationMs - mod))
    }

    if (!frame) {
      frame = frames[frameIndex] ||= makeFrame(frameIndex)
    }
    if (!frame) {
      throw new Error('unexpected !frame')
    }

    ctx.drawImage(frame, 0, 0, sWidth, sHeight, dx, dy, tile_size, tile_size)
  }
  return fn
}
