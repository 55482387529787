import type { Entity } from '../entity/index'
import createGridXyOfEmptyArrays from './createGridXyOfEmptyArrays'
import type { EntityGridXY } from './EntityGridXY.type'

export default function entityListToGridXY(ents: Array<Entity>, gridWidth: number, gridHeight: number): EntityGridXY {
  // console.log('entityListToGridXY')
  // Initialize the grid as an empty array
  // const grid: EntityGridXY = []
  const grid = createGridXyOfEmptyArrays<Entity>(gridWidth, gridHeight)

  for (let index = 0; index < ents.length; index++) {
    const entity = ents[index]
    const { x, y } = entity

    // Ensure the row exists in the grid
    if (!grid[x]) {
      grid[x] = []
    }
    if (!grid[x][y]) {
      grid[x][y] = []
    }

    // Place the entity in the correct (x, y) position
    // console.log('grid', grid)
    // console.log('grid[x]', grid[x])
    // console.log('grid[x][y]', grid[x][y])
    grid[x][y].push(entity)
  }

  return grid
}
