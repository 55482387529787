import type { Nullable } from "../../../typescript"
import findByIdOrThrow from "../findByIdOrThrow"
import type { HasPlayerId } from "../player/has_player_id"
import { samePosition } from "../tile_position_xy/samePosition"
import type { TilePositionXY } from "../tile_position_xy/TilePositionXY.type"
import entityTypeMetaList from "./entityTypeMetaList.generated"
import type { HasTaxiID } from "./HasTaxiID"
import type { Entity } from "./index"

export default function getTransportableDestPositionEntity(
  ents : Array<Entity>,
  taxiEnt: Entity,
  destPosition: Nullable<TilePositionXY>
): Nullable<Entity> {
  const entityTypeMeta = findByIdOrThrow(entityTypeMetaList, taxiEnt.etype_id)
  const transportsEntityIdList = entityTypeMeta.transports
  const taxiPlayerId = (taxiEnt as HasPlayerId).player_id
  if (destPosition && taxiPlayerId && transportsEntityIdList) {
    for (let index = 0; index < ents.length; index++) {
      const cargoEnt = ents[index];
      if ((cargoEnt as HasTaxiID).taxi_id) {
        // skip, already in taxi
      } else if ((cargoEnt as HasPlayerId).player_id === taxiPlayerId) {
        if (transportsEntityIdList.includes(cargoEnt.etype_id)) {
          if (samePosition(cargoEnt, destPosition)) {
            return cargoEnt
          }
        }
      }
    }
  }
}