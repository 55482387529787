import { literal, nullable, object, type InferOutput } from 'valibot'
import { Engine } from '../../../../engine/Engine.type'
import calculateSelectedPositionEnts from '../../../../entity/calculateSelectedPositionEnts'
import { Entity } from '../../../../entity/index'
import nextEntityIdFromEngine from '../../../../entity/next_entity_id_from_engine'
import { PaintTileOption, PaintTileOptionSchema } from '../../../../map_editor/paint_tile_option'
import { PlayerId, PlayerIdSchema } from '../../../../player/PlayerId'
import {
  TilePositionXY,
  TilePositionXYSchema,
} from '../../../../tile_position_xy/TilePositionXY.type'
import { ActionType } from '../ActionType'
import type { ActionLog } from '../../ActionLog.type'

export const PaintTileActionSchema = object({
  type: literal(ActionType.Map.PaintTile),
  options: PaintTileOptionSchema,
  position: TilePositionXYSchema,
  selectedPlayerId: nullable(PlayerIdSchema),
})

export type PaintTileAction = InferOutput<typeof PaintTileActionSchema>

// export type PaintTileAction = {
//   type: ActionType.Map.PaintTile
//   options: PaintTileOption
//   position: TilePositionXY
//   selectedPlayerId: PlayerId | null
// }

export function createPaintTileAction(
  options: PaintTileOption,
  position: TilePositionXY,
  selectedPlayerId: PlayerId | null
): PaintTileAction {
  return {
    type: ActionType.Map.PaintTile,
    options,
    position,
    selectedPlayerId,
  }
}

export async function handlePaintTileAction(
  engine: Engine,
  action: PaintTileAction,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  actionLog: ActionLog
): Promise<void> {
  const { options, position, selectedPlayerId } = action
  let painted = false
  // console.log('handlePaintTileAction', options, position)
  const x = position.x
  const y = position.y
  const layer_id = options?.entity?.layer_id
  if (layer_id) {
    const { state } = engine
    const ents = state.ents
    const a: Array<Entity> = []

    const newEnt: Entity = {
      ...options.entity,
      x: position.x,
      y: position.y,
      id: nextEntityIdFromEngine(state),
    } as Entity
    if ('player_id' in newEnt && options.assign_selected_player) {
      newEnt.player_id = selectedPlayerId
    }

    // only one entity shall occupy any tile per layer
    // if there is an exception, it will probably be added here
    for (let index = 0, ent_len = ents.length; index < ent_len; index++) {
      const ent = ents[index]
      // console.log('handlePaintTileAction', ent.layer_id, ent.layer_id, ent.x, ent.y)
      // console.table([ent, newEnt])
      if (layer_id == ent.layer_id && x == ent.x && y == ent.y) {
        if (painted) {
          // console.log('handlePaintTileAction', 'drop because duplicate')
          continue // drop because duplicate
        } else {
          // console.log('handlePaintTileAction', 'ents[index] = newEnt')
          painted = true
          a.push(newEnt)
        }
      } else {
        a.push(ents[index])
      }
    }
    if (!painted) {
      // console.log('handlePaintTileAction', "Added: no replacible tile found")
      a.push(newEnt)
      // console.log('handlePaintTileAction', newEnt)
    }
    state.ents = a
    calculateSelectedPositionEnts(engine)
  } else {
    // idk what a null entity layer would mean
    // but maybe later
    throw new Error('Unexpected !options?.entity?.layer_id')
  }
}
