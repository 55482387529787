import type { Nullable } from "../../../../typescript"
import { getTurnsLastStep } from "./getTurnsLastStep"
import type { PathTurnStepCollection } from "./PathTurnStepCollection.type"
import type { PathTurnSteps_Step } from "./PathTurnSteps_Step.type"

export function getLastStep(currentBestPath: Nullable<PathTurnStepCollection>): PathTurnSteps_Step | null {
  if (!currentBestPath) return null
  const { turns, lastStep } = currentBestPath
  if (lastStep) return lastStep
  // Check if there are any turns in the path
  // Get the last turn
  return getTurnsLastStep(turns)
}
