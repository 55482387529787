import type { NullableSize } from '@solid-primitives/resize-observer'
import { type Setter, createEffect } from 'solid-js'
import { modifyMutable, produce } from 'solid-js/store'
import calculateMapWidthHeightPxTileSize from '../../lib/core/calculateMapWidthHeightPx'
import type { Engine } from '../../lib/core/engine/Engine.type'
import { floor, min } from '../../lib/core/util/math'
import { BASE_TILE_SIZE } from '../../lib/core/view_ctx'

type ShowCanvasProps = {
  width: number
  height: number
  minWidth?: number
  minHeight?: number
}

const defaultMaxSize = 512
// const defaultMinSize = 128

// const tile_gap_px = 0
const tile_gap_px = 1

export default function createCanvasListStyleEffect(
  engine: Engine,
  props: ShowCanvasProps,
  elementSize: Readonly<NullableSize>,
  setContainerStyles: Setter<Partial<CSSStyleDeclaration>>
) {
  createEffect(() => {
    const { state } = engine
    const stateWidth = state.width
    const stateHeight = state.height
    // the absolute max size
    const absMaxWidthPx = stateWidth * floor(props.width / stateWidth)
    const absMaxHeightPx = stateHeight * floor(props.height / stateHeight)
    const tile_size_plus_gap = floor(min(absMaxWidthPx / stateWidth, absMaxHeightPx / stateHeight))
    const tile_size = tile_size_plus_gap - tile_gap_px

    modifyMutable(
      engine,
      produce((engine: Engine) => {
        const { viewCtx } = engine
        // const biggestRenderSidePx = max(renderWidthPx, renderHeightPx)

        // viewCtx.zoom = 25
        // viewCtx.zoom = floor((100 * floor(renderWidthPx / biggestSideTileCount)) / BASE_TILE_SIZE)
        // viewCtx.tile_size = calculateTileSize(viewCtx)
        viewCtx.tile_gap_px = tile_gap_px

        const maxWidth = props.width || defaultMaxSize
        let maxHeight = props.height || defaultMaxSize

        viewCtx.tile_size = tile_size
        // console.log('zoom', floor(min(absMaxWidthPx/stateWidth, absMaxHeightPx/stateHeight)), `floor(min(${absMaxWidthPx}/${stateWidth}, ${absMaxHeightPx}/${stateHeight}))`)
        viewCtx.zoom = (tile_size * 100) / BASE_TILE_SIZE
        calculateMapWidthHeightPxTileSize(engine)
        // console.log('viewCtx.zoom', viewCtx.zoom, 'tile_size', tile_size)
        // viewCtx.tile_gap_px = 1
        viewCtx.renderWidthPx = (tile_size + tile_gap_px) * stateWidth - tile_gap_px
        viewCtx.renderHeightPx = (tile_size + tile_gap_px) * stateHeight - tile_gap_px

        // console.log('viewCtx.renderWidthPx', viewCtx.renderWidthPx, 'viewCtx.renderHeightPx', viewCtx.renderHeightPx)
        viewCtx.mapWidthPx = viewCtx.renderWidthPx
        viewCtx.mapHeightPx = viewCtx.renderHeightPx
        // console.log({ canvasWidth: viewCtx.renderWidthPx, canvasHeight: viewCtx.renderHeightPx })
        maxHeight = min(maxHeight, viewCtx.renderHeightPx)

        setContainerStyles({
          width: `${viewCtx.renderWidthPx}px`,
          height: `${viewCtx.renderHeightPx}px`,
          maxWidth: `${maxWidth}px`,
          maxHeight: `${maxHeight}px`,
        })
        engine.canvasList.forEach((elem) => {
          elem.width = viewCtx.renderWidthPx
          elem.height = viewCtx.renderHeightPx
          const style = elem.style
          style.width = `${viewCtx.renderWidthPx}px`
          style.height = `${viewCtx.renderHeightPx}px`
          style.maxWidth = `${maxWidth}px`
          style.maxHeight = `${maxHeight}px`
          style.marginLeft = style.marginRight = `${(maxWidth - viewCtx.renderWidthPx) / 2}px`
          style.marginTop = style.marginBottom = `${(maxHeight - viewCtx.renderHeightPx) / 2}px`
        })
      })
    )
  })
}
