import type { BaseEntity } from '../entity/BaseEntity'
import type { HasTaxiID } from '../entity/HasTaxiID'
import entityTypeMetaList from '../entity/entityTypeMetaList.generated'
import type { Entity } from '../entity/index'
import findByIdOrThrow from '../findByIdOrThrow'
import type { HasPlayerId } from '../player/has_player_id'
import type { EntityGridXY } from './EntityGridXY.type'
import createLazyReadOnlyGridXY from './createLazyReadOnlyGridXY'

type CargoList = Array<Entity & HasTaxiID>

export default function createLazyCargoListGrid(
  // full state width
  gridWidth: number,
  // full state height
  gridHeight: number,
  // full state ents as GridXY
  grid: EntityGridXY,
  // unit attempting to enter a taxi
  taxiEnt: Entity
) {
  const entityTypeMeta = findByIdOrThrow(entityTypeMetaList, taxiEnt.etype_id)
  const transportsEntityIdList = entityTypeMeta.transports
  const lazyGrid = createLazyReadOnlyGridXY<CargoList>(
    gridWidth,
    gridHeight,
    function cargoListGridGetter(x: number, y: number): CargoList {
      const list : CargoList = []
      if (transportsEntityIdList) {
        const positionStack = grid?.[x]?.[y]
        if (positionStack) {
          if ((taxiEnt as BaseEntity)?.cargo?.length as number >= 0) {
            const taxiPlayerId = (taxiEnt as HasPlayerId).player_id
            for (let index = 0; index < positionStack.length; index++) {
              const cargoEnt = positionStack[index];
              if ((cargoEnt as HasPlayerId).player_id === taxiPlayerId) {
                if (transportsEntityIdList.includes(cargoEnt.etype_id)) {
                  list.push(cargoEnt as Entity & HasTaxiID)
                }
              }
            }
          }
        }
      }
      return list
    }
  )
  return lazyGrid
}
