export default function parseValidNumberFromInputEventOrNull(event: InputEvent) {
  const target: HTMLInputElement | null = event.target as HTMLInputElement | null
  // console.log('input.min, input.max', target?.min, target?.max)
  let newValue = parseFloat(target?.value as string) || null
  const max = parseFloat(target?.max as string)
  const min = parseFloat(target?.min as string)
  if (newValue !== null && (newValue > max || min > newValue)) {
    newValue = null
  }
  return newValue
}
