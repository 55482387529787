import { DisplayNameSchema } from '@sg/shared/src/valibot/schema/DisplayName.type'
import { boolean, object, optional, type InferOutput } from 'valibot'
import { EntitySchema } from '../entity/index'
import { PaintTileOptionFolderIdSchema } from './paint_tile_option_folder_id.enum'

export const PaintTileOptionSchema = object({
  dname: DisplayNameSchema,
  entity: optional(EntitySchema),
  assign_selected_player: optional(boolean()),
  folder_id: PaintTileOptionFolderIdSchema,
})

export type PaintTileOption = InferOutput<typeof PaintTileOptionSchema>
