export type GridXY<T> = T[][]

export default function createGridXY<T>(
  gridWidth: number,
  gridHeight: number,
  defaultValue: T
): GridXY<T> {
  const grid: GridXY<T> = Array.from({ length: gridWidth }, () =>
    Array(gridHeight).fill(defaultValue)
  )

  return grid
}
