import { BioSchema } from '@sg/shared/src/valibot/schema/Bio.type'
import { OptionalNullableDisplayNameSchema } from '@sg/shared/src/valibot/schema/DisplayName.type'
import { InferOutput, nullable, object, optional } from 'valibot'
import { StateMapDiskSchema } from './state_map_disk.enum'
import { StateOnlineIdSchema } from './StateOnlineId.type'

export const MAP_DEFAULT_WIDTH = 10
export const MAP_DEFAULT_HEIGHT = 10
// AWBW uses 50
// Civ2 also has a W times H must be less than X
export const MAP_MIN_SIZE = 8
export const MAP_MAX_SIZE = 32

export const StateMapSchema = object({
  id: optional(nullable(StateOnlineIdSchema)),
  dname: OptionalNullableDisplayNameSchema,
  bio: optional(nullable(BioSchema)),
  disk: StateMapDiskSchema,
})

export type StateMap = InferOutput<typeof StateMapSchema>
