import { InferOutput, maxValue, minValue, number, object, pipe } from 'valibot'

export const RNGConfigSchema = object({
  str: pipe(number(), minValue(0), maxValue(20)), // strength of RNG
  crit: pipe(number(), minValue(0), maxValue(20)), // strength of critical hits
  seed: number(),
  state: number(),
})

// TODO: should seed and state values be null or  'server' for multiplayer games?

export type RNGConfig = InferOutput<typeof RNGConfigSchema>
