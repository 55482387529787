import { format, formatDistanceToNow } from 'date-fns'
import NA from "../Util/na"
import type { JSXElement } from 'solid-js'

interface Props {
  value: string | null | undefined
  na?: JSXElement,
}

/**
 * @example 1999-01-01
 */
const formatStr = 'y-MM-dd'

const formatDistanceToNowOptions = {
  addSuffix: true,
}

const DateFormat = (props: Props) => {
  // createEffect(() => console.log('DateFormat.createEffect.props.value', props.value))
  return <>
    {props.value ? <time
      title={formatDistanceToNow(props.value, formatDistanceToNowOptions)}
      datetime={props.value}
    >{format(props.value, formatStr)}</time> : (props.na || NA())}
  </>
}


export default DateFormat