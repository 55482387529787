import type { HasMobility } from "../has_mobility"
import type { Entity } from "./index"
import { MoveTypeId } from "./move_type_id.enum"

type AirUnitEntity = Entity & HasMobility & {
  mtype_id: MoveTypeId.Helicopter
}

export default function isTargetEntityAirUnit (target : Entity): target is AirUnitEntity {
  if ((target as HasMobility).mtype_id === MoveTypeId.Helicopter) {
    return true
  }
  return false
}