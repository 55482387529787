import { InferOutput } from 'valibot'
import { OptionalLiteralTrueSchema } from './LiteralTrue'

export const AttackedThisTurnSchema = OptionalLiteralTrueSchema

export type AttackedThisTurn = InferOutput<typeof AttackedThisTurnSchema>

export type HasAttackedThisTurn = {
  attackedThisTurn?: AttackedThisTurn
}
