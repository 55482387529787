import type { Weapon } from './Weapon.type'
import { WeaponTypeId } from './WeaponTypeId.enum'

const maxAmmo = 6

export default function createMLRSWeapon(str: number): Weapon {
  return {
    wt_id: WeaponTypeId.Rocket,
    str,
    maxAmmo,
    ammo: maxAmmo,
    ammops: 1,
    minRange: 3,
    maxRange: 5,
  }
}
