import { Nullable } from '../../../../typescript'
import { Engine } from '../../../core/engine/Engine.type'
import { Entity } from '../../../core/entity'
import { LayerMeta } from '../../../core/layer_meta'
import getEntityPlayer from '../../../core/player/get_entity_player'
import { Player } from '../../../core/player/Player.type'
import { RenderSpriteFunction } from '../../../core/render_sprite'
import { min } from '../../../core/util/math'
import { BASE_TILE_SIZE } from '../../../core/view_ctx'
import {
  Mountain_Group1_Blue1_d,
  Mountain_Group1_Blue1_fill,
  Mountain_Group1_Blue2_d,
  Mountain_Group1_Blue2_fill,
  Mountain_Group1_Brown1_d,
  Mountain_Group1_Brown1_fill,
  Mountain_Group1_Brown2_d,
  Mountain_Group1_Brown2_fill,
  Mountain_Group1_Green1_d,
  Mountain_Group1_Green1_fill,
  Mountain_Group1_Green2_d,
  Mountain_Group1_Green2_fill,
  Mountain_Group1_Green3_d,
  Mountain_Group1_Green3_fill,
  Mountain_Group1_Green4_d,
  Mountain_Group1_Green4_fill,
  Mountain_Group1_Orange1_d,
  Mountain_Group1_Orange1_fill,
  Mountain_Group1_Yellow1_d,
  Mountain_Group1_Yellow1_fill,
  Mountain_height,
  Mountain_width,
} from '../../../svg/sprites_data'
import { createOffscreenCanvas } from '../../create_canvas'
import { EitherRenderingContext2D, getCanvas2dContext } from '../../get_canvas_2d_context'

const totalLoopMs = 500
const frameDurationMs = totalLoopMs / 2

export default function makeRenderMountain(engine: Engine, entity: Entity): RenderSpriteFunction {
  // console.log('makeRenderMountain')
  const tile_size = engine.viewCtx.tile_size
  const player: Nullable<Player> = getEntityPlayer(engine, entity)

  const sRenderMult = tile_size / BASE_TILE_SIZE
  // const sRenderMultInv = BASE_TILE_SIZE / tile_size
  const sWidth = Mountain_width * sRenderMult
  const sHeight = Mountain_height * sRenderMult

  const frames: Array<OffscreenCanvas> = []

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  function makeFrame(frameIndex: number): OffscreenCanvas {
    const elem = createOffscreenCanvas(sWidth, sHeight)

    const ctx = getCanvas2dContext(elem)
    if (ctx) {
      ctx.scale(sRenderMult, sRenderMult)

      ctx.fillStyle = Mountain_Group1_Brown1_fill
      ctx.fill(Mountain_Group1_Brown1_d)

      ctx.fillStyle = Mountain_Group1_Green4_fill
      ctx.fill(Mountain_Group1_Green4_d)

      ctx.fillStyle = Mountain_Group1_Green3_fill
      ctx.fill(Mountain_Group1_Green3_d)

      ctx.fillStyle = Mountain_Group1_Yellow1_fill
      ctx.fill(Mountain_Group1_Yellow1_d)

      ctx.fillStyle = Mountain_Group1_Green1_fill
      ctx.fill(Mountain_Group1_Green1_d)

      ctx.fillStyle = Mountain_Group1_Orange1_fill
      ctx.fill(Mountain_Group1_Orange1_d)

      ctx.fillStyle = Mountain_Group1_Brown2_fill
      ctx.fill(Mountain_Group1_Brown2_d)

      ctx.fillStyle = Mountain_Group1_Blue1_fill
      ctx.fill(Mountain_Group1_Blue1_d)

      ctx.fillStyle = Mountain_Group1_Blue2_fill
      ctx.fill(Mountain_Group1_Blue2_d)

      ctx.fillStyle = Mountain_Group1_Green2_fill
      ctx.fill(Mountain_Group1_Green2_d)
    }
    return elem
  }

  const fn = function renderMountain(
    ctx: EitherRenderingContext2D,
    lm: LayerMeta,
    entity: Entity,
    dx: number,
    dy: number,
    tile_size: number
  ): void {
    // console.log('renderMountain', Math.floor(lm.ts), Math.floor(lm.until))
    let frameIndex = 0

    // tank is bouncy
    if (player) {
      const mod = lm.ts % totalLoopMs
      if (mod > frameDurationMs) {
        frameIndex = 1
      }
      lm.until = min(lm.until, lm.ts + (frameDurationMs - mod))
    }

    ctx.drawImage(
      (frames[frameIndex] ||= makeFrame(frameIndex)),
      0,
      0,
      sWidth,
      sHeight,
      dx,
      dy,
      tile_size,
      tile_size
    )
  }
  return fn
}
