import { modifyMutable, produce } from 'solid-js/store'
import { Engine } from '../engine/Engine.type'
import { floor } from '../util/math'

/**
 * Adjusts the `pan_x` and `pan_y` values of the engine's `viewCtx` to center the visible area
 * of the canvas based on the rendering dimensions and buffer calculations.
 *
 * The function calculates the offset required to center the canvas content (game grid) within the
 * rendering area, taking into account:
 * - Tile size and gaps between tiles (`tile_size` and `tile_gap_px`)
 * - The dimensions of the grid (`width` and `height`)
 * - Extra buffer space around the canvas (`canvas_edge_buffer`)
 * - The rendering area dimensions (`renderWidthPx` and `renderHeightPx`)
 *
 * The centering is performed both horizontally (`pan_x_f` and `pan_x`) and vertically (`pan_y_f` and `pan_y`),
 * and the final integer positions are floored for pixel alignment.
 *
 * @param {Engine} engine - The game engine object containing the `viewCtx` and state properties.
 */
export function centerPan(engine: Engine): void {
  modifyMutable(
    engine,
    produce((engine: Engine) => {
      const { viewCtx } = engine
      const { width, height } = engine.state

      const { renderWidthPx, renderHeightPx, tile_size, tile_gap_px, canvas_edge_buffer } = viewCtx
      const bufferWidthPx = tile_size * width + tile_gap_px * (width - 1) + 2 * canvas_edge_buffer
      const bufferHeightPx =
        tile_size * height + tile_gap_px * (height - 1) + 2 * canvas_edge_buffer

      viewCtx.pan_x_f = (renderWidthPx - bufferWidthPx) / 2 + canvas_edge_buffer
      viewCtx.pan_y_f = (renderHeightPx - bufferHeightPx) / 2 + canvas_edge_buffer

      viewCtx.pan_x = floor(viewCtx.pan_x_f)
      viewCtx.pan_y = floor(viewCtx.pan_y_f)
    })
  )
}
