import type { Engine } from '../engine/Engine.type'
import entityListToGridXY from '../tile_position_xy/entityListToGridXY'
import findMostEfficientPathToTileV2 from '../tile_position_xy/findMostEfficientPathToTileV2/findMostEfficientPathToTileV2'
import { samePosition } from '../tile_position_xy/samePosition'

export default function calculateDestPositionHoverPositionPaths(engine: Engine): void {
  // console.log('calculateDestPositionHoverPositionPaths')
  const { draftMove } = engine
  if (draftMove) {
    const { state, hoveredPosition } = engine
    const { destPosition } = draftMove
    draftMove.destPositionPaths = null
    draftMove.hoveredPositionPaths = null
    // draftMove.rangedAttackPaths = null
    if (destPosition || hoveredPosition) {

      const { unit } = draftMove
      const { ents, width, height } = state

      const entityGridXY = entityListToGridXY(ents, width, height)

      if (destPosition) {
        draftMove.destPositionPaths = findMostEfficientPathToTileV2(
          entityGridXY,
          unit,
          width,
          height,
          destPosition.x,
          destPosition.y
        )
      }
      if (hoveredPosition) {
        if (samePosition(hoveredPosition, destPosition)) {
          draftMove.hoveredPositionPaths = draftMove.destPositionPaths
        } else {
          draftMove.hoveredPositionPaths = findMostEfficientPathToTileV2(
            entityGridXY,
            unit,
            width,
            height,
            hoveredPosition.x,
            hoveredPosition.y
          )
        }
      }
    }
  }
}
