import { A } from '@solidjs/router'
import { Component } from 'solid-js'
import SignOutButton from '../../components/SignOutButton'
import { createSessionSignal } from '../../rx/shared/session/createSessionSignal'

const SignOutPage: Component = () => {
  const session = createSessionSignal()[0]
  return (
    <div class="d-flex justify-content-center">
      <div class="col-6">
        <h1>Simple Tactics</h1>
        <br />
        {session() ? <SignOutButton /> : <>
          <p>
            You are not signed in
          </p>
          <A class="btn btn-primary" href="/auth/sign-in">Sign In</A>
        </>}
      </div>
    </div>
  )
}

export default SignOutPage
