import findByIdOrThrow from '../findByIdOrThrow'
import type { HasMobility } from '../has_mobility'
import entityTypeMetaList from './entityTypeMetaList.generated'
import type { Entity } from './index'

export default function isEntityAtFullMobility(unit: Entity): unit is Entity & HasMobility {
  const etm = findByIdOrThrow(entityTypeMetaList, unit.etype_id)
  const { mobility } = unit as HasMobility
  return (mobility && ((etm.entDefaults as HasMobility).mobility === mobility)) as boolean
}
