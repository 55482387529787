import { useBeforeLeave, type BeforeLeaveEventArgs } from '@solidjs/router'
import type { Accessor } from 'solid-js'
import onWindowEvent from '../../lib/onWindowEvent'

export default function useConfirmLeaveWithUnsavedChanges(shouldAskBeforeLeave: Accessor<boolean>) {
  useBeforeLeave((e: BeforeLeaveEventArgs) => {
    if (shouldAskBeforeLeave() && !e.defaultPrevented) {
      // preventDefault to block immediately and prompt user async
      e.preventDefault()
      setTimeout(() => {
        if (window.confirm('Discard unsaved changes - are you sure?')) {
          // user wants to proceed anyway so retry with force=true
          e.retry(true)
        }
      }, 100)
    }
  })

  function handleBeforeUnload(event: BeforeUnloadEvent) {
    if (shouldAskBeforeLeave()) {
      event.preventDefault()
    }
  }
  onWindowEvent('beforeunload', handleBeforeUnload)
}
