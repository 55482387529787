// The MAX_SAFE_INTEGER is 9,007,199,254,740,991
// but we don't want local ids to have to be strings
// on any potential future platform
// @link https://www.assemblyscript.org/types.html

// the smallest ID for our object's primary key
export const SG_SMALLEST_ID = 100000
export const POSTGRES_MAX_INT = 2147483647

export const MAX_I32 = 2147483647;
export const MIN_I32 = -2147483648;
export const MAX_I16 = 32767;
export const MIN_I16 = -32768;
export const MAX_I8 = 32767;
export const MIN_I8 = -32768;

export const ONE_MINUTE_SECONDS = 60;
export const ONE_HOUR_SECONDS = 60 * ONE_MINUTE_SECONDS;
export const ONE_DAY_SECONDS = 24 * ONE_HOUR_SECONDS;
export const SEVEN_DAYS_SECONDS = 7 * ONE_DAY_SECONDS;

let counter: number = 0;

export function nextCounter(): number {
  return ++counter;
}