import { OtpRequestSchema } from '@sg/backend/src/api/auth/handleOtp'
import { SignInWithOtpRequestSchema } from '@sg/backend/src/api/auth/handleSignIn'
import { useNavigate, useSearchParams } from '@solidjs/router'
import { Component, createSignal, Show } from 'solid-js'
import { parse } from 'valibot'
import { authAxios } from '../../axios'
import AlertBag from '../../components/AlertBag'
import Loader from '../../components/Loader'
import preventDefault from '../../lib/dom/event/prevent_default'
import { updateSessionValue } from '../../rx/shared/session/sessionValue'
import { createLoadingSignal } from '../../rx/signal/create_loading_signal'
import { createAlertBagStore, setAlertBagError, setAlertBagSuccess } from '../../rx/store/create_alert_bag_store'

type SignInPageSearchParams = Record<'return_to', string>

const SignInPage: Component = () => {
  const navigate = useNavigate()
  const loading = createLoadingSignal()
  const ab = createAlertBagStore()
  const [email, setEmail] = createSignal('')
  const [otp, setOtp] = createSignal('')
  const [requestToken, setRequestToken] = createSignal<string | null>(null)

  const [searchParams] = useSearchParams<SignInPageSearchParams>()

  const handleRequestOtp = async (e: SubmitEvent) => {
    preventDefault(e)
    setAlertBagError(ab, null)
    setAlertBagSuccess(ab, null)

    try {
      loading.start()

      const payload = parse(OtpRequestSchema, {
        email: email(),
      })
      const result = await authAxios.post('/auth/otp', payload)

      setAlertBagSuccess(ab, 'Check your email for a code!')
      setRequestToken(result.data.token) // Assume the API returns the UUID associated with the OTP request
    } catch (error) {
      console.error(error)
      setAlertBagError(ab, error as Error)
    } finally {
      loading.end()
    }
  }

  const handleVerifyOtp = async (e: SubmitEvent) => {
    preventDefault(e)
    setAlertBagError(ab, null)
    setAlertBagSuccess(ab, null)

    try {
      loading.start()

      const payload = parse(SignInWithOtpRequestSchema, {
        otp: otp(),
        token: requestToken(),
      })
      const result = await authAxios.post('/auth/sign-in', payload)

      if (result.data) {
        updateSessionValue(result.data)
      }

      setAlertBagSuccess(ab, 'Login Successful!')

      if (searchParams.return_to) {
        navigate(searchParams.return_to)
      }
    } catch (error) {
      console.error(error)
      setAlertBagError(ab, error as Error)
    } finally {
      loading.end()
    }
  }

  return (
    <div class="d-flex justify-content-center">
      <div class="col-6">
        <h1>Simple Tactics</h1>
        <Show when={!requestToken()} fallback={
          <form onSubmit={handleVerifyOtp}>
            <div class="form-group mb-3">
              <label class="form-label" for="otp">OTP Code</label>
              <input
                id="otp"
                class="form-control"
                type="text"
                placeholder="Enter the OTP code"
                value={otp()}
                onChange={(e) => setOtp(e.currentTarget.value)}
              />
            </div>
            <div class="form-group mb-3">
              <button type="submit" class="btn btn-primary d-block">
                {loading() ? <span>Verifying...</span> : <span>Verify OTP</span>}
              </button>
            </div>
            <AlertBag store={ab} />
          </form>
        }>
          <form onSubmit={handleRequestOtp}>
            <div class="form-group mb-3">
              <p class="form-text">Enter your email to receive an OTP code</p>
              <label class="form-label" for="email">Email</label>
              <input
                id="email"
                class="form-control"
                type="email"
                placeholder="Your email"
                value={email()}
                onChange={(e) => setEmail(e.currentTarget.value)}
              />
            </div>
            <div class="form-group mb-3">
              <button type="submit" class="btn btn-primary d-block">
                {loading() ? <span>Loading...</span> : <span>Send OTP</span>}
              </button>
            </div>
            <AlertBag store={ab} />
          </form>
        </Show>
      </div>
      <Loader signal={loading} />
    </div>
  )
}

export default SignInPage
