import { EntityTypeId } from '../../../entity/entity_type_id.enum'
import { Clobber } from './run/Clobber.enum'

export type MapScript_FormData_StageEntRule = {
  etype_id: EntityTypeId
  weight: number
  clobber?: Clobber
}

export function createStageEntWeight(
  etype_id: EntityTypeId,
  weight: number = 1,
  clobber?: Clobber
): MapScript_FormData_StageEntRule {
  return {
    etype_id,
    weight,
    clobber,
  }
}
