import type { ProfileId } from '@sg/st1/src/lib/core/ProfileId.type'
import createDefaultStateOnline from '@sg/st1/src/lib/core/state/create_default_state_online'
import randomStateIdValue from '@sg/st1/src/lib/core/state/randomStateIdValue'
import { StateSchema } from '@sg/st1/src/lib/core/state/State.type'
import { StateType } from '@sg/st1/src/lib/core/state/state_type.enum'
import type { Nullable } from '@sg/st1/src/typescript'
import { Context } from 'hono'
import { object, parse, type InferOutput } from 'valibot'
import authOr401 from '../../../lib/auth/authOr401'
import createAdminSupabaseClient from '../../../lib/supabase/createAdminSupabaseClient'

export const StoreMapBodySchema = object({
  state: StateSchema,
})

export type StoreMapBody = InferOutput<typeof StoreMapBodySchema>

export default async function handleStoreMap(c: Context) {
  console.log('handleStoreMap')
  const auth = await authOr401(c)

  console.log('c.req.json()', await c.req.json())
  const { state } = parse(StoreMapBodySchema, await c.req.json())

  const supabaseAdmin = createAdminSupabaseClient(c)

  // console.log('auth', auth)
  const userId: Nullable<ProfileId> = auth.sub
  console.log('userId', userId)
  if (!userId) {
    throw new Error('User not found')
  }

  const mapId = randomStateIdValue()

  state.online = state.online || createDefaultStateOnline()
  state.online.id = mapId
  state.map.id = mapId
  state.type = StateType.Map

  const { data, error } = await supabaseAdmin
    .from('st1_states')
    .insert({
      id: mapId,
      data: state,
      owner_profile_id: userId,
    })
    .select('id')

  console.log('data', data)

  if (error) {
    throw error
  }

  return c.json(
    {
      id: data[0].id,
    },
    201
  )
}
