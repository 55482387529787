import type { BaseEntity } from '../entity/BaseEntity'
import type { HasCargoList } from '../entity/HasCargoList'
import entityTypeMetaList from '../entity/entityTypeMetaList.generated'
import type { Entity } from '../entity/index'
import findByIdOrThrow from '../findByIdOrThrow'
import type { HasPlayerId } from '../player/has_player_id'
import type { EntityGridXY } from './EntityGridXY.type'
import createLazyReadOnlyGridXY from './createLazyReadOnlyGridXY'

type TaxiList = Array<Entity & HasCargoList>

export default function createLazyTaxiListGrid(
  // full state width
  gridWidth: number,
  // full state height
  gridHeight: number,
  // full state ents as GridXY
  grid: EntityGridXY,
  // unit attempting to enter a taxi
  cargoEnt: Entity
) {
  const lazyGrid = createLazyReadOnlyGridXY<TaxiList>(
    gridWidth,
    gridHeight,
    function taxiListGridGetter(x: number, y: number): TaxiList {
      const positionStack = grid?.[x]?.[y]
      const list : TaxiList = []
      if (positionStack) {
        const cargoPlayerId = (cargoEnt as HasPlayerId).player_id
        for (let index = 0; index < positionStack.length; index++) {
          const taxiEnt = positionStack[index];
          if ((taxiEnt as HasPlayerId).player_id === cargoPlayerId) {
            if ((taxiEnt as BaseEntity)?.cargo?.length as number >= 0) {
              const entityTypeMeta = findByIdOrThrow(entityTypeMetaList, taxiEnt.etype_id)
              const transportsEntityIdList = entityTypeMeta.transports
              if (transportsEntityIdList && transportsEntityIdList.includes(cargoEnt.etype_id)) {
                list.push(taxiEnt as Entity & HasCargoList)
              }
            }
          }
        }
      }
      return list
    }
  )
  return lazyGrid
}
