import type { BackendSessionPayload } from '@sg/backend/src/api/auth/BackendAuthSession.type'
import { isoNow } from '@sg/shared/src/lib/Date'
import { Accessor } from 'solid-js'
import { backendAxios } from '../../axios'
import createDefaultWait from '../../lib/createDefaultWait'
import { LoadingSignal } from '../../rx/signal/create_loading_signal'
import {
    AlertBagStore,
    setAlertBagError,
    setAlertBagSuccess,
} from '../../rx/store/create_alert_bag_store'
import { Nullable } from '../../typescript'

export interface HasIdNumber {
  id: number
  // deleted_at: string | null
}

// the mutate function is for use with signals or resource[1].mutate
export type MutateFunction = (item: HasIdNumber) => HasIdNumber
export type OnChangeFunction = (newValue: string | null) => void
export type GetItemValueFunction = (item: HasIdNumber) => string | null
export type SetItemValueFunction = (item: HasIdNumber, newValue: string | null) => void

export type GetAlertBagSuccessMessageFunction = (
  newValue: string | null,
  noun: string
) => Nullable<string>

// export class MutateTimestampFunctionSubject {
//   k: string
//   n: string
//   // abstract k: string
//   // abstract n: string
//   // n: string
//   constructor(k : string, noun : string) {
//     this.k = k
//     this.n = noun
//   }
//   g(item : HasIdNumber) : string | null {
//     return (item as any)[this.k]
//   }
//   s(item : HasIdNumber, newValue : string | null): void {
//     (item as any)[this.k] = newValue
//   }
//   m(newValue : string | null, noun : string) : Nullable<string> {
//     // return newValue ? `${noun} deleted` : `${noun} restored.`
//     return `${noun} updated`
//   }
// }

interface Props {
  disabled: Nullable<boolean>
  item: HasIdNumber
  // ariaLabel: string,
  onChange: OnChangeFunction
  ab: AlertBagStore
  noun: string
}

export function onClickMutateTimestampFunctionFactory(
  props: Props,
  timestampKey: string,
  ab: AlertBagStore,
  session: Accessor<Nullable<BackendSessionPayload>>,
  loading: LoadingSignal,
  getAlertBagSuccessMessage: GetAlertBagSuccessMessageFunction,
  getItemValue: GetItemValueFunction
) {
  return async () => {
    // console.log('DeleteButton.onClick')
    const wait = createDefaultWait()
    const itemId = props.item.id
    if (!itemId) {
      // throw new Error('!itemId')
      return
    }
    try {
      setAlertBagError(ab, null)
      const user_id = session()?.sub
      if (!user_id) {
        return
      }
      loading.start()

      const newTimeStampValue = getItemValue(props.item) ? null : isoNow()

      await backendAxios.post(`/state/${itemId}`, {
        [timestampKey]: newTimeStampValue,
        updated_at: isoNow(),
      })

      const absm = getAlertBagSuccessMessage(newTimeStampValue, props.noun)
      setAlertBagSuccess(ab, absm)

      // function mutateFn(item: HasIdNumber) {
      //   // console.log('mutateFn', item)
      //   item = { ...item }
      //   setItemValue(item, newTimeStampValue)
      //   return item
      // }
      props.onChange(newTimeStampValue)
    } catch (error) {
      // console.error(error)
      setAlertBagError(ab, error as Error)
    } finally {
      await wait
      loading.end()
    }
  }
}
