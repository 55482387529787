import { EmailSchema } from '@sg/shared/src/valibot/schema/Email.type'
import { UuidStringSchema } from '@sg/shared/src/valibot/schema/UuidString.type'
import { Context } from 'hono'
import { env } from 'hono/adapter'
import { sign } from 'hono/jwt'
import { Resend } from 'resend'
import { object, parse } from 'valibot'
import createAdminSupabaseClient from '../../lib/supabase/createAdminSupabaseClient'

export const OtpRequestSchema = object({
  email: EmailSchema,
})

export const OtpRequestTokenSchema = object({
  email: EmailSchema,
  uuid: UuidStringSchema,
})

export default async function handleOtpSignIn(c: Context) {
  const email = parse(OtpRequestSchema, await c.req.json()).email.toLowerCase()

  const supabaseAdmin = createAdminSupabaseClient(c)

  try {

    const { data, error } = await supabaseAdmin.rpc('auth_email_otp_create', {
      input_email: email,
    });
    if (error) {
      throw error
    }
    const row = data[0]
    if (!row) {
      throw new Error('Unexpected !row')
    }
    console.log('result', { row })

    // TODO: send email

    // token that represents the OTP request,
    // expires in 10 minutes
    const token: string = await sign({
      email,
      uuid: row.uuid,
      exp: Date.now() + 1000 * 60 * 10,
    }, env<{ JWT_SECRET: string }>(c).JWT_SECRET)

    const RESEND_API_KEY = env<{ RESEND_API_KEY: string }>(c).RESEND_API_KEY
    const payload = {
      token,
    }

    // include information about who requested the OTP?
    // CF-Connecting-IP
    // CF-IPCountry

    if (RESEND_API_KEY) {
      const resend = new Resend(RESEND_API_KEY);
      const sendEmailPayload = {
        from: 'no-reply@email.spacegazebo.com',
        tags: [{name: 'category', value: 'otp'}],
        to: email,
        subject: 'Your OTP code for Sign In',
        html: `<div style="font-family: Arial, sans-serif; text-align: center; padding: 20px; background-color: #f9f9f9; border-radius: 10px; color: #333;">
  <h2 style="color: #555;">Your OTP Code</h2>
  <p style="font-size: 16px; margin: 10px 0;">Use the code below to sign in:</p>
  <div style="display: inline-block; padding: 15px 25px; background-color: #007BFF; color: #fff; font-size: 28px; font-weight: bold; border-radius: 8px; letter-spacing: 4px;">
    ${row.generated_otp}
  </div>
  <p style="font-size: 14px; color: #666; margin-top: 20px;">
    If you did not request this code, please ignore this email.
  </p>
</div>
`,
      }
      const {
        data: sendEmailData,
        error: sendEmailError,
      } = await resend.emails.send(sendEmailPayload);
      if (sendEmailError) {
        console.error('sendEmailError', sendEmailError, sendEmailData, sendEmailPayload)
        return c.json({
          message: sendEmailError.message || 'ISE',
        }, 500)
      } else {
        // console.log('sendEmailData', sendEmailData)
      }
    } else {
      console.log(`OTP CODE: ${row.generated_otp}`)
      // for local development when no RESEND_API_KEY is present,
      // we will allow the developer to see the OTP in the network tab
      ;(payload as unknown as {debug_uuid: string}).debug_uuid = row.uuid
      ;(payload as unknown as {debug_otp: string}).debug_otp = row.generated_otp
    }

    return c.json(payload)
  } catch (err) {
    console.error(err)
    if ((err as Error)?.name) {
      return c.json({
        error: (err as Error)?.name,
      }, 500)
    }
    return c.json({
      error: 'ISE',
    }, 500)
  }
}
