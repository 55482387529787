import { Entity } from '../../core/entity/index'
import { LayerMeta } from '../../core/layer_meta'
import { EitherRenderingContext2D } from '../get_canvas_2d_context'

export default function defaultErrorRender(
  ctx: EitherRenderingContext2D,
  lm: LayerMeta,
  entity: Entity,
  dx: number,
  dy: number,
  d_tile_size: number
) {
  ctx.globalAlpha = 0.5
  ctx.strokeStyle = 'green'
  ctx.fillRect(dx, dy, d_tile_size, d_tile_size)
  ctx.globalAlpha = 1
  ctx.strokeStyle = 'red'
  ctx.lineWidth = 4
  ctx.strokeRect(dx, dy, d_tile_size, d_tile_size)
  ctx.moveTo(dx, dy)
  ctx.lineTo(dx + d_tile_size, dy + d_tile_size)
  ctx.moveTo(dx + d_tile_size, dy)
  ctx.lineTo(dx, dy + d_tile_size)
  ctx.stroke()
}
