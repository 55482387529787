import { array, check, InferOutput, maxLength, minLength, pipe } from 'valibot'
import { PlayerSchema } from '../player/Player.type'
import { MAX_PLAYERS, MIN_PLAYERS } from '../player/consts'
import idsAreUnique from './validation/idsAreUnique'

export const PlayerListSchema = pipe(
  array(PlayerSchema),
  minLength(MIN_PLAYERS),
  maxLength(MAX_PLAYERS),
  check(idsAreUnique as () => boolean, 'Duplicate Player IDs')
)

export type PlayerList = InferOutput<typeof PlayerListSchema>
