import { MapRowValue } from '@sg/backend/src/lib/db/getMapOr404'
import { Component, onMount } from 'solid-js'
import { createMutable, modifyMutable, produce, unwrap } from 'solid-js/store'
import toast from 'solid-toast'
import bindViewCtxToWindow from '../lib/bindViewCtxToWindow'
import bindEngineToComponent from '../lib/canvas/bind_engine_to_component'
import addBgGradientCanvas from '../lib/canvas/layer/bg_gradient'
import addDrawEntsByKindCanvas from '../lib/canvas/layer/ents_by_type'
import addTerrainCursorCanvasOld from '../lib/canvas/layer/terrain_cursor/add_canvas'
import addCursorCanvas from '../lib/canvas/layer/terrain_cursor/addCursorCanvas'
import calculateMapWidthHeightPxTileSize from '../lib/core/calculateMapWidthHeightPx'
import calculateTileSize from '../lib/core/calculateTileSize'
import createCenterPanOnMount from '../lib/core/createCenterPanOnMount'
import createEngineForMapEditor from '../lib/core/engine/createEngineForMapEditor'
import { Engine } from '../lib/core/engine/Engine.type'
import syncEngineWithPropsRow from '../lib/core/engine/syncEngineWithPropsRow'
import { EntityLayerId } from '../lib/core/entity/entity_layer_id.enum'
import { SelectedToolId } from '../lib/core/map_editor/SelectedToolId.enum'
import { createIsStateDirtyMemo } from '../lib/core/state/create_is_state_dirty_memo'
import { centerPan } from '../lib/core/view_ctx/centerPan'
import makeCanvasFullViewport from '../lib/dom/canvas/makeCanvasFullViewport'
import registerContextMenuEvent from '../lib/dom/event/context_menu'
import registerMouseClickEvent from '../lib/dom/event/mouse_click'
import registerMouseDownEvent from '../lib/dom/event/mouse_down'
import registerMouseMoveEvent from '../lib/dom/event/mouse_move'
import registerMouseUpEvent from '../lib/dom/event/mouse_up'
import registerKeyDownEvent from '../lib/dom/event/registerKeyDownEvent'
import registerKeyUpEvent from '../lib/dom/event/registerKeyUpEvent'
import registerMapEditKeyboardEvents from '../lib/dom/event/registerMapEditKeyboardEvents'
import registerTouchEventsV2 from '../lib/dom/event/registerTouchEventsV2'
import registerWheelEvent from '../lib/dom/event/wheel'
import createSetEngineAuthPlayerIdFromAuthProfileSignalEffect from '../rx/effect/createSetEngineAuthPlayerIdFromAuthProfileSignalEffect'
import createAuthProfileSignal from '../rx/shared/profile/createAuthProfileSignal'
import registerServerSentEvents from '../rx/sse/registerServerSentEvents'
import useConfirmLeaveWithUnsavedChanges from '../rx/use/useConfirmLeaveWithUnsavedChanges'
import EngineContext from './EngineContext'
import MapEditorToolbar from './Hud/MapEditorToolbar'
import ZoomCardWidget from './Hud/ZoomCardWidget'

interface Props {
  // session: BackendSessionPayload,
  row: MapRowValue,
}

const MapEditCanvas: Component<Props> = (props) => {
  const engine: Engine = createMutable<Engine>(createEngineForMapEditor())

  onMount(() => {
    syncEngineWithPropsRow(engine, props.row)
  })

  const [authProfile] = createAuthProfileSignal()
  createSetEngineAuthPlayerIdFromAuthProfileSignalEffect(engine, authProfile)

  function getEngine() { return unwrap(engine) }
  (window as unknown as { getEngine: () => Engine }).getEngine = getEngine

  bindViewCtxToWindow(engine)
  createCenterPanOnMount(engine)

  addBgGradientCanvas(engine)
  // addTerrainGridCanvas(engine)
  addDrawEntsByKindCanvas(engine, [EntityLayerId.TerrainL1])
  addDrawEntsByKindCanvas(engine, [EntityLayerId.TerrainL2, EntityLayerId.Unit2])

  // addDrawOffMapCloudsCanvas(engine)
  addTerrainCursorCanvasOld(engine)
  addCursorCanvas(engine)

  registerMouseMoveEvent(engine)
  registerMouseDownEvent(engine)
  registerMouseUpEvent(engine)
  registerMouseClickEvent(engine)
  registerContextMenuEvent(engine)
  registerWheelEvent(engine)
  registerKeyUpEvent(engine)
  registerKeyDownEvent(engine)
  registerMapEditKeyboardEvents(engine, true)
  // registerTouchEventsV2(engine)

  modifyMutable(engine, produce((engine) => {
    engine.canvasList.forEach(makeCanvasFullViewport)
  }))

  const isStateDirty = createIsStateDirtyMemo(engine)
  useConfirmLeaveWithUnsavedChanges(isStateDirty)

  bindEngineToComponent(engine)
  registerServerSentEvents(engine)

  modifyMutable(engine, produce((engine) => {
    engine.toast = toast
    // engine.selectedPosition = { x: 13, y: 9 }
    // calculateSelectedPositionEnts(engine)
    // engine.selectedTool = SelectedToolId.File
    engine.selectedTool = SelectedToolId.Inspect
    // engine.selectedTool = SelectedToolId.Paint
    // engine.selectedTool = SelectedToolId.Erase
    engine.viewCtx.zoom = 200
    engine.viewCtx.tile_size = calculateTileSize(engine.viewCtx)
    calculateMapWidthHeightPxTileSize(engine)
    centerPan(engine)
  }))

  return <EngineContext.Provider value={engine}>
    <div ref={(ref : HTMLElement) => {
      // console.log('ref', ref)
      if (ref) {
        registerTouchEventsV2(engine, ref)
      }
    }}>
      {engine.canvasList}
      <MapEditorToolbar/>
      <ZoomCardWidget/>
    </div>
  </EngineContext.Provider>
}

export default MapEditCanvas
