import { StateType } from './state_type.enum'
import type { StateTypeMeta } from './StateTypeMeta.type'

export const StateTypeMap : StateTypeMeta = {
  id: StateType.Map, dname: 'Map', api: 'st1/map'
} as const
export const StateTypeLobby : StateTypeMeta = {
  id: StateType.Lobby, dname: 'Lobby', api: 'st1/lobby'
} as const
export const StateTypeGame : StateTypeMeta = {
  id: StateType.Game, dname: 'Game', api: 'st1/game'
} as const
export const StateTypeReplay : StateTypeMeta = {
  id: StateType.Replay, dname: 'Replay', api: 'st1/replay'
} as const

const stateTypeMetaList: Array<StateTypeMeta> = [
  StateTypeMap,
  StateTypeLobby,
  StateTypeGame,
  StateTypeReplay,
] as const

export default stateTypeMetaList
