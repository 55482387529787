import { Nullable } from '../../../../typescript'
import { Engine } from '../../../core/engine/Engine.type'
import { Entity } from '../../../core/entity'
import { LayerMeta } from '../../../core/layer_meta'
import getEntityPlayer from '../../../core/player/get_entity_player'
import { Player } from '../../../core/player/Player.type'
import { LIGHTS_ON_HEX, SHADOW_ALPHA, SHADOW_FILL } from '../../../core/player/player_color'
import { RenderSpriteFunction } from '../../../core/render_sprite'
import { min } from '../../../core/util/math'
import { BASE_TILE_SIZE } from '../../../core/view_ctx'
import {
  Building_Group1_BuildingGrey1_d,
  Building_Group1_BuildingGrey1_fill,
  Building_Group1_RoofBeige1_d,
  Building_Group1_RoofBeige1_fill,
  Building_Group1_Shadow1_d,
  Building_Group1_WindowDot1_d,
  Building_Group1_WindowDot1_fill,
  Building_height,
  Building_width,
} from '../../../svg/sprites_data'
import { createOffscreenCanvas } from '../../create_canvas'
import { EitherRenderingContext2D, getCanvas2dContext } from '../../get_canvas_2d_context'

const totalLoopMs = 2000

export default function makeRenderBuilding(engine: Engine, entity: Entity): RenderSpriteFunction {
  // console.log('makeRenderBuilding')
  const tile_size = engine.viewCtx.tile_size
  const player: Nullable<Player> = getEntityPlayer(engine, entity)

  const sRenderMult = tile_size / BASE_TILE_SIZE
  // const sRenderMultInv = BASE_TILE_SIZE / tile_size
  const sWidth = Building_width * sRenderMult
  const sHeight = Building_height * sRenderMult

  const frames: Array<OffscreenCanvas> = []

  function makeFrame(frameIndex: number): OffscreenCanvas {
    const elem = createOffscreenCanvas(sWidth, sHeight)

    const ctx = getCanvas2dContext(elem)
    if (ctx) {
      ctx.scale(sRenderMult, sRenderMult)

      ctx.fillStyle = player?.color1 || Building_Group1_BuildingGrey1_fill
      ctx.fill(Building_Group1_BuildingGrey1_d)

      ctx.fillStyle = player?.color1 || Building_Group1_BuildingGrey1_fill
      ctx.fill(Building_Group1_BuildingGrey1_d)

      ctx.fillStyle = player?.color2 || Building_Group1_RoofBeige1_fill
      ctx.fill(Building_Group1_RoofBeige1_d)

      ctx.fillStyle = LIGHTS_ON_HEX
      ctx.fill(Building_Group1_WindowDot1_d)

      ctx.globalAlpha = SHADOW_ALPHA
      ctx.fillStyle = SHADOW_FILL
      ctx.fill(Building_Group1_Shadow1_d)

      ctx.globalAlpha = 1

      if (frameIndex === 0) {
        ctx.fillStyle = Building_Group1_WindowDot1_fill
        ctx.fill(Building_Group1_WindowDot1_d)
      }
    }
    return elem
  }

  const fn = function renderBuilding(
    ctx: EitherRenderingContext2D,
    lm: LayerMeta,
    entity: Entity,
    dx: number,
    dy: number,
    tile_size: number
  ): void {
    // lights off by default
    let frameIndex = 0
    const dWidth = tile_size
    const dHeight = 2 * tile_size

    // sometimes lights on
    if (player) {
      const mod = lm.ts % totalLoopMs
      if (mod > 400) {
        frameIndex = 1
      }
      lm.until = min(lm.until, lm.ts + (totalLoopMs - mod))
    }

    // const player: Nullable<Player> = entity?.player_id
    ctx.drawImage(
      (frames[frameIndex] ||= makeFrame(frameIndex)),
      0,
      0,
      sWidth,
      sHeight,
      dx,
      dy - tile_size,
      dWidth,
      dHeight
    )
  }

  return fn
}
