import { Nullable } from '../../../../typescript'
import { Engine } from '../../../core/engine/Engine.type'
import createCursorEntityAnimation from '../../../core/entity/animation/createCursorEntityAnimation'
import { EntityAnimation } from '../../../core/entity/animation/entity_animation'
import { EntityAnimationGroupId } from '../../../core/entity/animation/entity_animation_group_id.enum'
import { LayerMeta } from '../../../core/layer_meta'
import { CURSOR_GREY_HEX } from '../../../core/player/player_color'
import { TilePositionXY } from '../../../core/tile_position_xy/TilePositionXY.type'
import { BASE_TILE_SIZE } from '../../../core/view_ctx'
import {
  CursorTriangle_Group1_Black1_d,
  CursorTriangle_Group1_Black1_fill,
  CursorTriangle_Group1_Grey1_d,
  CursorTriangle_Group1_Grey1_fill,
  CursorTriangle_Group1_Yellow1_d,
} from '../../../svg/sprites_data'
import { createOffscreenCanvas } from '../../create_canvas'
import { EitherRenderingContext2D, getCanvas2dContext } from '../../get_canvas_2d_context'
import rotateCanvas90 from '../../rotateCanvas90'
import createInspectIconOffscreenCanvas from '../createInspectIconOffscreenCanvas'

const groupId = EntityAnimationGroupId.ToolInspectHoverCursor

function makeOneCorner(ctx: EitherRenderingContext2D, sRenderMult: number, sRenderMultInv: number) {
  ctx.scale(sRenderMult, sRenderMult)

  ctx.fillStyle = CursorTriangle_Group1_Grey1_fill
  ctx.fill(CursorTriangle_Group1_Grey1_d)

  ctx.fillStyle = CursorTriangle_Group1_Black1_fill
  ctx.fill(CursorTriangle_Group1_Black1_d)

  ctx.fillStyle = CURSOR_GREY_HEX
  ctx.fill(CursorTriangle_Group1_Yellow1_d)

  ctx.scale(sRenderMultInv, sRenderMultInv)
}

const ratio1 = 1.5
const ratio2 = 2
const ratio3 = 1

export default function makeInspectHoverCursorRenderFn(engine: Engine) {
  // console.log('makeInspectHoverCursorRenderFn')
  let animation: Nullable<EntityAnimation> = null
  const frameIndex = 0

  function makeFrame(tile_size: number) {
    const cursor_tile_size = tile_size * ratio1

    const sRenderMult = tile_size / (BASE_TILE_SIZE * ratio2)
    const sRenderMultInv = (BASE_TILE_SIZE * ratio2) / tile_size

    // console.log('inspectHoverCursorRenderFn.makeFrame', {tile_size, sRenderMult, sRenderMultInv})
    const elem = createOffscreenCanvas(cursor_tile_size, cursor_tile_size)
    const ctx = getCanvas2dContext(elem)

    if (ctx) {
      // ctx.globalAlpha = 0.25
      // ctx.fillRect(0,0, cursor_tile_size, cursor_tile_size)
      // ctx.globalAlpha = 1

      ctx.save()
      makeOneCorner(ctx, sRenderMult, sRenderMultInv)
      rotateCanvas90(ctx, cursor_tile_size)
      makeOneCorner(ctx, sRenderMult, sRenderMultInv)
      rotateCanvas90(ctx, cursor_tile_size)
      // makeOneCorner(ctx, sRenderMult, sRenderMultInv)
      rotateCanvas90(ctx, cursor_tile_size)
      makeOneCorner(ctx, sRenderMult, sRenderMultInv)

      ctx.restore()
    }

    {
      const cursor_tile_size2 = tile_size * 2
      const innerOffset = (cursor_tile_size2 - cursor_tile_size) / 2
      const iconSize = tile_size * ratio3
      const iconOffset = cursor_tile_size2 - iconSize
      // console.log('tile_size', tile_size, 'cursor_tile_size2', cursor_tile_size2)
      // console.log('iconSize', iconSize, 'iconOffset', iconOffset)
      const inspectIconElem = createInspectIconOffscreenCanvas(CURSOR_GREY_HEX, iconSize, iconSize)

      const elem2 = createOffscreenCanvas(cursor_tile_size2, cursor_tile_size2)
      const ctx = getCanvas2dContext(elem2)

      if (ctx) {
        ctx.drawImage(elem, innerOffset, innerOffset)

        ctx.translate(iconOffset, iconOffset)

        // ctx.strokeStyle = 'pink'
        // ctx.strokeRect(0, 0, iconSize, iconSize)
        // ctx.globalAlpha = 0.1
        // ctx.fillStyle = 'black'
        // ctx.fillRect(0, 0, iconSize, iconSize)
        // ctx.globalAlpha = 1

        // ctx.scale(sRenderMult, sRenderMult)
        ctx.drawImage(inspectIconElem, 0, 0)
      }
      return elem2
    }
  }

  const fn = function inspectHoverCursorRenderFn(
    ctx: EitherRenderingContext2D,
    lm: LayerMeta,
    position: TilePositionXY,
    tile_size: number
  ) {
    // console.log('inspectHoverCursorRenderFn', {tile_size, ...position})

    const { viewCtx } = engine
    const { tile_gap_px, pan_x, pan_y } = viewCtx
    const tile_size_plus_gap: number = tile_size + tile_gap_px

    if (animation?.tileSize !== tile_size) {
      // console.log('tile size changed', animation?.tileSize, 'to', tile_size, engine.frameCache)
      animation = engine.frameCache.find((v) => v.groupId === groupId && v.tileSize === tile_size)
      if (!animation) {
        // console.log('cache miss', 'engine.frameCache.length=', engine.frameCache.length)
        animation = createCursorEntityAnimation(
          EntityAnimationGroupId.ToolInspectHoverCursor,
          tile_size
        )
        engine.frameCache.push(animation)
      }
    }

    const frame = (animation.frames[frameIndex] ||= makeFrame(tile_size))
    // const dw: number = tile_size + (2 * tile_size_plus_gap)
    // const dh: number = tile_size + (2 * tile_size_plus_gap)
    const dw: number = frame.width
    const dh: number = frame.height

    // const cursor_offset_px = canvas_edge_buffer + ((dw - tile_size) / 2)
    const cursor_offset_px = -((dw - tile_size) / 2)
    // const cursor_offset_px = tile_size
    // const cursor_offset_px = 0

    const dx: number = position.x * tile_size_plus_gap + pan_x + cursor_offset_px
    const dy: number = position.y * tile_size_plus_gap + pan_y + cursor_offset_px

    // console.log('inspectHoverCursorRenderFn', {tile_size, dx, dy, dw, dh})

    // ctx.drawImage(frame, 0, 0, frame.width, frame.height, dx, dy, tile_size, tile_size)
    ctx.drawImage(frame, dx, dy, dw, dh)
  }
  return fn
}
