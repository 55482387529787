import type { HasPlayerId } from '../player/has_player_id'
import type { PlayerId } from '../player/PlayerId'
import type { Entity } from './index'

export default function getPlayerEntities(
  ents: Array<Entity>,
  playerId: PlayerId
): Array<Entity & HasPlayerId> {
  return ents.filter((ent: Entity): ent is Entity & HasPlayerId => {
    return (ent as HasPlayerId).player_id === playerId
  })
}
