import { isServer } from 'solid-js/web'
import { literal, object, type InferOutput } from 'valibot'
import { Engine } from '../../../../engine/Engine.type'
import { ActionType } from '../ActionType'
import type { ActionLog } from '../../ActionLog.type'


export const RestoreMapActionSchema = object({
  type: literal(ActionType.Map.RestoreMap),
})

export type RestoreMapAction = InferOutput<typeof RestoreMapActionSchema>

export function createRestoreMapAction(): RestoreMapAction {
  return {
    type: ActionType.Map.RestoreMap,
  }
}

export async function handleRestoreMapAction(
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  engine: Engine,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  action: RestoreMapAction,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  actionLog: ActionLog
): Promise<void> {
  // console.log('handleRestoreMapAction', action)

  if (isServer) {
    console.log('TODO isServer')
  } else {
    console.log('TODO !isServer')
    // probably nothing, unless is a local game
    // user will be redirected to a formal lobby page
  }
}
