import { EntityLayerId } from "../entity/entity_layer_id.enum"

export interface HasEntityLayerId {
  layer_id: EntityLayerId
}

export function byEntityLayerIdDesc(a: HasEntityLayerId, b: HasEntityLayerId) {
  return b.layer_id - a.layer_id
}

export function byEntityLayerIdAsc(a: HasEntityLayerId, b: HasEntityLayerId) {
  return a.layer_id - b.layer_id
}