import { InferOutput, literal, object } from 'valibot'
import { EntityTypeMeta, entityTypeDefaults } from '../../EntityTypeMeta'
import { HasXYSchema } from '../../has_xy'
import { EntityLayerId } from '../entity_layer_id.enum'
import { EntityTypeId } from '../entity_type_id.enum'
import { EntityIdSchema } from '../EntityId.type'
import { BaseEntitySchema } from '../BaseEntity'

const entDefaults = {
  etype_id: EntityTypeId.Desert,
  layer_id: EntityLayerId.TerrainL1,
} as const

export const DesertEntitySchema = object({
  ...BaseEntitySchema.entries,
  etype_id: literal(EntityTypeId.Desert),
  layer_id: literal(EntityLayerId.TerrainL1),
  id: EntityIdSchema,
  ...HasXYSchema.entries,
})

export type DesertEntity = InferOutput<typeof DesertEntitySchema>

export const DesertEntityTypeMeta: EntityTypeMeta = {
  ...entityTypeDefaults,
  id: EntityTypeId.Desert,
  dname: 'Desert',
  layer_id: EntityLayerId.TerrainL1,
  defense: 1,
  entDefaults,
}

export function createDesertEntity(id: number, x: number, y: number): DesertEntity {
  return {
    ...entDefaults,
    id,
    x,
    y,
  }
}
