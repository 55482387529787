import type { Weapon } from './Weapon.type'
import { WeaponTypeId } from './WeaponTypeId.enum'

const maxAmmo = 9

export default function createAntiAirCannonWeapon(str: number): Weapon {
  return {
    wt_id: WeaponTypeId.AntiAirCannon,
    str,
    maxAmmo,
    ammo: maxAmmo,
    ammops: 1,
    ca: true,
  }
}
