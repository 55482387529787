import { MoveTypeId } from './move_type_id.enum'
import type { MoveTypeMeta } from './MoveTypeMeta.type'

const moveTypeMetaList: Array<MoveTypeMeta> = [
  { id: MoveTypeId.None, dname: 'None' },
  { id: MoveTypeId.Foot, dname: 'Foot' },
  { id: MoveTypeId.Boot, dname: 'Boot' },
  { id: MoveTypeId.Tires, dname: 'Tires' },
  { id: MoveTypeId.Treads, dname: 'Treads' },
  { id: MoveTypeId.Helicopter, dname: 'Helicopter' },
]

export default moveTypeMetaList
