import { Icon } from "solid-heroicons"
import { exclamationTriangle } from "solid-heroicons/outline"
import type { ParentComponent } from "solid-js"

const AlertWarning: ParentComponent = (props) => {
  return <div class="alert alert-warning" role="alert" aria-label="warning alert">
      <div>
        <Icon path={exclamationTriangle} class="icon" />
        {props.children}
      </div>
    </div>
}

export default AlertWarning