import { ArmorTypeId } from '../Armor/ArmorTypeId.enum'
import type { WeaponTypeArmorTypePair } from './WeaponTypeArmorTypePair.type'
import { WeaponTypeId } from './WeaponTypeId.enum'
import weaponTypeArmorTypePairList from './weaponTypeArmorTypePairList'

export default function getWeaponTypeArmorTypePair(
  wt_id: WeaponTypeId,
  at_id: ArmorTypeId
): WeaponTypeArmorTypePair {
  for (let index = 0; index < weaponTypeArmorTypePairList.length; index++) {
    const pair = weaponTypeArmorTypePairList[index]
    if (pair.wt === wt_id && pair.at === at_id) {
      return pair
    }
  }
  return { wt: wt_id, at: at_id, v: 1 }
}
