import { modifyMutable, produce } from 'solid-js/store'
import { Engine } from './engine/Engine.type'
import { abs, floor, max, min } from './util/math'

const MAX_VELOCITY = 25

const PAN_MULT = 3
const FRICTION = 1.8

export function progressArrowKeyPan(engine: Engine, frameMs: number): void {
  // console.log('progressArrowKeyPan', frameMs)
  modifyMutable(
    engine,
    produce((engine: Engine) => {
      const { viewCtx, keydownMap } = engine
      const { ArrowRight, ArrowLeft, ArrowDown, ArrowUp } = keydownMap

      // console.log('keydownMap', { ArrowRight, ArrowLeft, ArrowDown, ArrowUp })

      const framePanMult = PAN_MULT * (frameMs / 33)
      // const frameFriction = FRICTION * (frameMs / 33)
      // if frameFriction < 1, then it might make pan grow out of control
      const frameFriction = FRICTION

      if (ArrowRight && !ArrowLeft) {
        viewCtx.pan_vx = min(viewCtx.pan_vx + framePanMult, MAX_VELOCITY)
      } else if (viewCtx.pan_vx > 0) {
        viewCtx.pan_vx /= frameFriction
      }
      if (ArrowLeft && !ArrowRight) {
        viewCtx.pan_vx = max(viewCtx.pan_vx - framePanMult, -MAX_VELOCITY)
      } else if (viewCtx.pan_vx < 0) {
        viewCtx.pan_vx /= frameFriction
      }
      if (ArrowDown && !ArrowUp) {
        viewCtx.pan_vy = min(viewCtx.pan_vy + framePanMult, MAX_VELOCITY)
      } else if (viewCtx.pan_vy > 0) {
        viewCtx.pan_vy /= frameFriction
      }
      if (ArrowUp && !ArrowDown) {
        viewCtx.pan_vy = max(viewCtx.pan_vy - framePanMult, -MAX_VELOCITY)
      } else if (viewCtx.pan_vy < 0) {
        viewCtx.pan_vy /= frameFriction
      }

      if (abs(viewCtx.pan_vx) < 0.1) {
        viewCtx.pan_vx = 0
      }
      if (abs(viewCtx.pan_vy) < 0.1) {
        viewCtx.pan_vy = 0
      }

      // if (viewCtx.pan_vx !== 0 || viewCtx.pan_vy !== 0) {
      //   console.log('pan_vx', viewCtx.pan_vx, 'pan_vy', viewCtx.pan_vy)
      // }

      // Apply the deltas to the view context
      viewCtx.pan_x_f -= viewCtx.pan_vx
      viewCtx.pan_y_f -= viewCtx.pan_vy

      // Update floored values for pixel-perfect rendering
      viewCtx.pan_x = floor(viewCtx.pan_x_f)
      viewCtx.pan_y = floor(viewCtx.pan_y_f)
    })
  )
}
