import { A } from '@solidjs/router'

export default function NotFound() {
  return (
    <main>
      <h1>Not Found</h1>
      <h2>Page Not Found</h2>
      <p>
        Visit <A href="/">Home</A>{" "}.
      </p>
    </main>
  )
}
