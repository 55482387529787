import { AxiosError } from 'axios'
import { AlertBagValue } from '../../rx/store/create_alert_bag_store'

export default function alertBagValueToString(value: AlertBagValue): string {
  // console.log('message.value', value)
  if (value instanceof AxiosError) {
    const data = value.response?.data
    return data?.message || data?.error || value.message
  }
  return (value as Error)?.message || (value as string) || 'unknown'
}
