/*eslint @typescript-eslint/no-explicit-any: ["off"]*/

/**
 * @param input
 *
 * there is something called structured clone,
 * but it's neither faster nor better suited
 * for cloning state.
 *
 * valibot's parse will probably superceed deepClone
 *
 * @returns deep cloned input
 */
export function deepClone(input: any): any {
  if (input) {
    return JSON.parse(JSON.stringify(input))
  }
  return input
}
