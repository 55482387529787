import { Nullable } from '../../../../typescript'
import { Engine } from '../../../core/engine/Engine.type'
import { Entity } from '../../../core/entity'
import shouldEntityShowFadedFrames from '../../../core/entity/shouldEntityShowFadedFrames'
import { LayerMeta } from '../../../core/layer_meta'
import getEntityPlayer from '../../../core/player/get_entity_player'
import { Player } from '../../../core/player/Player.type'
import { SHADOW_ALPHA } from '../../../core/player/player_color'
import { RenderSpriteFunction } from '../../../core/render_sprite'
import { min } from '../../../core/util/math'
import { BASE_TILE_SIZE } from '../../../core/view_ctx'
import {
  MediumTank_Group1_BodyHorizontalLineGrey1_d,
  MediumTank_Group1_BodyHorizontalLineGrey1_fill,
  MediumTank_Group1_BodyLowerGrey1_d,
  MediumTank_Group1_BodyLowerGrey1_fill,
  MediumTank_Group1_BodyLowerGrey2_d,
  MediumTank_Group1_BodyLowerGrey2_fill,
  MediumTank_Group1_HeadCannonGrey1_d,
  MediumTank_Group1_HeadCannonGrey1_fill,
  MediumTank_Group1_HeadGrey1_d,
  MediumTank_Group1_HeadGrey1_fill,
  MediumTank_Group1_HeadGrey2_d,
  MediumTank_Group1_HeadGrey2_fill,
  MediumTank_Group1_HeadGrey3_d,
  MediumTank_Group1_HeadGrey3_fill,
  MediumTank_Group1_HeadHatGrey1_d,
  MediumTank_Group1_HeadHatGrey1_fill,
  MediumTank_Group1_HeadHatGrey2_d,
  MediumTank_Group1_HeadHatGrey2_fill,
  MediumTank_Group1_HeadHatGrey3_d,
  MediumTank_Group1_HeadHatGrey3_fill,
  MediumTank_Group1_HeadMachineGunGrey1_d,
  MediumTank_Group1_HeadMachineGunGrey1_fill,
  MediumTank_Group1_TreadBlack1_d,
  MediumTank_Group1_TreadBlack1_fill,
  MediumTank_Group1_WheelsInnerGrey1_d,
  MediumTank_Group1_WheelsInnerGrey1_fill,
  MediumTank_Group1_WheelsOuterBlack1_d,
  MediumTank_Group1_WheelsOuterBlack1_fill,
  MediumTank_height,
  MediumTank_width,
} from '../../../svg/sprites_data'
import { DEFAULT_GLOBAL_COMPOSITE_OPERATION, SOURCE_ATOP } from '../../canvas'
import { createOffscreenCanvas } from '../../create_canvas'
import { addBackgroundBorder } from '../../draw/add_background_border'
import createFadedFrameCanvas from '../../draw/createFadedFrameCanvas'
import { EitherRenderingContext2D, getCanvas2dContext } from '../../get_canvas_2d_context'

const totalLoopMs = 1000

export default function makeRenderMediumTank(engine: Engine, entity: Entity): RenderSpriteFunction {
  // console.log('makeRenderMediumTank')
  const tile_size = engine.viewCtx.tile_size
  const player: Nullable<Player> = getEntityPlayer(engine, entity)

  const sRenderMult = tile_size / BASE_TILE_SIZE
  const sRenderMultInv = BASE_TILE_SIZE / tile_size
  const sWidth = MediumTank_width * sRenderMult
  const sHeight = MediumTank_height * sRenderMult

  const frames: Array<OffscreenCanvas> = []
  const fadedFrames: Array<OffscreenCanvas> = []

  function makeFrame(frameIndex: number): OffscreenCanvas {
    const elem = createOffscreenCanvas(sWidth, sHeight)

    const ctx = getCanvas2dContext(elem)
    if (ctx) {
      ctx.scale(sRenderMult, sRenderMult)

      ctx.fillStyle = MediumTank_Group1_TreadBlack1_fill
      ctx.fill(MediumTank_Group1_TreadBlack1_d)

      ctx.fillStyle = MediumTank_Group1_WheelsOuterBlack1_fill
      ctx.fill(MediumTank_Group1_WheelsOuterBlack1_d)

      ctx.fillStyle = MediumTank_Group1_WheelsInnerGrey1_fill
      ctx.fill(MediumTank_Group1_WheelsInnerGrey1_d)

      if (frameIndex === 1 || frameIndex === 2) {
        ctx.translate(0, -1)
      }
      ctx.fillStyle = player?.color2 || MediumTank_Group1_BodyLowerGrey1_fill
      ctx.fill(MediumTank_Group1_BodyLowerGrey1_d)

      ctx.fillStyle = player?.color1 || MediumTank_Group1_BodyLowerGrey2_fill
      ctx.fill(MediumTank_Group1_BodyLowerGrey2_d)

      ctx.globalCompositeOperation = SOURCE_ATOP
      ctx.globalAlpha = 0.5
      ctx.fillStyle = MediumTank_Group1_BodyHorizontalLineGrey1_fill
      ctx.fill(MediumTank_Group1_BodyHorizontalLineGrey1_d)
      ctx.globalAlpha = 1
      ctx.globalCompositeOperation = DEFAULT_GLOBAL_COMPOSITE_OPERATION

      if (frameIndex === 2 || frameIndex === 3) {
        ctx.translate(-1, 0)
      }

      ctx.fillStyle = player?.color1 || MediumTank_Group1_HeadCannonGrey1_fill
      ctx.fill(MediumTank_Group1_HeadCannonGrey1_d)

      if (frameIndex === 2 || frameIndex === 3) {
        ctx.translate(1, 0)
      }

      ctx.fillStyle = player?.color2 || MediumTank_Group1_HeadHatGrey1_fill
      ctx.fill(MediumTank_Group1_HeadHatGrey1_d)

      ctx.fillStyle = MediumTank_Group1_HeadHatGrey2_fill
      ctx.fill(MediumTank_Group1_HeadHatGrey2_d)

      ctx.fillStyle = MediumTank_Group1_HeadHatGrey3_fill
      ctx.fill(MediumTank_Group1_HeadHatGrey3_d)

      ctx.fillStyle = player?.color1 || MediumTank_Group1_HeadGrey1_fill
      ctx.fill(MediumTank_Group1_HeadGrey1_d)

      ctx.fillStyle = MediumTank_Group1_BodyHorizontalLineGrey1_fill
      ctx.fill(MediumTank_Group1_BodyHorizontalLineGrey1_d)

      ctx.globalAlpha = SHADOW_ALPHA
      ctx.fillStyle = MediumTank_Group1_HeadGrey2_fill
      ctx.fill(MediumTank_Group1_HeadGrey2_d)
      ctx.globalAlpha = 1

      ctx.fillStyle = player?.color2 || MediumTank_Group1_HeadGrey3_fill
      ctx.fill(MediumTank_Group1_HeadGrey3_d)

      ctx.globalAlpha = SHADOW_ALPHA
      // ctx.globalCompositeOperation = LUMINOSITY
      ctx.fillStyle = player?.color2 || MediumTank_Group1_HeadGrey3_fill
      ctx.fill(MediumTank_Group1_HeadGrey3_d)
      // ctx.globalCompositeOperation = DEFAULT_GLOBAL_COMPOSITE_OPERATION
      ctx.globalAlpha = 1

      if (frameIndex === 1 || frameIndex === 2) {
        ctx.translate(0, 1)
      }

      ctx.scale(sRenderMultInv, sRenderMultInv)
      addBackgroundBorder(ctx, elem, 0, 0, 0, 255, 1)
      ctx.scale(sRenderMult, sRenderMult)

      if (frameIndex === 1 || frameIndex === 2) {
        ctx.translate(0, -1)
      }

      ctx.fillStyle = MediumTank_Group1_HeadMachineGunGrey1_fill
      ctx.fill(MediumTank_Group1_HeadMachineGunGrey1_d)

      if (frameIndex === 1 || frameIndex === 2) {
        ctx.translate(0, 1)
      }
    }
    return elem
  }

  const fn = function renderMediumTank(
    ctx: EitherRenderingContext2D,
    lm: LayerMeta,
    entity: Entity,
    dx: number,
    dy: number,
    tile_size: number
  ): void {
    // console.log('renderMediumTank', Math.floor(lm.ts), Math.floor(lm.until))
    let frameIndex = 0
    let frame: Nullable<OffscreenCanvas>

    if (shouldEntityShowFadedFrames(entity)) {
      // if the unit was built, or is out of moves
      // it shall be faded instead of bright and animated
      fadedFrames[0] ||= createFadedFrameCanvas((frames[0] ||= makeFrame(0)))
      frame = fadedFrames[0]
    } else if (player) {
      // unit is bouncy
      const mod = lm.ts % totalLoopMs
      if (mod > 800) {
        frameIndex = 3
      } else if (mod > 400) {
        frameIndex = 2
      } else if (mod > 200) {
        frameIndex = 1
      }
      lm.until = min(lm.until, lm.ts + (totalLoopMs - mod))
    }

    if (!frame) {
      frame = frames[frameIndex] ||= makeFrame(frameIndex)
    }
    if (!frame) {
      throw new Error('unexpected !frame')
    }

    ctx.drawImage(frame, 0, 0, sWidth, sHeight, dx, dy, tile_size, tile_size)
  }
  return fn
}
