import { MyProfilePayload } from '@sg/backend/src/api/profile/handleGetMe'
import { backendAxios } from '../../../axios'
import type { Nullable } from '../../../typescript'
import type { ProfileId } from '../../../lib/core/ProfileId.type'

export default async function fetchMyAuthProfile(
  profileId: Nullable<ProfileId>
): Promise<Nullable<MyProfilePayload>> {
  if (profileId) {
    const response = await backendAxios.get(`/profile/me`)

    return response.data as MyProfilePayload
  }
}
