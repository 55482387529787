import type { GameRowValue } from "@sg/backend/src/lib/db/getGameOr404"
import type { LobbyRowValue } from "@sg/backend/src/lib/db/getLobbyOr404"
import type { MapRowValue } from "@sg/backend/src/lib/db/getMapOr404"
import { modifyMutable, produce, unwrap } from "solid-js/store"
import type { Nullable } from "../../../typescript"
import { deepClone } from "../../deep_clone"
import type { Engine } from "./Engine.type"

export default function syncEngineWithPropsRow (engine : Engine, row: Nullable<GameRowValue | MapRowValue | LobbyRowValue>) {
  if (row) {
    const { data, action_logs } = row
    const newState = unwrap(data)
    modifyMutable(engine, produce((engine: Engine) => {
      engine.prevState = deepClone(newState)
      engine.state = deepClone(newState)
      engine.actionLogs = deepClone(unwrap(action_logs)) || []
    }))
  }
}