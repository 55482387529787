

import { createMemo, on, type Accessor } from "solid-js"
import { safeParse, type BaseIssue, type BaseSchema, type InferOutput, type SafeParseResult } from "valibot"

export default function createValibotSafeParseMemo<
  S extends BaseSchema<unknown, unknown, BaseIssue<unknown>>
>(
  schema : S,
  getValue: Accessor<unknown>,
): Accessor<SafeParseResult<S> & {
  value: InferOutput<S> | null
}> {
  const validatedSearchParam = createMemo(on(getValue, (value) => {
    const sp = safeParse(schema, value)
    // console.log('sp', sp)
    return {
      ...sp,
      value: sp.success ? sp.output : null,
    }
  }))

  return validatedSearchParam
}
