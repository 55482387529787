import { backendAxios } from '../../../../axios'
import noop from '../../../ldsh/noop'
import { Engine } from '../../engine/Engine.type'
import type { StateOnlineId } from '../StateOnlineId.type'
import Action from './action/Action.type'
import type { ActionLog } from './ActionLog.type'
import { typeToPathMap } from './typeToPathMap'

let prevPromise: Promise<unknown> | null = null

export default async function postRemoteAction(
  engine: Engine,
  action: Action,
  game_id: StateOnlineId,
  actionLog: ActionLog
): Promise<void> {
  await prevPromise

  const nextPromise = postRemoteActionInner(engine, action, game_id, actionLog).catch(noop)

  prevPromise = nextPromise.catch(noop)

  return nextPromise
}

async function postRemoteActionInner(
  engine: Engine,
  action: Action,
  game_id: StateOnlineId,
  actionLog: ActionLog
): Promise<void> {
  // console.log('<postRemoteActionInner>')
  const typePath = typeToPathMap[engine.state.type]
  const path = `/st1/${typePath}/${game_id}`
  const body = {
    action,
    cref: actionLog.cref,
  }
  // console.log('postRemoteAction', path, body)

  await backendAxios.post(path, body)
}
