import { OptionalNullableDisplayNameSchema } from '@sg/shared/src/valibot/schema/DisplayName.type'
import { InferOutput, nullable, number, object, optional, string } from 'valibot'
import { StateOnlineIdSchema } from './StateOnlineId.type'

export const StateOnlineSchema = object({
  id: optional(nullable(StateOnlineIdSchema)),
  dname: OptionalNullableDisplayNameSchema,
  bio: optional(nullable(string())),
  passphrase: optional(nullable(string())),
  /**
   * At the start of the game,
   * a player is given this much time to delay.
   * Once their timer runs out, they are declared
   * loser via inactivity.
   */
  initialTime: number(),
  /**
   * At the start of a player's turn,
   * additional time is added to their timer.
   */
  extraTimePerTurn: number(),
  /**
   * After the previous player's turn has ended,
   * the next player has this amount of time to make their move.
   * If the time is exceeded, the player's turn is automatically ended
   * and the next player's turn will start.
   */
  maxTimePerTurn: number(),
})

export type StateOnline = InferOutput<typeof StateOnlineSchema>
