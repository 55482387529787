import { modifyMutable, produce } from 'solid-js/store'
import type { Engine } from '../../core/engine/Engine.type'
import resetEngineToDefaultSelectedTool from '../../core/engine/resetEngineToDefaultSelectedTool'
import onWindowEvent from '../../onWindowEvent'

export default function registerContextMenuEvent(engine: Engine) {
  // const { viewCtx } = engine
  // const dirty = engine.cache[1]
  function onContextMenu(/*event: MouseEvent*/) {
    console.log('onContextMenu')

    modifyMutable(
      engine,
      produce((engine) => {
        resetEngineToDefaultSelectedTool(engine)
      })
    )
    // return false to prevent right click menu
    return false
  }

  onWindowEvent('contextmenu', onContextMenu)
}
