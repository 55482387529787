import type { Engine } from '../engine/Engine.type'
import { samePosition } from '../tile_position_xy/samePosition'
import { byEntityTypeIdAsc, byLayerIdDesc } from '../util/id'
import type { Entity } from './index'

export default function calculateSelectedPositionEnts(engine: Engine): void {
  const { selectedPosition } = engine
  if (selectedPosition) {
    engine.selectedPositionEnts = engine.state.ents
      .filter((ent: Entity) => samePosition(ent, selectedPosition))
      .sort(byEntityTypeIdAsc)
      .sort(byLayerIdDesc)
  } else {
    engine.selectedPositionEnts = []
  }
}
