import type { Nullable } from "../../../../../typescript"
import type { Engine } from "../../../engine/Engine.type"
import isMapControlTarget from "../../../entity/isMapControlTarget"
import findByIdOrThrow from "../../../findByIdOrThrow"
import { PlayerGameStatus } from "../../../player/PlayerGameStatus"
import type { PlayerId } from "../../../player/PlayerId"

export default function checkMapControlWinCondition(
  engine: Engine,
  annexingPlayerId: Nullable<PlayerId>
) {
  if (!annexingPlayerId) {
    return
  }
  const annexingPlayer = findByIdOrThrow(engine.state.players, annexingPlayerId)
  const { state } = engine
  const { config, ents } = state
  const { winMapControlPercent } = config

  if (winMapControlPercent) {
    // the first player to acquire X percent of the map wins
    const allMapControlTargets = ents.filter(ent => isMapControlTarget(ent))
    const playerMapControlTargets = allMapControlTargets.filter(ent => ent.player_id === annexingPlayerId)
    if (playerMapControlTargets.length / allMapControlTargets.length >= winMapControlPercent) {
      annexingPlayer.game_status = PlayerGameStatus.Won
    }
  }
}