import { LobbyRowValue } from '@sg/backend/src/lib/db/getLobbyOr404.ts'
import { createResource, type Accessor } from 'solid-js'
import { parse } from 'valibot'
import { backendAxios } from '../../axios'
import { StateSchema } from '../../lib/core/state/State.type'
import type { StateOnlineId } from '../../lib/core/state/StateOnlineId.type'
import { Nullable } from '../../typescript'

async function fetchLobbyById(stateId: Nullable<StateOnlineId>): Promise<LobbyRowValue | null> {
  if (stateId) {
    const response = await backendAxios.get(`/st1/lobby/${stateId}`)
    const row: LobbyRowValue = response.data
    // validate row
    row.data = parse(StateSchema, row.data)
    return row as LobbyRowValue
  }
  return null
}

export default function createLobbyRowResource(stateId: Accessor<Nullable<StateOnlineId>>) {
  const resource = createResource(stateId, fetchLobbyById)
  return resource
}
