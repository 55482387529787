import type { TilePositionXY } from './TilePositionXY.type'

const tilePositionCache: Map<number, Map<number, TilePositionXY>> = new Map()

export default function coord(x: number, y: number): TilePositionXY {
  // Get the sub-cache for the x-coordinate, or create it if it doesn't exist
  let tilePositionSubCache = tilePositionCache.get(x)
  if (!tilePositionSubCache) {
    tilePositionSubCache = new Map()
    tilePositionCache.set(x, tilePositionSubCache) // Update the main cache
  }

  // Check if the (x, y) position exists in the sub-cache
  let position = tilePositionSubCache.get(y)
  if (!position) {
    // Create a new TilePositionXY and store it
    position = Object.freeze({ x, y })
    tilePositionSubCache.set(y, position)
  }

  return position
}
