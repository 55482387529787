import type { Entity } from "../entity/index"
import isTargetEntityAirUnit from "../entity/isTargetEntityAirUnit"
import { WeaponInvalidId } from "../entity/Weapon/WeaponInvalidId.enum"
import type { WeaponEstimate } from "./WeaponEstimate.type"

/**
 * Applies common validation checks on a weapon estimate, adding relevant error codes to the estimate
 * if certain conditions are not met. These checks include ammo availability, target suitability,
 * range limits, and value validation.
 *
 * @param target - The target entity which the weapon may interact with. Used to check if it meets
 *                 specific requirements for the weapon, such as air-only targeting.
 * @param estimate - An object containing the weapon, its estimated effectiveness (value),
 *                   and the distance to the target. This object may be modified to include
 *                   relevant errors if the weapon estimate is deemed invalid.
 *
 * The function performs the following checks:
 * - **Out of Ammo**: If `weapon.ammo` is zero, adds `WeaponInvalidId.OutOfAmmo`.
 * - **Air-only Target**: If `weapon.airOnly` is true and the target is not an air unit,
 *   adds `WeaponInvalidId.OnlyTargetsAir`.
 * - **Minimum Range**: If `distance` is less than `weapon.minRange`, adds `WeaponInvalidId.BelowMinRange`.
 * - **Maximum Range**: If `distance` exceeds `weapon.maxRange`, adds `WeaponInvalidId.AboveMaxRange`.
 * - **Value Validation**: If `value` is not greater than zero, adds `WeaponInvalidId.ValueNotGreaterThanZero`.
 *
 * This function modifies `estimate.errors` by adding applicable `WeaponInvalidId` entries if any
 * conditions are unmet.
 */
export default function applyDraftMoveAttackEstimateCommonInvalids(target: Entity, estimate: WeaponEstimate): void {
  const { weapon, value, distance } = estimate

  // Out of Ammo check
  if ((weapon.ammops ?? 0) > (weapon.ammo ?? 0)) {
    estimate.errors.push(WeaponInvalidId.OutOfAmmo)
  }

  // Air-only target check
  if (weapon.airOnly && !isTargetEntityAirUnit(target)) {
    estimate.errors.push(WeaponInvalidId.OnlyTargetsAir)
  }

  // Distance range checks
  const minRange = weapon.minRange ?? 0
  const maxRange = weapon.maxRange ?? 1

  if (distance < minRange) {
    estimate.errors.push(WeaponInvalidId.BelowMinRange)
  }
  if (distance > maxRange) {
    estimate.errors.push(WeaponInvalidId.AboveMaxRange)
  }

  // Check if the attack value is greater than zero
  if (value <= 0) {
    estimate.errors.push(WeaponInvalidId.ValueNotGreaterThanZero)
  }
}
