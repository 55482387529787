import { A, useParams } from '@solidjs/router'
import { createMemo, Show } from 'solid-js'
import entityTypeMetaList from '../../../lib/core/entity/entityTypeMetaList.generated'
import { EntityTypeMeta, isSpriteTerrain } from '../../../lib/core/EntityTypeMeta'
import { toSlug } from '../../../lib/slug'
import { Nullable } from '../../../typescript'
import NotFound from '../../NotFound'

const CodexTerrainShowPage = () => {

  // console.log('CodexTerrainShowPage')

  const params = useParams()

  const terrain = createMemo<Nullable<EntityTypeMeta>>(() => {
    return entityTypeMetaList.find((sprite: EntityTypeMeta) => sprite && isSpriteTerrain(sprite) && toSlug(sprite.dname) === toSlug(params.terrain))
  })
  // createEffect(() => {
  //   console.log('terrain', terrain())
  // })

  return (<Show when={terrain()} fallback={NotFound()}>
    <div class="pagec">
      <h2>
        <A href="/codex">Codex</A>{' - '}
        <A href="/codex/terrain">Terrain</A>{' - '}
        Terrain: {terrain()?.dname}
      </h2>
      <A href={`/codex/terrain/${toSlug(terrain()?.dname)}`}>
        {terrain()?.dname}
      </A>
    </div>
  </Show>
  )
}

export default CodexTerrainShowPage