import { hasLocal } from '../has_local'
import { StateMap } from './state_map'
import { StateMapDisk } from './state_map_disk.enum'

export default function createDefaultStateMap(): StateMap {
  return {
    id: null,
    dname: null,
    bio: null,
    disk: hasLocal ? StateMapDisk.Local : StateMapDisk.Cloud,
  }
}
