import { disableCanvasSmoothing } from "./disable_canvas_smoothing"

/**
 * @link https://github.com/microsoft/TypeScript/issues/53614#issuecomment-1642708607
 */
export type EitherRenderingContext2D = CanvasRenderingContext2D | OffscreenCanvasRenderingContext2D

export function getCanvas2dContext(elem: HTMLCanvasElement | OffscreenCanvas): EitherRenderingContext2D | null {
  const ctx = elem.getContext('2d') as EitherRenderingContext2D | null
  if (ctx) {
    disableCanvasSmoothing(ctx)
  }
  return ctx
}
