import type { HasPlayerId } from '../player/has_player_id'
import type { AnnexableEntityUnion } from './AnnexableEntityUnion.type'
import { isEntityInfantry } from './entityTypeMetaList.generated'
import type { Entity } from './index'
import isEntityAnnexable from './isEntityAnnexable'

export default function canUnitAnnexEntity(
  unit: Entity,
  entity: Entity
): entity is AnnexableEntityUnion {
  if (isEntityInfantry(unit)) {
    if (unit.player_id && unit.player_id !== (entity as HasPlayerId).player_id) {
      if (isEntityAnnexable(entity)) {
        return true
      }
    }
  }
  return false
}
