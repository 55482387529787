import { createMemo } from 'solid-js'
import type { Engine } from '../../lib/core/engine/Engine.type'
import findById from '../../lib/core/findById'
import type { Player } from '../../lib/core/player/Player.type'
import type { Nullable } from '../../typescript'

export function createAuthPlayerMemo(engine: Engine) {
  const memo = createMemo((): Nullable<Player> => {
    return findById(engine.state.players, engine.authPlayerId)
  })
  return memo
}
