import { isServer } from 'solid-js/web'
import { literal, object, type InferOutput } from 'valibot'
import { Engine } from '../../../../engine/Engine.type'
import { ActionType } from '../ActionType'
import type { ActionLog } from '../../ActionLog.type'


export const DeleteMapActionSchema = object({
  type: literal(ActionType.Map.DeleteMap),
})

export type DeleteMapAction = InferOutput<typeof DeleteMapActionSchema>

export function createDeleteMapAction(): DeleteMapAction {
  return {
    type: ActionType.Map.DeleteMap,
  }
}

export async function handleDeleteMapAction(
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  engine: Engine,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  action: DeleteMapAction,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  actionLog: ActionLog
): Promise<void> {
  // console.log('handleDeleteMapAction', action)

  if (isServer) {
    console.log('TODO isServer')
  } else {
    console.log('TODO !isServer')
    // probably nothing, unless is a local game
    // user will be redirected to a formal lobby page
  }
}
