import type { Nullable } from "../../../../../typescript"
import type { Engine } from "../../../engine/Engine.type"
import type { BaseEntity } from "../../../entity/BaseEntity"
import getPlayerEntities from "../../../entity/getPlayerEntities"
import type { Entity } from "../../../entity/index"
import findByIdOrThrow from "../../../findByIdOrThrow"
import { PlayerGameStatus } from "../../../player/PlayerGameStatus"
import type { PlayerId } from "../../../player/PlayerId"

export default function checkLostLastEntityWinCondition(
  engine: Engine,
  losingPlayerId: Nullable<PlayerId>
) {
  if (!losingPlayerId) {
    return
  }

  const losingPlayer = findByIdOrThrow(engine.state.players, losingPlayerId)
  const { state } = engine
  const { ents } = state

  const playerEnts = getPlayerEntities(ents, losingPlayerId).filter((ent: Entity) => !(ent as BaseEntity).taxi_id)

  if (playerEnts.length === 0) {
    // losingPlayerId has no entities left, so they are lost
    losingPlayer.game_status = PlayerGameStatus.Lost
    return
  }
}