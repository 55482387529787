/**
 * remove value from array without creating a new array
 **/
export default function removeItem<T>(arr: Array<T>, value: T): void {
  let index
  while (true) {
    index = arr.indexOf(value)
    if (index === -1) {
      return
    }
    arr.splice(index, 1)
  }
}