import { maxValue, minValue, number, pipe } from "valibot"
import type { PlayerIdOrNull } from "../../player/PlayerIdOrNull"
import { MAX_I16 } from "../../util/number"
import type { ActionLog } from "./ActionLog.type"

export const ClientRefIDSchema = pipe(number(), minValue(1), maxValue(MAX_I16))

export default function isClientReferenceIdUnique (
  actionLogs: Array<ActionLog>,
  playerId: PlayerIdOrNull,
  round: number,
  cref: number): boolean {
  for (let i = actionLogs.length - 1; i >= 0; i--) {
    const actionLog = actionLogs[i]
    if (actionLog.round === round) {
      if (actionLog.player_id === playerId) {
        if (actionLog.cref === cref) {
          return false
        }
      }
    } else {
      // break out of the loop
      // previous rounds don't matter
      break
    }
  }
  return true
}