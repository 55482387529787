import type { BackendSessionPayload } from '@sg/backend/src/api/auth/BackendAuthSession.type'
import { emit, EventListenerList } from '../../../lib/event/event'
import ls from '../../../lib/localStorage'
import { Nullable } from '../../../typescript'
import VITE_BACKEND_JWT_LS_KEY from './auth_session_key'
import getBackendSessionPayloadFromLocalStorage from './getAuthSessionFromLocalStorage'

export const sessionValueUpdatedEvent: EventListenerList<Nullable<BackendSessionPayload>> = [(newValue) => {
  ls.setItem(VITE_BACKEND_JWT_LS_KEY, JSON.stringify(newValue))
}]

let sessionValue: Nullable<BackendSessionPayload> = getBackendSessionPayloadFromLocalStorage()

// export default sessionValue

export function getSessionValue(): Nullable<BackendSessionPayload> {
  return sessionValue
}

// disabled, it's now OTP code only
// // get and parse this from the URL
// // #access_token=example&expires_at=1714327071&expires_in=3600&refresh_token=e4bTpnc53e0px0DOesdMLQ&token_type=bearer&type=magiclink
// // const hash = new URLSearchParams(window.location.hash.slice(1)).get('access_token')
// const hash = fromEntries(new URLSearchParams(location.hash.slice(1)))
// if (hash?.access_token) {
//   const tmpSession = fromEntries(new URLSearchParams(location.hash.slice(1))) as Partial<BackendSessionPayload>
//   supabaseAxios.get('/auth/v1/user', {
//     headers: {
//       Authorization: `Bearer ${tmpSession.at}`,
//     },
//   }).then((response: AxiosResponse) => {
//     tmpSession.user = response.data
//     updateSessionValue(tmpSession as BackendSessionPayload)

//     // replaces current history item (user will have to go back to email to get link)
//     history.replaceState(null, '', location.pathname + location.search);
//     // adds next history item:
//     // history.pushState(null, '', location.pathname + location.search);
//   })
// }

export function updateSessionValue(newValue: Nullable<BackendSessionPayload>): void {
  // console.log('updateSessionValue')
  sessionValue = newValue
  emit(sessionValueUpdatedEvent, newValue)
}


window.addEventListener('storage', function (event) {
  if (event.key === VITE_BACKEND_JWT_LS_KEY) {
    const newValue: Nullable<BackendSessionPayload> = event.newValue ? JSON.parse(event.newValue) : null
    updateSessionValue(newValue)
  }
})
