import { backendAxios } from "../../../axios"
import { getSessionValue, updateSessionValue } from "./sessionValue"

const ONE_MINUTE = 60 * 1000

let promise: Promise<void> | null

export default function fetchRefreshedAccessToken(): Promise<void> {
  console.log('fetchRefreshedAccessToken')
  if (!promise) {
    promise = inner()
    setTimeout(() => promise = null, ONE_MINUTE)
  }
  return promise
}

export function waitIfRefreshingAccessToken() : Promise<void> {
  return promise || Promise.resolve()
}

// curl 'https://hwylrqkmnilaijnprsat.supabase.co/auth/v1/token?grant_type=refresh_token' \
//   -H 'authority: hwylrqkmnilaijnprsat.supabase.co' \
//   -H 'accept: */*' \
//   -H 'accept-language: en-US,en;q=0.9' \
//   -H 'apikey: REDACTED' \
//   -H 'authorization: Bearer REDACTED' \
//   -H 'content-type: application/json;charset=UTF-8' \
//   -H 'origin: http://localhost:3000' \
//   -H 'referer: http://localhost:3000/' \
//   -H 'sec-ch-ua: "Chromium";v="113", "Not-A.Brand";v="24"' \
//   -H 'sec-ch-ua-mobile: ?0' \
//   -H 'sec-ch-ua-platform: "macOS"' \
//   -H 'sec-fetch-dest: empty' \
//   -H 'sec-fetch-mode: cors' \
//   -H 'sec-fetch-site: cross-site' \
//   -H 'user-agent: Mozilla/5.0 (Macintosh; Intel Mac OS X 10_15_7) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/113.0.0.0 Iron Safari/537.36' \
//   -H 'x-client-info: supabase-js-web/2.42.7' \
//   -H 'x-supabase-api-version: 2024-01-01' \
//   --data-raw '{"refresh_token":"9Y-uTEXUcTIuVEeqnn1AoQ"}' \
//   --compressed

async function inner(): Promise<void> {
  console.log('fetchRefreshedAccessToken.inner')
  const oldSessionValue = await getSessionValue()
  if (oldSessionValue?.rt) {
    const response = await backendAxios.post(`/auth/refresh`, {
      refresh_token: oldSessionValue.rt,
    })
    updateSessionValue({
      ...oldSessionValue,
      ...response.data,
    })
  }
}
