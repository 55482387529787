import { literal, object, type InferOutput } from 'valibot'
import { Engine } from '../../../../engine/Engine.type'
import { ActionType } from '../ActionType'
import type { ActionLog } from '../../ActionLog.type'

// type BotId = Nullable<AuthProfile['id']>

export const DeleteLobbyActionSchema = object({
  type: literal(ActionType.Lobby.DeleteLobby),
})

export type DeleteLobbyAction = InferOutput<typeof DeleteLobbyActionSchema>

export function createDeleteLobbyAction(): DeleteLobbyAction {
  return {
    type: ActionType.Lobby.DeleteLobby,
  }
}

export async function handleDeleteLobbyAction(
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  engine: Engine,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  action: DeleteLobbyAction,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  actionLog: ActionLog
): Promise<void> {
  // const stateId = engine.state.id
  // if (isServer) {
  //   //
  // }
  // IDK lol
}
