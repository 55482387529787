import { createEffect, type Accessor } from 'solid-js'
import { modifyMutable, produce } from 'solid-js/store'
import type { Engine } from '../../lib/core/engine/Engine.type'
import setAuthPlayerIdFromProfileId from '../../lib/core/engine/setAuthPlayerIdFromProfileId'
import type { Nullable } from '../../typescript'
import type { MyProfilePayload } from '@sg/backend/src/api/profile/handleGetMe'

export default function createSetEngineAuthPlayerIdFromAuthProfileSignalEffect(
  engine: Engine,
  authProfile: Accessor<Nullable<MyProfilePayload>>
) {
  createEffect(() => {
    modifyMutable(
      engine,
      produce((engine) => {
        setAuthPlayerIdFromProfileId(engine, authProfile()?.id)
      })
    )
  })
}
