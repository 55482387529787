import {
  type IndexGameQuery
} from '@sg/backend/src/api/st1/game/handleIndexGame'
import { A, useSearchParams } from '@solidjs/router'
import { Card } from 'solid-bootstrap'
import { For, Show, Suspense, createSignal } from 'solid-js'
import { createMutable, produce } from 'solid-js/store'
import AlertBag from '../../components/AlertBag'
import Breadcrumbs from '../../components/Breadcrumb/Breadcrumbs'
import DeleteButton from '../../components/Btn/DeleteButton'
import CardText from '../../components/Card/CardText'
import CardTitle from '../../components/Card/CardTitle'
import ContinueOrSpectateGameButton from '../../components/ContinueOrSpectateGameButton'
import EngineContext from '../../components/EngineContext'
import GameShowCanvas from '../../components/GameShowCanvas'
import Loader from '../../components/Loader'
import NewGameIcon from '../../components/MapEditor/NewGameIcon'
import StateTypeText from '../../components/State/StateTypeText'
import MapSizeText from '../../components/Text/MapSizeText'
import EmSmallTextMuted from '../../components/Util/EmSmallTextMuted'
import bindEngineToComponent from '../../lib/canvas/bind_engine_to_component'
import createEngineForUI from '../../lib/core/engine/createEngineForUI'
import { Engine } from '../../lib/core/engine/Engine.type'
import { ProfileIdSchema } from '../../lib/core/ProfileId.type'
import gameDisplayName from '../../lib/core/state/gameDisplayName'
import { StateTypeGame } from '../../lib/core/state/stateTypeMetaList.type'
import { parseIntOrNull } from '../../lib/core/util/math'
import createSetEngineAuthPlayerIdFromAuthProfileSignalEffect from '../../rx/effect/createSetEngineAuthPlayerIdFromAuthProfileSignalEffect'
import createValibotSafeParseMemo from '../../rx/memo/createValibotSafeParseMemo'
import createGameCollectionResource from '../../rx/resource/createGameCollectionResource'
import createAuthProfileSignal from '../../rx/shared/profile/createAuthProfileSignal'
import { createLoadingSignal, registerLoadingResource } from '../../rx/signal/create_loading_signal'
import { createAlertBagStore, registerAlertBagResource } from '../../rx/store/create_alert_bag_store'
import type { Nullable } from '../../typescript'

const IndexGamePage = () => {
  const loading = createLoadingSignal()
  const ab = createAlertBagStore()
  const [searchParams] = useSearchParams<Record<keyof IndexGameQuery, string>>()
  const ownerProfileId = createValibotSafeParseMemo(
    ProfileIdSchema,
    () => parseIntOrNull(searchParams.owner_profile_id)
  )
  const playerProfileId = createValibotSafeParseMemo(
    ProfileIdSchema,
    () => parseIntOrNull(searchParams.player_profile_id)
  )

  const stateCollectionResource = createGameCollectionResource(() => {
    return {
      player_profile_id: playerProfileId().value,
      owner_profile_id: ownerProfileId().value,
    }
  })
  registerLoadingResource(loading, stateCollectionResource)
  registerAlertBagResource(ab, stateCollectionResource)

  const engine: Engine = createMutable(createEngineForUI())

  const [authProfile] = createAuthProfileSignal()
  createSetEngineAuthPlayerIdFromAuthProfileSignalEffect(engine, authProfile)

  bindEngineToComponent(engine)

  return (
    <EngineContext.Provider value={engine}>
      <div class="pagec">
        <Breadcrumbs />
        <h2>Game List</h2>
        <Show when={authProfile()?.id && playerProfileId().value !== authProfile()?.id}>
          <A href={`/games?player_profile_id=${authProfile()?.id}`} class="btn btn-primary">
            My Games
          </A>
        </Show>
        <Show when={!(authProfile()?.id && playerProfileId().value !== authProfile()?.id)}>
          <A href="/games" class="btn btn-primary">
            Public Games
          </A>
        </Show>
        <A href="/lobby/create" class="btn btn-success">
          <NewGameIcon /> New Game
        </A>
        <AlertBag store={ab} />
        <Loader signal={loading} />
        <Suspense fallback={<div class="text-muted">Loading...</div>}>
          <Show when={stateCollectionResource[0]()}>{(stateCollection) =>
            <>
              <For each={stateCollection()} fallback={<div class="text-muted">Empty List</div>}>
                {(stateRow) => {
                  // console.log('item', item)
                  const [game, setGame] = createSignal(stateRow)
                  // function game(): GameItemResourceValue {
                  //   return item
                  // }

                  // createEffect(() => {
                  //   console.log('game.deleted_at', game().deleted_at, game())
                  // })
                  // createEffect(() => {
                  //   console.log('game.deleted_at', item.deleted_at)
                  // })

                  const stateId = (): Nullable<number> => game().data.online?.id

                  return <Card>
                    <div class="card-body clearfix">
                      <div class="float-end">
                        <GameShowCanvas row={game()} width={400} height={400} />
                      </div>
                      <CardTitle>
                        <A href={`/game?game_id=${stateId()}`}
                          aria-label="Goto Game"
                          title="Goto Game"
                        >{gameDisplayName(game().data)}</A>
                        {' '}
                        <StateTypeText type={game().data.type} />
                        {' '}
                        <DeleteButton
                          st={StateTypeGame} disabled={loading()}
                          item={game()} ab={ab}
                          onChange={(newValue) => {
                            setGame(produce((stateRow) => {
                              stateRow.deleted_at = newValue
                            }))
                          }}
                        />
                        {' '}
                        <ContinueOrSpectateGameButton row={stateRow} />
                      </CardTitle>
                      <CardText>
                        {game().data.online?.bio || EmSmallTextMuted('no description')}
                      </CardText>
                      <CardText>
                        Size: <MapSizeText state={game().data} />
                      </CardText>
                    </div>
                  </Card>
                }}
              </For>
            </>
          }</Show>
        </Suspense>
      </div>
    </EngineContext.Provider>
  )
}

export default IndexGamePage