import { now } from '@sg/shared/src/lib/Date'
import { format, formatDistanceToNow } from 'date-fns'
import { createSignal, Show, type Accessor, type JSXElement } from 'solid-js'
import createMountedInverval from '../../lib/createMountedInterval'
import NA from "../Util/na"

interface Props {
  value: number | null | undefined
  na?: JSXElement,
}

/**
 * @example 1999-01-01
 */
const formatStr = 'y-MM-dd hh:mm:ss aa'

const formatDistanceToNowOptions = {
  addSuffix: true,
}

const INTERVAL_TIME_ONE_MINUTE = 1000 * 60

function getIntervalTimeFromDiff(diff: number): number | undefined {
  // console.log('diff', diff, diff / INTERVAL_TIME_ONE_MINUTE)
  if (diff < INTERVAL_TIME_ONE_MINUTE) {
    return INTERVAL_TIME_ONE_MINUTE
  }
}

const TimeAgo = (props: Props) => {

  return <Show when={props.value} fallback={props.na || NA()} children={(value : Accessor<number>) => {
    const [getText, setText] = createSignal(formatDistanceToNow(value(), formatDistanceToNowOptions))

    const intervalTime = getIntervalTimeFromDiff(now() - value())
    if (intervalTime) {
      createMountedInverval(() => {
        setText(formatDistanceToNow(value(), formatDistanceToNowOptions))
      }, intervalTime)
    }

    return <time
        title={format(value(), formatStr)}
        datetime={format(value(), formatStr)}
      >{getText()}</time>
    }}
  />
}

export default TimeAgo
