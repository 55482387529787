import { Accessor, createMemo } from 'solid-js'
import { Nullable } from '../../../typescript'
import { Engine } from '../engine/Engine.type'
import findById from '../findById'
import { Player } from './Player.type'

export default function createSelectedPlayerMemo(engine: Engine): Accessor<Nullable<Player>> {
  const selectedPlayer = createMemo<Nullable<Player>>(() => {
    const players = engine.state.players
    const playerId = engine.selectedPlayerId

    return findById(players, playerId)
  })
  return selectedPlayer
}
