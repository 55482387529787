import { array, object, type InferOutput } from 'valibot'
import { Entity } from '../entity'
import { EntityLayerId, EntityLayerIdSchema } from '../entity/entity_layer_id.enum'
import { State } from '../state/State.type'
import { TilePositionXY } from '../tile_position_xy/TilePositionXY.type'

export const EraseTileOptionSchema = object({
  entity_layer_ids: array(EntityLayerIdSchema),
})

export type EraseTileOption = InferOutput<typeof EraseTileOptionSchema>

// export type EraseTileOption = {
//   entity_layer_ids: Array<EntityLayerId>
// }

const defaults: EraseTileOption = {
  entity_layer_ids: [2] as EntityLayerId[],
  // auto_fix_roads: true,
}

export function createEraseTileOption(input: Partial<EraseTileOption> | null = null) {
  return { ...defaults, input }
}

export function eraseMapTile(
  state: State,
  options: EraseTileOption,
  position: TilePositionXY
): void {
  const x = position.x
  const y = position.y
  // console.log('eraseMapTile', options, position)
  const entity_layer_ids = options.entity_layer_ids
  const a: Array<Entity> = []
  for (let index = 0, ent_len = state.ents.length; index < ent_len; index++) {
    const ent = state.ents[index]
    // console.log(ent.layer_id, ent.x, ent.y)
    if (x == ent.x && y == ent.y && entity_layer_ids.includes(ent.layer_id)) {
      // do nothing, we're deleting this tile
      // console.log(ent.layer_id, ent.x, ent.y)
    } else {
      a.push(state.ents[index])
    }
  }
  state.ents = a
}
