import { Player } from './Player.type'
import { PlayerKind } from './PlayerKind.enum'

export default function resetPlayerSlotToOpen(player: Player) {
  player.kind = PlayerKind.OpenSlot
  // the UI will use placeholder_dname
  player.dname = null
  player.is_local = null
  player.profile_id = null
  player.invite_id = null
  player.bot_id = null
}
