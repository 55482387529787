import { onCleanup, onMount } from 'solid-js'

export default function onElementEvent<K extends keyof HTMLElementEventMap>(
  elem: HTMLElement,
  name: K,
  fn: (this: HTMLElement, ev: HTMLElementEventMap[K]) => void | null | boolean,
  options?: boolean | AddEventListenerOptions
): void {
  onMount(() => {
    elem.addEventListener(name, fn, options)
  })
  onCleanup(() => {
    // the if statement is necessary because elem can become null
    // after the fact, despite being a valid reference earlier.
    if (elem) {
      elem.removeEventListener(name, fn, options)
    }
  })
}
