import createGridXY from './createGridXy'

export type ReadWriteGridXY = ReturnType<typeof createReadWriteGridXY>

export default function createReadWriteGridXY<T>(
  gridWidth: number,
  gridHeight: number,
  defaultValue: T
) {
  const data = createGridXY(gridWidth, gridHeight, defaultValue)

  function readCell(x: number, y: number): T {
    return data[x]?.[y]
  }
  function writeCell(x: number, y: number, newValue: T): void {
    data[x][y] = newValue
  }
  const restable = [readCell, writeCell] as const
  return restable
}
