import type { MyProfilePayload } from '@sg/backend/src/api/profile/handleGetMe'
import { PatchMyProfileBodySchema } from '@sg/backend/src/api/profile/handlePatchMe'
import { A } from '@solidjs/router'
import { FormGroup, FormLabel } from 'solid-bootstrap'
import { FaSolidFloppyDisk } from 'solid-icons/fa'
import { Component, createEffect, createMemo, createSignal, Show } from 'solid-js'
import { parse } from 'valibot'
import { backendAxios } from '../axios'
import Breadcrumbs from '../components/Breadcrumb/Breadcrumbs'
import EditAvatar from '../components/EditAvatar'
import Loader from '../components/Loader'
import SignInButton from '../components/SignInButton'
import toastError from '../lib/AlertBag/toastError'
import preventDefault from '../lib/dom/event/prevent_default'
import hasNonUndefinedProperty from '../lib/hasNonUndefinedProperty'
import onlyDirty from '../lib/only_dirty'
import { updateAuthProfileValue } from '../rx/shared/profile/authProfileValue'
import createAuthProfileSignal from '../rx/shared/profile/createAuthProfileSignal'
import { createLoadingSignal } from '../rx/signal/create_loading_signal'

const ProfilePage: Component = () => {
  const loading = createLoadingSignal()
  const [handle, setHandle] = createSignal<MyProfilePayload['handle']>(null)
  const [dname, setDname] = createSignal<MyProfilePayload['dname']>(null)
  const [email, setEmail] = createSignal<MyProfilePayload['email']>(null)
  // const [avatarId, setAvatarId] = createSignal<MyProfilePayload['avatar_id']>(null)

  const [authProfile] = createAuthProfileSignal()

  createEffect(() => {
    const p = authProfile()
    // setAvatarId(p?.avatar_id || null)
    setHandle(p?.handle || null)
    setDname(p?.dname || null)
    setEmail(p?.email || null)
  })

  const allFormData = createMemo(() => {
    return {
      // avatar_id: avatarId(),
      handle: handle(),
      dname: dname(),
      email: email(),
    }
  })

  const dirtyData = createMemo(() => {
    const p = authProfile()
    return onlyDirty(allFormData(), p)
  })
  const isDirty = createMemo(() => {
    return hasNonUndefinedProperty(dirtyData())
  })

  // createEffect(() => {
  //   console.log('dirtyData', dirtyData())
  // })

  const updateProfile = async (e: Event) => {
    preventDefault(e)

    try {
      loading.start()
      const user = authProfile()
      if (user) {
        // curl 'https://hwylrqkmnilaijnprsat.supabase.co/rest/v1/profiles' \
        //   -H 'authority: hwylrqkmnilaijnprsat.supabase.co' \
        //   -H 'accept: */*' \
        //   -H 'accept-language: en-US,en;q=0.9' \
        //   -H 'apikey: REDACTED' \
        //   -H 'authorization: Bearer REDACTED' \
        //   -H 'content-profile: public' \
        //   -H 'content-type: application/json' \
        //   -H 'origin: http://localhost:3000' \
        //   -H 'prefer: resolution=merge-duplicates' \
        //   -H 'referer: http://localhost:3000/' \
        //   -H 'sec-ch-ua: "Chromium";v="113", "Not-A.Brand";v="24"' \
        //   -H 'sec-ch-ua-mobile: ?0' \
        //   -H 'sec-ch-ua-platform: "macOS"' \
        //   -H 'sec-fetch-dest: empty' \
        //   -H 'sec-fetch-mode: cors' \
        //   -H 'sec-fetch-site: cross-site' \
        //   -H 'user-agent: Mozilla/5.0 (Macintosh; Intel Mac OS X 10_15_7) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/113.0.0.0 Iron Safari/537.36' \
        //   -H 'x-client-info: supabase-js-web/2.42.7' \
        //   --data-raw '{"id":"e07cada7-1a5c-4aaa-9541-96c32a712f8c","handle":"a_handle2","dname":"MyDisplayName","avatar_id":"0.9046106501494644.jpg","updated_at":"2024-04-30T01:04:40.525Z"}' \
        //   --compressed

        const payload = parse(PatchMyProfileBodySchema, {
          handle: handle(),
          dname: dname(),
          email: email(),
        })

        await backendAxios.post(`/profile/me`, payload)

        updateAuthProfileValue({
          ...authProfile(),
          ...payload as MyProfilePayload,
        })

        // let { error } = await supabase.from('profiles').upsert(updates)

        // if (error) {
        //   throw error
        // }
      }
    } catch (error) {
      console.error(error)
      toastError(error)
    } finally {
      loading.end()
    }
  }

  return (
    <div class="container w-24-rem" style={{"max-height":"80vh"}}>
      <Breadcrumbs>
        <A href="/auth/profile">Profile</A>
      </Breadcrumbs>
      <form onSubmit={updateProfile}>
        <Show when={authProfile()?.id}>
          <FormGroup>
            <EditAvatar
              size={150}
              onUpload={(e: Event) => {
                console.log('EditAvatar.onUpload', e)
              }}
            />
          </FormGroup>
          <FormGroup>
            <FormLabel>Email</FormLabel>
            <input
              type="email"
              disabled
              class="form-control"
              value={authProfile()?.email || ''}
            />
          </FormGroup>
          <FormGroup>
            <FormLabel>Handle</FormLabel>
            <input
              type="text"
              class="form-control"
              value={handle() || ''}
              onChange={(e) => setHandle(e.currentTarget.value)}
            />
          </FormGroup>
        </Show>
        <Show when={!authProfile()?.id}>
          <FormGroup>
            <SignInButton />
          </FormGroup>
        </Show>
        <FormGroup>
          <FormLabel>Display Name</FormLabel>
          <input
            type="text"
            class="form-control"
            value={dname() || ''}
            onInput={(e) => setDname(e.currentTarget.value)}
          />
        </FormGroup>
        <br />
        <FormGroup>
          <button type="submit" class="btn btn-primary" disabled={loading() || !isDirty()}>
            <FaSolidFloppyDisk />{' '}
            {loading() ? 'Saving ...' : 'Update Profile'}
          </button>
          <Loader signal={loading} />
        </FormGroup>
      </form>
    </div>
  )
}

export default ProfilePage
