import { floor, random } from '../util/math'
import { MAX_U16, isSafeInteger } from '../util/number'

export const MAP_SEED_MIN_SIZE = -1
export const MAP_SEED_MAX_SIZE = MAX_U16

/**
 * non-integers and negative numbers actually work fine AFAIK,
 * but my RNG algorithm was not written for presenting.
 *
 * @param value
 * @returns
 */
export function isInvalidSeedValue(value: number | unknown): value is number {
  // isSafeInteger covers `floor(value)===value` check
  // `isSafeInteger(NaN | null | undefined)` will always be false
  if (!isSafeInteger(value)) {
    // console.error('isInvalidSeedValue', value, 'is not isSafeInteger(value)', isSafeInteger(value))
    return true
  }
  if ((value as number) > MAP_SEED_MAX_SIZE) {
    // console.error('isInvalidSeedValue', value, 'is too big')
    return true
  }
  if ((value as number) < MAP_SEED_MIN_SIZE) {
    // console.error('isInvalidSeedValue', value, 'is too small')
    return true
  }
  // console.info('isInvalidSeedValue', value, 'is allowed')
  return false
}

/**
 *
 * @returns {number} random number between 0 and MAP_SEED_MAX_SIZE
 */
export function randomMapSeed(): number {
  return floor(random() * MAP_SEED_MAX_SIZE)
}
