import { ResourceReturn, createEffect, createSignal } from 'solid-js'

export type LoadingSignal = (() => boolean) & {
  start: () => void
  end: () => void
  count: () => number
}

function decrement(a: number): number {
  return a - 1
}
function increment(a: number): number {
  return a + 1
}

export function createLoadingSignal(): LoadingSignal {
  // eslint-disable-next-line solid/reactivity
  const signal = createSignal<number>(0)

  function acc(): boolean {
    return signal[0]() > 0
  }
  acc.start = function () {
    signal[1](increment)
  }
  acc.end = function () {
    signal[1](decrement)
  }
  acc.count = signal[0]

  return acc as LoadingSignal
}

export function registerLoadingResource<T, U>(
  loading: LoadingSignal,
  resource: ResourceReturn<T, U>
): void {
  createEffect(() => {
    if (resource[0].loading) {
      loading.start()
    } else {
      loading.end()
    }
  })
}
