import { onCleanup, onMount } from 'solid-js'
import type { Engine } from '../../../lib/core/engine/Engine.type'
import createCornerWidgetEngineSignal from './createCornerWidgetEngineSignal'

export default function useCornerWidget(engine: Engine) {
  const setEngine = createCornerWidgetEngineSignal()[1]

  onMount(() => setEngine(engine))

  onCleanup(() => setEngine(null))
}
