import type { EntityTypeMeta } from '../EntityTypeMeta'
import { byPriceAsc, type HasPrice } from '../HasPrice'
// import type { HasPrice } from '../HasPrice'
import entityTypeMetaList from './entityTypeMetaList.generated'

const entityTypeMetaForFactoryList = entityTypeMetaList
  .filter((entityTypeMeta: EntityTypeMeta): entityTypeMeta is EntityTypeMeta & HasPrice => {
    return (entityTypeMeta as HasPrice).price > 0
  })
  .sort(byPriceAsc)

export type EntityTypeMetaForFactory = (typeof entityTypeMetaForFactoryList)[number]

export default entityTypeMetaForFactoryList
