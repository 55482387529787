import {
  GameRowCollection,
  IndexGameQuerySchema,
  type IndexGameQuery,
} from '@sg/backend/src/api/st1/game/handleIndexGame'
import { createResource, type Accessor } from 'solid-js'
import { parse, safeParse } from 'valibot'
import { backendAxios } from '../../axios'
import { StateSchema } from '../../lib/core/state/State.type'

async function fetchGameCollectionByQuery(inputParams: IndexGameQuery): Promise<GameRowCollection> {
  // console.log('fetchGameCollectionByQuery')
  const result = safeParse(IndexGameQuerySchema, inputParams)
  if (result.success) {
    const params = result.output
    // console.log('fetchGameCollectionByQuery.backendAxios.get.start')
    const response = await backendAxios.get(`/st1/game`, { params })
    // console.log('fetchGameCollectionByQuery.backendAxios.get.end')
    const collection = response.data as GameRowCollection
    collection.forEach((row): void => {
      // validate row
      row.data = parse(StateSchema, row.data)
    })
    return collection as GameRowCollection
  }
  return []
}

export default function createGameCollectionResource(
  getInputQuery: Accessor<Partial<IndexGameQuery>>
) {
  const resource = createResource(getInputQuery, fetchGameCollectionByQuery)
  return resource
}
