import { Component, onMount } from "solid-js"
import { hasCloud } from "../../lib/core/has_cloud"
import { hasLocal } from "../../lib/core/has_local"
import { StateMapDisk } from "../../lib/core/state/state_map_disk.enum"
import { disableElement, enableElement } from "../../lib/jsx/disabled"

interface Props {
  value: StateMapDisk;
  onChange: (value: StateMapDisk) => void;
}

const SelectStateMapDisk: Component<Props> = (props) => {

  // let elem: HTMLButtonElement | undefined = undefined

  // const [selectedValue, setSelectedValue] = createSignal(props.value);

  // const handleChange = (event: Event) => {
  //   const target = event.target as HTMLSelectElement;
  //   const selectedValue = target.value;
  //   setSelectedValue(selectedValue);
  //   props.onChange(selectedValue);
  // };

  const options = []
  if (hasLocal) {
    options.push(<option value={StateMapDisk.Local}>Local</option>)
  }
  if (hasCloud) {
    options.push(<option value={StateMapDisk.Cloud}>Cloud</option>)
  }

  return <select
    ref={(elem) => {
      function onDisabledChanged() {
        if (options.length > 1) {
          if (elem) {
            enableElement(elem)
          }
        } else if (elem) {
          disableElement(elem)
        }
      }
      onMount(onDisabledChanged)
    }}
    class="form-control"
    name="disk"
    value={props.value}
    onInput={(event: InputEvent) => {
      const selectedValue = ((event.target as HTMLSelectElement)?.value) as StateMapDisk
      // setSelectedValue(selectedValue);
      props.onChange(selectedValue);
    }}
  >
    {options}
  </select>
}

export default SelectStateMapDisk

