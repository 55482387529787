import { createMemo, For, Show, type Component } from "solid-js"
import { getDotPath, type BaseIssue, type BaseSchema, type BaseSchemaAsync, type SafeParseResult } from "valibot"



type InvalidFeedbackProps = {
  name: string,
  v: SafeParseResult<BaseSchema<unknown, unknown, BaseIssue<unknown>> | BaseSchemaAsync<unknown, unknown, BaseIssue<unknown>>>
}
const InvalidFeedback: Component<InvalidFeedbackProps> = (props) => {
  const issues = createMemo(() => props.v.issues?.filter((issue) => getDotPath(issue) === props.name))
  return <Show when={(issues() as Array<unknown>)?.length > 0}>
    <div class="invalid-feedback">
      <For each={issues()}>{(issue) => { return issue.message }}
      </For>
    </div>
  </Show>
}

export default InvalidFeedback