import { createSignal } from 'solid-js'
import { InferOutput, literal, union } from 'valibot'

export type OneOrZeroSignal = ReturnType<typeof createOneOrZeroSignal>

export const OneOrZeroSchema = union([literal(0), literal(1)])

export type OneOrZero = InferOutput<typeof OneOrZeroSchema>

export function createOneOrZeroSignal(initialValue: OneOrZero) {
  // eslint-disable-next-line solid/reactivity
  return createSignal<0 | 1>(initialValue)
}

// @deprecated use valibot's parse
export function strToOneOrZero(value: string) {
  if (value === '1') return 1
  if (value === '0') return 0
  throw new Error('unknown value: ' + value)
}
