import { Signal, createRoot, createSignal } from 'solid-js'

const signal: Signal<boolean> = createRoot(() => {
  // eslint-disable-next-line solid/reactivity
  const signal = createSignal<boolean>(false)

  return signal
})

export default function createIsModalMenuOpenSignal() {
  return signal
}
