import type { Nullable } from '../../../typescript'
import { TilePositionXY } from './TilePositionXY.type'

export function samePosition(p1: Nullable<TilePositionXY>, p2: Nullable<TilePositionXY>): boolean {
  if (p1 && p2) {
    if (p2 === p1) return true
    if (p1.x == p2.x && p1.y == p2.y) return true
  }
  return false
}
