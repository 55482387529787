/**
 * Checks if `value` is `null` or `undefined`.
 *
 * isNil(null)
 * // => true
 *
 * isNil(void 0)
 * // => true
 *
 * isNil(0)
 * // => false
 *
 * isNil(NaN)
 * // => false
 */
export default function isNil<T>(value: T | null | undefined): value is null | undefined {
  return value == null
}
