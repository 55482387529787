import { maxValue, minValue, number, pipe } from "valibot"
import type { PlayerIdOrNull } from "../../player/PlayerIdOrNull"
import getRandomInteger from "../../rng/getRandomInteger"
import { MAX_I16 } from "../../util/number"
import type { ActionLog } from "./ActionLog.type"

export const ClientRefIDSchema = pipe(number(), minValue(1), maxValue(MAX_I16))

export default function nextClientReferenceId (actionLogs: Array<ActionLog>, playerId: PlayerIdOrNull, round: number): number {
  const set = new Set<number>()
  for (let i = actionLogs.length - 1; i >= 0; i--) {
    const actionLog = actionLogs[i]
    if (actionLog.round === round) {
      if (actionLog.player_id === playerId) {
        set.add(actionLog.cref)
      }
    } else {
      // break out of the loop
      // previous rounds don't matter
      break
    }
  }
  let maxTries = 100
  do {
    const next = getRandomInteger(1, MAX_I16)
    if (!set.has(next)) {
      return next
    }
  } while (maxTries-->0)
  
  throw new Error('maxTries --> 0')
}