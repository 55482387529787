import { Component } from 'solid-js'
import type { State } from '../../lib/core/state/State.type'
import NA from '../Util/na'

interface Props {
  state: State;
}

const MapSizeText: Component<Props> = (props) => {

  return (
    <>
      {(props.state?.width && props.state?.height) ? (
        <>
          {props.state?.width} <span class="text-muted">x</span> {props.state?.height}
        </>
      ) : (
        NA()
      )}
    </>
  );
};

export default MapSizeText;