import type { Nullable } from "../../../typescript"
import type { Engine } from "../engine/Engine.type"
import type { TilePositionXY } from "./TilePositionXY.type"

export default function isPositionInBounds(position : Nullable<TilePositionXY>, engine : Engine): position is TilePositionXY {
  if (position) {
    const { x, y } = position
    if (x >= 0 && y >= 0) {
      const { width, height } = engine.state
      return (x < width && y < height)
    }
  }
  return false
}