import type { HasTaxiID } from '../entity/HasTaxiID'
import type { Entity } from '../entity/index'
import type { HasHP } from '../has_hp'
import type { HasPlayerId } from '../player/has_player_id'
import type { EntityGridXY } from './EntityGridXY.type'
import createLazyReadOnlyGridXY from './createLazyReadOnlyGridXY'

type AttackableEntity = Entity & HasHP

export default function createLazyAttackableUnitAtGrid(
  // full state width
  gridWidth: number,
  // full state height
  gridHeight: number,
  // full state ents as GridXY
  grid: EntityGridXY,
  // unit attempting to move
  unit: Entity
) {
  const unitPlayerId = (unit as HasPlayerId).player_id
  const lazyCostGrid = createLazyReadOnlyGridXY<Array<AttackableEntity>>(
    gridWidth,
    gridHeight,
    function costGridGetter(x: number, y: number): Array<AttackableEntity> {
      const positionStack = grid?.[x]?.[y]
      if (positionStack) {
        return positionStack.filter((ent: Entity): ent is AttackableEntity => {
          if ((ent as HasTaxiID).taxi_id) {
            // cant attack units inside of transport
            return false
          }
          return (ent as HasHP).hp > 0 && (ent as HasPlayerId).player_id !== unitPlayerId
        })
      }
      return []
    }
  )
  return lazyCostGrid
}
