import { now } from '@sg/shared/src/lib/Date'
import { floor } from "../../../lib/core/util/math"
import { getSessionValue } from "./sessionValue"

export default function isAccessTokenExpired() {
  const sessionValue = getSessionValue()
  // console.assert(username);
  const expiresAt = sessionValue?.exp || 0
  const nowAt = floor(now() / 1000)
  const isExpired = expiresAt < nowAt + 500
  // console[isExpired ? 'warn' : 'log']('isAccessTokenExpired', { isExpired, expiresAt, nowAt})
  return isExpired
}
