import { enum_ } from 'valibot'

export enum WeaponTypeId {
  None = 0,
  InfantryMachineGun = 100,
  VehicleMachineGun = 101,
  AntiAirCannon = 102,
  TankCannon = 300,
  ArtillaryCannon = 400,
  Rocket = 600, // Unguided
  Missile = 900, // Guided
}

export const WeaponTypeIdSchema = enum_(WeaponTypeId, 'Invalid Weapon Type ID')
