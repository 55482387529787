import { OTPSchema } from '@sg/shared/src/valibot/schema/OTP.type'
import { Context } from 'hono'
import { env } from 'hono/adapter'
import { sign, verify } from 'hono/jwt'
import { object, parse, string } from 'valibot'
import createAdminSupabaseClient from '../../lib/supabase/createAdminSupabaseClient'
import type { BackendAccessTokenPayload, BackendRefreshTokenPayload, BackendSessionPayload } from './BackendAuthSession.type'
import { OtpRequestTokenSchema } from './handleOtp'
import { JwtTokenType } from './JwtTokenType.enum'

const UNEXPECTED_AUTH_HEADER = {
  message: 'UNEXPECTED_AUTH_HEADER',
}

export const SignInWithOtpRequestSchema = object({
  token: string(),
  otp: OTPSchema,
})

export default async function handleSignIn(c: Context) {
  console.log(env<{ JWT_SECRET: string }>(c))
  const authHeader = c.req.header('Authorization')
  if (authHeader) {
    return c.json(UNEXPECTED_AUTH_HEADER, 400)
  }
  const { token, otp } = parse(SignInWithOtpRequestSchema, await c.req.json())
  const { uuid, email } = parse(OtpRequestTokenSchema, await verify(token, env<{ JWT_SECRET: string }>(c).JWT_SECRET))

  const supabaseAdmin = createAdminSupabaseClient(c)

  try {
    const { data: profileId, error } = await supabaseAdmin.rpc('auth_email_otp_upsert_profile', {
      input_email: email,
      input_uuid: uuid,
      input_otp: otp,
    });
    if (error) {
      throw error
    }
    if (!profileId) {
      throw new Error('Unexpected !profileId')
    }
    console.log('result', { profileId })

    // TODO: send email that login successfully happened

    // access_token expires in 10 minutes
    const atPayload : BackendAccessTokenPayload = {
      t: JwtTokenType.ACCESS,
      sub: profileId,
      exp: Date.now() + 1000 * 60 * 10,
    }
    const access_token: string = await sign(atPayload, env<{ JWT_SECRET: string }>(c).JWT_SECRET)

    // access_token expires in 30 days
    const rtPayload : BackendRefreshTokenPayload = {
      t: JwtTokenType.REFRESH,
      sub: profileId,
      exp: Date.now() + 1000 * 60 * 60 * 24 * 30,
    }
    const refresh_token: string = await sign(rtPayload, env<{ JWT_SECRET: string }>(c).JWT_SECRET)

    const sessionPayload : BackendSessionPayload = {
      ...atPayload,
      at: access_token,
      rt: refresh_token,
    }

    return c.json(sessionPayload)
  } catch (err) {
    console.error(err)
    if ((err as Error)?.name) {
      return c.json({
        error: (err as Error)?.name,
      }, 500)
    }
    return c.json({
      error: 'ISE',
    }, 500)
  }
}
