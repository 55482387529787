import noop from '../ldsh/noop'
import { createOffscreenCanvas } from './create_canvas'
import { EitherRenderingContext2D } from './get_canvas_2d_context'

// interface HasSmoothingProperties {
//   webkitImageSmoothingEnabled?: boolean
//   mozImageSmoothingEnabled?: boolean
//   imageSmoothingEnabled?: boolean
//   imageSmoothingQuality?: string
// }

// type HTMLCanvasElement = HTMLCanvasElement & HasSmoothingProperties
// type OffscreenCanvas = OffscreenCanvas & HasSmoothingProperties

// old version of function that
// new version detects browser properties
// export function disableCanvasSmoothingB(input: EitherRenderingContext2D) {
//   // this rename probably gets dropped I hope
//   const ctx: EitherRenderingContext2D & HasSmoothingProperties = input as EitherRenderingContext2D & HasSmoothingProperties
//   ctx.mozImageSmoothingEnabled = false
//   ctx.oImageSmoothingEnabled = false
//   ctx.webkitImageSmoothingEnabled = false
//   ctx.msImageSmoothingEnabled = false
//   ctx.imageSmoothingEnabled = false
//   // ctx.imageSmoothingQuality = 'low'
//   // ctx.imageSmoothingQuality = 'pixelated' // not allowed?
//   // ctx.translate(0.5, 0.5)
//   // const dpi = window.devicePixelRatio
//   // if (dpi) {
//   //   ctx.scale(dpi, dpi)
//   // }
// }

type disableCanvasSmoothingFunction = (ctx: EitherRenderingContext2D) => void

/**
 * Use pixelated effect
 *
 * When does imageSmoothingEnabled = false need to be set?
 * - getContext('2d')
 * - on canvas resize
 *
 * I'm under the impression that this version of the function
 * will allow the most amount of JS engines to optimize
 * to byte code
 */
export const disableCanvasSmoothing: disableCanvasSmoothingFunction = (() => {
  const ctx = createOffscreenCanvas(1, 1).getContext('2d')
  if (ctx) {
    if ('imageSmoothingEnabled' in ctx)
      return (ctx: EitherRenderingContext2D) => {
        ctx.imageSmoothingEnabled = false
      }
    if ('mozImageSmoothingEnabled' in ctx)
      return (ctx: EitherRenderingContext2D) => {
        ;(ctx as unknown as Record<string, boolean>).mozImageSmoothingEnabled = false
      }
    if ('webkitImageSmoothingEnabled' in ctx)
      return (ctx: EitherRenderingContext2D) => {
        ;(ctx as unknown as Record<string, boolean>).webkitImageSmoothingEnabled = false
      }
    if ('msImageSmoothingEnabled' in ctx)
      return (ctx: EitherRenderingContext2D) => {
        ;(ctx as unknown as Record<string, boolean>).msImageSmoothingEnabled = false
      }
    if ('oImageSmoothingEnabled' in ctx)
      return (ctx: EitherRenderingContext2D) => {
        ;(ctx as unknown as Record<string, boolean>).oImageSmoothingEnabled = false
      }
  }
  return noop
})()

// export const disableCanvasSmoothing: disableCanvasSmoothingFunction = (() => {
//   const ctx = createOffscreenCanvas(1, 1).getContext('2d')
//   if (ctx) {
//     const innerFn = [
//       'mozImageSmoothingEnabled',
//       'oImageSmoothingEnabled',
//       'webkitImageSmoothingEnabled',
//       'msImageSmoothingEnabled',
//       'imageSmoothingEnabled',
//     ].filter((key: string) => key in ctx)
//       .map((key: string) => `c.${key} = false`)
//       .join(';')

//     const fn = Function('c', innerFn)
//     // console.log('disableCanvasSmoothing', innerFn)
//     // // const fn: string = `function disableCanvasSmoothing (c) {${innerFn}}`
//     // console.log('Function', fn)
//     // // console.log('eval', eval(fn))

//     return fn as disableCanvasSmoothingFunction
//     // return noop
//   }
//   return noop as disableCanvasSmoothingFunction
// })()
