import createMapEditorPlayer from '../player/createMapEditorPlayer'
import { createNewRNGConfig } from '../rng/index'
import createDefaultStateConfig from '../state/create_default_state_config'
import createDefaultStateMap from '../state/create_default_state_map'
import type { State } from '../state/State.type'
import { StateType } from '../state/state_type.enum'

export default function blankStateForEngineInit(): State {
  const rng = createNewRNGConfig()

  const state: State = {
    // id: randomUid(),
    round: 1,
    type: StateType.Map,
    config: createDefaultStateConfig(),
    map: createDefaultStateMap(),
    width: 1,
    height: 1,
    ents: [],
    players: [],
    turnPlayerId: null,
    rng,
  }

  // const ent = createPlainEntity(randomId(state.ents, state.rng), 1, 1)
  state.players.push(createMapEditorPlayer(state, rng))

  return state
}
