import { Entity } from '.'
import type { HasAttackedThisTurn } from '../AttackedThisTurn'
import type { HasWasBuiltThisTurn } from '../WasBuiltThisTurn'
import isEntityOutOfMoves from './isEntityOutOfMoves'
import isEntityUnit from './isEntityUnit'

export default function isEntityHasMovesLeft(entity: Entity): boolean {
  if ((entity as HasAttackedThisTurn).attackedThisTurn) {
    return false
  }
  if ((entity as HasWasBuiltThisTurn).builtThisTurn) {
    return false
  }
  if (isEntityOutOfMoves(entity)) {
    return false
  }
  return isEntityUnit(entity)
}
