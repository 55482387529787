import { InferOutput, maxLength, nullable, optional, pipe, string } from 'valibot'

export const DNAME_MINIMUM = 0
export const DNAME_MAXIMUM = 40

export const DESCRIPTION_MINIMUM = 0
export const DESCRIPTION_MAXIMUM = 255

export const GAME_PASSPHRASE_MINIMUM = 10
export const GAME_PASSPHRASE_MAXIMUM = 255

export const DisplayNameSchema = pipe(string(), maxLength(DNAME_MAXIMUM))
export const NullableDisplayNameSchema = nullable(DisplayNameSchema)
export const OptionalDisplayNameSchema = optional(DisplayNameSchema)
export const OptionalNullableDisplayNameSchema = optional(NullableDisplayNameSchema)

export type DisplayName = InferOutput<typeof DisplayNameSchema>

// console.log(DisplayNameSchema)