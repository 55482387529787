import { Signal, createRoot, createSignal } from 'solid-js'
import type { Engine } from '../../../lib/core/engine/Engine.type'
import { Nullable } from '../../../typescript'

const signal: Signal<Nullable<Engine>> = createRoot(() => {
  // eslint-disable-next-line solid/reactivity
  const signal = createSignal<Nullable<Engine>>()

  return signal
})

export default function createCornerWidgetEngineSignal() {
  return signal
}
