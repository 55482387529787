import { InferOutput, number } from 'valibot'

// health points
// capture progress would be stored in log
// otherwise neutral tiles would need
// fulltime dedicated entities
export const HPSchema = number()

export type HP = InferOutput<typeof HPSchema>

export const MIN_HEALTH_POINTS = 0
export const HEALTH_POINTS_UI_STEP = 0.1
export const MAX_HEALTH_POINTS = 20
