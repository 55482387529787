import type { Engine } from '../engine/Engine.type'
import type { HasTaxiID } from '../entity/HasTaxiID'
import type { HasHP } from '../has_hp'
import type { HasPlayerId } from '../player/has_player_id'
import getManhattanDistance from '../tile_position_xy/getManhattanDistance'
import isOrthogonallyAdjacent from '../tile_position_xy/isOrthogonallyAdjacent'
import { samePosition } from '../tile_position_xy/samePosition'
import toCoord from '../tile_position_xy/toCoord'
import createDraftMoveAttackEstimate from './createDraftMoveAttackEstimate'
import type { DraftMove_AttackEstimate } from './DraftMove_AttackEstimate.type'

export default function calculateDraftMoveAttackEstimates(engine: Engine): void {
  // console.log('calculateDraftMoveAttackEstimates')
  const { draftMove, state, hoveredPosition } = engine
  if (draftMove) {
    // console.log('calculateDraftMoveAttackEstimates', deepClone({
    //   draftMove: engine.draftMove, hoveredPosition,
    // }))
    const estimates: Array<DraftMove_AttackEstimate> = []
    const { unit, destPosition, attackPosition } = draftMove
    const { player_id: unitPlayerId } = unit as HasPlayerId
    if (unitPlayerId) {
      const targetPosition = hoveredPosition || attackPosition || destPosition
      // || coord(unit.x, unit.y)
      if (targetPosition) {
        const { ents } = state
        let index = ents.length
        while (index--) {
          const target = ents[index]
          if ((target as HasTaxiID).taxi_id) {
            // cant attack units inside of transport
          } else if ((target as HasPlayerId).player_id === unitPlayerId) {
            // cant attack your own units
          } else if ((target as HasHP).hp > 0) {
            const distance = getManhattanDistance(destPosition || unit, targetPosition)
            if (samePosition(target, hoveredPosition)) {
              if (distance > 1) {
                // hoveredPositionPaths?
                // console.log('calculateDraftMoveAttackEstimates.distance > 1')
                const { hoveredPositionPaths } = draftMove
                if (hoveredPositionPaths) {
                  const { lastStep, targetStep } = hoveredPositionPaths
                  if (lastStep && targetStep?.enemies?.find(e => e.canDirectAttack)) {
                    estimates.push(createDraftMoveAttackEstimate(engine, unit, toCoord(lastStep), target))
                  }
                }
              } else {
                estimates.push(createDraftMoveAttackEstimate(engine, unit, destPosition || toCoord(unit), target))
              }
            } else if (samePosition(target, attackPosition)) {
              estimates.push(createDraftMoveAttackEstimate(engine, unit, destPosition || toCoord(unit), target))
            } else if (destPosition && isOrthogonallyAdjacent(target, destPosition)) {
              estimates.push(createDraftMoveAttackEstimate(engine, unit, destPosition, target))
            } else {
              // console.log('calculateDraftMoveAttackEstimates.else9')
            }
          }
        }
      }
    }
    // console.log('draftMove.attackEstimates = estimates', deepClone(estimates))
    draftMove.attackEstimates = estimates
  }
}
