import { GameRowValue } from '@sg/backend/src/lib/db/getGameOr404.ts'
import { createResource, type Accessor } from 'solid-js'
import { parse } from 'valibot'
import { backendAxios } from '../../axios'
import { StateSchema } from '../../lib/core/state/State.type'
import type { StateOnlineId } from '../../lib/core/state/StateOnlineId.type'
import { Nullable } from '../../typescript'

async function fetchGameById(stateId: Nullable<StateOnlineId>): Promise<GameRowValue | null> {
  if (stateId) {
    const response = await backendAxios.get(`/st1/game/${stateId}`)
    const row: GameRowValue = response.data
    // validate row
    row.data = parse(StateSchema, row.data)
    return row as GameRowValue
  }
  return null
}

export default function createGameRowResource(stateId: Accessor<Nullable<StateOnlineId>>) {
  const resource = createResource(stateId, fetchGameById)
  return resource
}
