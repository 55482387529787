import { array, fallback, InferOutput, nullable, number, object, optional } from 'valibot'
import { OneOrZeroSchema } from '../../../rx/signal/create_one_or_zero_signal'
import { RestrictedUnitRuleSchema } from '../restricted_unit_rule'
import { WinAnnexAnyHqDefault } from './create_default_state_config'

export const StateConfigSchema = object({
  // weather: clear/rain/snow/random,
  fow: OneOrZeroSchema,
  incomeMult: number(), // default 1
  startIncome: number(), // default 0
  winMapControlPercent: optional(nullable(number())), // default 90%
  /**
   * capturing any player's HQ ends the game.
   */
  winAnnexAnyHq: fallback(OneOrZeroSchema, WinAnnexAnyHqDefault), // default false
  /**
   * A player who lost their HQ can still build move their units
   * As long as they still have an ally.
   */
  requireCompleteKills: OneOrZeroSchema, // default false
  /**
   * Declare the player with the highest income winner.
   */
  winMaxDays: optional(nullable(number())), // default null
  maxUnitsPerPlayer: optional(nullable(number())), // default null
  restrictedUnits: array(RestrictedUnitRuleSchema),
})

export type StateConfig = InferOutput<typeof StateConfigSchema>
