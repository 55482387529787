import { Key } from '@solid-primitives/keyed'
import { useBeforeLeave } from '@solidjs/router'
import { Modal } from 'solid-bootstrap'
import { Component, createMemo, useContext, type Accessor } from 'solid-js'
import type { Engine } from '../lib/core/engine/Engine.type'
import { createBuildingEntity } from '../lib/core/entity/EntityType/Building'
import { createInfantryEntity } from '../lib/core/entity/EntityType/Infantry'
import getPerTurnIncomeFromPlayerEnts from '../lib/core/entity/getPerTurnIncomeFromPlayerEnts'
import getPlayerEntities from '../lib/core/entity/getPlayerEntities'
import isEntityUnit from '../lib/core/entity/isEntityUnit'
import isMapControlTarget from '../lib/core/entity/isMapControlTarget'
import formatGameMoney from '../lib/core/player/formatGameMoney'
import type { Player } from '../lib/core/player/Player.type'
import playerDisplayName from '../lib/core/player/playerDisplayName'
import { floor } from '../lib/core/util/math'
import useIsPlayersListModalOpenSignal from '../rx/shared/ModalMenu/useIsPlayersListModalOpenSignal'
import PlayerGameStatusBadge from './Badge/PlayerGameStatusBadge'
import EngineContext from './EngineContext'
import PlayerKindIcon from './Icon/PlayerKindIcon'
import SingleSpriteIcon from './MapEditor/SingleSpriteIcon'
import NA from './Util/na'
import { FaSolidCentSign } from 'solid-icons/fa'

const GamePlayersListModal: Component = () => {
  const engine: Engine = useContext(EngineContext)

  const [isPlayersListModalOpen, setIsPlayersListModalOpen] = useIsPlayersListModalOpenSignal()

  // const handleClickToggleIsMenuOpen = (event: Event) => {
  //   setIsMenuOpen(!isMenuOpen())
  //   preventDefault(event)
  // }
  const closeMenu = () => setIsPlayersListModalOpen(false)

  useBeforeLeave(closeMenu)

  const allMapControlTargetsCount = createMemo(() => {
    return engine.state.ents.filter(isMapControlTarget).length
  })

  return (
    <Modal show={isPlayersListModalOpen()} onHide={closeMenu} centered contentClass="mbgo">
      <Modal.Body>
        <h2 class="text-center w-100">Players</h2>
        <table class="table table-sm">
          <thead>
            <tr>
              <td class="px-0" colspan="2" />{/* for icon */}
              <td class="px-0" colspan="2">Player</td>
              <td class="text-end">Units</td>
              <td class="text-end">Income</td>
              <td class="text-end">Cash</td>
              <td class="text-end" title="Map Control" aria-label="Map Control">🗺️</td>
            </tr>
          </thead>
          <tbody>
            <Key each={engine.state.players} by="id" fallback={<div>Zero Players</div>}
              children={(player: Accessor<Player>) => {

                const playerEnts = createMemo(() => {
                  return getPlayerEntities(engine.state.ents, player().id)
                })

                const perTurnIncome = createMemo<number>(() => {
                  return getPerTurnIncomeFromPlayerEnts(playerEnts())
                })
                const unitsCount = createMemo<number>(() => {
                  return playerEnts().filter(isEntityUnit).length
                })
                const mapControl = createMemo<ReturnType<typeof NA> | number>(() => {
                  const total = allMapControlTargetsCount()
                  if (total) {
                    const entsCount = playerEnts().filter(isMapControlTarget).length
                    const value = floor(100 * (entsCount / total))
                    console.log('MapControl', entsCount, total, value)
                    return `${value}%`
                  }
                  return NA()
                })

                return (
                  <tr>
                    <td class="px-0">
                      <SingleSpriteIcon
                        entity={createInfantryEntity(1, 0, 0, player().id)}
                        noBg
                      />
                    </td>
                    <td class="px-0"><PlayerKindIcon player={player()} /></td>
                    <td class="px-0">{playerDisplayName(player())}</td>
                    <td class="px-0"><PlayerGameStatusBadge player={player()} /></td>
                    <td class="text-end">{unitsCount()}</td>
                    <td class="text-end">
                      <SingleSpriteIcon
                        entity={createBuildingEntity(1, 0, 0, player().id)}
                        noBg
                      />
                      <span class="text-muted ps-1"><FaSolidCentSign /></span>
                      {formatGameMoney(perTurnIncome())}
                      <span class="text-muted small">/turn</span>
                    </td>
                    <td class="text-end">
                      💰
                      <span class="text-muted ps-1"><FaSolidCentSign /></span>
                      {formatGameMoney(player().money)}
                    </td>
                    <td class="text-end">{mapControl()}</td>
                  </tr>
                )
              }}
            />
          </tbody>
        </table>
      </Modal.Body>
      {/* <Modal.Footer>
        <button class="btn btn-secondary" onClick={closeMenu}>Cancel</button>
        <button class="btn btn-primary" onClick={closeMenu}>Save New Rule</button>
      </Modal.Footer> */}
    </Modal>
  )
}

export default GamePlayersListModal
