import { FaSolidArrowLeft, FaSolidCentSign, FaSolidTrashCan } from 'solid-icons/fa'
import { Component, createEffect, createMemo, createSignal, For, Match, Show, Switch, useContext } from 'solid-js'
import { modifyMutable, produce } from 'solid-js/store'
import type { Engine } from '../../lib/core/engine/Engine.type'
import calculateSelectedPositionEnts from '../../lib/core/entity/calculateSelectedPositionEnts'
import getFactoryBuildUnitsStatus, { FactoryBuildUnitsStatus } from '../../lib/core/entity/canFactoryBuildUnits'
import createFactoryUnitEntity from '../../lib/core/entity/createFactoryUnitEntity'
import type { FactoryEntity } from '../../lib/core/entity/EntityType/Factory'
import entityTypeMetaForFactoryList from '../../lib/core/entity/entityTypeMetaForFactoryList'
import entityTypeMetaList, { isEntityFactory } from '../../lib/core/entity/entityTypeMetaList.generated'
import getEntitiesAtPosition from '../../lib/core/entity/getEntitiesAtPosition'
import type { Entity } from '../../lib/core/entity/index'
import type { EntityTypeMeta } from '../../lib/core/EntityTypeMeta'
import findByIdOrThrow from '../../lib/core/findByIdOrThrow'
import type { HasPrice } from '../../lib/core/HasPrice'
import { hasWasBuiltThisTurn } from '../../lib/core/hasWasBuiltThisTurn'
import isEntityTypeMetaHasPrice from '../../lib/core/isEntityTypeMetaHasPrice'
import formatGameMoney from '../../lib/core/player/formatGameMoney'
import { createCancelFactoryOrderAction } from '../../lib/core/state/flux/action/Game/CancelFactoryOrderAction'
import { createPlaceFactoryOrderAction } from '../../lib/core/state/flux/action/Game/PlaceFactoryOrderAction'
import dispatchClient from '../../lib/core/state/flux/dispatchClient'
import Button from '../../lib/jsx/Button'
import withBusy from '../../lib/withBusy'
import { createAuthPlayerMemo } from '../../rx/signal/createAuthPlayerSignal'
import type { Nullable } from '../../typescript'
import EngineContext from '../EngineContext'
import LoadingIcon from '../LoadingIcon'
import SingleSpriteIcon from '../MapEditor/SingleSpriteIcon'

const shouldAutoCloseAfterPurchase = true

const FactoryStoreCornerWidget: Component = () => {
  const engine: Engine = useContext(EngineContext)
  const authPlayer = createAuthPlayerMemo(engine)

  // const isInspectTool = createMemo<boolean>(() => {
  //   // console.log('isInspectTool.memo', engine.selectedTool, SelectedToolId.Inspect, engine.selectedTool === SelectedToolId.Inspect)
  //   // track(EngineCache.SelectedToolId)
  //   return engine.selectedTool === SelectedToolId.Inspect
  // })

  // createEffect(() => {
  //   console.log('engine.selectedPosition', unwrap(engine.selectedPosition))
  // })
  // createEffect(() => {
  //   console.log('engine.selectedPositionEnts', unwrap(engine.selectedPositionEnts))
  // })

  const factoryEntity = createMemo((): Nullable<FactoryEntity> => {
    const { authPlayerId } = engine
    if (authPlayerId) {
      const factoryEntity = engine.selectedPositionEnts.find(isEntityFactory)
      if (factoryEntity && factoryEntity.player_id === authPlayerId) {
        return factoryEntity
      }
    }
  })

  const entitiesAtPosition = createMemo((): Array<Entity> => {
    const ent = factoryEntity()
    if (ent) {
      return getEntitiesAtPosition(engine.state.ents, ent)
    }
    return []
  })

  const isFactoryStatusOpen = createMemo((): boolean => {
    const ent = factoryEntity()
    if (ent) {
      const status = getFactoryBuildUnitsStatus(ent, entitiesAtPosition())
      return status === FactoryBuildUnitsStatus.OrderPlaced
        || status === FactoryBuildUnitsStatus.WaitingForOrder
    }
    return false
  })

  const budget = createMemo((): number => {
    let budget: number = (authPlayer()?.money || 0)
    const etype_id = entitiesAtPosition().find(hasWasBuiltThisTurn)?.etype_id
    if (etype_id) {
      const entityTypeMeta: EntityTypeMeta = findByIdOrThrow(entityTypeMetaList, etype_id)
      if (isEntityTypeMetaHasPrice(entityTypeMeta)) {
        budget += entityTypeMeta.price
      }
    }
    return budget
  })

  const [selectedEntityTypeMeta, setSelectedEntityTypeMeta] = createSignal<Nullable<EntityTypeMeta>>(null)
  createEffect(() => {
    if (!selectedEntityTypeMeta()) {
      const etype_id = entitiesAtPosition().find(hasWasBuiltThisTurn)?.etype_id
      if (etype_id) {
        const entityTypeMeta: EntityTypeMeta = findByIdOrThrow(entityTypeMetaList, etype_id)
        setSelectedEntityTypeMeta(entityTypeMeta)
      }
    }
  })

  const [isBusy, setIsBusy] = createSignal<boolean>(false)

  return (
    <Show when={isFactoryStatusOpen()}>
      <div class="cw cw-factory-store cw-sm-fs float-end">
        <div class="d-flex justify-content-evenly mb-2">
          <Switch>
            <Match when={selectedEntityTypeMeta()}
              children={<Button
                disabled={isBusy()}
                name="cancel_order"
                class="btn btn-secondary btn-sm"
                onClick={withBusy(isBusy, setIsBusy, async () => {
                  await dispatchClient(engine, createCancelFactoryOrderAction(factoryEntity() as FactoryEntity))
                  setSelectedEntityTypeMeta(null)
                })}
              >
                <Show when={isBusy()} children={<LoadingIcon />} fallback={<FaSolidTrashCan />} />
                {' Cancel Build Order'}
              </Button>}
            />
            <Match when={budget() > 10} children={<span>Build Orders?</span>} />
            <Match when={true} children={<span>Not enough money...</span>} />
          </Switch>
          <Button
            disabled={isBusy()}
            name="back"
            class="btn btn-secondary btn-sm"
            onClick={withBusy(isBusy, setIsBusy, async () => {
              // No Action required
              modifyMutable(engine, produce((engine) => {
                engine.selectedPosition = null
                calculateSelectedPositionEnts(engine)
              }))
            })}
          >
            <FaSolidArrowLeft />
            {' Back'}
          </Button>
        </div>
        <table class="table table-sm">
          <tbody>
            <For each={entityTypeMetaForFactoryList}>
              {(entityTypeMeta: EntityTypeMeta & HasPrice) => {
                const [tableDanger, setTableDanger] = createSignal<boolean>(false)
                const isSelected = () => selectedEntityTypeMeta()?.id === entityTypeMeta.id
                const entity = createMemo<Entity>(() => {
                  const entity = createFactoryUnitEntity(factoryEntity() as FactoryEntity, entityTypeMeta, 1)
                  if (!isSelected()) {
                    // clear the visual effect
                    delete entity.builtThisTurn
                  }
                  return entity
                })

                return <tr classList={{
                  'disabled': isBusy() || entityTypeMeta.price > budget(),
                  'table-success': isSelected(),
                  'table-danger': tableDanger(),
                }}
                  onClick={
                    // eslint-disable-next-line solid/reactivity
                    withBusy(isBusy, setIsBusy, async () => {
                      if (isSelected()) {
                        await dispatchClient(engine, createCancelFactoryOrderAction(factoryEntity() as FactoryEntity))
                        setSelectedEntityTypeMeta(null)
                      } else if (entityTypeMeta.price > budget()) {
                        setTableDanger(true)
                        setTimeout(setTableDanger, 111, false)
                      } else {
                        // placing new order auto-cancels old order
                        await dispatchClient(engine, createPlaceFactoryOrderAction(factoryEntity() as FactoryEntity, entityTypeMeta.id))
                        if (shouldAutoCloseAfterPurchase) {
                          modifyMutable(engine, produce((engine) => {
                            engine.selectedPosition = null
                            calculateSelectedPositionEnts(engine)
                          }))
                        } else {
                          setSelectedEntityTypeMeta(entityTypeMeta)
                        }
                      }
                    })}
                >
                  <td>
                    <SingleSpriteIcon
                      entity={entity()}
                      tileSize={64}
                      noBg
                      style={{
                        'margin-top': '-80px',
                        'margin-bottom': '-10px',
                      }}
                    />
                  </td>
                  <td>
                    {entityTypeMeta.dname}
                  </td>
                  <td>
                    <span class="text-muted"><FaSolidCentSign /></span>{' '}
                    {formatGameMoney(entityTypeMeta.price)}
                  </td>
                </tr>
              }}
            </For>
          </tbody>
        </table>
      </div>
    </Show>
  )
}

export default FactoryStoreCornerWidget
