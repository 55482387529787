import { Nullable } from '../../../../typescript'
import { Engine } from '../../../core/engine/Engine.type'
import { Entity } from '../../../core/entity'
import shouldEntityShowFadedFrames from '../../../core/entity/shouldEntityShowFadedFrames'
import { LayerMeta } from '../../../core/layer_meta'
import getEntityPlayer from '../../../core/player/get_entity_player'
import { Player } from '../../../core/player/Player.type'
import { RenderSpriteFunction } from '../../../core/render_sprite'
import { min } from '../../../core/util/math'
import { BASE_TILE_SIZE } from '../../../core/view_ctx'
import {
  MediumAntiAir_Group1_BodyDoorWindowBlue1_d,
  MediumAntiAir_Group1_BodyDoorWindowBlue1_fill,
  MediumAntiAir_Group1_BodyLowerGrey1_d,
  MediumAntiAir_Group1_BodyLowerGrey1_fill,
  MediumAntiAir_Group1_BodyTopGrey1_d,
  MediumAntiAir_Group1_BodyTopGrey1_fill,
  MediumAntiAir_Group1_BottomMissileGrey1_d,
  MediumAntiAir_Group1_BottomMissileGrey1_fill,
  MediumAntiAir_Group1_RackPoleGrey1_d,
  MediumAntiAir_Group1_RackPoleGrey1_fill,
  MediumAntiAir_Group1_TopMissileGrey1_d,
  MediumAntiAir_Group1_TopMissileGrey1_fill,
  MediumAntiAir_Group1_WheelAxelBgGrey1_d,
  MediumAntiAir_Group1_WheelAxelBgGrey1_fill,
  MediumAntiAir_Group1_WheelsInnerGrey1_d,
  MediumAntiAir_Group1_WheelsInnerGrey1_fill,
  MediumAntiAir_Group1_WheelsOuterBlack1_d,
  MediumAntiAir_Group1_WheelsOuterBlack1_fill,
  MediumAntiAir_height,
  MediumAntiAir_width,
} from '../../../svg/sprites_data'
import { createOffscreenCanvas } from '../../create_canvas'
import { addBackgroundBorder } from '../../draw/add_background_border'
import createFadedFrameCanvas from '../../draw/createFadedFrameCanvas'
import { EitherRenderingContext2D, getCanvas2dContext } from '../../get_canvas_2d_context'

// const areGunsRaised = true

const totalLoopMs = 1000

export default function makeRenderMediumAntiAir(
  engine: Engine,
  entity: Entity
): RenderSpriteFunction {
  // console.log('makeRenderMediumAntiAir')
  const tile_size = engine.viewCtx.tile_size
  const player: Nullable<Player> = getEntityPlayer(engine, entity)

  const sRenderMult = tile_size / BASE_TILE_SIZE
  const sRenderMultInv = BASE_TILE_SIZE / tile_size
  const sWidth = MediumAntiAir_width * sRenderMult
  const sHeight = MediumAntiAir_height * sRenderMult

  const frames: Array<OffscreenCanvas> = []
  const fadedFrames: Array<OffscreenCanvas> = []

  function makeFrame(frameIndex: number): OffscreenCanvas {
    const elem = createOffscreenCanvas(sWidth, sHeight)

    const ctx = getCanvas2dContext(elem)
    if (ctx) {
      ctx.scale(sRenderMult, sRenderMult)

      ctx.fillStyle = MediumAntiAir_Group1_WheelAxelBgGrey1_fill
      ctx.fill(MediumAntiAir_Group1_WheelAxelBgGrey1_d)

      ctx.fillStyle = MediumAntiAir_Group1_WheelsOuterBlack1_fill
      ctx.fill(MediumAntiAir_Group1_WheelsOuterBlack1_d)

      ctx.fillStyle = MediumAntiAir_Group1_WheelsInnerGrey1_fill
      ctx.fill(MediumAntiAir_Group1_WheelsInnerGrey1_d)

      if (frameIndex === 1 || frameIndex === 2) {
        ctx.translate(0, -1)
      }

      ctx.fillStyle = player?.color1 || MediumAntiAir_Group1_BodyLowerGrey1_fill
      ctx.fill(MediumAntiAir_Group1_BodyLowerGrey1_d)

      ctx.fillStyle = player?.color2 || MediumAntiAir_Group1_BodyTopGrey1_fill
      ctx.fill(MediumAntiAir_Group1_BodyTopGrey1_d)

      ctx.fillStyle = MediumAntiAir_Group1_BodyDoorWindowBlue1_fill
      ctx.fill(MediumAntiAir_Group1_BodyDoorWindowBlue1_d)

      ctx.fillStyle = player?.color1 || MediumAntiAir_Group1_RackPoleGrey1_fill
      ctx.fill(MediumAntiAir_Group1_RackPoleGrey1_d)

      ctx.fillStyle = player?.color2 || MediumAntiAir_Group1_TopMissileGrey1_fill
      ctx.fill(MediumAntiAir_Group1_TopMissileGrey1_d)

      ctx.fillStyle = player?.color2 || MediumAntiAir_Group1_BottomMissileGrey1_fill
      ctx.fill(MediumAntiAir_Group1_BottomMissileGrey1_d)

      if (frameIndex === 1 || frameIndex === 2) {
        ctx.translate(0, 1)
      }

      ctx.scale(sRenderMultInv, sRenderMultInv)
      addBackgroundBorder(ctx, elem, 0, 0, 0, 255, 1)
    }
    return elem
  }

  const fn = function renderMediumAntiAir(
    ctx: EitherRenderingContext2D,
    lm: LayerMeta,
    entity: Entity,
    dx: number,
    dy: number,
    tile_size: number
  ): void {
    // console.log('renderMediumAntiAir', Math.floor(lm.ts), Math.floor(lm.until))
    let frameIndex = 0
    let frame: Nullable<OffscreenCanvas>

    if (shouldEntityShowFadedFrames(entity)) {
      // if the unit was built, or is out of moves
      // it shall be faded instead of bright and animated
      fadedFrames[0] ||= createFadedFrameCanvas((frames[0] ||= makeFrame(0)))
      frame = fadedFrames[0]
    } else if (player) {
      // unit is bouncy
      const mod = lm.ts % totalLoopMs
      if (mod > 800) {
        frameIndex = 3
      } else if (mod > 400) {
        frameIndex = 2
      } else if (mod > 200) {
        frameIndex = 1
      }
      lm.until = min(lm.until, lm.ts + (totalLoopMs - mod))
    }

    if (!frame) {
      frame = frames[frameIndex] ||= makeFrame(frameIndex)
    }
    if (!frame) {
      throw new Error('unexpected !frame')
    }

    ctx.drawImage(frame, 0, 0, sWidth, sHeight, dx, dy, tile_size, tile_size)
  }

  return fn
}
