import noop from "../../ldsh/noop"
import type { DraftMove } from "../draft_move/DraftMove.type"
import findByXY from "../findByXY"

export default function createCanUnitDirectAttackThisTurn(draftMove : DraftMove | null) {
  const rangedEstimates = draftMove?.rangedEstimates
  if (!rangedEstimates) {
    return noop as () => false
  }
  return function canUnitRangedAttackThisTurn (x: number, y: number) {
    const rangedEstimate = findByXY(rangedEstimates, x, y)
    if (rangedEstimate?.estimate) {
      return true
    }
    return false
  }
}
