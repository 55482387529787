import { max, min } from '../../core/util/math'
import { DEFAULT_GLOBAL_COMPOSITE_OPERATION, DESTINATION_OVER } from '../canvas'
import { createOffscreenCanvas } from '../create_canvas'
import GaussianBlur from '../filter/arahaya_image_filters/gaussian_blur'
import { EitherRenderingContext2D, getCanvas2dContext } from '../get_canvas_2d_context'

// 0,0,0 = black
// 255 alpha = filled
export function addBackgroundBorder(
  ctx: EitherRenderingContext2D,
  elem: OffscreenCanvas,
  red: number,
  green: number,
  blue: number,
  targetAlpha: number,
  growSize: number = 0
) {
  const sw = elem.width
  const sh = elem.height
  // const imageData = ctx.getImageData(0, 0, sw, sh)
  const imageData = GaussianBlur(ctx.getImageData(0, 0, sw, sh), 1)
  const data = imageData.data

  const isFilled: Set<number> = new Set()

  // track which pixels are filled
  for (let y = 0; y < sh; y++) {
    for (let x = 0; x < sw; x++) {
      const index = (y * sw + x) * 4
      const alpha = data[index + 3]
      if (alpha > 127) {
        isFilled.add(index)
      }
    }
  }

  const toFill: Set<number> = new Set()

  if (growSize > 0) {
    for (let y1 = 0; y1 < sh; y1++) {
      for (let x1 = 0; x1 < sw; x1++) {
        const index1 = (y1 * sw + x1) * 4
        if (isFilled.has(index1)) {
          toFill.add(index1)
          const y2_top = max(0, y1 - growSize)
          const y2_bottom = min(sh, y1 + growSize + 1)
          const x2_left = max(0, x1 - growSize)
          const x2_right = min(sh, x1 + growSize + 1)
          for (let y2 = y2_top; y2 < y2_bottom; y2++) {
            for (let x2 = x2_left; x2 < x2_right; x2++) {
              const index2 = (y2 * sw + x2) * 4
              toFill.add(index2)
            }
          }
        }
      }
    }
  }

  for (let y = 0; y < sh; y++) {
    for (let x = 0; x < sw; x++) {
      const index = (y * sw + x) * 4
      // const alpha = data[index + 3]
      if (toFill.has(index)) {
        data[index] = red // Red component
        data[index + 1] = green // Green component
        data[index + 2] = blue // Blue component
        data[index + 3] = targetAlpha // Alpha component
      }
    }
  }

  // console.log(sh * sw, 'isFilled', isFilled)

  const elem2 = createOffscreenCanvas(elem.width, elem.height)
  const ctx2 = getCanvas2dContext(elem2)
  if (ctx2) {
    // Put the modified pixel data back to the canvas
    ctx2.putImageData(imageData, 0, 0)
    // ctx2.putImageData(GaussianBlur(imageData, 1), 0, 0)
  }

  ctx.globalCompositeOperation = DESTINATION_OVER
  ctx.drawImage(elem2, 0, 0)
  ctx.globalCompositeOperation = DEFAULT_GLOBAL_COMPOSITE_OPERATION
}
