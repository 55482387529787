/**
 * @link https://github.com/solidjs/solid-router/discussions/231#discussioncomment-4710562
 */

type WindowLocation = {
  state?: {
    previous: string
  }
}

export default function getBackPath() {
  return (window.location as WindowLocation).state?.previous ? -1 : '/'
}
