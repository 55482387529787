import type { BackendSessionPayload } from "@sg/backend/src/api/auth/BackendAuthSession.type"
import type { Nullable } from "../../../typescript"

export default function canSessionCreateGame(session: Nullable<BackendSessionPayload>) {
  if (session) {
    const { sub, guest } = session
    if (guest) {
      return false
    }
    if (sub) {
      return true
    }
  }
  return false
}