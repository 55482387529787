import { enum_ } from 'valibot'

export enum PlayerTurnStatus {
  // The player is waiting for their turn. They cannot take any actions yet.
  Waiting = 2,

  // It is the player's turn, but they haven't started clicked "Start Turn" yet
  Pending = 3,

  // The player is actively taking their turn and can perform actions.
  Playing = 4,

  // The player has completed their turn, and the game is ready for the next player.
  Done = 5,
}

export const PlayerTurnStatusSchema = enum_(PlayerTurnStatus, 'Invalid Player Turn Status')

export const playerTurnStatusIconsMap : Record<PlayerTurnStatus, string> = {
  [PlayerTurnStatus.Waiting]: '🕒',
  [PlayerTurnStatus.Pending]: '⏺️',
  [PlayerTurnStatus.Playing]: '🟢',
  [PlayerTurnStatus.Done]: '✅',
};

export const playerTurnStatusTextMap : Record<PlayerTurnStatus, string> = {
  [PlayerTurnStatus.Waiting]: 'Waiting',
  [PlayerTurnStatus.Pending]: 'Pending',
  [PlayerTurnStatus.Playing]: 'Playing',
  [PlayerTurnStatus.Done]: 'Done',
};