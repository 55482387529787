import type { Engine } from "../../engine/Engine.type"
import getTransportableDestPositionEntity from "../../entity/getTransportableDestPositionEntity"
import { createMoveUnitAction } from "../../state/flux/action/Game/MoveUnitAction"
import dispatchClient from "../../state/flux/dispatchClient"

export default function handleUnitPickupEntityAction(engine: Engine) {
  // console.log('handleUnitPickupEntityAction')

  const { draftMove } = engine
  if (draftMove) {
    const cargoEnt = getTransportableDestPositionEntity(engine.state.ents, draftMove.unit, draftMove.destPosition)
    if (cargoEnt) {
      dispatchClient(engine, createMoveUnitAction({...draftMove, pickup: cargoEnt }))
    }
  }
}
