import type { HasXY } from './has_xy'

export default function findByXY<T extends HasXY>(list: Array<T>, x: number, y: number): T | null {
  for (let index = 0; index < list.length; index++) {
    const item = list[index]
    if (item.x === x && item.y === y) {
      return item
    }
  }
  return null
}
