import { getCanvas2dContext } from '../../get_canvas_2d_context'

export function createImageData(w: number, h: number) {
  return new ImageData(w, h)
}

export function clamp(value: number) {
  return value > 255 ? 255 : value < 0 ? 0 : value
}

export function buildMap(f: mapRGBFunction) {
  const m = []
  for (let k = 0, v; k < 256; k += 1) {
    m[k] = (v = f(k)) > 255 ? 255 : v < 0 ? 0 : v | 0
  }
  return m
}

export function applyMap(src: Uint8ClampedArray, dst: Uint8ClampedArray, map: number[]) {
  for (let i = 0, l = src.length; i < l; i += 4) {
    dst[i] = map[src[i]]
    dst[i + 1] = map[src[i + 1]]
    dst[i + 2] = map[src[i + 2]]
    dst[i + 3] = src[i + 3]
  }
}

export type mapRGBFunction = (value: number) => number

export function mapRGB(src: Uint8ClampedArray, dst: Uint8ClampedArray, func: mapRGBFunction) {
  applyMap(src, dst, buildMap(func))
}

export function getPixelIndex(x: number, y: number, width: number, height: number, edge: number) {
  if (x < 0 || x >= width || y < 0 || y >= height) {
    switch (edge) {
      case 1: // clamp
        x = x < 0 ? 0 : x >= width ? width - 1 : x
        y = y < 0 ? 0 : y >= height ? height - 1 : y
        break
      case 2: // wrap
        x = (x %= width) < 0 ? x + width : x
        y = (y %= height) < 0 ? y + height : y
        break
      default: // transparent
        return null
    }
  }
  return (y * width + x) << 2
}

export function getPixel(
  src: Uint8ClampedArray,
  x: number,
  y: number,
  width: number,
  height: number,
  edge: number
) {
  if (x < 0 || x >= width || y < 0 || y >= height) {
    switch (edge) {
      case 1: // clamp
        x = x < 0 ? 0 : x >= width ? width - 1 : x
        y = y < 0 ? 0 : y >= height ? height - 1 : y
        break
      case 2: // wrap
        x = (x %= width) < 0 ? x + width : x
        y = (y %= height) < 0 ? y + height : y
        break
      default: // transparent
        return 0
    }
  }

  const i = (y * width + x) << 2

  // ARGB
  return (src[i + 3] << 24) | (src[i] << 16) | (src[i + 1] << 8) | src[i + 2]
}

export function getPixelByIndex(src: number[], i: number) {
  return (src[i + 3] << 24) | (src[i] << 16) | (src[i + 1] << 8) | src[i + 2]
}

/**
 * one of the most important functions in this library.
 * I want to make this as fast as possible.
 */
export function copyBilinear(
  src: Uint8ClampedArray,
  x: number,
  y: number,
  width: number,
  height: number,
  dst: Uint8ClampedArray,
  dstIndex: number,
  edge: number
) {
  const fx = x < 0 ? (x - 1) | 0 : x | 0 // floor(x)
  const fy = y < 0 ? (y - 1) | 0 : y | 0 // floor(y)
  const wx = x - fx
  const wy = y - fy
  let i
  let nw = 0
  let ne = 0
  let sw = 0
  let se = 0

  if (fx >= 0 && fx < width - 1 && fy >= 0 && fy < height - 1) {
    // in bounds, no edge actions required
    i = (fy * width + fx) << 2

    if (wx || wy) {
      nw = (src[i + 3] << 24) | (src[i] << 16) | (src[i + 1] << 8) | src[i + 2]

      i += 4
      ne = (src[i + 3] << 24) | (src[i] << 16) | (src[i + 1] << 8) | src[i + 2]

      i = i - 8 + (width << 2)
      sw = (src[i + 3] << 24) | (src[i] << 16) | (src[i + 1] << 8) | src[i + 2]

      i += 4
      se = (src[i + 3] << 24) | (src[i] << 16) | (src[i + 1] << 8) | src[i + 2]
    } else {
      // no interpolation required
      dst[dstIndex] = src[i]
      dst[dstIndex + 1] = src[i + 1]
      dst[dstIndex + 2] = src[i + 2]
      dst[dstIndex + 3] = src[i + 3]
      return
    }
  } else {
    // edge actions required
    nw = getPixel(src, fx, fy, width, height, edge)

    if (wx || wy) {
      ne = getPixel(src, fx + 1, fy, width, height, edge)
      sw = getPixel(src, fx, fy + 1, width, height, edge)
      se = getPixel(src, fx + 1, fy + 1, width, height, edge)
    } else {
      // no interpolation required
      dst[dstIndex] = (nw >> 16) & 0xff
      dst[dstIndex + 1] = (nw >> 8) & 0xff
      dst[dstIndex + 2] = nw & 0xff
      dst[dstIndex + 3] = (nw >> 24) & 0xff
      return
    }
  }

  const cx = 1 - wx
  const cy = 1 - wy
  const r =
    (((nw >> 16) & 0xff) * cx + ((ne >> 16) & 0xff) * wx) * cy +
    (((sw >> 16) & 0xff) * cx + ((se >> 16) & 0xff) * wx) * wy
  const g =
    (((nw >> 8) & 0xff) * cx + ((ne >> 8) & 0xff) * wx) * cy +
    (((sw >> 8) & 0xff) * cx + ((se >> 8) & 0xff) * wx) * wy
  const b = ((nw & 0xff) * cx + (ne & 0xff) * wx) * cy + ((sw & 0xff) * cx + (se & 0xff) * wx) * wy
  const a =
    (((nw >> 24) & 0xff) * cx + ((ne >> 24) & 0xff) * wx) * cy +
    (((sw >> 24) & 0xff) * cx + ((se >> 24) & 0xff) * wx) * wy

  dst[dstIndex] = r > 255 ? 255 : r < 0 ? 0 : r | 0
  dst[dstIndex + 1] = g > 255 ? 255 : g < 0 ? 0 : g | 0
  dst[dstIndex + 2] = b > 255 ? 255 : b < 0 ? 0 : b | 0
  dst[dstIndex + 3] = a > 255 ? 255 : a < 0 ? 0 : a | 0
}

/**
 * @param r 0 <= n <= 255
 * @param g 0 <= n <= 255
 * @param b 0 <= n <= 255
 * @return Array(h, s, l)
 */
export function rgbToHsl(r: number, g: number, b: number) {
  r /= 255
  g /= 255
  b /= 255

  //        var max = max(r, g, b),
  //            min = min(r, g, b),
  const max = r > g ? (r > b ? r : b) : g > b ? g : b
  const min = r < g ? (r < b ? r : b) : g < b ? g : b
  const chroma = max - min
  let h = 0
  let s = 0
  // Lightness
  const l = (min + max) / 2

  if (chroma !== 0) {
    // Hue
    if (r === max) {
      h = (g - b) / chroma + (g < b ? 6 : 0)
    } else if (g === max) {
      h = (b - r) / chroma + 2
    } else {
      h = (r - g) / chroma + 4
    }
    h /= 6

    // Saturation
    s = l > 0.5 ? chroma / (2 - max - min) : chroma / (max + min)
  }

  return [h, s, l]
}

/**
 * @param h 0.0 <= n <= 1.0
 * @param s 0.0 <= n <= 1.0
 * @param l 0.0 <= n <= 1.0
 * @return Array(r, g, b)
 */
export function hslToRgb(h: number, s: number, l: number) {
  let m1,
    m2,
    hue,
    r,
    g,
    b,
    rgb = []

  if (s === 0) {
    r = g = b = (l * 255 + 0.5) | 0
    rgb = [r, g, b]
  } else {
    if (l <= 0.5) {
      m2 = l * (s + 1)
    } else {
      m2 = l + s - l * s
    }

    m1 = l * 2 - m2
    hue = h + 1 / 3

    let tmp
    for (let i = 0; i < 3; i += 1) {
      if (hue < 0) {
        hue += 1
      } else if (hue > 1) {
        hue -= 1
      }

      if (6 * hue < 1) {
        tmp = m1 + (m2 - m1) * hue * 6
      } else if (2 * hue < 1) {
        tmp = m2
      } else if (3 * hue < 2) {
        tmp = m1 + (m2 - m1) * (2 / 3 - hue) * 6
      } else {
        tmp = m1
      }

      rgb[i] = (tmp * 255 + 0.5) | 0

      hue -= 1 / 3
    }
  }

  return rgb
}

export function cloneImageData(srcImageData: ImageData) {
  return copyImageData(srcImageData, createImageData(srcImageData.width, srcImageData.height))
}

/**
 * slower
 */
export function cloneBuiltin(srcImageData: ImageData) {
  const srcWidth = srcImageData.width,
    srcHeight = srcImageData.height,
    canvas = new OffscreenCanvas(srcWidth, srcHeight),
    context = getCanvas2dContext(canvas)

  canvas.width = srcWidth
  canvas.height = srcHeight

  if (!context) {
    throw new Error('Typescript claims context could be null')
  }
  context.putImageData(srcImageData, 0, 0)
  const dstImageData = context.getImageData(0, 0, srcWidth, srcHeight)

  canvas.width = 0
  canvas.height = 0

  return dstImageData
}

export function copyImageData(srcImageData: ImageData, dstImageData: ImageData) {
  const srcPixels = srcImageData.data
  const dstPixels = dstImageData.data
  let srcLength = srcPixels.length

  while (srcLength--) {
    dstPixels[srcLength] = srcPixels[srcLength]
  }

  return dstImageData
}
