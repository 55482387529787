import calculateSelectedPositionEnts from '../entity/calculateSelectedPositionEnts'
import { SelectedToolId } from '../map_editor/SelectedToolId.enum'
import type { Engine } from './Engine.type'

export default function resetEngineToDefaultSelectedTool(engine: Engine) {
  // console.log('resetEngineToDefaultSelectedTool')
  // console.log('engine.draftMove = null')
  engine.draftMove = null
  engine.selectedTool = SelectedToolId.Inspect
  engine.selectedPosition = null
  calculateSelectedPositionEnts(engine)
}
