import getUnitDefenseAtPosition from '../entity/getUnitDefenseAtPosition'
import type { Entity } from '../entity/index'
import type { EntityGridXY } from './EntityGridXY.type'
import createLazyReadOnlyGridXY from './createLazyReadOnlyGridXY'

type DefenseValueOrNull = number | null

export default function createLazyDefenseValueGrid(
  // full state width
  gridWidth: number,
  // full state height
  gridHeight: number,
  // full state ents as GridXY
  grid: EntityGridXY,
  // unit attempting to move
  unit: Entity
) {
  const lazyCostGrid = createLazyReadOnlyGridXY<DefenseValueOrNull>(
    gridWidth,
    gridHeight,
    function costGridGetter(x: number, y: number): DefenseValueOrNull {
      const positionStack = grid?.[x]?.[y]
      if (positionStack) {
        return getUnitDefenseAtPosition(unit, positionStack)
      }
      return null
    }
  )
  return lazyCostGrid
}
