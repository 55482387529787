
const ls = window.localStorage

export default ls

export function getJsonOrNullFromLocalStorage<T>(key: string): T | null {
  const value = ls.getItem(key)
  // console.log(`getJsonOrNullFromLocalStorage.ls[key=${key}]`, value)
  if (value) {
    try {
      return JSON.parse(value)
    } catch (error) {
      console.error(error)
    }
  }
  return null
}
