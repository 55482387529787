// export type DisabledProps = typeof disabledProps
// export const disabledProps = {
//   disabled: true,
//   classList: { disabled: true },
//   ariaDisabled: true,
//   style: {
//     cursor: 'not-allowed',
//   },
// }

// export type NotDisabledProps = typeof notDisabledProps
// export const notDisabledProps = {
//   disabled: void 0,
//   classList: {},
//   ariaDisabled: void 0,
//   style: {},
// }

// export default function disabled(value : boolean) {
//   // console.log('disabled', !!value, value)
//   return value ? disabledProps : notDisabledProps
// }

export function disableElement(elem: HTMLElement): void {
  ;(elem as HTMLButtonElement).disabled = true
  elem.ariaDisabled = 'true'
  elem.classList.add('disabled')
  elem.style.cursor = 'not-allowed'
}
export function enableElement(elem: HTMLElement): void {
  elem.removeAttribute('disabled')
  elem.removeAttribute('ariaDisabled')
  elem.style.removeProperty('cursor')
  elem.classList.remove('disabled')
}
