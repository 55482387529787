import { A } from '@solidjs/router'
import { Component, Show } from 'solid-js'
import preventDefault from '../../lib/dom/event/prevent_default'
import preventCursorSelectText from '../../lib/dom/event/prevent_mouse_select_text'
import stopImmediatePropagation from '../../lib/dom/event/stopImmediatePropagation'
import createCornerWidgetEngineSignal from '../../rx/shared/CornerWidget/createCornerWidgetEngineSignal'
import createIsModalMenuOpenSignal from '../../rx/shared/ModalMenu/createIsModalMenuOpenSignal'
import createAuthProfileSignal from '../../rx/shared/profile/createAuthProfileSignal'
import EngineContext from '../EngineContext'
import GameActionLogListModal from '../GameActionLogListModal'
import GamePlayersListModal from '../GamePlayersListModal'
import BreakpointCardWidget from '../Hud/BreakpointCardWidget'
import ZoomCardWidget from '../Hud/ZoomCardWidget'
import ShowAvatar from '../ShowAvatar'
import DraftMoveMenuWidget from './DraftMoveMenuWidget'
import FactoryStoreCornerWidget from './FactoryStoreCornerWidget'
import OtherTurnPlayerCornerWidget from './OtherTurnPlayerCornerWidget'
import PlayerCornerWidget from './PlayerCornerWidget'
import TopLeftEngineToolCornerWidget from './TopLeftEngineToolCornerWidget'
import YourTurnModalCornerWidget from './YourTurnModalCornerWidget'

const TheCornerWidget: Component = () => {
  const [authProfile] = createAuthProfileSignal()
  const [isMenuOpen, setIsMenuOpen] = createIsModalMenuOpenSignal()
  const handleClickToggleIsMenuOpen = (event: Event) => {
    setIsMenuOpen(!isMenuOpen())
    preventDefault(event)
  }
  // const closeMenu = () => setIsMenuOpen(false)

  // useBeforeLeave(closeMenu)

  const [getEngine] = createCornerWidgetEngineSignal()

  return <div
    ref={(elem) => {
      preventCursorSelectText(elem)
    }}
    class="cwr"
    onWheel={stopImmediatePropagation}
    onMouseUp={stopImmediatePropagation}
    onMouseDown={stopImmediatePropagation}
    onClick={stopImmediatePropagation}
    onContextMenu={stopImmediatePropagation}
    onMouseMove={stopImmediatePropagation}
  >
    <div class="cwrs">
      <div class="cw-row">
        <Show when={getEngine()}>{(engine) => <>
          <EngineContext.Provider value={engine()}>
            <FactoryStoreCornerWidget />
            <OtherTurnPlayerCornerWidget />
            <DraftMoveMenuWidget />
            <GamePlayersListModal />
            <GameActionLogListModal />
            <ZoomCardWidget />
            <BreakpointCardWidget />
          </EngineContext.Provider>
        </>}</Show>
      </div>
    </div>
    <div class="cwre">
      <div class="cw-row">
        <A href="/auth/profile"
          class="cw-pp"
          onClick={handleClickToggleIsMenuOpen}
        >
          <ShowAvatar
            profile={authProfile()}
            size={80}
          // loading={profile}
          />
        </A>
        <Show when={getEngine()}
          children={(engine) => <EngineContext.Provider value={engine()}>
            <YourTurnModalCornerWidget />
            <PlayerCornerWidget />
          </EngineContext.Provider>
          } />
      </div>
      <div class="cw-row">
        <Show when={getEngine()}
          children={(engine) => <EngineContext.Provider value={engine()}>
            <TopLeftEngineToolCornerWidget />
          </EngineContext.Provider>
          } />
      </div>
    </div>
  </div>
}

export default TheCornerWidget
