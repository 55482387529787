import type { Nullable } from '../../typescript'
import type { HasID } from './has_id'

export default function findById<T extends HasID>(list: Array<T>, id: Nullable<HasID['id']>): T | null {
  if (id) {
    for (let index = 0; index < list.length; index++) {
      const item = list[index]
      if (item.id === id) {
        return item
      }
    }
  }
  return null
}
