import { Nullable } from '../../../typescript'
import { Engine } from '../engine/Engine.type'
import { Entity } from '../entity'
import findById from '../findById'
import { HasPlayerId } from './has_player_id'
import { Player } from './Player.type'

export default function getEntityPlayer(engine: Engine, entity: Entity): Nullable<Player> {
  return findById(engine.state.players, (entity as HasPlayerId).player_id)
}
