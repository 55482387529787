const M = Math

export const min = M.min
export const max = M.max
export const abs = M.abs
export const sin = M.sin
export const cos = M.cos
export const tan = M.tan
export const atan2 = M.atan2
export const ceil = M.ceil
export const floor = M.floor
export const pow = M.pow
export const log = M.log
export const sqrt = M.sqrt
export const random = M.random
export const round = M.round
export const sign = M.sign
export const PI = M.PI

// A nice function that returns -1 or 1
// export function randSign() {
//   return floor(random()*2)*-2+1
// }

export function range(start: number, end: number, step: number = 1): number[] {
  const length = floor((end - start) / step) + 1
  return Array.from({ length }, (_, index) => start + index * step)
}

export function parseIntOrNull(value: string | number | null | undefined): number | null {
  const parsedValue = parseInt(value as string, 10)
  return isNaN(parsedValue) ? null : parsedValue
}