import { InferOutput, nullable, optional, variant } from 'valibot'
import { entitySchemaList } from './entityTypeMetaList.generated'

/**
 * Entity Union
 */
export const EntitySchema = variant('etype_id', entitySchemaList)

export type Entity = InferOutput<typeof EntitySchema>

export const NullableEntitySchema = nullable(EntitySchema)

export type NullableEntity = InferOutput<typeof NullableEntitySchema>

export const OptionalNullableEntitySchema = optional(NullableEntitySchema)

export type OptionalNullableEntity = InferOutput<typeof OptionalNullableEntitySchema>
