import { createEffect, on } from "solid-js"
import type { Engine } from "../../../core/engine/Engine.type"
import type { LayerMeta } from "../../../core/layer_meta"
import { floor, max, PI } from "../../../core/util/math"
import { getCanvas2dContext } from "../../get_canvas_2d_context"

const RED_COLOR = 'red'

export default function createRenderBlockedTargetIconCanvasBuffer (engine : Engine, lm: LayerMeta, buffer: OffscreenCanvas) {
  createEffect(on(() => engine.viewCtx.tile_size, (tile_size: number) => {
    const sWidth = tile_size * 2
    const sHeight = tile_size * 2
    buffer.width = sWidth
    buffer.height = sHeight
    lm.until = 0

    const ctx = getCanvas2dContext(buffer)
    if (ctx) {

      ctx.clearRect(0, 0, sWidth, sHeight)

      // console.log('attackPosition', attackPosition)
      // const sWCut1 = (sWidth - tile_size) / 2
      // const sHCut1 = (sHeight - tile_size) / 2
      const sWCut2 = (sWidth - tile_size) * (3 / 8)
      const sHCut2 = (sHeight - tile_size) * (3 / 8)
      const radius = floor(tile_size * 0.75)
      ctx.strokeStyle = RED_COLOR
      ctx.lineWidth = max(2, floor(tile_size / 16))
      // console.log('ctx.lineWidth', ctx.lineWidth)
      ctx.beginPath()
      ctx.arc(tile_size, tile_size, radius, 0, PI * 2)
      ctx.stroke()

      ctx.beginPath()

      ctx.moveTo(sWCut2, sHCut2)
      ctx.lineTo(sWidth - sWCut2, sHeight - sHCut2)

      ctx.stroke()
    }
  }))
}